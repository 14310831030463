import AppFooter from "components/layout/AppFooter"
import React from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { useUrlQuery } from "services/utility/hooks/useURL"
import { useSelector } from "react-redux"
import { getUserActiveState } from "redux/reducers/appSlice"

export default function AuthFlowLayout() {
	const isActive = useSelector(getUserActiveState)
	const location = useLocation()
	const navigation = useNavigate()

	const { redirect } = useUrlQuery()

	React.useEffect(() => {
		return () => {
			if (!redirect && redirect !== false) {
				if (isActive) {
					navigation("/dashboard")
				}
			}
		}
	}, [location])

	return (
		<>
			<Outlet />
			<AppFooter />
		</>
	)
}
