import DashDesktopContainer from "components/DashDesktopContainer"
import React from "react"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { merchantRegValidatorScheme } from "libs/validator/miscValidators"
import { useMutation } from "react-query"
import { CreateMerchantAccount } from "services/Api/merchant/merchant.apis"
import {
	ShowInfo,
	ShowServerErrors,
	ShowSuccess
} from "services/utility/Utils/_toaster"
import { useNavigate } from "react-router-dom"
import ShowSpinner from "components/misc/Spinner"
type InitValsProps = {
	business_name: string
	min_investment_amount: string
	max_investment_amount: string
}
export default function BecomeAMerchant() {
	const router = useNavigate()
	const InitFormData: InitValsProps = {
		business_name: "",
		min_investment_amount: "",
		max_investment_amount: ""
	}

	const createMerchantAccountApi = useMutation(CreateMerchantAccount, {
		onSuccess(res: any) {
			if (res?.status) {
				ShowSuccess(
					"Merchant Account",
					`${res?.message}. ${res?.data?.merchant_code} is your merchant registration code.`
				)
				router("/dashboard")
			} else {
				ShowServerErrors("Error", res)
			}
		}
	})
	const isLoading = createMerchantAccountApi.isLoading

	const _handleSubmit = (data: InitValsProps) =>
		createMerchantAccountApi.mutateAsync(data)

	return (
		<DashDesktopContainer
			title="Become a merchant"
			showTitle={false}
			showOnMobile
		>
			<div className="p-5 bg-white rounded-md">
				<div className="p-4 rounded-tl-md rounded-tr-md bg-gray-300 text-white">
					<h1 className="p-1 font-bold text-3xl text-center text-blue">
						Merchant Registration
					</h1>
				</div>
				<p className="p-3 text-center text-lg">
					Please provide your preferred merchant name to be used for
					transactions
				</p>

				<Formik
					initialValues={InitFormData}
					onSubmit={_handleSubmit}
					validationSchema={merchantRegValidatorScheme}
				>
					{({ handleChange, values }) => (
						<Form className="space-y-6 p-5">
							<div className="form-group">
								<label
									htmlFor="form_businessNameInput"
									className="form-label w-100 input-label"
								>
									Business Name
								</label>

								<Field
									id="form_businessNameInput"
									name="business_name"
									type="text"
									value={values?.business_name}
									onChange={handleChange}
									className="form-control form-control-lg form-control-user"
								/>

								<ErrorMessage
									name="business_name"
									component={"p"}
									className="text-red-600 mt-2 p-2"
								/>
							</div>

							<div className="py-3">
								<h4 className="font-bold text-md text-black">
									Investment Amount
								</h4>
							</div>
							<div className="grid xs:grid-cols-1 md:grid-cols-2 gap-4">
								<div className="">
									<label
										htmlFor="form_minAmountInput"
										className="form-label w-100 input-label"
									>
										Min Amount
									</label>
									<Field
										id="form_minAmountInput"
										name="min_investment_amount"
										type="number"
										step="any"
										value={values?.min_investment_amount}
										onChange={handleChange}
										className="form-control form-control-lg form-control-user"
									/>

									<ErrorMessage
										name="min_investment_amount"
										component={"p"}
										className="text-red-600 mt-2 p-2"
									/>
								</div>

								<div className="">
									<label
										htmlFor="form_maxAmount"
										className="form-label w-100 input-label"
									>
										Max Amount
									</label>
									<Field
										id="form_maxAmount"
										name="max_investment_amount"
										type="number"
										step="any"
										value={values?.max_investment_amount}
										onChange={handleChange}
										className="form-control form-control-lg form-control-user"
									/>

									<ErrorMessage
										name="max_investment_amount"
										component={"p"}
										className="text-red-600 mt-2 p-2"
									/>
								</div>
							</div>

							<button
								disabled={isLoading}
								className=" bg-blue-500 disabled:bg-blue-500/50 mb-3 inline-block w-full rounded p-3  text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
								type="submit"
								data-te-ripple-init
								data-te-ripple-color="light"
							>
								{isLoading && <ShowSpinner />}
								{!isLoading && "Submit"}
							</button>
						</Form>
					)}
				</Formik>

				<div className="my-4">
					<h2 className="font-bold text-2xl p-1 text-red-400">NOTE:</h2>
					<p className="p-1 text-gray-500">
						Every other information will be drawn from your profile information.
						You are not permitted to change the business name once submitted,
						your investment amount determines the user to be merged with you for
						transaction.
					</p>
				</div>
			</div>
		</DashDesktopContainer>
	)
}
