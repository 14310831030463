import CardNavigator, { CardNavigatorInterface } from "components/CardNavigator"
import BKCustomSelect, { BKCustomSelectItems } from "components/input/BKCustomSelect"
import React from "react"
import QRCode from "react-qr-code"
import { useLoaderData, useNavigate, useParams } from "react-router-dom"
import { ShowInfo } from "services/utility/Utils/_toaster"

interface SelectedNetworkInterface {
	address: string
	asset_id: string
	confirmation: number
	expire_at: string
	minimum_deposit: string
	network_id: string
	network_name: string
	dest_tag: string
}

export default function WalletReceiveCoin({ returnLink, showHistory }: CardNavigatorInterface) {
	const receiveCoinAsset: any = useLoaderData()
	const [selectedNetwork, setSelectedNetwork] = React.useState<SelectedNetworkInterface | null>(null)
	const navigate = useNavigate()

	const { assetId } = useParams()

	const defaultNetwork: SelectedNetworkInterface = receiveCoinAsset?.data?.network[0]

	const networks = receiveCoinAsset?.data?.network

	const handleUserRefLinkCopy = () => {
		navigator.clipboard.writeText(selectedNetwork ? selectedNetwork?.address : defaultNetwork?.address)

		ShowInfo("Success", "Address copied")
	}

	const handleNetworkSelect = (nw: BKCustomSelectItems) => {
		if (Array.isArray(networks)) {
			const network = networks?.find(network => network?.network_id === nw?.value)
			setSelectedNetwork(network)
		}
	}

	const handleDoneBtn = () => navigate("/dashboard/spot-wallet")

	let networkOpts: BKCustomSelectItems[] = []

	networkOpts = networks?.map((network: any) => ({
		name: network?.network_name,
		value: network?.network_id
	}))

	return (
		<CardNavigator returnLink={returnLink} historyParams={assetId} showHistory={showHistory}>
			<section>
				<h3 className="mb-3">
					<strong>Receive</strong>
				</h3>
				<form className="mt-3">
					<div className="mb-3">
						<section className="d-flex justify-content-center align-items-center mb-4">
							<QRCode value={selectedNetwork ? selectedNetwork?.address : defaultNetwork?.address} />
						</section>
						<p className="text-center mt-1">Ensure you see the selected asset to this deposit address. this address does not support other asset receival.</p>
					</div>
					<div className="mb-3">
						<span className="text-muted mb-2 d-block">
							<strong>Wallet</strong>
						</span>
						<p className="mt-1">
							<strong>{selectedNetwork ? selectedNetwork?.address : defaultNetwork?.address}</strong>
							<i onClick={handleUserRefLinkCopy} className="far fa-copy ms-3 pointer"></i>
						</p>
					</div>
					<div className="mb-3">
						<span className="text-muted mb-2 d-block">
							<strong>Network</strong>
						</span>
						<BKCustomSelect defaultValue={networkOpts[0]?.value} displayText={networkOpts[0]?.name} options={networkOpts} onChange={handleNetworkSelect} name="network_id" />
					</div>
					<div className="my-4">
						<h4>
							<strong>Details</strong>
						</h4>
						<div className="table-responsive">
							<table className="table">
								<tbody>
									<tr>
										<td>
											<strong>Minimum Deposit</strong>
											<br />
										</td>
										<td>{selectedNetwork ? selectedNetwork?.minimum_deposit : defaultNetwork?.minimum_deposit}</td>
									</tr>
									<tr>
										<td>
											<strong>Expected Network Confirmation</strong>
											<br />
										</td>
										<td>{selectedNetwork ? selectedNetwork?.confirmation : defaultNetwork?.confirmation}</td>
									</tr>
									<tr>
										<td>
											<strong>Expiring Date</strong>
											<br />
										</td>
										<td>{selectedNetwork ? selectedNetwork?.expire_at : defaultNetwork?.expire_at}</td>
									</tr>
									<tr>
										<td>
											<strong>Destination Tag</strong>
											<br />
										</td>
										<td>{selectedNetwork ? selectedNetwork?.dest_tag : defaultNetwork?.dest_tag}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div className="my-4">
						<button onClick={handleDoneBtn} className="btn btn-primary btn-lg w-100 py-3" type="button">
							<strong>Done</strong>
						</button>
					</div>
				</form>
			</section>
		</CardNavigator>
	)
}
