import {
	GetTransactions,
	FetchTransaction,
	GetRecentTransactions,
	FilterTransaction,
	FetchTransactionDetails
} from "./transaction.apis"
export const userTransactionApi = () => ({
	GetTransactions,
	FetchTransaction,
	GetRecentTransactions,
	FilterTransaction,
	FetchTransactionDetails
})
