import React from "react"
import { sleep } from "services/utility/Utils/_sleep"

const wedgeSrc: string = "https://crypto.com/price/static/widget/index.js"

function TopCoinsSection() {
	React.useEffect(() => {
		const scriptTag = document.createElement("script")
		const bodyTag = document.querySelector<HTMLBodyElement>("body")

		sleep(3000).then(() => {
			scriptTag.src = wedgeSrc
			if (bodyTag) {
				bodyTag.append(scriptTag)
			}
		})
	})

	return (
		<section className="bp_home-section-003 bg-white flex flex-column items-center py-4">
			<strong className=" mb-4 text-center text-3xl sm:text-4xl font-link ">Market's Top Coin</strong>
				<h5 className="text-center mb-3 font-montserrat  text-lg sm:text-xl  font-wdth-700">Access a growing list of markets and expand your portfolio</h5>
				<div className="w-full" id="crypto-widget-CoinList" data-transparent="true" data-design="modern" data-coin-ids="1,166,136,382,20,29,2,2180,4002,426"></div>
		</section>
	)
}

export default TopCoinsSection
