import ShowSpinner from "components/misc/Spinner"
import React from "react"
import { useMutation } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import { useAuthApis } from "services/Api/auth/auth.apis.index"
import { sleep } from "services/utility/Utils/_sleep"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { extractToken } from "services/utility/Utils/utilities"
import PasswordInput from "components/PasswordInput"
import { useUrlQuery } from "services/utility/hooks/useURL"
import { useDispatch } from "react-redux"
import { setApiToken } from "redux/reducers/appSlice"

export default function SignupForm() {
	const { CreateAccount } = useAuthApis()
	const dispatcher = useDispatch()
	const { ref } = useUrlQuery()

	const navigate = useNavigate()

	const createAccountApi = useMutation(CreateAccount, {
		onSuccess: (res, params: FormData) => {
			if (res?.status) {
				ShowSuccess("Sign Up", "Your account was successfully created!")

				const apiToken = extractToken(res?.data?.token)

				dispatcher(setApiToken(apiToken))

				sleep(1000).then(() => navigate(`/auth/verify?email=${params.get("email")}`))
			} else ShowServerErrors("Sign Up", res)
		}
	})

	const isCreating = createAccountApi.isLoading

	const handleUserAccountCreation = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e?.target)

		createAccountApi.mutate(formData)
	}
	return (
		<form className="user" onSubmit={handleUserAccountCreation}>
			<div className="form-group">
				<div className="or-divider">
					<hr />
					<span className="or-text">OR</span>
				</div>
				<label className="form-label w-100 input-label">
					<strong>Email</strong>
				</label>
				<input className="form-control form-control-lg form-control-user" type="email" placeholder="Enter a valid email" name="email" />

				{ref && <input defaultValue={ref} className="form-control form-control-lg form-control-user" type="hidden" placeholder="Enter a valid email" name="ref_id" />}
			</div>
			<PasswordInput label="Create Password" />
			<div className="form-check my-2 w-100 d-block py-1">
				<input id="formCheck-1" className="form-check-input" type="checkbox" required />
				<label className="form-check-label" htmlFor="formCheck-1">
					I have read and agree to Beekle{" "}
					<a href="#terms" className="link">
						Terms and Conditions
					</a>
					,{" "}
					<a href="#terms" className="link">
						Privacy Policy
					</a>{" "}
					and <a href="#usage">Usage Conduct</a>.
				</label>
			</div>
			
			<button disabled={isCreating} className="btn btn-primary btn-lg d-block login-btn w-100 bg-blue" type="submit">
				{isCreating && <ShowSpinner color="text-light" />}
				{!isCreating && "Create"}
			</button>

			<small className="form-text my-2 w-100 d-block p-1">
				Already have an account?{" "}
				<Link to="/auth" className="link">
					Log in
				</Link>
			</small>
		</form>
	)
}
