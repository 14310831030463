/**
 * Sleep for n milliseconds
 * @param {number} ms The time in milliseconds to sleep for resolve promise
 */
export const sleep = ms => {
	return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * Delay operation execution
 * @param {function} callback A callback function to be called after timout
 * @param {number} ms The time in ms to delay execution of operation
 */
export const delay = (callback, ms) => new Promise(() => setTimeout(callback, ms))
