import PageInfo from "components/misc/Page"
import ShowSpinner from "components/misc/Spinner"
import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { useMutation } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import { useAuthApis } from "services/Api/auth/auth.apis.index"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"

export default function ForgotPasswordPage() {
	const { ForgotPwdImg, LOGO } = useFlusStores()

	const { ForgetPassword } = useAuthApis()
	const navigate = useNavigate()

	const forgetPasswordApi = useMutation(ForgetPassword, {
		onSuccess: (res, params: FormData) => {
			if (res?.status) {
				ShowSuccess(
					"Forget Password",
					"Success! A link has been sent to your email address for verification."
				)

				navigate(`/auth/forgot-pwd-verify?email=${params.get("email")}`)
			} else ShowServerErrors("Forget Password", res)
		}
	})

	const isProcessing = forgetPasswordApi.isLoading

	const handleUserSubmitAction = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		forgetPasswordApi.mutateAsync(formData)
	}

	return (
		<section>
			<PageInfo titleText="Recover Account Password" />
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-9 col-lg-12 col-xl-10">
						<div className="card o-hidden border-0 my-5">
							<div className="card-body p-0">
								<div className="row">
									<div className="col-lg-6 d-none d-lg-flex p-4 bg-light-blue">
										<div className="d-flex flex-grow-1 justify-content-center align-items-center auth-bg-image">
											<img src={ForgotPwdImg} alt="" width="350" />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="p-5">
											<div className="text-center">
												<img src={LOGO} alt="Beekle" width="50" />
												<h4 className="text-dark mb-4 mt-3">
													Reset your{" "}
													<span className="text-blue">
														<strong>Beekle</strong>
													</span>{" "}
													Password
												</h4>
												<p className="text-start p-2 bg-light-blue">
													Enter your registered email address
												</p>
											</div>
											<form className="user" onSubmit={handleUserSubmitAction}>
												<div className="form-group mb-3">
													<label className="form-label w-100 input-label">
														<strong>Email</strong>
													</label>
													<input
														className="form-control form-control-lg form-control-user"
														type="email"
														placeholder="Enter your registered email"
														name="email"
														required
													/>
												</div>
												<button
													className="btn btn-primary btn-lg d-block login-btn w-100 bg-blue"
													type="submit"
													disabled={isProcessing}
												>
													{!isProcessing && "Submit"}
													{isProcessing && <ShowSpinner color="text-light" />}
												</button>
												<small className="form-text my-2 w-100 d-block p-1">
													Already have an account?{" "}

													<Link to="/auth" className="link">
														Log in
													</Link>
													
												</small>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
