import { useFlusStores } from "@bilmapay/react-flux-storage"
import CustomModal from "components/misc/CustomModal"
import { APPEAL_MODAL } from "flus/constants/modals.id.const"
import React from "react"
import { userTicketApis } from "services/Api/ticket/ticket.index"
import { useMutation } from "react-query"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import ShowSpinner from "components/misc/Spinner"
import { useSelector } from "react-redux"
import { getAppealOrderToken } from "redux/reducers/dashboardSlice"
const _formRef = React.createRef<HTMLFormElement>()
const dismissModalRef = React.createRef<HTMLSpanElement>()

export default function AppealModal() {
	const _appealOrderToken = useSelector(getAppealOrderToken)

	const { CreateAppeal } = userTicketApis()

	const createAppealApi = useMutation(CreateAppeal, {
		onSuccess: (res: any) => {
			if (res?.status) {
				ShowSuccess("Success", res?.message)

				if (dismissModalRef?.current) {
					dismissModalRef?.current?.click()
				}

				if (_formRef?.current) {
					_formRef?.current?.reset()
				}
			} else ShowServerErrors("Error", res)
		}
	})

	const isCreating = createAppealApi.isLoading

	const handleFormSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		createAppealApi.mutateAsync(formData)
	}

	return (
		<CustomModal modalId={APPEAL_MODAL} title="Appeal">
			<form className="user" onSubmit={handleFormSubmit} ref={_formRef}>
				{_appealOrderToken && <input type="hidden" name="order_token" value={_appealOrderToken} onChange={() => {}} />}
				<div className="form-group mb-3">
					<label className="form-label w-100 input-label">
						<strong>Subject</strong>
					</label>
					<input className="form-control form-control-lg form-control-user" type="text" placeholder="Enter Appeal Subject" name="subject" />
				</div>

				<div className="form-group mb-3">
					<label className="form-label w-100 input-label">
						<strong>Message</strong>
					</label>
					<textarea className="form-control form-control-lg form-control-user" placeholder="Enter appeal message here" name="message"></textarea>
				</div>

				<span ref={dismissModalRef} data-bs-dismiss="modal">
					<hr />
				</span>
				<button disabled={isCreating} className="btn btn-primary btn-lg d-block login-btn w-100 bg-blue mt-4" type="submit">
					{!isCreating && "Create Appeal"}
					{isCreating && <ShowSpinner color="text-light" />}
				</button>
			</form>
		</CustomModal>
	)
}
