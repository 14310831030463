import { useFlusStores } from "@bilmapay/react-flux-storage"
import DashDesktopContainer from "components/DashDesktopContainer"
import React from "react"
import { Link, useLoaderData } from "react-router-dom"
import { userNotificationApis } from "services/Api/notification/notify.index"
import { uniqueId } from "services/utility/Utils/Utils"
import { useMutation } from "react-query"
import { ShowServerErrors } from "services/utility/Utils/_toaster"
import ShowSpinner from "components/misc/Spinner"
import classNames from "classnames"

interface NotificationsItem {
	date?: string
	label?: string
	link: string
	message?: string
	notification_id: string
	status?: string
	title?: string
}

type NotificationsProps = { data: Array<NotificationsItem> }

export default function Notification() {
	const { LOGO } = useFlusStores()

	const notifications: any = useLoaderData()
	const { data }: NotificationsProps = notifications

	const { UpdateNotification } = userNotificationApis()

	const updateNotifyStatusApi = useMutation(UpdateNotification, {
		onSuccess: (res: any, params: FormData | any) => {
			if (res?.status) {
				if (params) {
					window.location.href = params.get("path")
				}
			} else ShowServerErrors("Error", res)
		}
	})

	const isUpdateing = updateNotifyStatusApi.isLoading

	const handleNotificationUpdate = (notifyId: string, path: string) => {
		const formData = new FormData()

		formData.append("notification_id", notifyId)
		formData.append("path", path)

		updateNotifyStatusApi.mutateAsync(formData)
	}

	return (
		<DashDesktopContainer title="Notifications" showOnMobile>
			<section className="my-4 p-4 bg-white">
				{isUpdateing && <ShowSpinner />}
				<ul className="list-group list-group-flush">
					{data && Array.isArray(data) && (
						<>
							{data?.length > 0 && (
								<>
									{data.map(notify => (
										<li key={uniqueId()} className={classNames({ "list-group-item": true, bk_notif_new: notify?.status === "0" })}>
											<div className="row">
												<div className="col-3 col-sm-2 col-md-2 col-lg-1 col-xl-1 col-xxl-1">
													<img src={LOGO} alt="Beekle" width="50" />
												</div>
												<div className="col">
													<h6 className="text-muted">
														<strong>{notify?.title}</strong>
													</h6>
													<p>
														<span className="me-2" dangerouslySetInnerHTML={{ __html: notify?.message as string }}></span>
														<Link
															onClick={e => {
																e.preventDefault()

																handleNotificationUpdate(notify?.notification_id, `${notify?.link?.includes("?") ? `${notify?.link}?&s=${notify?.status}` : `?s=${notify?.status}`}`)
															}}
															to={`#`}
															className="text-primary"
														>
															{notify?.label}
														</Link>
													</p>
													<small className="text-muted">{notify?.date}</small>
												</div>
											</div>
										</li>
									))}
								</>
							)}
						</>
					)}
				</ul>
			</section>
		</DashDesktopContainer>
	)
}
