import { createSlice } from "@reduxjs/toolkit"
import { FindAssetAPIObjectProps } from "interfaces/PropsTypes"
import { AppState } from "redux/appGlobalStore"
import { PaymentMethodsInterface, TransactionProps } from "types/app.types"

type WalletSliceState = {
	transferAsset?: FindAssetAPIObjectProps
	paymentMethodList?: Array<PaymentMethodsInterface>
	fundingWalletCoins?: any
	spotwalletIcons?: any
	assetTrans?: Array<TransactionProps>
}
const initialState: WalletSliceState = {}

export const walletSlice = createSlice({
	name: "wallet",
	initialState,
	reducers: {
		setTransferAsset(state, action) {
			state.transferAsset = action?.payload
		},

		setPaymentMethods(state, action) {
			state.paymentMethodList = action?.payload
		},

		setAssetTransactions(state, action) {
			state.assetTrans = action?.payload
		},

		setFundingWalletCoins(state, action) {
			state.fundingWalletCoins = action?.payload
		},

		setSpotWalletCoins(state, action) {
			state.spotwalletIcons = action?.payload
		}
	}
})

export const { setTransferAsset, setPaymentMethods, setAssetTransactions, setFundingWalletCoins, setSpotWalletCoins } = walletSlice.actions

export const getTransferAsset = (state: AppState) => state?.wallet?.transferAsset
export const getPaymentMethods = (state: AppState) => state?.wallet?.paymentMethodList
export const getAssetTransactions = (state: AppState) => state?.wallet?.assetTrans
export const getFundingWalletCoins = (state: AppState) => state?.wallet?.fundingWalletCoins
export const getSpotWalletCoins = (state: AppState) => state?.wallet?.spotwalletIcons
