import PasswordInput from "components/PasswordInput"
import ShowSpinner from "components/misc/Spinner"
import { ErrorMessage, Field, Formik } from "formik"
import React from "react"
import { useMutation } from "react-query"
import { Form, useNavigate } from "react-router-dom"
import { ReleaseDepositRequest } from "services/Api/merchant/merchant.apis"
import { sleep } from "services/utility/Utils/_sleep"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"

import * as yup from "yup"

const pinValidatorScheme = yup.object().shape({
	pin: yup.string().required("Please enter your account PIN")
})

type InitFormDataProps = {
	pin: string
	txn_id?: string
}

type ReleaseDepositFormProps = {
	transId?: string
}
export default function ReleaseDepositForm({
	transId
}: ReleaseDepositFormProps) {
	const depositReleaseApi = useMutation(ReleaseDepositRequest, {
		onSuccess(res: any, variables, context) {
			if (res?.status) {
				sleep(900).then(() => {
					window.location.reload()
				})

				ShowSuccess("Success", res?.message)
			} else ShowServerErrors("Error", res)
		}
	})
	const isLoading = depositReleaseApi.isLoading

	const _handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()
		const formData = new FormData(e.target)
		formData?.append("txn_id", transId as string)

		depositReleaseApi.mutateAsync(formData)
	}

	return (
		<>
			<form className="space-y-6 xs:p-3 md:p-5" onSubmit={_handleSubmit}>
				<div className="grid grid-cols-1 gap-4">
					<div className="mb-4">
						<PasswordInput inputName="pin" label="Enter PIN" />
					</div>
				</div>

				<button
					disabled={isLoading}
					className="bg-gradient-to-r from-blue-500 to-blue-500 disabled:from-blue-500/50 disabled:to-blue-500/50 mb-3 inline-block w-fit rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
					type="submit"
					data-te-ripple-init
					data-te-ripple-color="light"
				>
					{isLoading && <ShowSpinner />}
					{!isLoading && "Submit"}
				</button>
			</form>
		</>
	)
}
