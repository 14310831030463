import classNames from "classnames"
import ShowSpinner from "components/misc/Spinner"
import React from "react"
import { useMutation } from "react-query"
import { useLoaderData } from "react-router-dom"
import { userProfileApi } from "services/Api/profile/profile.index"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"

const profileImagePreview = React.createRef<HTMLImageElement>()
const profileImageFile = React.createRef<HTMLInputElement>()
const previewUploadedFileName = React.createRef<HTMLLIElement>()
const uploadProfilePhotoForm = React.createRef<HTMLFormElement>()

export default function ProfileHeader() {
	const userProfile: any = useLoaderData()
	const { data }: { data?: Object | any } = userProfile

	const { UpdateProfilePhoto } = userProfileApi()

	const updateProfilePhotoApi = useMutation(UpdateProfilePhoto, {
		onSuccess: (res: any) => {
			if (res?.status) {
				ShowSuccess("Profile", "Profile photo updated successfully!")
			} else ShowServerErrors("Profile", res)
		}
	})

	const isUploadingImage = updateProfilePhotoApi.isLoading

	const handleProfileUploadForm = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		updateProfilePhotoApi.mutateAsync(formData)
	}

	const handleProfileImageSelection = () => {
		if (profileImageFile?.current) {
			profileImageFile.current.click()
		}
	}

	React.useEffect(() => {
		/* handle file previewing */
		const fileInput = document.getElementById("upl__profile_photo")

		if (fileInput) {
			fileInput.addEventListener("change", function (e: any) {
				const profilePhoto = e.target.files[0]

				if (previewUploadedFileName?.current) {
					previewUploadedFileName.current.innerText = profilePhoto?.name
				}

				if (profilePhoto) {
					const fileReader = new FileReader()

					fileReader.onload = (ev: any) => {
						if (profileImagePreview?.current) {
							profileImagePreview.current.src = ev.target?.result
						}
					}
					fileReader.readAsDataURL(profilePhoto)
				}
			})
		}
	})

	return (
		<header className="mb-4">
			<form ref={uploadProfilePhotoForm} onSubmit={handleProfileUploadForm}>
				<div className="row">
					<div className="col-lg-3 d-flex align-items-center justify-content-center">
						<section className="profile-avatar-sec">
							<img ref={profileImagePreview} className="rounded-circle img-fluid m-3 bk__userProfileAvatar" width="150" height="150" src={!data?.photo?.includes("uploads/placeholder") ? data?.photo : data?.avatar} alt="" />
							<span className="badge rounded-pill bg-danger profile-status-badge p-2">
								<i className="far fa-times-circle"></i>
							</span>

							<input ref={profileImageFile} type="file" name="photo" id="upl__profile_photo" accept="image/*" hidden />
						</section>
					</div>
					<div className="col d-flex justify-content-center align-items-center">
						<section className="w-100">
							<ul className="list-inline d-flex w-75 trigger-profile-upl w-100 pointer ">
								<li ref={previewUploadedFileName} className="list-inline-item text-muted w-100 px-2 d-flex align-items-center justify-content-start pointer border-end-0 m-0 border-top border-bottom border-start " onClick={handleProfileImageSelection}>
									Choose File
								</li>
								<li className="list-inline-item text-muted px-2 d-flex align-items-center justify-content-start m-0 border-top border-bottom">
									<i className="fas fa-cloud-upload-alt icon-lg"></i>
								</li>
								<li>
									<button className="list-inline-item text-white p-2 bg-blue px-4 justify-content-center align-items-center" type="submit" style={{ borderColor: "transparent" }}>
										<strong>
											{!isUploadingImage && "Upload"}
											{isUploadingImage && <ShowSpinner size={20} color="text-light" />}
										</strong>
									</button>
								</li>
							</ul>

							{/* data?.rating */}
							<section className="d-flex justify-content-center align-content-center">
								<i
									className={classNames({
										"fa fa-star rating-star": true,
										stared: parseInt(data?.rating) >= 1
									})}
								></i>
								<i
									className={classNames({
										"fa fa-star rating-star": true,
										stared: parseInt(data?.rating) >= 2
									})}
								></i>
								<i
									className={classNames({
										"fa fa-star rating-star": true,
										stared: parseInt(data?.rating) >= 3
									})}
								></i>
								<i
									className={classNames({
										"fa fa-star rating-star": true,
										stared: parseInt(data?.rating) >= 4
									})}
								></i>
								<i
									className={classNames({
										"fa fa-star rating-star": true,
										stared: parseInt(data?.rating) === 5
									})}
								></i>
							</section>
						</section>
					</div>
				</div>
			</form>
		</header>
	)
}
