// icon:ecommerce_wallet | Linea Iconset https://linea.io/ | Benjamin Sigidi
import * as React from "react"

function IconEcommerceWallet(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 64 64" fill="currentColor" height="1em" width="1em" {...props}>
			<path fill="none" stroke="currentColor" strokeMiterlimit={10} strokeWidth={2} d="M61 44v11H1V15h60v11M6 9h48v6M6 9a5 5 0 00-5 5" />
			<path fill="none" stroke="currentColor" strokeMiterlimit={10} strokeWidth={2} d="M43.125 26c-4.972 0-9 4.029-9 9a9 9 0 009 9H63V26H43.125z" />
			<path fill="none" stroke="currentColor" strokeMiterlimit={10} strokeWidth={2} d="M47 35 A3 3 0 0 1 44 38 A3 3 0 0 1 41 35 A3 3 0 0 1 47 35 z" />
		</svg>
	)
}

export default IconEcommerceWallet
