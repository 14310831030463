export const authEndpoint = {
	CSRF_TOKEN: "/sanctum/csrf-cookie",
	CREATE_ACCOUNT: "/register",
	LOGIN: "/login",
	LOGOUT: "/logout",
	VERIFY_EMAIL: "/verification",
	CHANGE_VERIFICATION_EMAIL: "/change-email",
	RESEND_LINK: "/resend-verification-link",
	FORGET_PASSWORD: "/forget-password",
	VERIFY_FORGET_PASSWORD: "/verify-reset-password-link",
	RESET_PASSWORD: "/reset-password",
	FINALIZE_GOOGLE_AUTH: "/finish-google-login"
}
