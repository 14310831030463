import { createSlice } from "@reduxjs/toolkit"
import { AppState } from "redux/appGlobalStore"
import { OrderStatusInterface } from "types"

type WithdrawalSliceState = {
	client: {
		steps: "decline" | "completed" | "withdrawal" | "verify_payment" | "awaiting_payment" | "view_pop" | "release_funds"
		timer?: NodeJS.Timer
		data?: OrderStatusInterface
	}
	merchant: {
		steps: "decline" | "completed" | "payment_request" | "payment_details" | "upload_pop" | "awaiting_release" | "escalate"
		timer?: NodeJS.Timer
		data?: null
	}
}

const initialState: WithdrawalSliceState = {
	client: {
		steps: "withdrawal"
	},
	merchant: {
		steps: "payment_request"
	}
}

export const withdrawalSlice = createSlice({
	name: "withdrawal",
	initialState,
	reducers: {
		changeClientWithdrawStep(state, action) {
			state.client.steps = action?.payload
		},

		setClientWithdrawalTimerId(state, action) {
			state.client.timer = action?.payload
		},

		stopClientWithdrawalTimer(state, action) {
			state.client.data = action?.payload

			if (state?.client.timer) {
				window.clearInterval(state?.client?.timer)
			}
		},

		changeMerchantWithdrawStep(state, action) {
			state.merchant.steps = action?.payload
		},

		setMerchantWithdrawalTimerId(state, action) {
			state.merchant.timer = action?.payload
		},

		stopMerchantWithdrawalTimer(state, action) {
			if (state?.merchant.data) state.merchant.data = action?.payload

			if (state?.merchant.timer) {
				window.clearInterval(state?.merchant?.timer)
			}
		}
	}
})

export const {
	setMerchantWithdrawalTimerId,
	stopMerchantWithdrawalTimer,
	stopClientWithdrawalTimer,
	setClientWithdrawalTimerId,
	changeClientWithdrawStep,
	changeMerchantWithdrawStep
} = withdrawalSlice.actions

export const getClientCurrentWithdrawStep = (state: AppState) => state?.withdrawal?.client?.steps
export const getClientWithdrawTimerId = (state: AppState) => state?.withdrawal?.client?.timer
export const getClientWithdrawStatusData = (state: AppState) => state?.withdrawal?.client?.data
export const getMerchantWithdrawData = (state: AppState) => state?.withdrawal?.merchant?.data
export const getMerchanCurrentWithdrawStep = (state: AppState) => state?.withdrawal?.merchant?.steps
