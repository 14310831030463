// icon:trash | Fontawesome https://fontawesome.com/ | Fontawesome
import * as React from "react"

function IconTrash(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 448 512" fill="currentColor" height="1em" width="1em" {...props}>
			<path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64s14.3 32 32 32h384c17.7 0 32-14.3 32-32s-14.3-32-32-32h-96l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32l21.2 339c1.6 25.3 22.6 45 47.9 45h245.8c25.3 0 46.3-19.7 47.9-45L416 128z" />
		</svg>
	)
}

export default IconTrash
