import React from "react"

interface ModalProps {
	children?: React.ReactNode
	title?: string
	uniqueId: string
}
export default function Modal({ title, uniqueId, children }: ModalProps) {
	return (
		<div id={uniqueId} className="modal fade" role="dialog" tabIndex={-1}>
			<div
				className="modal-dialog modal-lg modal-dialog-scrollable modal-fullscreen-sm-down"
				role="document"
			>
				<div className="modal-content">
					<div className="modal-header">
						<h4 className="modal-title">{title && title}</h4>
						<button
							className="btn-close"
							type="button"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div className="modal-body">{children}</div>
					{/* <div className="modal-footer d-flex justify-content-center align-items-center">
						<button className="btn btn-light" type="button" data-bs-dismiss="modal">
							Close
						</button>
					</div> */}
				</div>
			</div>
		</div>
	)
}
