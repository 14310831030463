import ShowSpinner from "components/misc/Spinner"

import { ErrorMessage, Field, Form, Formik } from "formik"
import React from "react"
import { useMutation } from "react-query"
import { useDispatch } from "react-redux"
import { changeClientStep } from "redux/reducers/depositSlice"
import {
	toggleDepositCashBridgePanel,
	toggleWithdrawalCashBridgePanel
} from "redux/reducers/panelsSlice"
import { changeClientWithdrawStep } from "redux/reducers/withdrawalSlice"
import {
	FetchWithdrawalCharges,
	InitiateDeposit,
	InitiateWithdrawal
} from "services/Api/cashbridge/cashbridge.apis"
import { FetchPaymentMethods } from "services/Api/payments/payment.apis"
import { uniqueId } from "services/utility/Utils/Utils"
import { sleep } from "services/utility/Utils/_sleep"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { BankProps } from "types/app.types"

import * as yup from "yup"

const amountValidatorScheme = yup.object().shape({
	amount: yup.string().required("Please enter amount."),
	bank: yup.string().required("Please select your preferred bank.")
})

type InitFormDataProps = {
	amount: string
	bank: string
}

const _receivableRef = React.createRef<HTMLParagraphElement>()
const _chargesRef = React.createRef<HTMLParagraphElement>()

export default function WithdrawalForm() {
	const dispatcher = useDispatch()
	const [banks, setBanks] = React.useState<Array<BankProps>>([])

	const fetchPaymentMethodsApi = useMutation(FetchPaymentMethods, {
		onSuccess(res: any) {
			if (res?.status) {
				setBanks(res?.data)
			}
		}
	})

	const isFetchingBanks = fetchPaymentMethodsApi.isLoading

	React.useEffect(() => {
		if (banks?.length === 0) {
			fetchPaymentMethodsApi.mutateAsync()
		}
	}, [banks?.length])

	const InitFormData: InitFormDataProps = {
		amount: "",
		bank: ""
	}

	const initiateWithdrawalApi = useMutation(InitiateWithdrawal, {
		onSuccess(res: any) {
			if (res?.status) {
				ShowSuccess("Success", res?.message)

				dispatcher(
					toggleWithdrawalCashBridgePanel({
						params: res?.data?.txn_id,
						data: res?.data
					})
				)
				sleep(100).then(() =>
					dispatcher(changeClientWithdrawStep("verify_payment"))
				)
			} else ShowServerErrors("Error", res)
		}
	})
	const isLoading = initiateWithdrawalApi.isLoading

	const _handleSubmit = (values: InitFormDataProps) =>
		initiateWithdrawalApi.mutateAsync(values)

	// ---------------------> [Checking transactions status]

	const fetchChargesApi = useMutation(FetchWithdrawalCharges, {
		onSuccess(res: any) {
			if (res?.status) {
				if (_receivableRef?.current && _chargesRef?.current) {
					_receivableRef.current.innerText = `Receiable Amount: ${res?.data?.receive_amount}`
					_chargesRef.current.innerText = `Charges: ${res?.data?.charges}`
				}
			}
		}
	})
	const isFetchingCharges = fetchChargesApi.isLoading

	const _handleChargesFetch = (_amount: any) =>
		_amount ? fetchChargesApi.mutateAsync({ amount: _amount }) : null

	return (
		<>
			<Formik
				initialValues={InitFormData}
				onSubmit={_handleSubmit}
				validationSchema={amountValidatorScheme}
			>
				{({ handleChange, values }) => (
					<Form className="space-y-6 xs:p-3 md:p-5">
						<div className="grid grid-cols-1 gap-4">
							<div className="form-group">
								<label
									htmlFor="form_amountInput"
									className="form-label w-100 input-label"
								>
									Withdraw Amount
								</label>
								<Field
									id="form_amountInput"
									name="amount"
									type="number"
									step="any"
									value={values?.amount}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										handleChange(e)
										_handleChargesFetch(e.target.value)
									}}
									className="form-control form-control-lg form-control-user"
								/>
								<ErrorMessage
									name="amount"
									component={"p"}
									className="text-red-600 mt-2 p-2"
								/>
								<p className="text-blue-500 mt-2" ref={_receivableRef}>
									Receivable:{" "}
								</p>
								<p className="text-gray-500" ref={_chargesRef}>
									Charges:{" "}
								</p>
							</div>

							<div className="relative mb-3">
								<Field
									id="form_bankInput"
									name="bank"
									as="select"
									value={values?.bank}
									onChange={handleChange}
									className="form-control form-control-lg form-control-user"
								>
									<option value="">Select Bank</option>
									{banks?.map(bank => (
										<option key={uniqueId()} value={bank?.payment_method_id}>
											{bank?.bank_name}({bank?.account_number})
										</option>
									))}
								</Field>

								<ErrorMessage
									name="bank"
									component={"p"}
									className="text-red-600 mt-2 p-2"
								/>
							</div>
						</div>

						<button
							className="bg-blue-500 disabled:bg-blue-500/50 mb-3 inline-block w-fit rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
							type="submit"
							data-te-ripple-init
							data-te-ripple-color="light"
						>
							{!isLoading && "Submit"}
							{isLoading && <ShowSpinner />}
						</button>
					</Form>
				)}
			</Formik>
		</>
	)
}
