import AuthFlowLayout from "layouts/AuthFlowLayout"
import DefaultLayout from "layouts/DefaultLayout"
import OnboardingLayout from "layouts/OnboardingLayout"
import AboutPages from "views/about/AboutPages"
import ContactUsPage from "views/contactus/ContactUsPage"
import ErrorPage from "views/error/ErrorPage"
import FaqsPage from "views/faqs/FaqsPage"
import CreateNewPassword from "views/forget-pwd/CreateNewPassword"
import ForgotPasswordPage from "views/forget-pwd/ForgotPassword"
import HomePage from "views/home/HomePage"
import LoginPage from "views/login/LoginPage"
import OnboardingInit from "views/onboarding"
import LocationInfo from "views/onboarding/locationinfo/LocationInfo"
import ContactInfo from "views/onboarding/contactinfo/ContactInfo"
import PersonalInfo from "views/onboarding/personalinfo/PersonalInfo"
import ReviewInfo from "views/onboarding/reviewinfo/ReviewInfo"
import SignupPage from "views/signup/SignupPage"
import ChangeVerificationEmail from "views/verification/ChangeVerificationEmail"
import LinkPasswordVerificationPage from "views/verification/LinkPasswordVerificationPage"
import LinkVerificationPage from "views/verification/LinkVerficationPage"
import PasswordVerificationPage from "views/verification/PasswordVerificationPage"
import VerificationPage from "views/verification/VerificationPage"
import GoogleAuth from "views/google/GoogleAuth"
import DashboardLayout from "layouts/DashboardLayout"
import Dashboard from "views/dashboard"
import EditProfile from "views/dashboard/profile/Edit"
import Settings from "views/dashboard/profile/Settings"
import AddPaymentMethod from "views/dashboard/profile/AddPaymentMethod"
import InviteFriend from "views/dashboard/profile/InviteFriend"
import UserVerification from "views/dashboard/verification/UserVerification"
import IDVerification from "views/dashboard/verification/IDVerification"
import PORVerification from "views/dashboard/verification/PORVerification"
import { userProfileApi } from "services/Api/profile/profile.index"
import { userPaymentMethodApis } from "services/Api/payments/payment.index"
import { userKYCVerificatinApi } from "services/Api/kyc/kyc.index"
import ProtectedRoute from "components/routes/ProtectedRoute"
import AuthRoutes from "components/routes/AuthRoutes"
import { WalletRoutes } from "views/dashboard/dashboard.routes"
import { FetchCoinAssets, FetchSpotWallet } from "services/Api/wallet/wallet.apis"
import TicketRoom from "views/dashboard/dispute-zone/TicketRoom"
import { merchantRoutes } from "views/dashboard/merchant/merchant.routes"
import MerchantDashboardLayout from "components/merchant/layout/MerchantDashboardLayout"

import SpotWallet from "views/dashboard/wallet/spotwallet/SpotWallet"

const {FetchProfile, PrefetchProfile} = userProfileApi()
const {FetchBanks} = userPaymentMethodApis()
const {LoadKYCLevels} = userKYCVerificatinApi()

const fetchProfileInfo =  ({params}) => {
	return FetchProfile()
}


const fetchBanks = ({params})  => {
	return FetchBanks()
}
const preProfile = ({params})  => {
	return PrefetchProfile()
}

const loadKYCs = ({params}) => {
	return LoadKYCLevels()
}

const handleAssetListFetching = ({params}) => {
    return FetchCoinAssets()
} 

export const ApplicationRootRoutes = [
	{
		path: "/",
		element: <DefaultLayout />,
		children: [
			{
				index: true,
				element: <HomePage />
			},
			{
				path: "about",
				element: <AboutPages />
			},
			{
				path: "contact-us",
				element: <ContactUsPage />
			},
			{
				path: "faqs",
				element: <FaqsPage />
			}
		],
		errorElement: <ErrorPage />
	},
	{
		path: "/verification",
		element: <LinkVerificationPage />,
		errorElement: <ErrorPage />
	},
	{
		path: "/google-callback/:authToken",
		element: <GoogleAuth />,
		errorElement: <ErrorPage />
	},
	{
		path: "/reset-password",
		element: <LinkPasswordVerificationPage />,
		errorElement: <ErrorPage />
	},
	/* Authentication routes */
	{
		path: "/auth",
		element: (
			<AuthRoutes>
				<AuthFlowLayout />
			</AuthRoutes>
		),
		children: [
			{
				index: true,
				element: (
					<AuthRoutes>
						<LoginPage />
					</AuthRoutes>
				)
			},
			{
				path: "login",
				element: (
					<AuthRoutes>
						<LoginPage />
					</AuthRoutes>
				)
			},
			{
				path: "signup",
				element: (
					<AuthRoutes>
						<SignupPage />
					</AuthRoutes>
				)
			},

			{
				path: "verify",
				element: (
					<AuthRoutes>
						<VerificationPage />
					</AuthRoutes>
				)
			},

			{
				path: "change-email-verify",
				element: (
					<AuthRoutes>
						<ChangeVerificationEmail />
					</AuthRoutes>
				)
			},
			{
				path: "forgot-pwd",
				element: (
					<AuthRoutes>
						<ForgotPasswordPage />
					</AuthRoutes>
				)
			},
			{
				path: "forgot-pwd-verify",
				element: (
					<AuthRoutes>
						<PasswordVerificationPage />
					</AuthRoutes>
				)
			},
			{
				path: "create-new-pwd",
				element: (
					<AuthRoutes>
						<CreateNewPassword />
					</AuthRoutes>
				)
			}
		],
		errorElement: <ErrorPage />
	},
	/* Onboarding */
	{
		path: "/onboard",
		element: <OnboardingLayout />,
		
		children: [
			{
				index: true,
				element: <OnboardingInit />,
				
			},
			{
				path: "personal-info",
				element: <PersonalInfo />,
				loader: preProfile,
			},
			{
				path: "location-info",
				element: <LocationInfo />
			},
			{
				path: "others-info",
				element: <ContactInfo />
			},
			{
				path: "review",
				element: <ReviewInfo />
			}
		],
		errorElement: <ErrorPage />
	},
	/* Dashboards */
	{
		path: "/dashboard",
		element: (
			<ProtectedRoute>
				<DashboardLayout />
			</ProtectedRoute>
		),
		loader:fetchProfileInfo,
		children: [
					/* Dashboard index page */
					{
						index: true,
						element: (
							<ProtectedRoute>
								<SpotWallet />
							</ProtectedRoute>
						),
						loader:FetchSpotWallet
					},
					/* marketplace */
					{
						path: 'marketplace',
						element: (
							<ProtectedRoute>
								<Dashboard />
							</ProtectedRoute>
						),
						loader:handleAssetListFetching
						
					},
					/* User profile edit page  */
					{
						path: 'profile/edit',
						element:( 
							<ProtectedRoute>
								<EditProfile />
							</ProtectedRoute>
						),
						loader:fetchProfileInfo,
					},
					/* Account Settings page */
					{
						path: 'profile/settings',
						element: (
							<ProtectedRoute>
								<Settings />
							</ProtectedRoute>
						),
						loader:fetchProfileInfo,
					},
					/* Payment method page  */
					{
						path: 'profile/payments',
						element: (
							<ProtectedRoute>
								<AddPaymentMethod />
							</ProtectedRoute>
						),
						loader:fetchBanks,
					},
					/* Invite or referral page  */
					{
						path: 'profile/invite',
						element: (
							<ProtectedRoute>
								<InviteFriend />
							</ProtectedRoute>
						),
						loader:fetchProfileInfo,
					},
					/* Account verification page  */
					{
						path: 'verification',
						element: (
							<ProtectedRoute>
								<UserVerification />
							</ProtectedRoute>
						),
						loader:loadKYCs,
					},
					/* Account ID Verification page  */
					{
						path: 'verification/id',
						element: (
							<ProtectedRoute>
								<IDVerification />
							</ProtectedRoute>
						),
						loader:fetchProfileInfo,
					},
					/* Account Proof of resident verification page  */
					{
						path: 'verification/por',
						element: (
							<ProtectedRoute>
								<PORVerification />
							</ProtectedRoute>
						),
						loader:fetchProfileInfo,
					},

					/* Spot wallet page */
					...WalletRoutes
				],
		errorElement: <ErrorPage />
	},

	{
		path: "/merchant",
		element: (
			<ProtectedRoute>
				<MerchantDashboardLayout />
			</ProtectedRoute>
		),
		loader:fetchProfileInfo,
		children: [{},...merchantRoutes],
		errorElement: <ErrorPage />
	},
	/* public route */
	{
		path: "/tickets/room/:ticketToken",
		element: <TicketRoom />,
		errorElement: <ErrorPage />
	}
]
