import { GET, POST, PUT, DROP } from "configs/api"
import { handleError } from "services/utility/Utils/Utils"
import { marketplaceEndpoints } from "./marketplace.endpoints"

export type FetchAssetApiProps = {
	assetId: string
	page: string
}

export const FetchBuyMarketplace = async (params: FetchAssetApiProps | any = { assetId: "", page: "1" }) => {
	try {
		return await GET(marketplaceEndpoints.FETCH_BUY_MARKETPLACE(params?.assetId, params?.page))
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchSellMarketplace = async (params: FetchAssetApiProps | any = { assetId: "", page: "1" }) => {
	try {
		return await GET(marketplaceEndpoints.FETCH_SELL_MARKETPLACE(params?.assetId, params?.page))
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchQuantity = async (params: FormData | object) => {
	try {
		return await POST(marketplaceEndpoints.FETCH_ASSET_QUANTITY, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const BuyAsset = async (params: FormData | object) => {
	try {
		return await POST(marketplaceEndpoints.BUY_ASSET, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const BuyAssetDetails = async (params: FormData | object) => {
	try {
		return await POST(marketplaceEndpoints.BUY_ASSET_DETAILS, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const PayAsset = async (params: FormData | object) => {
	try {
		return await POST(marketplaceEndpoints.PAY_ASSET, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const CancelOrder = async (params: FormData | object) => {
	try {
		return await POST(marketplaceEndpoints.CANCEL_ORDER, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const SellAsset = async (params: FormData | object) => {
	try {
		return await POST(marketplaceEndpoints.SELL_ASSET, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const SellAssetDetails = async (params: FormData | object) => {
	try {
		return await POST(marketplaceEndpoints.SELL_ASSET_DETAILS, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const ReleaseAsset = async (params: FormData | object) => {
	try {
		return await POST(marketplaceEndpoints.RELEASE_ASSETS, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const BuyerAwaitFundRelease = async (params: FormData | object) => {
	try {
		return await POST(marketplaceEndpoints.AWAIT_FUND_RELEASE, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const SellerAwaitPaymentRelease = async (params: FormData | object) => {
	try {
		return await POST(marketplaceEndpoints.AWAIT_PAYMENT_RELEASE, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const CreateChat = async (params: FormData | object) => {
	try {
		return await POST(marketplaceEndpoints.CREATE_CHAT, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const CreateMediaChat = async (params: FormData | object) => {
	try {
		return await POST(marketplaceEndpoints.CREATE_FILE_CHAT, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const getChat = async (params: FormData | object) => {
	try {
		return await POST(marketplaceEndpoints.GET_CHAT, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}
