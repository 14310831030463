import { CloudArrowUpIcon, XCircleIcon } from "@heroicons/react/24/solid"
import React, { useState } from "react"
import ShowSpinner from "./Spinner"

type FileUploadProps = {
	transactionId?: string
	name?: string
	isLoading?: boolean
	handleUpload?: (data: FormData) => void
}
const FileUpload: React.FC<FileUploadProps> = props => {
	const [selectedFiles, setSelectedFiles] = useState<File[]>([])

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files
		if (files) {
			setSelectedFiles([...selectedFiles, ...Array.from(files)])
		}
	}

	const handleIconClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click()
		}
	}

	const handleRemoveFile = (index: number) => {
		const updatedFiles = [...selectedFiles]
		updatedFiles.splice(index, 1)
		setSelectedFiles(updatedFiles)
	}

	const fileInputRef = React.createRef<HTMLInputElement>()

	const _handleUpload = () => {
		const formData = new FormData()

		selectedFiles.forEach((file, index) => {
			formData?.append(`${props?.name}[${index}]`, (file as any) as Blob)
		})

		formData.append("txn_id", props?.transactionId as string)

		if (props?.handleUpload && typeof props?.handleUpload === "function") props?.handleUpload(formData)
	}
	return (
		<div className="p-3">
			<h5 className="text-gray-600 p-1 text-blue-500 font-bold text-center mb-3">Upload proof of payment.</h5>
			<input type="file" multiple style={{ display: "none" }} ref={fileInputRef} onChange={handleFileChange} />
			<button disabled={props?.isLoading} className="m-auto block border-1 border-blue-600 text-blue-600 rounded-md p-3 mb-3" onClick={handleIconClick}>
				<CloudArrowUpIcon className="w-10 h-10" />
			</button>
			<ul className="divide-y px-3 mb-4">
				{selectedFiles.map((file, index) => (
					<li key={index} className="flex gap-1 items-center p-2">
						<XCircleIcon className="cursor-pointer text-red-500 w-6 h-6" onClick={() => handleRemoveFile(index)} /> {file.name}
					</li>
				))}
			</ul>

			<button disabled={props?.isLoading} typeof="button" className="m-auto  mt-4 block w-full rounded-md p-3 bg-blue-500 text-white disabled:hover:cursor-wait" onClick={_handleUpload}>
				{!props?.isLoading && "Submit"}
				{props?.isLoading && <ShowSpinner />}
			</button>
		</div>
	)
}

export default FileUpload
