import React from "react"
// import { useFlusStores } from "@bilmapay/react-flux-storage"
// import { generalApis } from "services/Api/general/gen.index"
// import { useMutation } from "react-query"
// import AwaitResponse from "components/AwaitResponse"
// import { uniqueId } from "services/utility/Utils/Utils"

// type SupportedCoints = {
// 	asset_id: number
// 	name: string
// 	symbol: string
// 	icon: string
// }

function CustomerSection() {

    // const [supportedCoints, setSupportedCoins] = React.useState<Array<SupportedCoints>>()

    // const { FetchSupportedCoins } = generalApis()

    // const fetchSpportedCoinsApi = useMutation(FetchSupportedCoins, {
    // 	onSuccess: (res: any) => {
    // 		if (res?.status) {
    // 			setSupportedCoins(res?.data)
    // 		}
    // 	}
    // })

    return (
        <section className=" flex flex-row flex-wrap space-x-4 space-y-1 sm:space-x-20 bg-blue-600 py-10 items-center justify-center">

            <div className="flex flex-column items-left justify-center">
                <strong className="text-4xl font-link text-white">8k+</strong>
                <p className="text-lg my-2 openFont text-white" >Active Users</p>
            </div>

            <div className="flex flex-column items-left justify-center">
                <strong className="text-4xl font-link text-white">2min</strong>
                <p className="text-lg my-2 openFont text-white" >Trade Duration</p>
            </div>

            <div className="flex flex-column items-left justify-center">
                <strong className="text-4xl font-link text-white">100+</strong>
                <p className="text-lg my-2 openFont text-white" >Supported Coins</p>
            </div>

            <div className="flex flex-column items-left justify-center">
                <strong className="text-4xl font-link text-white">24/7</strong>
                <p className="text-lg my-2 openFont text-white" >Customer Support</p>
            </div>

        </section>
    )
}

export default CustomerSection
