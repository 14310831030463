// icon:hourglass-split | Bootstrap https://icons.getbootstrap.com/ | Bootstrap
import * as React from "react"

function IconHourglassSplit(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg fill="currentColor" viewBox="0 0 16 16" height="1em" width="1em" {...props}>
			<path d="M2.5 15a.5.5 0 110-1h1v-1a4.5 4.5 0 012.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 013.5 3V2h-1a.5.5 0 010-1h11a.5.5 0 010 1h-1v1a4.5 4.5 0 01-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0112.5 13v1h1a.5.5 0 010 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 004.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 00-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
		</svg>
	)
}

export default IconHourglassSplit
