import React, { useEffect } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { ApplicationRootRoutes } from "configs/ApplicationRootRoutes.routes"
import { useAuthApis } from "services/Api/auth/auth.apis.index"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { generalApis } from "services/Api/general/gen.index"
import { useMutation } from "react-query"
import AwaitResponse from "components/AwaitResponse"

import { setConfig } from "redux/reducers/appSlice"
import { useDispatch } from "react-redux"
import NewDesktopNav from "components/layout/NewLandingPage";
import { useRef } from 'react';
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const applicationRoutes = createBrowserRouter(ApplicationRootRoutes)

const App: React.FC = () => {

	const { FetchCSRFXSSToken } = useAuthApis()
	const dispatcher = useDispatch()

	const { FetchGeneralContent } = generalApis()
	const fetchGeneralConteApi = useMutation(FetchGeneralContent, {
		onSuccess(res: any) {
			if (res?.status) {
				dispatcher(setConfig(res?.data))
			}
		}
	})
	

	useEffect(() => {
		return () => {
			FetchCSRFXSSToken()
		}
	})

	return (
		
		<AwaitResponse api={fetchGeneralConteApi.mutateAsync}>
			<ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
			<RouterProvider router={applicationRoutes} />
			<div className="App">
        </div>
		</AwaitResponse>
	)
}

export default App
