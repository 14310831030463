import PageInfo from "components/misc/Page"
import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { Link } from "react-router-dom"
import { useUrlQuery } from "services/utility/hooks/useURL"

export default function PasswordVerificationPage() {
	const { LOGO, VerificationImg } = useFlusStores()
	const { email } = useUrlQuery()

	return (
		<section>
			<PageInfo titleText="Forget Password Verification Sent" />
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-9 col-lg-12 col-xl-10">
						<div className="card o-hidden border-0 my-5">
							<div className="card-body p-0">
								<div className="row">
									<div className="col-lg-6 d-none d-lg-flex p-4 bg-light-blue">
										<div className="d-flex flex-grow-1 justify-content-center align-items-center auth-bg-image">
											<img src={VerificationImg} alt="" width="350" />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="p-5">
											<div className="text-center mb-5">
												<img src={LOGO} alt="" width="50" />
												<h4 className="text-dark mb-4 mt-3">Verify your email</h4>
												<p className="text-center p-2">
													We have sent an email to <br />
													<strong>{email}.</strong>
												</p>
												<p className="text-center p-2">
													We want to make sure that it's you, please check your email for a password
													reset link.
												</p>
											</div>
											<form className="user">
												<div className="row mb-3">
													<div className="col">
														<button
															className="btn btn-primary btn-lg d-block login-btn w-100 bg-blue"
															type="button"
															onClick={() => window.history.back()}
														>
															Go Back
														</button>
													</div>
												</div>
												<small className="form-text my-2 w-100 d-block p-1 text-center">
													<Link className="text-primary bk-reg-continue" to="/auth">
														<strong>Continue</strong>
													</Link>
												</small>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
