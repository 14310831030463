export const cashBridgeEndpoints = {
	withdrawal: {
		CREATE: "/initiate-withdrawal",
		CHARGES: "/query-withdrawal-charge",
		CONTINUE: "/proceed-withdrawal",
		LISTEN: "/listen-withdrawal-status",
		RELEASE: "/release-withdrawal",
		ACTIVE: "/check-active-withdrawal"
	},

	deposit: {
		CREATE: "/initiate-deposit",
		CHARGES: "/query-deposit-charge",
		LISTEN: "/listen-deposit-status",
		UPLOAD_PROOF: "/upload-deposit-proof",
		ESCALATE: "/escalate-deposit",
		ACTIVE: "/check-active-deposit"
	}
}
