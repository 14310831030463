import { useFlusDispatcher } from "@bilmapay/react-flux-storage"
import ShowSpinner from "components/misc/Spinner"
import { USER_LOGOUT } from "flus/constants/auth.const"
import React from "react"
import { useMutation } from "react-query"
import { useDispatch } from "react-redux"
import { Outlet, useNavigate } from "react-router-dom"
import { LOGOUT } from "redux/reducers/appSlice"
import { useAuthApis } from "services/Api/auth/auth.apis.index"
import { sleep } from "services/utility/Utils/_sleep"

export default function OnboardingLayout() {
	const navigate = useNavigate()
	const dispatcher = useDispatch()

	const { LogoutAccount } = useAuthApis()

	const logoutAccountApi = useMutation(LogoutAccount, {
		onSuccess: res => {
			dispatcher(LOGOUT())

			sleep(200).then(() => navigate("/auth"))
		}
	})

	const isLoggingOut = logoutAccountApi.isLoading
	const handleLogout = (e: any) => logoutAccountApi.mutateAsync()

	return (
		<section className="bk-onboarding-overlay">
			<button onClick={handleLogout} className="btn-close"></button>

			{!isLoggingOut && <Outlet />}
			{isLoggingOut && <ShowSpinner size={25} />}
		</section>
	)
}
