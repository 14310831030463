import PageInfo from "components/misc/Page"
import ShowSpinner from "components/misc/Spinner"
import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { useMutation } from "react-query"
import { Link } from "react-router-dom"
import { useAuthApis } from "services/Api/auth/auth.apis.index"
import { useUrlQuery } from "services/utility/hooks/useURL"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"

export default function VerificationPage() {
	const { LOGO, VerificationImg } = useFlusStores()

	const { email } = useUrlQuery()
	const { ResendVerificationLink } = useAuthApis()

	const resendVerificationApi = useMutation(ResendVerificationLink, {
		onSuccess: res => {
			if (res?.status) {
				ShowSuccess("Verification", `Success! A new verification link has been sent to ${email}.`)
			} else ShowServerErrors("Verification", res)
		}
	})

	const isSending = resendVerificationApi.isLoading

	const handleLinkResending = () => {
		resendVerificationApi.mutate()
	}
	return (
		<section>
			<PageInfo titleText="Verify Email" />
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-9 col-lg-12 col-xl-10">
						<div className="card o-hidden border-0 my-5">
							<div className="card-body p-0">
								<div className="row">
									<div className="col-lg-6 d-none d-lg-flex p-4 bg-light-blue">
										<div className="d-flex flex-grow-1 justify-content-center align-items-center auth-bg-image">
											<img src={VerificationImg} alt="" width="350" />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="p-5">
											<div className="text-center">
												<img src={LOGO} alt="" width="50" />
												<h4 className="text-dark mb-4 mt-3 text-2xl">Verification link sent to <strong>{email}.</strong></h4>
												<h6 className="text-center p-2">

												Click the link sent to you within 24 hours to complete your registration. </h6>
												<strong><h6 className="font-black text-md text-blue-700">Also Check your spam folder for verification email. </h6> </strong>
												

												<p> <br /> </p>
												<p className="text-center p-2">Did not receive a mail?</p>
											</div>
											<form className="user">
												<div className="row mb-3">
													<div className="col p-0">
														<Link
															className="btn btn-primary btn-lg d-block login-btn w-100 bg-blue"
															role="button"
															to={`/auth/change-email-verify?email=${email}`}
														>
															Change Email
														</Link>
													</div>
													<div className="col">
														<button
															className="btn btn-primary btn-lg d-block login-btn w-100 bg-blue"
															type="button"
															onClick={handleLinkResending}
															disabled={isSending}
														>
															{!isSending && "Resend"}
															{isSending && <ShowSpinner color="text-light" />}
														</button>
													</div>
												</div>
												<small className="form-text my-2 w-100 d-block p-1 text-center">
													<Link className="text-primary bk-reg-continue" to="/auth">
														<strong>Continue</strong>
													</Link>
												</small>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
