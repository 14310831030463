import CardNavigator, { CardNavigatorInterface } from "components/CardNavigator"
import BKCustomSelect, { BKCustomSelectItems } from "components/input/BKCustomSelect"
import WalletTransferSwapcard, { SwapperInterface } from "components/wallet/WalletTransferSwapcard"
import React from "react"
import { useMutation } from "react-query"
import { useLoaderData, useNavigate, useParams } from "react-router-dom"
import { userWalletApis } from "services/Api/wallet/wallet.index"
import ShowSpinner from "components/misc/Spinner"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { decryptString } from "services/utility/Utils/utilities"
import { useUrlQuery } from "services/utility/hooks/useURL"
import { AssetsListItemProps } from "types/app.types"
import AwaitResponse from "components/AwaitResponse"
import { useDispatch, useSelector } from "react-redux"
import { getTransferAsset, setTransferAsset } from "redux/reducers/walletSlice"

const configPropsRef = React.createRef<HTMLDivElement>()
const balanceRef = React.createRef<HTMLSpanElement>()

export default function WalletTransfer({ returnLink, showHistory }: CardNavigatorInterface) {
	const { opt } = useUrlQuery()
	const { assetId } = useParams()

	const defaultConfigs: SwapperInterface = JSON.parse(decryptString(opt))

	const [swapper, setSwapper] = React.useState<SwapperInterface | null>(defaultConfigs)
	const [selectedCoin, setSelectedCoin] = React.useState<BKCustomSelectItems>()

	const assetList: any = useLoaderData()
	let assets: Array<BKCustomSelectItems>
	const transferAsset = useSelector(getTransferAsset)

	const dispatcher = useDispatch()

	assets = assetList?.data?.map((asset: any) => ({
		icon: asset?.icon,
		name: asset?.symbol,
		value: asset?.asset_id
	}))

	const defaultAsset: AssetsListItemProps = assetList?.data?.find((asset: AssetsListItemProps) => parseInt(asset?.asset_id) === parseInt(assetId as string))

	React.useEffect(() => {
		if (configPropsRef?.current) {
			if (transferAsset && swapper) {
				if (swapper?.up?.value === "funding") {
					handleBalanceDisplaying(transferAsset?.funding_balance)
				} else {
					handleBalanceDisplaying(transferAsset?.spot_balance)
				}
			}
		}
	})

	/* handle the toggler default balance displaying  */
	const handleBalanceDisplaying = (balance: string) => {
		if (balanceRef?.current) {
			balanceRef.current.innerHTML = balance
		}
	}

	const { FindAsset, TransferCoin } = userWalletApis()

	/* Make an api call to fetch asset details */
	const findAssetApi = useMutation(FindAsset, {
		onSuccess: res => {
			if (res?.status) {
				dispatcher(setTransferAsset(res?.data))
			}
		}
	})

	const isFetchingAsset = findAssetApi.isLoading

	/* handle on coin select */
	const handleUseCoinSelection = (selected: BKCustomSelectItems) => {
		const fd = new FormData()

		setSelectedCoin(selected)

		fd.append("asset_id", selected.value)

		findAssetApi.mutateAsync(fd)
	}

	// -------------------------------------------------------------------------------------------
	const navigate = useNavigate()

	/* make a transfer api call  */
	const transferCoinApi = useMutation(TransferCoin, {
		onSuccess: res => {
			if (res?.status) {
				ShowSuccess("Transfer", "Your coin transfer has been sent.")
				navigate(returnLink)
			} else ShowServerErrors("Transfer", res)
		}
	})

	const isTransfering = transferCoinApi.isLoading

	/* handle user transfer */
	const handleUserTransfer = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		if (selectedCoin) {
			const formData = new FormData(e.target)
			formData.append("asset_id", selectedCoin?.value)
			transferCoinApi.mutateAsync(formData)
		}
	}

	const handleDefaultAssetFetching = async () => {
		const selectedItem: BKCustomSelectItems = {
			name: defaultAsset?.name,
			value: defaultAsset?.asset_id,
			icon: defaultAsset?.icon
		}

		setSelectedCoin(selectedItem)

		const fd = new FormData()
		fd.append("asset_id", defaultAsset?.asset_id)

		return await findAssetApi.mutateAsync(fd)
	}

	return (
		<CardNavigator returnLink={returnLink} showHistory={showHistory} historyParams={assetId}>
			<section>
				<h3 className="mb-3">
					<strong>Transfer</strong>
				</h3>
				<form className="mt-3" onSubmit={handleUserTransfer}>
					<div className="mb-3">
						<span className="text-muted mb-2 d-block me-2">Choose a coin to Transfer</span>
						{isFetchingAsset && <ShowSpinner size={20} />}
						<BKCustomSelect options={assets} onChange={handleUseCoinSelection} displayText={defaultAsset?.symbol} defaultValue={defaultAsset?.asset_id} displayIcon={defaultAsset?.icon} />
					</div>
					<WalletTransferSwapcard swapper={swapper} setSwapper={setSwapper} configPropsRef={configPropsRef} handlebalanceSwapping={handleBalanceDisplaying} asset={transferAsset} />
					<div className="mb-3">
						<span className="text-muted mb-2 d-block">Amount</span>
						<input className="form-control form-control-lg bk-bg-light" type="number" step="any" name="amount" />
						<ul className="list-inline d-flex mt-2">
							<li className="list-inline-item w-100">Balance</li>
							<li className="list-inline-item text-end w-100">
								<AwaitResponse api={handleDefaultAssetFetching}>
									<span ref={balanceRef}></span> {transferAsset?.symbol}
								</AwaitResponse>
							</li>
						</ul>
					</div>
					<div className="my-4">
						<button disabled={isTransfering} className="btn btn-primary btn-lg w-100" type="submit">
							{!isTransfering && <strong>Transfer</strong>}
							{isTransfering && <ShowSpinner color="text-light" />}
						</button>
					</div>
				</form>
			</section>
		</CardNavigator>
	)
}
