import { GET, POST, PUT, DROP } from "configs/api"
import { handleError } from "services/utility/Utils/Utils"
import { generalEndpoints } from "./gen.endpoints"

export const FetchSupportedCoins = async () => {
	try {
		return await GET(generalEndpoints.SUPPORTED_COINS)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchTestimonials = async () => {
	try {
		return await GET(generalEndpoints.TESTIMONIALS)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const SendContactUs = async (params: FormData) => {
	try {
		return await POST(generalEndpoints.CONTACT_US, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchFAQs = async () => {
	try {
		return await GET(generalEndpoints.FAQ)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchGeneralContent = async () => {
	try {
		return await GET(generalEndpoints.GEN_CONTENT)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchAboutUs = async () => {
	try {
		return await GET(generalEndpoints.ABOUT)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const Search = async (params: FormData) => {
	try {
		return await POST(generalEndpoints.SEARCH, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}
