import ProtectedRoute from "components/routes/ProtectedRoute";
import WalletLayout from "layouts/WalletLayout";
import { Navigate } from "react-router-dom";
import { userNotificationApis } from "services/Api/notification/notify.index";
import { userOfferApis } from "services/Api/offer/offer.index";
import { userProfileApi } from "services/Api/profile/profile.index";
import { userWalletApis } from "services/Api/wallet/wallet.index";
import CreateOffer from "./create-offer/CreateOffer";
import DisputeZone from "./dispute-zone/DisputeZone";
import TransactionHistory from "./history/TransactionHistory";
import MarketNews from "./market-news/MarketNews";
import AwaitPaymentRelease from "./marketplace/AwaitPaymentRelease";
import BuyCoinDetails from "./marketplace/buy/BuyCoinDetails";
import BuyCoin from "./marketplace/buy/BuyCoint";
import MakePayment from "./marketplace/buy/MakePayment";
import GroupChat from "./marketplace/chat/GroupChat";
import P2PChat from "./marketplace/chat/P2PChat";
import OrderCancellation from "./marketplace/OrderCancellation";
import SellCoin from "./marketplace/sell/SellCoin";
import SellCoinDetails from "./marketplace/sell/SellCoinDetails";
import SellerAwaitFundRelease from "./marketplace/SellerAwaitFundRelease";
import MyOffer from "./my-offer/MyOffer";
import Notification from "./notification/Notification";
import FundingWallet from "./wallet/Fundingwallet/FundingWallet";
import FundingWalletTransfer from "./wallet/Fundingwallet/FundingWalletTransfer";
import SpotWallet from "./wallet/spotwallet/SpotWallet";
import SpotWalletReceive from "./wallet/spotwallet/SpotWalletReceive";
import SpotWalletReceiveCoin from "./wallet/spotwallet/SpotWalletReceiveCoin";
import SpotWalletSend from "./wallet/spotwallet/SpotWalletSend";
import SpotWalletSendCoin from "./wallet/spotwallet/SpotWalletSendCoin";
import SpotWalletTransfer from "./wallet/spotwallet/SpotWalletTransfer";
import WalletHistory from "./wallet/WalletHistory";
import { userMarketnewsApis } from 'services/Api/marketnews/marketnews.index';
import MarketnewsDetails from "./market-news/MarketnewsDetails";
import { userTransactionApi } from "services/Api/transactions/transactions.index";
import { userTicketApis } from "services/Api/ticket/ticket.index";
import BecomeAMerchant from "./BecomeAMerchant";
import { merchantRoutes } from "./merchant/merchant.routes";
import GiftCard from "./giftcard/giftcard";

const {FetchCoinAssets,FetchAssets, ReceiveCoinAsset, FetchSpotWallet, FetchFundingWallet} = userWalletApis()
const {FetchMyOffers} = userOfferApis()
const {FetchNotifications} = userNotificationApis()
const {FetchProfile} = userProfileApi()
const {saveUserID} = userProfileApi()
const {GetTransactions} = userTransactionApi()
const {FetchTickets} = userTicketApis()
const {FetchNews, FetchNewsDetails} = userMarketnewsApis();

const fetchMarketNews = () => {
    return FetchNews()
}

const fetchNewsDetails = ({params}) => {
    return FetchNewsDetails(params?.slug)
} 

const fetchProfileInfo =  ({params}) => {
	return FetchProfile()
}

const saveInfo =  ({params}) => {
	return saveUserID()
}


const handleAssetListFetching = ({params}) => {
    return FetchCoinAssets()
} 

const handleAssetFetching = ({params}) => {
    return FetchAssets()
} 

const handleReceiveCoin = ({params}) => {
    const fd = new FormData()
    fd.append('asset_id',params.assetId)

    return ReceiveCoinAsset(fd)
} 

const handleSpotwalletFetching = ({params}) => {
    return FetchSpotWallet();
}

const handleFundingWalletFetching = ({params}) => {
    return FetchFundingWallet();
}

const fetchMyOffers = ({params}) => {
    return FetchMyOffers()
}


const fetchNotifications = () => {
    return FetchNotifications()
}

const fetchTransactions = () => {
    return GetTransactions()
}

const fetchTickets = () => {
    return FetchTickets()
}


export const WalletRoutes = [
    
    /* Spot Wallets pages */
    {
        path: 'spot-wallet',
        element: (
            <ProtectedRoute>
                <WalletLayout />
            </ProtectedRoute>
        ),
        children: [
            {
                index: true,
                element: (
                    <ProtectedRoute>
                        <SpotWallet />
                    </ProtectedRoute>
                ),
                loader: handleSpotwalletFetching
            }, 

            {
                path: 'send',
                element: (
                    <ProtectedRoute>
                        <SpotWalletSend />
                    </ProtectedRoute>
                ),
            }, 
            {
                path: 'receive',
                element: (
                    <ProtectedRoute>
                        <SpotWalletReceive />
                    </ProtectedRoute>
                ),
               
            }, 
            {
                path: 'transfer/:assetId',
                element: (
                    <ProtectedRoute>
                        <SpotWalletTransfer />
                    </ProtectedRoute>
                ),
                loader:handleAssetFetching,
                errorElement: <Navigate to={"/dashboard/spot-wallet"} />
            }, 

            {
                path: 'send-coin/:assetId',
                element: (
                    <ProtectedRoute>
                        <SpotWalletSendCoin />
                    </ProtectedRoute>
                ),
                loader:handleAssetListFetching
            }, 
            {
                path: 'receive-coin/:assetId',
                element: (
                    <ProtectedRoute>
                        <SpotWalletReceiveCoin />
                    </ProtectedRoute>
                ),
                loader: handleReceiveCoin
            }, 
        ]
    },
    /* Funding Wallet Pages */
    {
        path: 'funding-wallet',
        element: (
            <ProtectedRoute>
                <WalletLayout />
            </ProtectedRoute>
        ),
        children: [
            {
                index: true,
                element: (
                    <ProtectedRoute>
                        <FundingWallet />
                    </ProtectedRoute>
                ),
                loader: handleFundingWalletFetching
            }, 
            {
                path: 'transfer/:assetId',
                element: (
                    <ProtectedRoute>
                        <FundingWalletTransfer />
                    </ProtectedRoute>
                ),
                loader:handleAssetFetching,
                errorElement: <Navigate to={"/dashboard/funding-wallet"} />
            }, 
            
        ]
    },
    {
        path: 'wallet-history',
        element: (
            <ProtectedRoute>
                <WalletHistory />
            </ProtectedRoute>
        )
    },
    {
        path: 'disputes',
        element: (
            <ProtectedRoute>
                <DisputeZone />
            </ProtectedRoute>
        ),
        loader: fetchTickets
    },
    {
        path: 'create-offer',
        element: (
            <ProtectedRoute>
                <CreateOffer />
            </ProtectedRoute>
        )
    },
    {
        path: 'my-offer',
        element: (
            <ProtectedRoute>
                <MyOffer />
            </ProtectedRoute>
        ), 
        loader: fetchMyOffers
    },
    {
        path: 'giftcard',
        element: (
            <ProtectedRoute>
                <GiftCard />
            </ProtectedRoute>
        ),
        // loader: fetchTransactions
    },
    {
        path: 'history',
        element: (
            <ProtectedRoute>
                <TransactionHistory />
            </ProtectedRoute>
        ),
        loader: fetchTransactions
    },
    
   
    {
        path: 'notification',
        element: (
            <ProtectedRoute>
                <Notification />
            </ProtectedRoute>
        ),
        loader: fetchNotifications
    },
    {
        path: 'sell-coin',
        element: (
            <ProtectedRoute>
                <SellCoin />
            </ProtectedRoute>
        )
    },
    {
        path: 'sell-coin-details',
        element: (
            <ProtectedRoute>
                <SellCoinDetails />
            </ProtectedRoute>
        )
    },
    {
        path: 'await-fund-release',
        element: (
            <ProtectedRoute>
                <SellerAwaitFundRelease />
            </ProtectedRoute>
        )
    },

    {
        path: 'await-payment-release',
        element: (
            <ProtectedRoute>
                <AwaitPaymentRelease />
            </ProtectedRoute>
        )
    },
    {
        path: 'cancel-order',
        element: (
            <ProtectedRoute>
                <OrderCancellation />
            </ProtectedRoute>
        )
    },

    {
        path: 'chat/group',
        element: (
            <ProtectedRoute>
                <GroupChat />
            </ProtectedRoute>
        )
    },

    {
        path: 'chat/peer',
        element: (
            <ProtectedRoute>
                <P2PChat />
            </ProtectedRoute>
        ),
        loader: fetchProfileInfo
    },

    /* BUY COIN */
    {
        path: 'buy-coin',
        element: (
            <ProtectedRoute>
                <BuyCoin />
            </ProtectedRoute>
        )
    },

    {
        path: 'buy-coin-details',
        element: (
            <ProtectedRoute>
                <BuyCoinDetails />
            </ProtectedRoute>
        )
    },
    {
        path: 'buy-coin/make-payment',
        element: (
            <ProtectedRoute>
                <MakePayment />
            </ProtectedRoute>
        )
    },

    {
        path: 'market-news',
        element: (
            <ProtectedRoute>
                <MarketNews />
            </ProtectedRoute>
        ),
        loader: fetchMarketNews
    },
    {
        path: 'market-news/:slug',
        element: (
            <ProtectedRoute>
                <MarketnewsDetails />
            </ProtectedRoute>
        ),
        loader: fetchNewsDetails
    },

    {
        path: 'register',
        element: (
            <ProtectedRoute>
                <BecomeAMerchant />
            </ProtectedRoute>
        ),
    },

]
