import ShowSpinner from "components/misc/Spinner"
import React from "react"
import { useMutation } from "react-query"
import { useLoaderData } from "react-router-dom"
import { userProfileApi } from "services/Api/profile/profile.index"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"

export default function Settings() {
	const profileResponse: any = useLoaderData()

	const { data }: { [key: string]: string | number | any } = profileResponse

	const { SetAccountPIN, UpdatePassword, ToggleSettings } = userProfileApi()

	// ------------------------------------------------

	const setAccountPinApi = useMutation(SetAccountPIN, {
		onSuccess: res => {
			if (res?.status) {
				ShowSuccess("Settings", "Account PIN updated successfully!")
			} else ShowServerErrors("Settings", res)
		}
	})

	const isSettingAccountPIN = setAccountPinApi.isLoading

	const handleAccountPinSetting = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)
		setAccountPinApi.mutateAsync(formData)
	}

	// ------------------------------------------------

	const updateAccountPasswordApi = useMutation(UpdatePassword, {
		onSuccess: res => {
			if (res?.status) {
				ShowSuccess("Settings", "Account password updated successfully!")
			} else ShowServerErrors("Settings", res)
		}
	})
	const isUpdatingPassword = updateAccountPasswordApi.isLoading

	const handleAccountPasswordUpdate = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		updateAccountPasswordApi.mutateAsync(formData)
	}

	// ------------------------------------------------

	const toggleSettingsApi = useMutation(ToggleSettings, {
		onSuccess: res => {
			if (res?.status) {
				ShowSuccess("Settings", "Your settings has been updated successully!")
			} else ShowServerErrors("Settings", res)
		}
	})

	const isTogglingSettings = toggleSettingsApi.isLoading

	const handleUserSettingToggle = (e: React.ChangeEvent<HTMLInputElement>, settings: string) => {
		const fd = new FormData()

		fd.append("attribute", settings)

		toggleSettingsApi.mutateAsync(fd)
	}

	return (
		<section className="p-3 bg-white">
			<header className="mb-3">
				<h1>Settings</h1>
				<p>
					Ensure you take charge of your space on Beekle by completing the security setup
					appropriately. Check our <a href="#a">security guide</a> to know more.{" "}
				</p>
			</header>
			<section className="py-4 border-bottom">
				<form onSubmit={handleAccountPinSetting}>
					<div className="row">
						<div className="col-md-5 mb-3">
							<h2 className="text-blue">Setup PIN</h2>
							<p className="text-danger">
								Your four digit in will help you in the authentication of various procedures in the
								course of performing operations on Beekle.
							</p>
						</div>
						<div className="col">
							<div className="mb-3">
								<label className="form-label">
									<strong>Password</strong>
								</label>
								<input
									name="password"
									required
									className="form-control form-control-lg"
									type="password"
								/>
							</div>
							<div className="mb-3">
								<label className="form-label">
									<strong>PIN</strong>
								</label>
								<input
									name="pin"
									required
									className="form-control form-control-lg"
									type="password"
								/>
							</div>
							<div className="mb-3">
								<label className="form-label">
									<strong>Confirm PIN</strong>
								</label>
								<input
									name="pin_confirmation"
									required
									className="form-control form-control-lg"
									type="password"
								/>
							</div>
							<button
								disabled={isSettingAccountPIN}
								className="btn btn-primary btn-lg w-100 bg-blue"
								type="submit"
							>
								{!isSettingAccountPIN && "Set PIN"}
								{isSettingAccountPIN && <ShowSpinner color="text-light" />}
							</button>
						</div>
					</div>
				</form>
			</section>
			<section className="py-4 border-top border-bottom">
				<form onSubmit={handleAccountPasswordUpdate}>
					<div className="row">
						<div className="col-md-5 mb-3">
							<h1 className="text-blue">Change Password</h1>
							<p className="text-danger">
								Ensure you use a strong password to give room for maximum security of your crypto
								asset. A minimum of 12 characters, uppercase, lower case &amp; special symbols.
							</p>
						</div>
						<div className="col">
							<div className="mb-3">
								<label className="form-label">
									<strong>Old Password</strong>
								</label>
								<input
									name="old_password"
									required
									className="form-control form-control-lg"
									type="password"
								/>
							</div>
							<div className="mb-3">
								<label className="form-label">
									<strong>New Password</strong>
								</label>
								<input
									name="new_password"
									required
									className="form-control form-control-lg"
									type="password"
								/>
							</div>
							<div className="mb-3">
								<label className="form-label">
									<strong>Confirm Password</strong>
								</label>
								<input
									name="new_password_confirmation"
									required
									className="form-control form-control-lg"
									type="password"
								/>
							</div>
							<button
								disabled={isUpdatingPassword}
								className="btn btn-primary btn-lg w-100 bg-blue"
								type="submit"
							>
								{!isUpdatingPassword && "Change Password"}
								{isUpdatingPassword && <ShowSpinner color="text-light" />}
							</button>
						</div>
					</div>
				</form>
			</section>
			<section className="py-4 border-top">
				<h3 className="\">
					Email Address Verification<i className="fas fa-check-circle float-end text-success"></i>
				</h3>
				<h3 className="\">
					Phone Number Verification<i className="fas fa-times-circle float-end text-danger"></i>
				</h3>
			</section>
			<section className="py-4 border-top">
				<h1 className="text-blue">
					Control
					<span className="ms-4">{isTogglingSettings && <ShowSpinner />}</span>
				</h1>
				<p>
					Control and give priority to usage by enabling and disobliging the necessary
					functionalities as regards your need. Ensure you take proper security measures in the
					course of this setup.
				</p>
				<form>
					<div className="form-check form-switch mb-3">
						<input
							id="formCheck-4"
							className="form-check-input"
							type="checkbox"
							onChange={e => handleUserSettingToggle(e, "wallet_pin_transfer")}
							disabled={isTogglingSettings}
							defaultChecked={data?.wallet_pin_transfer === "1" ? true : false}
						/>
						<label className="form-check-label" htmlFor="formCheck-4">
							<strong>Allow the usage of pin during Wallet Transfer</strong>
						</label>
					</div>

					<div className="form-check form-switch mb-3">
						<input
							id="allowPINUsageInP2PFunding"
							className="form-check-input"
							type="checkbox"
							onChange={e => handleUserSettingToggle(e, "p2p_pin_transaction")}
							disabled={isTogglingSettings}
							defaultChecked={data?.p2p_pin_transaction === "1" ? true : false}
						/>
						<label className="form-check-label" htmlFor="allowPINUsageInP2PFunding">
							<strong>Allow the usage of pin during P2P funding and Release</strong>
						</label>
					</div>
					<div className="form-check form-switch mb-3">
						<input
							id="formCheck-5"
							className="form-check-input"
							type="checkbox"
							onChange={e => handleUserSettingToggle(e, "avatar_status")}
							disabled={isTogglingSettings}
							defaultChecked={data?.avatar_status === "1" ? true : false}
						/>
						<label className="form-check-label" htmlFor="formCheck-5">
							<strong>Use Avatar as a profile picture</strong>
							<br />
						</label>
					</div>
					<div className="form-check form-switch mb-3">
						<input
							id="formCheck-6"
							className="form-check-input"
							type="checkbox"
							onChange={e => handleUserSettingToggle(e, "notification_status")}
							disabled={isTogglingSettings}
							defaultChecked={data?.notification_status === "1" ? true : false}
						/>
						<label className="form-check-label" htmlFor="formCheck-6">
							<strong>Allow me to get notification on trade</strong>
							<br />
						</label>
					</div>
				</form>
			</section>
		</section>
	)
}
