import { GET, POST, PUT, DROP } from "configs/api"
import { handleError } from "services/utility/Utils/Utils"
import { offerEndpoints } from "./offer.endpoints"

export const FetchCurrencyPairs = async () => {
	try {
		return await GET(offerEndpoints.FETCH_CURRENCY_PAIRS)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchMyOffers = async () => {
	try {
		return await GET(offerEndpoints.FETCH_MY_OFFERS)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const CreateSellOffer = async (params: FormData | object) => {
	try {
		return await POST(offerEndpoints.CREATE_SELL_OFFER, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const CreateBuyOffer = async (params: FormData | object) => {
	try {
		return await POST(offerEndpoints.CREATE_BUY_OFFER, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchResponseTime = async () => {
	try {
		return await GET(offerEndpoints.FETCH_RESPONSE_TIME)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchMyOfferDetails = async (params: FormData | object) => {
	try {
		return await POST(offerEndpoints.FETCH_MY_OFFER_DETAILS, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const ToggleOfferStatus = async (params: FormData | object) => {
	try {
		return await POST(offerEndpoints.TOGGLE_OFFER_STATUS, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}
