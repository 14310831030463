import DashDesktopContainer from "components/DashDesktopContainer"
import DashMobileContainer from "components/DashMobileContainer"
import DashSearchForm from "components/layout/nav/DashSearchForm"
import React from "react"
import DashDesktopContent from "./DashDesktopContent"
import DashMobileContent from "./DashMobileContent"

export default function Dashboard() {
	return (
		<>
			<DashMobileContainer>
				<DashMobileContent />
			</DashMobileContainer>
			<DashDesktopContainer title="P2P Market">
				<DashDesktopContent />
			</DashDesktopContainer>
			<DashSearchForm />
		</>
	)
}
