import ShowSpinner from "components/misc/Spinner"
import { ErrorMessage, Field, Form, Formik } from "formik"
import React from "react"
import { useMutation } from "react-query"
import { useDispatch } from "react-redux"
import { changeClientStep } from "redux/reducers/depositSlice"
import { toggleDepositCashBridgePanel } from "redux/reducers/panelsSlice"
import {
	FetchDepositCharges,
	InitiateDeposit
} from "services/Api/cashbridge/cashbridge.apis"
import { sleep } from "services/utility/Utils/_sleep"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"

import * as yup from "yup"

const amountValidatorScheme = yup.object().shape({
	amount: yup.string().required("Please enter amount.")
})

type InitFormDataProps = {
	amount: string
}

const receivableRef = React.createRef<HTMLParagraphElement>()
const chargesRef = React.createRef<HTMLParagraphElement>()

export default function DepositForm() {
	const dispatcher = useDispatch()

	const InitFormData: InitFormDataProps = {
		amount: ""
	}

	const initiateDepositApi = useMutation(InitiateDeposit, {
		onSuccess(res: any) {
			if (res?.status) {
				ShowSuccess("Success", res?.message)

				dispatcher(
					toggleDepositCashBridgePanel({
						params: res?.data?.txn_id,
						data: res?.data
					})
				)
				sleep(100).then(() =>
					dispatcher(changeClientStep("awaiting_acceptance"))
				)
			} else ShowServerErrors("Error", res)
		}
	})
	const isLoading = initiateDepositApi.isLoading

	const _handleSubmit = (values: InitFormDataProps) =>
		initiateDepositApi.mutateAsync(values)

	// ---------------------> [Checking transactions status]

	const fetchChargesApi = useMutation(FetchDepositCharges, {
		onSuccess(res: any) {
			if (res?.status) {
				if (receivableRef?.current && chargesRef?.current) {
					receivableRef.current.innerText = `Receiable Amount: ${res?.data?.receive_amount}`
					chargesRef.current.innerText = `Charges: ${res?.data?.charges}`
				}
			}
		}
	})
	const isFetchingCharges = fetchChargesApi.isLoading

	const _handleChargesFetch = (_amount: any) =>
		_amount ? fetchChargesApi.mutateAsync({ amount: _amount }) : null

	return (
		<Formik
			initialValues={InitFormData}
			onSubmit={_handleSubmit}
			validationSchema={amountValidatorScheme}
		>
			{({ handleChange, values }) => (
				<Form className="space-y-6 xs:p-3 md:p-5">
					<div className="grid grid-cols-1 gap-4">
						<div className="form-group">
							<label className="form-label w-100 input-label">
								<strong>Enter the amount to deposit</strong>
							</label>
							<Field
								id="form_amountInput"
								name="amount"
								type="number"
								step="any"
								value={values?.amount}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									handleChange(e)
									_handleChargesFetch(e?.target?.value)
								}}
								className="form-control form-control-lg form-control-user"
							/>

							<p className="text-blue-500 mt-2" ref={receivableRef}>
								Receivable:{" "}
							</p>
							<p className="text-gray-500" ref={chargesRef}>
								Charges:{" "}
							</p>
							<ErrorMessage
								name="amount"
								component={"p"}
								className="text-red-600 mt-2 p-2"
							/>
						</div>
					</div>

					<button
						className="bg-blue-500 disabled:bg-blue-500/50 mb-3 inline-block w-fit rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
						type="submit"
						data-te-ripple-init
						data-te-ripple-color="light"
					>
						{!isLoading && "Submit"}
						{isLoading && <ShowSpinner />}
					</button>
				</Form>
			)}
		</Formik>
	)
}
