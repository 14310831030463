import React from "react"
import { Link } from "react-router-dom"
import { useFlusStores } from "@bilmapay/react-flux-storage"

type FiatBalanceCardProps = {
	balance: {
		amount: string
		name: string
		symbol: string
		type: string
		wallet_id: string
	}
}
export default function FiatBalanceCard(props: FiatBalanceCardProps) {
	const { NairaFlag } = useFlusStores()

	return (
		<div className="card mt-4 mb-3">
			<div className="card-body">
				<h4 className="card-title mb-3 d-flex">
					<img
						className="img-fluid me-2"
						src={NairaFlag}
						alt="NG-flag"
						width="25"
					/>
					<strong>Fiat Balance:</strong>
				</h4>
				<h2 className="text-center text-dark card-title mb-2 text-blue">
					<span className="font-monospace">{props?.balance?.amount}</span>NGN
				</h2>
				<div className="text-center d-none">
					<div
						className="btn-group btn-group-lg mt-3 fiat-balance-btns w-100"
						role="group"
					>
						<Link
							to="/dashboard/spot-wallet/send"
							className="btn btn-light shadow-sm me-3"
							role="button"
						>
							Send
						</Link>
						<Link
							to="/dashboard/spot-wallet/receive"
							className="btn btn-light shadow-sm me-3"
							role="button"
						>
							Receive
						</Link>
						<Link
							to={`/dashboard/spot-wallet/transfer`}
							className="btn btn-light shadow-sm"
							role="button"
						>
							Transfer
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}
