import ShowSpinner from "components/misc/Spinner"
import { NAIRA_SIGN } from "libs/currency"
import React from "react"
import { useMutation } from "react-query"
import { useDispatch } from "react-redux"
import { changeMerchantWithdrawStep } from "redux/reducers/withdrawalSlice"
import { AcceptWithdrawalRequest, DeclineDepositRequest } from "services/Api/merchant/merchant.apis"
import { sleep } from "services/utility/Utils/_sleep"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { OrderHistoryInterface } from "types"

type WithdrawalRequestProps = {
	data: OrderHistoryInterface
}
export default function WithdrawalRequest({ data }: WithdrawalRequestProps) {
	const dispatcher = useDispatch()

	const acceptWithdrawalRequestApi = useMutation(AcceptWithdrawalRequest, {
		onSuccess(res: any) {
			if (res?.status) {
				ShowSuccess("Success", res?.message)

				dispatcher(changeMerchantWithdrawStep("payment_details"))
			} else ShowServerErrors("Error", res)
		}
	})

	const isAccepting = acceptWithdrawalRequestApi.isLoading
	const _acceptDepositRequest = () => acceptWithdrawalRequestApi.mutateAsync({ txn_id: data?.txn_id })

	// -----------------------------------------> [Decline request]

	const declineOrderApi = useMutation(DeclineDepositRequest, {
		onSuccess(res: any) {
			if (res?.status) {
				ShowSuccess("Success", res?.message)

				sleep(900).then(() => {
					window.location.reload()
				})
			} else ShowServerErrors("Error", res)
		}
	})
	const isDeclining = declineOrderApi.isLoading
	const _declineDepositRequest = () => declineOrderApi.mutateAsync({ txn_id: data?.txn_id })
	return (
		<>
			<div className="relative my-6 flex-1 px-4 sm:px-6">
				<div className="border-t border-gray-100">
					<dl className="divide-y divide-gray-100">
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-semibold leading-6 text-gray-500">Order ID:</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data?.txn_id}</dd>
						</div>
					</dl>
				</div>

				<div className="border-t border-gray-100">
					<dl className="divide-y divide-gray-100">
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-semibold leading-6 text-gray-500">User's Name:</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data?.client_name}</dd>
						</div>
					</dl>
				</div>

				<div className="border-t border-gray-100">
					<dl className="divide-y divide-gray-100">
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-semibold leading-6 text-gray-500">Amount:</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
								{NAIRA_SIGN}
								{data?.amount}
							</dd>
						</div>
					</dl>
				</div>

				<div className="border-t border-gray-100">
					<dl className="divide-y divide-gray-100">
						<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
							<dt className="text-sm font-semibold leading-6 text-gray-500">Date:</dt>
							<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data?.date}</dd>
						</div>
					</dl>
				</div>

				<div className="p-2 flex items-center gap-4">
					<button className="p-3 w-full rounded-md bg-blue-500 text-white disabled:bg-blue-500/50" disabled={isAccepting} onClick={_acceptDepositRequest}>
						{!isAccepting && "Accept"}
						{isAccepting && <ShowSpinner />}
					</button>
					<button
						disabled={isDeclining}
						onClick={_declineDepositRequest}
						className="p-3 w-full rounded-md border-1 border-red-500 text-red-500 transition ease-in duration-300 hover:bg-red-500 hover:text-white disabled:border-red-500/50 disabled:hover:bg-red-500/50"
					>
						{!isDeclining && "Decline"}
						{isDeclining && <ShowSpinner />}
					</button>
				</div>
			</div>
		</>
	)
}
