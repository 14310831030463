import {
	AddPaymentMethod,
	FetchPaymentMethods,
	DeletePaymentMethods,
	FetchBanks
} from "./payment.apis"
export const userPaymentMethodApis = () => ({
	AddPaymentMethod,
	FetchPaymentMethods,
	DeletePaymentMethods,
	FetchBanks
})
