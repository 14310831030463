import React from "react"
import { countriesAndDailcode } from "services/utility/Utils/countryAndDailcode"
import { uniqueId } from "services/utility/Utils/Utils"

export default function CountryDailCodeSelector({
	inputName,
	defaultValue
}: {
	inputName?: string
	defaultValue?: string
}) {
	const countryList = countriesAndDailcode
	return (
		<select className="form-select form-select-lg" name={inputName ? inputName : "country_code"}>
			{countryList.map((country: object | any) => (
				<option
					key={uniqueId()}
					value={country.dialCode}
					selected={country.dialCode === defaultValue}
				>
					{country.name}({country.dialCode})
				</option>
			))}
		</select>
	)
}
