import { useFlusStores } from "@bilmapay/react-flux-storage"
import CustomModal from "components/misc/CustomModal"
import PasswordInput from "components/PasswordInput"
import { CONFIRM_PIN_MODAL, RELEASE_COIN_MODAL } from "flus/constants/modals.id.const"
import React from "react"

interface ConfirmPinModalProps {
	params?: any
	handleSubmit: (e: React.ChangeEvent<HTMLFormElement>) => void
}
export default function ConfirmPinModal({ params, handleSubmit }: ConfirmPinModalProps) {
	const { LockIcon } = useFlusStores()

	return (
		<CustomModal modalId={CONFIRM_PIN_MODAL}>
			<div>
				<img className="m-auto d-block" src={LockIcon} alt="" width="50" height="50" />
				<h4 className="text-center mt-3">
					<strong>Confirm PIN</strong>
				</h4>
				<p className="lead text-center text-muted">Enter your transaction pin to validate this release</p>
				<form className="user px-5" onSubmit={handleSubmit}>
					<PasswordInput inputName="pin" label="Transaction PIN" placeholder="Enter PIN" />

					<div className="btn-group m-auto d-block text-center mt-5" role="group">
						<button className="btn btn-primary me-4" type="button" data-bs-dismiss="modal">
							Cancel
						</button>
						<button className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#${RELEASE_COIN_MODAL}`} type="submit">
							Proceed
						</button>
					</div>
				</form>
			</div>
		</CustomModal>
	)
}
