import CardNavigator from "components/CardNavigator"
import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"

export default function WalletSend({ returnLink }: { returnLink: string }) {
	const { GiftImg } = useFlusStores()

	return (
		<CardNavigator returnLink={returnLink}>
			<h3 className="card-title mb-3">
				<img className="me-3" src={GiftImg} alt="" width="75" />
				<strong>Generate Gift Code</strong>
			</h3>
			<p className="lead text-success card-text">
				Get your gift code to be used on a variety of our <br />
				supported planform.
			</p>
			<form className="mt-3">
				<div className="mb-3">
					<span className="text-muted mb-2 d-block">Amount</span>
					<input
						className="form-control form-control-lg bk-bg-light"
						type="text"
						placeholder="Enter amount here"
					/>
					<small className="text-danger">You do not have up to the amount .</small>
				</div>
				<div>
					<div className="form-group">
						<label className="form-label w-100 input-label text-muted">
							<strong>Passphrase</strong>
							<br />
						</label>
						<input
							className="form-control form-control-lg password-toggle-input"
							type="password"
							placeholder="Enter a passphrase"
							name="password"
						/>
						<i className="fas fa-eye-slash img-icon toggle-password"></i>
					</div>
					<ul className="list-inline d-flex mt-2">
						<li className="list-inline-item w-100">Balance</li>
						<li className="list-inline-item text-end w-100">0.00 NGN</li>
					</ul>
				</div>
				<div className="my-4">
					<button className="btn btn-primary btn-lg w-100 bk-navy-blue py-3" type="submit">
						<strong>Generate Now</strong>
					</button>
					<span className="text-danger d-block text-center mt-1">
						Note: The generated gift code is only redeemable on
						<br />
						our supported platform!!!
					</span>
				</div>
			</form>
			<div className="or-divider">
				<hr />
				<span className="or-text">OR</span>
			</div>
			<form className="mt-3">
				<div className="mb-3">
					<span className="text-muted mb-2 d-block">Wallet ID</span>
					<input
						className="form-control form-control-lg bk-bg-light"
						type="text"
						placeholder="Enter Wallet ID"
					/>
					<small className="text-danger">
						Your balance is not enough to perform this transaction
					</small>
				</div>
				<div>
					<div className="form-group">
						<label className="form-label w-100 input-label text-muted">
							<strong>Passphrase</strong>
							<br />
						</label>
						<input
							className="form-control form-control-lg password-toggle-input"
							type="password"
							placeholder="Enter a passphrase"
							name="password"
						/>
						<i className="fas fa-eye-slash img-icon toggle-password"></i>
					</div>
					<small className="text-danger">
						Your balance is not enough to perform this transaction
					</small>
				</div>
				<div className="my-4">
					<button className="btn btn-primary btn-lg w-100 bk-navy-blue py-3" type="submit">
						<strong>Submit</strong>
					</button>
				</div>
			</form>
		</CardNavigator>
	)
}
