import React, { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { useDispatch, useSelector } from "react-redux"
import { getWithdrawalCashbridgePanel, toggleDepositCashBridgePanel, toggleWithdrawalCashBridgePanel } from "redux/reducers/panelsSlice"

import { getClientCurrentWithdrawStep } from "redux/reducers/withdrawalSlice"
import WithdrawalForm from "../withdrawal/WithdrawalForm"
import PayerDetails from "../withdrawal/PayerDetails"
import WithdrawalAwaitingPayment from "../withdrawal/WithdrawalAwaitingPayment"
import WithdrawalReleaseFunds from "../withdrawal/WithdrawalReleaseFunds"
import ReleaseWithdrawalForm from "../withdrawal/ReleaseWithdrawalForm"

export default function WithdrawalCashbridgePanel() {
	const { data, params, show } = useSelector(getWithdrawalCashbridgePanel)
	const currentStep = useSelector(getClientCurrentWithdrawStep)

	const dispatcher = useDispatch()

	const _handleClose = () => dispatcher(toggleWithdrawalCashBridgePanel({ status: false }))

	return (
		<Transition.Root show={show} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={_handleClose}>
				<Transition.Child as={Fragment} enter="ease-in-out duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto relative w-screen max-w-lg">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-500"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-500"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
											<button type="button" className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" onClick={_handleClose}>
												<span className="absolute -inset-2.5" />
												<span className="sr-only">Close panel</span>
												<XMarkIcon className="h-6 w-6" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									<div className="flex h-full right-0 flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="">
											<Dialog.Title className="px-4 sm:px-6 text-base uppercase font-semibold leading-6 text-gray-900">Bridge Withdrawal</Dialog.Title>
											<hr className="my-2 bg-gray-400" />
										</div>

										{currentStep === "withdrawal" && <WithdrawalForm />}

										{currentStep === "verify_payment" && <PayerDetails data={data} />}

										{currentStep === "awaiting_payment" && <WithdrawalAwaitingPayment />}

										{currentStep === "view_pop" && <WithdrawalReleaseFunds />}

										{currentStep === "release_funds" && <ReleaseWithdrawalForm />}
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
