import React from "react"
import { uniqueId } from "services/utility/Utils/Utils"

export const dropdownSelectedValueInputRef = React.createRef<HTMLInputElement>()
const dropdownSelectedValueImgRef = React.createRef<HTMLImageElement>()

export interface BKCustomSelectItems {
	/**
	 * The display name as the selected value.
	 */
	name: string
	/**
	 * The value to be set as the input value
	 */
	value: string
	/**
	 * And icon link absolute or relative url
	 */
	icon: string
	[k: string]: any
}

export interface BKCustomSelectItem {
	/**
	 * An array of list options to be display on the dropdown
	 */
	options: Array<BKCustomSelectItems>
	/**
	 * The underlaying input name for the dropdown
	 */
	name?: string
	onChange?: (params: BKCustomSelectItems) => void
	defaultValue?: string | any
	displayText?: string | any
	displayIcon?: string | any
	keyName?: string
	valueName?: string
	inputRef?: React.RefObject<HTMLInputElement>
	showIcon?: boolean
	showDefault?: boolean
}

export default function BKCustomSelect({ displayText, displayIcon, defaultValue, keyName, valueName, inputRef, options, name, onChange, showIcon = true, showDefault = true }: BKCustomSelectItem) {
	const dropdownMenuRef = React.createRef<HTMLElement>()
	const dropdownSelectedValueRef = React.createRef<HTMLParagraphElement>()

	// displaying default items
	const defaultItem = options[0]

	if (typeof displayText === "undefined" && typeof displayIcon === "undefined" && typeof defaultValue === "undefined") {
		// @ts-ignore
		displayText = defaultItem?.name ? defaultItem?.name : defaultItem[keyName]
		// @ts-ignore
		defaultValue = defaultItem?.value ? defaultItem?.value : defaultItem[valueName]

		displayIcon = defaultItem?.icon
	}

	React.useEffect(() => {
		if (showDefault) {
			if (dropdownSelectedValueInputRef?.current) {
				dropdownSelectedValueInputRef.current.value = defaultValue
			}
			if (inputRef?.current) {
				inputRef.current.value = defaultValue
			}
		}
	})

	React.useEffect(() => {
		document.body.addEventListener("click", function (e: any) {
			if ((e.target.classList.contains("bk__custom_dropdown") || e.target.classList.contains("bk__selected_value") || e.target.classList.contains("bk__dropdown_value")) === false) {
				if (dropdownMenuRef?.current) {
					if (dropdownMenuRef?.current?.classList.contains("show")) {
						dropdownMenuRef?.current?.classList.toggle("show")
					}
				}
			}
		})
	})

	const handleDropdownToggle = () => {
		if (dropdownMenuRef?.current) {
			dropdownMenuRef?.current.classList.toggle("show")
		}
	}

	const handleSelect = (selectedItem: BKCustomSelectItems) => {
		if (!showIcon && inputRef?.current) {
			// @ts-ignore
			dropdownSelectedValueRef.current.textContent = selectedItem?.name ? selectedItem?.name : selectedItem[keyName]
			// @ts-ignore
			inputRef.current.value = selectedItem?.value ? selectedItem?.value : selectedItem[valueName]
		} else {
			if (dropdownSelectedValueRef?.current && dropdownSelectedValueInputRef?.current) {
				// @ts-ignore
				dropdownSelectedValueRef.current.textContent = selectedItem?.name ? selectedItem?.name : selectedItem[keyName]
				// @ts-ignore
				dropdownSelectedValueInputRef.current.value = selectedItem?.value ? selectedItem?.value : selectedItem[valueName]
			}

			if (dropdownSelectedValueImgRef?.current) {
				if (selectedItem?.icon) {
					dropdownSelectedValueImgRef.current.src = selectedItem.icon
				}
			}
		}

		if (typeof onChange === "function") {
			onChange(selectedItem)
		}

		handleDropdownToggle()
	}

	return (
		<section className="bk__custom_dropdown">
			<section className="bk__dropdown_value" onClick={handleDropdownToggle}>
				{showIcon && <img ref={dropdownSelectedValueImgRef} className="me-2 d-inline" src={displayIcon} alt="" width="25" />}
				<p className="bk__selected_value d-inline" ref={dropdownSelectedValueRef}>
					{displayText ? displayText : "Select"}
				</p>
				<input type="hidden" name={name} ref={inputRef ? inputRef : dropdownSelectedValueInputRef} onChange={() => {}} />
			</section>

			<section className="bk__dropdown_menu" ref={dropdownMenuRef}>
				<div className="list-group list-group-flush nav-group bk__dropdown_items">
					{options?.length > 0 && (
						<>
							{options?.map(option => (
								<li onClick={() => handleSelect(option)} key={uniqueId()} className="list-group-item list-group-item-action bk__dropdown_item">
									{option?.icon && <img className="me-2" src={option?.icon} alt="" width="25" />}
									<span>
										{/* @ts-ignore */}
										<strong>{option?.name ? option?.name : option[keyName]}</strong>
									</span>
								</li>
							))}
						</>
					)}
				</div>
			</section>
		</section>
	)
}
