import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { Link, useLoaderData } from "react-router-dom"
import AppMenu from "./AppMenu"
import UserMobileMenu from "./UserMobileMenu"
import DashMobileNav from "./nav/DashMobileNav"
import DashDesktopNav from "./nav/DashDesktopNav"

export default function DashHeader() {
	const userProfile: any = useLoaderData()

	const { Dashbanner } = useFlusStores()

	return (
		<>
			{userProfile?.data?.verification_status === "0" && (
				<div className="alert alert-warning alert-dismissible" role="alert">
					<button className="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
					<span>Kindly verify your account to get full access to our services on Beekle</span>
					<Link className="btn btn-warning float-end" role={"button"} to="/dashboard/verification">
						Verify
					</Link>
				</div>
			)}
			<header className="bp_header bp_intro">
				<DashMobileNav />
				<DashDesktopNav />

				<section className="dash-banner">
					<img className="img-fluid" src={Dashbanner} alt="" />
				</section>

				<UserMobileMenu />
				<AppMenu />
			</header>
		</>
	)
}
