import React, { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { useDispatch, useSelector } from "react-redux"
import { getDepositCashbridgePanel, toggleDepositCashBridgePanel } from "redux/reducers/panelsSlice"
import DepositForm from "../deposit/DepositForm"
import IconSpinner from "components/icons/IconSpinner"
import { useMutation } from "react-query"
import { CheckDepositStatus, UploadDepositProof } from "services/Api/cashbridge/cashbridge.apis"
import PayeeDetails from "../deposit/PayeeDetails"
import FileUpload from "components/misc/FileUpload"
import { ShowError, ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { changeClientStep, getClientCurrentStep, setClientDepositTimer, stopClientDepositTimer } from "redux/reducers/depositSlice"
import { sleep } from "services/utility/Utils/_sleep"
import EscalateDepositForm from "../deposit/EscalateDepositForm"
import AwaitDepositAcceptance from "../deposit/AwaitDepositAcceptance"
import AwaitClientDepositRelease from "../deposit/AwaitClientDepositRelease"

export default function DepositCashbridgePanel() {
	const { data, params, show } = useSelector(getDepositCashbridgePanel)
	const currentStep = useSelector(getClientCurrentStep)

	const [timerId, setTimerId] = React.useState<NodeJS.Timer>()
	const [uploadPOP, setUploadPOP] = React.useState<boolean>(false)

	const dispatcher = useDispatch()

	const _handleClose = () => dispatcher(toggleDepositCashBridgePanel({ status: false }))

	const checkDepositStatusApi = useMutation(CheckDepositStatus, {
		onSuccess(res: any) {
			if (res?.status) {
				switch (res?.data?.status_code?.toString()) {
					case "1":
						dispatcher(stopClientDepositTimer(res?.data))

						dispatcher(
							toggleDepositCashBridgePanel({
								params: res?.data?.txn_id,
								data: res?.data
							})
						)
						sleep(100).then(() => dispatcher(changeClientStep("deposit_details")))

						break

					case "2":
						dispatcher(stopClientDepositTimer(res?.data))

						ShowError("Declined", res?.data?.status)
						dispatcher(changeClientStep("deposit"))

						_handleClose()

						break
					default:
				}
			}
		}
	})

	const checkDepositReleaseStatusApi = useMutation(CheckDepositStatus, {
		onSuccess(res: any) {
			if (res?.status) {
				if (res?.data?.status_code === "5") {
					if (timerId) {
						window.clearInterval(timerId)
					}

					ShowSuccess("Success", "Deposit released successfully!")

					sleep(900).then(() => {
						window?.location?.reload()
					})
				}
			}
		}
	})

	const isChecking = checkDepositStatusApi.isLoading || checkDepositReleaseStatusApi.isLoading

	React.useEffect(() => {
		const intervalId = setInterval(() => {
			if (data && params) {
				checkDepositStatusApi.mutateAsync({ txn_id: params })
			}
		}, 9000)

		dispatcher(setClientDepositTimer(intervalId))
		return () => {
			if (timerId) {
				window.clearInterval(timerId)
			}
		}
	}, [params])

	const _checkDepositStatus = () => {
		const timer = setInterval(() => {
			checkDepositReleaseStatusApi.mutateAsync({ txn_id: params })
		}, 9000)

		dispatcher(setClientDepositTimer(timer))
	}

	// -------------------> [Make Deposit]
	const _handleMakeDeposit = () => {
		setUploadPOP(true)
		sleep(100).then(() => dispatcher(changeClientStep("upload_pop")))
	}
	// ------------------> [Upload POP]
	const uploadDepositProofOfPaymentApi = useMutation(UploadDepositProof, {
		onSuccess(res: any) {
			if (res?.status) {
				_checkDepositStatus()

				ShowSuccess("Success", res?.message)
				setUploadPOP(false)
				dispatcher(
					toggleDepositCashBridgePanel({
						params: res?.data?.txn_id,
						data: res?.data
					})
				)

				sleep(100).then(() => dispatcher(changeClientStep("awaiting_release")))
			} else ShowServerErrors("Error", res)
		}
	})
	const isUploading = uploadDepositProofOfPaymentApi.isLoading

	const _handleUpload = (data: FormData) => uploadDepositProofOfPaymentApi.mutateAsync(data)

	return (
		<Transition.Root show={show} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={_handleClose}>
				<Transition.Child as={Fragment} enter="ease-in-out duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto relative w-screen max-w-lg">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-500"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-500"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
											<button type="button" className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" onClick={_handleClose}>
												<span className="absolute -inset-2.5" />
												<span className="sr-only">Close panel</span>
												<XMarkIcon className="h-6 w-6" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									<div className="flex h-full right-0 flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="">
											<Dialog.Title className="px-4 sm:px-6 text-base uppercase font-semibold leading-6 text-gray-900">Bridge Deposit</Dialog.Title>
											<hr className="my-2 bg-gray-400" />
										</div>

										{currentStep === "deposit" && <DepositForm />}

										{currentStep === "awaiting_acceptance" && <AwaitDepositAcceptance isChecking={isChecking} />}

										{currentStep === "deposit_details" && <PayeeDetails onMadePayment={_handleMakeDeposit} data={data} />}

										{currentStep === "upload_pop" && <FileUpload name="pop" transactionId={data?.txn_id} handleUpload={_handleUpload} isLoading={isUploading} />}

										{currentStep === "awaiting_release" && <AwaitClientDepositRelease />}

										{currentStep === "escalate" && <EscalateDepositForm tranxId={params} />}
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
