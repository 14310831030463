import { initializeApp } from "firebase/app"
import { getMessaging, getToken } from "firebase/messaging"

// Your web app's Firebase configuration
export const firebaseConfig = {
	apiKey: "AIzaSyAg57LFi4j3VESSNfBCgFIElnHMwWOi_oI",
	authDomain: "beekle-9aa1a.firebaseapp.com",
	databaseURL: "https://beekle-9aa1a-default-rtdb.firebaseio.com",
	projectId: "beekle-9aa1a",
	storageBucket: "beekle-9aa1a.appspot.com",
	messagingSenderId: "280681239508",
	appId: "1:280681239508:web:6a0c2d929957f69ef5fc41"
}

export const VAPID_KEY = "BEbkUi_RlMX_eU4BPahL3vXARRm_lNMgf08O8ZJ75wxMmXfg_-bo1HGCRMD_9CSOFu4WYloI66zlWGqXVVjdKCk"

// Initialize Firebase
export const fbApp = initializeApp(firebaseConfig)

// Initialize Firebase Cloud Messaging and get a reference to the service
export const fbMessaging = getMessaging(fbApp)

export const requestFBToken = async (swRegistration: ServiceWorkerRegistration) => {
	return await getToken(fbMessaging, {
		vapidKey: VAPID_KEY,
		serviceWorkerRegistration: swRegistration
	}).catch(err => {
		console.log("An error occurred while retrieving token. ", err)
		// ...
	})
}
