import { config } from "configs/config"
import bootstrapMin from "bootstrap/dist/js/bootstrap.bundle"
import $ from "jquery"

/**
 * Generate a unique id for use with map as keys or any other purpose.
 * @param {number} length The length of the unique id
 * @returns string
 */
function uniqueId(length = 20) {
	let result = ""
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
	const charactersLength = characters.length
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}
	return result
}

/**
 * Use the countdownTimer utility to set a timeout counter 
 * @param {number|string} mins The minutes to count down to
 * @param {function(boolean,number,number)} callback The callback handle which will be called 
after the count down minit has finished counting.
 */
function countdownTimer(mins = 10, callback = null) {
	let timer = mins * 60,
		minutes,
		seconds
	const x = setInterval(function () {
		minutes = parseInt(timer / 60, 10)
		seconds = parseInt(timer % 60, 10)

		minutes = minutes < 10 ? `0${minutes}` : minutes
		seconds = seconds < 10 ? `0${seconds}` : seconds

		if (typeof callback === "function") {
			callback(true, minutes, seconds)
		}

		if (--timer < 0) {
			timer = 0
			window.clearInterval(x)
			// timer = duration; // uncomment this line to reset timer automatically after reaching 0
			return typeof callback === "function" ? callback(false, minutes, seconds) : ""
		}
	}, 1000)
}


 


/**
 * Sort the given array in alphabetical other
 * @param {array} arr The array to be sorted
 * @returns array
 */
function ArraySortAlpha(arr = []) {
	// make sure it's an array the user is providing
	if (!Array.isArray(arr)) return arr // return whatever user provided back

	return arr.sort((a, b) => {
		if (a.name.toUpperCase() < b.name.toUpperCase()) {
			return -1
		} else if (a.name.toUpperCase() > b.name.toUpperCase()) {
			return 1
		} else {
			return 0
		}
	})
}

/**
 * Handler server error messages
 * @param {Throwable} error a throwable error
 */
function handleError(error) {
	try {
		
	} catch (error) {}
}

/**
 * The method converts an object keys/value to a form data and return the form data
 * @param {object} params An object with key->value
 */
function setFormData(params = {}) {
	if (Object.keys(params).length) {
		const formdata = new FormData()
		for (const formField in params) {
			formdata.append(formField, params[formField])
		}

		return formdata
	}

	return params
}

/**
 * Load a remote resource asset
 * @param {string} assetFilePath The remote file path
 * @returns string
 */
function asset(assetFilePath) {
	if (!assetFilePath) {
		return
	}

	if (assetFilePath.includes("storage")) return `${config("app.baseUrl")}/${assetFilePath}`

	return assetFilePath
}

/** Decrypt a base46 string to a normal string */
function base64Decode(encode_string) {
	return encode_string ? window.atob(encode_string) : encode_string
}

/** Encrypt a string to base64 string */

function base64Encode(data) {
	return data ? window.btoa(data) : data
}

/**
 * Initialize bootstrap tooltip on elements
 */
function initBSTooltip() {
	const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
	return tooltipTriggerList.map(function (tooltipTriggerEl) {
		return new bootstrapMin.Tooltip(tooltipTriggerEl)
	})
}

 

 

/**
 * Shuffle array randomly
 * @param {array} arr The array to shuffle
 */
function arrayShuffleRandomizer(arr) {
	let currentIndex = arr.length,
		randomIndex

	/* whle there remain elements to suffle.  */
	while (currentIndex !== 0) {
		/* pick a remaining element. */
		randomIndex = Math.floor(Math.random() * currentIndex)

		currentIndex--

		/* Swap it with the current element */
		;[arr[currentIndex], arr[randomIndex]] = [arr[randomIndex], arr[currentIndex]]
	}

	return arr
}

function getDiscount(_amount, discount) {
	const _discountVal = _amount * (discount / 100)
	const _disc = Math.round(_discountVal * 100) / 100

	const newAmount = _amount - _disc

	return {
		amount: newAmount,
		discount: _disc
	}
}

/* Export utilities modules  */
export { uniqueId, countdownTimer, ArraySortAlpha, handleError, setFormData, base64Decode, base64Encode, asset, initBSTooltip,  arrayShuffleRandomizer, getDiscount }
