import {
	FetchCSRFXSSToken,
	CreateAccount,
	LoginAccount,
	VerifyEmail,
	ResendVerificationLink,
	ChangeVerificationEmail,
	ForgetPassword,
	VerifyPasswordReset,
	ResetAccountPassword,
	FinalizeGoogleAuth,
	LogoutAccount
} from "./auth.apis"

export const useAuthApis = () => ({
	FetchCSRFXSSToken,
	CreateAccount,
	LoginAccount,
	VerifyEmail,
	ResendVerificationLink,
	ChangeVerificationEmail,
	ForgetPassword,
	VerifyPasswordReset,
	ResetAccountPassword,
	FinalizeGoogleAuth,
	LogoutAccount
})
