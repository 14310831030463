import AwaitResponse from "components/AwaitResponse"
import React from "react"
import { useMutation } from "react-query"
import { Link, useLoaderData } from "react-router-dom"
import { userMarketplaceApis } from "services/Api/marketplace/marketplace.index"
import { ShowServerErrors } from "services/utility/Utils/_toaster"
import { AssetsListItemProps, BackendResponse } from "types/app.types"
import { uniqueId } from "services/utility/Utils/Utils"
import { encryptString } from "services/utility/Utils/utilities"
import EmptyContentMessage from "components/EmptyContentMessage"
import NextPrevPagination from "components/misc/NextPrevPagination"
import { useDispatch, useSelector } from "react-redux"
import { getMK_DSK_AssetId, getMarketplaceBuyAsset, getMarketplaceSellAsset, setDashboardState } from "redux/reducers/dashboardSlice"

interface DataTableProps {
	type?: "buy-btn" | "sell-btn"
}


export default function DataTable({ type = "buy-btn" }: DataTableProps) {
	const marketplaceBuyAssets = useSelector(getMarketplaceBuyAsset)
	const marketplaceSellAssets = useSelector(getMarketplaceSellAsset)
	const mk_dsk_assetId = useSelector(getMK_DSK_AssetId)

	const dispatcher = useDispatch()

	const assetList: any = useLoaderData()

	const { data }: { data: Array<AssetsListItemProps> } = assetList

	const { FetchBuyMarketplace, FetchSellMarketplace } = userMarketplaceApis()

	const fetchBuyAssets = useMutation(FetchBuyMarketplace, {
		onSuccess: (res: BackendResponse | any) => {
			if (res?.status) {
				dispatcher(setDashboardState({ key: "marketplaceBuyAssets", value: res?.data }))
			} else ShowServerErrors("Error", res)
		}
	})

	const fetchSellAssets = useMutation(FetchSellMarketplace, {
		onSuccess: (res: BackendResponse | any) => {
			if (res?.status) {
				dispatcher(setDashboardState({ key: "marketplaceSellAssets", value: res?.data }))
			} else ShowServerErrors("Error", res)
		}
	})

	const handleAssetFetching = async (param: object = { assetId: data[0]?.asset_id }) => {
		return type === "buy-btn" ? await fetchBuyAssets.mutateAsync(param) : await fetchSellAssets.mutateAsync(param)
	}

	const paginator = type === "buy-btn" ? fetchBuyAssets : fetchSellAssets

	const pagination = marketplaceBuyAssets?.pagination

	const onNext = (_page: string | null) => ({ assetId: mk_dsk_assetId ? mk_dsk_assetId : data[0]?.asset_id, page: _page })
	const onPrev = (_page: string | null) => ({ assetId: mk_dsk_assetId ? mk_dsk_assetId : data[0]?.asset_id, page: _page })

	return (
		<AwaitResponse api={handleAssetFetching}>
			{/* Buy Tab Content */}
			{marketplaceBuyAssets?.data && type === "buy-btn" && (
				<>
					<div className="table-responsive mt-3 border-0">
						<table className="table table-hover table-sm table-bordered">
							<thead>
								<tr>
									<th className="text-center">
										Advertisers <br />
										(Total Asset)
									</th>
									<th className="text-center">Trade Rate (NGN)</th>
									<th className="text-center">
										Trade Limit (NGN)
										<br />
										Order Completed
									</th>
									<th className="text-center">Payment Method</th>
									<th className="text-center">Action</th>
								</tr>
							</thead>
							<tbody>
								{marketplaceBuyAssets?.data?.length > 0 && (
									<>
										{marketplaceBuyAssets?.data.map(asset => (
											<tr key={uniqueId()}>
												<td>
													<div>
														<div className="d-flex justify-content-start align-items-center">
															<img src={asset?.user_photo} alt="" width="25" />
															<strong className="ms-1">{asset?.user_name}</strong>
														</div>
														<div>
															<p className="text-primary">
																{asset?.remaining_unit} {asset?.asset_symbol}
															</p>
														</div>
													</div>
												</td>
												<td className="text-center">{asset?.rate_per_coin} NGN</td>
												<td className="text-center">
													{asset?.trade_limit}
													<p className="text-primary">
														{asset?.total_order} Orders ({asset?.percentage_score})
													</p>
												</td>
												<td className="text-center">Bank Transfer</td>
												<td className="text-center">
													<Link to={`/dashboard/buy-coin?pl=${encryptString(JSON.stringify(asset))}`} className={`btn btn-success bk__mkp_action_btn mt-3 ${type}`} role="button">
														Buy {asset?.asset_symbol}
													</Link>
												</td>
											</tr>
										))}
									</>
								)}
							</tbody>
						</table>

						{(!marketplaceBuyAssets?.data || marketplaceBuyAssets?.data?.length <= 0) && <EmptyContentMessage text="No content!" />}
					</div>
				</>
			)}

			{/* Sell Tab Content */}
			{marketplaceSellAssets?.data && type === "sell-btn" && (
				<>
					<div className="table-responsive mt-3 border-0">
						<table className="table table-hover table-sm table-bordered">
							<thead>
								<tr>
									<th className="text-center">
										Advertisers <br />
										(Total Asset)
									</th>
									<th className="text-center">Trade Rate (NGN)</th>
									<th className="text-center">
										Trade Limit (NGN)
										<br />
										Order Completed
									</th>
									<th className="text-center">Payment Method</th>
									<th className="text-center">Action</th>
								</tr>
							</thead>
							<tbody>
								{marketplaceSellAssets?.data?.length > 0 && (
									<>
										{marketplaceSellAssets?.data.map(asset => (
											<tr key={uniqueId()}>
												<td>
													<div>
														<div className="d-flex justify-content-start align-items-center">
															<img src={asset?.user_photo} alt="" width="25" />
															<strong className="ms-1">{asset?.user_name}</strong>
														</div>
														<div>
															<p className="text-primary">
																{asset?.remaining_unit} {asset?.asset_symbol}
															</p>
														</div>
													</div>
												</td>
												<td className="text-center">{asset?.rate_per_coin} NGN</td>
												<td className="text-center">
													{asset?.trade_limit}
													<p className="text-primary">
														{asset?.total_order} Orders ({asset?.percentage_score})
													</p>
												</td>
												<td className="text-center">{asset?.payment_method}</td>
												<td className="text-center">
													<Link to={`/dashboard/sell-coin?pl=${encryptString(JSON.stringify(asset))}`} className={`btn btn-success bk__mkp_action_btn mt-3 ${type}`} role="button">
														Sell {asset?.asset_symbol}
													</Link>
												</td>
											</tr>
										))}
									</>
								)}
							</tbody>
						</table>
						{(!marketplaceSellAssets?.data || marketplaceSellAssets?.data?.length <= 0) && <EmptyContentMessage text="No content!" />}
					</div>
				</>
			)}

			<NextPrevPagination pagination={pagination} paginator={paginator} onNext={onNext} onPrev={onPrev} />
		</AwaitResponse>
	)
}
