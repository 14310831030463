import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"

export default function FaqsHeader() {
	const { faqsBg } = useFlusStores()
	return (
		<section>
			<img className="img-fluid" src={faqsBg} alt="FAQs" />
		</section>
	)
}
