import classNames from "classnames"
import Modal from "components/Modal"
import { TRANS_DETAILS } from "flus/constants/modals.id.const"
import React from "react"
import { useSelector } from "react-redux"
import { getTransactionDetails } from "redux/reducers/dashboardSlice"
import { uniqueId } from "services/utility/Utils/Utils"

export default function TransactionDetail() {
	const tranxDetails = useSelector(getTransactionDetails)

	return (
		<Modal uniqueId={TRANS_DETAILS} title="Payment Details">
			{tranxDetails && (
				<>
					<p>This page shows full details of your transaction</p>
					<span className="p-3 w-100 d-block mb-3" style={{ backgroundColor: "rgba(0, 122, 255, 0.11)" }}></span>

					<div className="text-end">
						<img className="me-2" src={tranxDetails?.asset_icon} style={{ width: "25px", height: "25px", borderRadius: "100%" }} alt="" />

						<strong>{tranxDetails?.asset_name}</strong>
					</div>

					<h3 className="text-blue">Details</h3>
					<div className="text-start">
						{tranxDetails?.mode === "Transfer" && (
							<p className="text-primary">
								<strong className="text-muted">Transaction Date:</strong> {tranxDetails?.date}
								<br />
								<strong className="text-muted">Transaction Type:</strong> {tranxDetails?.mode}
								<br />
								<strong className="text-muted">Description:</strong> {tranxDetails?.description}
								<br />
								<strong className="text-muted">Transaction Amount:</strong> {tranxDetails?.amount}
								<br />
								<strong className="text-muted">Quantity:</strong> {tranxDetails?.quantity}
							</p>
						)}

						{/* for Sell & Buy */}
						{(tranxDetails?.mode === "Sell" || tranxDetails?.mode === "Buy") && (
							<>
								<p className="text-primary">
									{tranxDetails?.mode === "Buy" ? (
										<>
											<strong className="text-muted">Seller:</strong> {tranxDetails?.seller}
											<br />
										</>
									) : (
										<>
											<strong className="text-muted">Buyer:</strong> {tranxDetails?.buyer}
											<br />
										</>
									)}
									<strong className="text-muted">Transaction Type:</strong> {tranxDetails?.mode}
									<br />
									<strong className="text-muted">Transaction Amount:</strong> {tranxDetails?.amount}
									<br />
									<strong className="text-muted">Quantity:</strong> {tranxDetails?.quantity}
									<br />
									<strong className="text-muted">Payment Method:</strong> {tranxDetails?.payment_method}
									<br />
									<strong className="text-muted">Order ID:</strong> {tranxDetails?.order_id}
								</p>

								<span className="p-3 w-100 d-block" style={{ backgroundColor: "rgba(0, 122, 255, 0.11)" }}></span>
								<h4 className="py-2">Banking Details</h4>

								<p className="text-primary">
									{tranxDetails?.account_details?.map(bank => (
										<React.Fragment key={uniqueId()}>
											<strong className="text-muted">Account Name:</strong> {bank?.account_name}
											<br />
											<strong className="text-muted">Bank Name:</strong> {bank?.bank_name}
											<br />
											<strong className="text-muted">Account Number:</strong> {bank?.account_number}
											<br />
											<br />
										</React.Fragment>
									))}
								</p>
							</>
						)}

						{/* for Receive & Send */}
						{(tranxDetails?.mode === "Receive" || tranxDetails?.mode === "Send") && (
							<p className="text-primary">
								<strong className="text-muted">Address:</strong> {tranxDetails?.address}
								<br />
								<strong className="text-muted">Destination Tag:</strong> {tranxDetails?.destination_tag}
								<br />
								<strong className="text-muted">Label:</strong> {tranxDetails?.label}
								<br />
								<strong className="text-muted">Gas Fee:</strong> {tranxDetails?.gas_fee}
								<br />
								<strong className="text-muted">Quantity:</strong> {tranxDetails?.quantity}
								<br />
								<strong className="text-muted">Confirmation:</strong> {tranxDetails?.confirmation}
							</p>
						)}
					</div>
					<span className="p-3 w-100 d-block" style={{ backgroundColor: "rgba(0, 122, 255, 0.11)" }}></span>

					<h3
						className={classNames({
							"text-start": true,
							"text-warning": tranxDetails?.status?.toLowerCase() === "pending",
							"text-success": tranxDetails?.status?.toLowerCase() === "success",
							"text-danger": tranxDetails?.status?.toLowerCase() === "fail"
						})}
					>
						{tranxDetails?.status}
					</h3>
				</>
			)}
		</Modal>
	)
}
