import React from "react"
import { useLoaderData } from "react-router-dom"
import { userPaymentMethodApis } from "services/Api/payments/payment.index"
import { useMutation } from "react-query"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { useFlusDispatcher, useFlusStores, FLUX_DYNAMIC_STORE } from "@bilmapay/react-flux-storage"
import ShowSpinner from "components/misc/Spinner"
import { uniqueId } from "services/utility/Utils/Utils"
import EmptyContentMessage from "components/EmptyContentMessage"
import AwaitResponse from "components/AwaitResponse"
import { useDispatch, useSelector } from "react-redux"
import { getPaymentMethods, setPaymentMethods } from "redux/reducers/walletSlice"

const formRef = React.createRef<HTMLFormElement>()

export default function AddPaymentMethod() {
	const dispatcher = useDispatch()
	const paymentMethodList = useSelector(getPaymentMethods)
	const banksResponse: any = useLoaderData()

	const { data }: { data: string[] } = banksResponse

	const { FetchPaymentMethods, AddPaymentMethod, DeletePaymentMethods } = userPaymentMethodApis()

	const fetchPaymentMethodApi = useMutation(FetchPaymentMethods, {
		onSuccess: res => {
			if (res?.status) {
				if (formRef?.current) {
					formRef?.current?.reset()
				}

				dispatcher(setPaymentMethods(res?.data))
			}
		}
	})

	const isFetchingPaymentMethods = fetchPaymentMethodApi.isLoading

	const addPaymentMethodApi = useMutation(AddPaymentMethod, {
		onSuccess: res => {
			if (res?.status) {
				ShowSuccess("Paymenth Method", "Payment Method saved successfully!")

				fetchPaymentMethodApi.mutateAsync()
			} else {
				ShowServerErrors("Error", res)
			}
		}
	})

	const isAddingPaymentMethod = addPaymentMethodApi.isLoading

	const deletePaymentMethodApi = useMutation(DeletePaymentMethods, {
		onSuccess: res => {
			if (res?.status) {
				fetchPaymentMethodApi.mutateAsync()
			} else ShowServerErrors("Payment Method", res)
		}
	})

	const isDeletingPaymentMethod = deletePaymentMethodApi.isLoading

	const handlePaymentMethodAdding = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		addPaymentMethodApi.mutateAsync(formData)
	}

	const handlerUserAccoutDeletion = (bankId: string) => {
		const fd = new FormData()
		fd.append("payment_method_id", bankId)

		deletePaymentMethodApi.mutate(fd)
	}

	return (
		<section className="p-3 bg-white">
			<header className="mb-3">
				<h1>
					<strong>Add Payment Method</strong>
				</h1>
				<p>Ensure you verify the payment method and details you provided avoid loss of funds during the process of withdrawal. Check our user policy an guide of usage to know more. </p>
			</header>
			<section className="py-4 border-top border-bottom">
				<form onSubmit={handlePaymentMethodAdding} ref={formRef}>
					<h3 className="text-blue border-bottom pb-3">
						<strong>Add Bank Account</strong>
					</h3>
					<div className="mb-3">
						<label className="form-label">
							<strong>Bank Name</strong>
						</label>
						<select className="form-select form-select-lg" required name="bank_name">
							<option>Select Bank</option>
							{data.map((bank?: any) => (
								<option value={bank?.name} key={bank?.code}>
									{bank?.name}
								</option>
							))}
						</select>
					</div>
					<div className="mb-3">
						<label className="form-label">
							<strong>Account Holder</strong>
						</label>
						<input name="account_name" required className="form-control form-control-lg" type="text" />
					</div>
					<div className="mb-3">
						<label className="form-label">
							<strong>Account Number</strong>
						</label>
						<input name="account_number" required className="form-control form-control-lg" type="text" />
					</div>
					<button disabled={isAddingPaymentMethod || isDeletingPaymentMethod || isFetchingPaymentMethods} className="btn btn-primary btn-lg m-auto d-block w-75 bg-blue" type="submit">
						{!isAddingPaymentMethod && "Add Bank"}
						{isAddingPaymentMethod && <ShowSpinner color="text-light" />}
					</button>
				</form>
			</section>
			<section className="py-4 border-top">
				<h3 className="text-blue mb-3">
					<strong>Added banks</strong>
					{isDeletingPaymentMethod && (
						<>
							<span className="ms-4">
								<em>Deleting...</em> <ShowSpinner />
							</span>
						</>
					)}
				</h3>
				<AwaitResponse api={fetchPaymentMethodApi.mutateAsync}>
					<div className="table-responsive">
						<table className="table table-bordered">
							<thead>
								<tr>
									<th className="table-light">Date Added</th>
									<th className="table-light">Bank Name</th>
									<th className="table-light">Account Holder</th>
									<th className="table-light">Account Number</th>
									<th className="table-light">Account</th>
								</tr>
							</thead>
							<tbody>
								{!isFetchingPaymentMethods && (
									<>
										{paymentMethodList && paymentMethodList?.length > 0 && (
											<>
												{paymentMethodList.map((paymentMethod: any) => (
													<tr key={uniqueId()}>
														<td className="text-muted">
															<strong>{paymentMethod?.date}</strong>
														</td>
														<td className="text-danger">{paymentMethod?.bank_name}</td>
														<td>{paymentMethod?.account_name}</td>
														<td>{paymentMethod?.account_number}</td>
														<td>
															<button disabled={isDeletingPaymentMethod} className="btn btn-danger w-100" type="button" onClick={() => handlerUserAccoutDeletion(paymentMethod?.payment_method_id)}>
																Delete
															</button>
														</td>
													</tr>
												))}
											</>
										)}
									</>
								)}
							</tbody>
						</table>

						{(!paymentMethodList || paymentMethodList?.length <= 0) && (
							<>
								<EmptyContentMessage text="No Bank added yet" />
							</>
						)}
					</div>
				</AwaitResponse>
			</section>
		</section>
	)
}
