import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import PageInfo from "components/misc/Page"
import SignupForm from "./SignupForm"
import { config } from "configs/config"

export default function SignupPage() {
	const { LOGO, SignupImg, GoogleIcon } = useFlusStores()

	const googleAuth = config("googleAuth")
	return (
		<>
			<PageInfo titleText="Sign Up" />

			<section>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-9 col-lg-12 col-xl-10">
							<div className="card o-hidden border-0 my-5">
								<div className="card-body p-0">
									<div className="row">
										<div className="col-lg-6 d-none d-lg-flex p-4 bg-light-blue">
											<div className="d-flex flex-grow-1 justify-content-center align-items-center auth-bg-image">
												<img src={SignupImg} alt="" width="350" />
											</div>
										</div>
										<div className="col-lg-6">
											<div className="p-5">
												<div className="text-center">
													<img src={LOGO} alt="" width="50" />
													<h4 className="text-dark mb-4 mt-3">
														Create an Account <br />
														with{" "}
														<span className="text-blue">
															<strong>Beekle</strong>
														</span>
													</h4>
												</div>
												<a
													href={googleAuth}
													className="btn btn-primary d-block login_with-google btn-user w-100 mb-2 text-dark"
													role="button"
												>
													<img src={GoogleIcon} alt="" width="18" /> Continue with Google
												</a>
												<SignupForm />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
