import { GET, POST, PUT, DROP } from "configs/api"
import { handleError } from "services/utility/Utils/Utils"
import { transactionEndpoints } from "./transactions.endpoints"

export const GetTransactions = async (page: string = "1") => {
	try {
		return await GET(transactionEndpoints.GET(page))
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchTransaction = async (assetId: string) => {
	try {
		return await GET(transactionEndpoints.FETCH(assetId))
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const GetRecentTransactions = async (page: string = "1") => {
	try {
		return await GET(transactionEndpoints.RECENT(page))
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FilterTransaction = async (params: FormData | object) => {
	try {
		return await POST(transactionEndpoints.FILTER, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchTransactionDetails = async (params: FormData | object) => {
	try {
		return await POST(transactionEndpoints.DETAILS, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}
