import AwaitResponse from "components/AwaitResponse"
import CardNavigator from "components/CardNavigator"
import SellerPaymentChannels from "components/misc/SellerPaymentChannels"
import React from "react"
import { useMutation } from "react-query"
import { Link } from "react-router-dom"
import { userMarketplaceApis } from "services/Api/marketplace/marketplace.index"
import { useUrlQuery } from "services/utility/hooks/useURL"
import { secondsCountdownTimer } from "services/utility/Utils/utilities"
import { ShowServerErrors } from "services/utility/Utils/_toaster"
import { useDispatch, useSelector } from "react-redux"
import { getBuyAssetDetails, setBuyAssetDetails } from "redux/reducers/dashboardSlice"

const timerRef = React.createRef<HTMLElement>()

export default function BuyCoinDetails() {
	const _buyAssetDetails = useSelector(getBuyAssetDetails)
	const dispatcher = useDispatch()

	/* Get the payload and order_token */
	const { ot } = useUrlQuery()

	const { BuyAssetDetails } = userMarketplaceApis()

	const buyAssetDetailsApi = useMutation(BuyAssetDetails, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(setBuyAssetDetails(res?.data))
			} else ShowServerErrors("Error", res)
		}
	})

	const handleAssetDetailsFetching = async () => {
		const fd = new FormData()
		fd.append("order_token", ot)
		return buyAssetDetailsApi.mutateAsync(fd)
	}

	React.useEffect(() => {
		if (_buyAssetDetails?.remaining_minutes) {
			const timer: unknown = _buyAssetDetails?.remaining_minutes
			secondsCountdownTimer(timer as number, (isFinished, timeLef) => {
				if (timerRef?.current) {
					const seconds: unknown = timeLef
					const timerSec = seconds as string
					timerRef.current.innerHTML = `${timerSec}s`
				}
			})
		}
	})

	return (
		<CardNavigator returnLink="/dashboard">
			<AwaitResponse api={handleAssetDetailsFetching}>
				<section>
					<header className="mb-4">
						<h1 className="mb-3 text-blue">
							<strong>Order Details</strong>
							<br />
						</h1>
					</header>
					<form className="trade-form">
						<section className=" mb-3">
							<header>
								<div className="row">
									<div className="col-md-6 col-lg-6 mb-3 d-flex justify-content-start align-items-center">
										<h4 className="seller-object-name">
											<br />
											<strong className="name-icon">{_buyAssetDetails?.user_name[0]}</strong> {_buyAssetDetails?.user_name}
										</h4>
									</div>
									<div className="col-md-6 col-lg-6 mb-3 d-flex justify-content-start align-items-center">
										<h5>
											Order end in: 
											<span className="text-danger" ref={timerRef}>
												0
											</span>
										</h5>
									</div>
								</div>
							</header>
							<div className="table-responsive border rounded">
								<table className="table table-borderless">
									<tbody>
										<tr>
											<td>
												<strong>Rate per USD:</strong>
											</td>
											<td className="text-end">{_buyAssetDetails?.rate_per_coin} NGN</td>
										</tr>
										<tr>
											<td>
												<strong>Response Time (Avg):</strong>
											</td>
											<td className="text-end">{_buyAssetDetails?.response_time}</td>
										</tr>
										<tr>
											<td>
												<strong>Asset Amount: </strong>
											</td>
											<td className="text-end">{_buyAssetDetails?.asset_amount}</td>
										</tr>
										<tr>
											<td>
												<strong>Amount Payable:</strong>
											</td>
											<td className="text-end">{_buyAssetDetails?.amount_payable} NGN</td>
										</tr>
										<tr>
											<td>
												<strong>Order ID:</strong>
											</td>
											<td className="text-end">
												{_buyAssetDetails?.order_no}
												<i className="fa fa-copy ms-3 pointer"></i>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</section>
						<section className="">
							<ul className="list-inline d-flex">
								<li className="list-inline-item w-100">
									<strong>
										<em>Payment Methods:</em>
									</strong>
								</li>
								<li className="list-inline-item w-100 text-primary">
									<em>{_buyAssetDetails?.payment_method}</em>
								</li>
							</ul>

							<SellerPaymentChannels paymentChannels={_buyAssetDetails?.account_details as []} />
						</section>
						<section className=" text-center">
							<div className="btn-group btn-group-lg w-100" role="group">
								<Link to={`/dashboard/cancel-order?ot=${ot}`} className="btn btn-light btn-lg me-3 w-100" type="button">
									Cancel
								</Link>
								<Link className="btn btn-primary bg-blue w-100 " role="button" to={`/dashboard/buy-coin/make-payment?ot=${ot}`}>
									Proceed
								</Link>
							</div>
						</section>

						<a href={`tel:${_buyAssetDetails?.seller_phone_number}`} className="badge bg-primary traders-chat-toggler bg-blue text-white justify-content-center align-content-center pt-3">
							<i className="fas fa-phone"></i>
						</a>
					</form>
					<article className="mb-4 mt-4  bg-gray">
						<h5 className="text-success">
							<strong>Trading Terms</strong>
						</h5>
						<p>
							<strong>{_buyAssetDetails?.trading_terms}</strong>
						</p>
					</article>
				</section>
			</AwaitResponse>
		</CardNavigator>
	)
}
