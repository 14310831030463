import { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { PaperClipIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { useDispatch, useSelector } from "react-redux"
import { getLimitPanel, toggleLimitPanel } from "redux/reducers/panelsSlice"
import { ErrorMessage, Field, Form, Formik } from "formik"
import ShowSpinner from "components/misc/Spinner"
import { setLimitValidatorScheme } from "libs/validator/miscValidators"
import { useMutation } from "react-query"
import { UpdateMerchantLimit } from "services/Api/merchant/merchant.apis"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { UserProfileProps } from "types/app.types"

type InitFormDataProps = {
	min_trade_amount: string
	max_trade_amount: string
}

type SetLimitPanelProps = {
	user?: UserProfileProps
}
export default function SetLimitPanel({ user }: SetLimitPanelProps) {
	const { data, params, show } = useSelector(getLimitPanel)
	const dispatcher = useDispatch()

	const _handleClose = () => dispatcher(toggleLimitPanel({ status: false }))

	const InitFormData: InitFormDataProps = {
		min_trade_amount: user?.min_trade_amount as string,
		max_trade_amount: user?.max_trade_amount as string
	}

	const setLimitApi = useMutation(UpdateMerchantLimit, {
		onSuccess(res: any) {
			if (res?.status) {
				ShowSuccess("Success", res?.message)
				_handleClose()
			} else ShowServerErrors("Error", res)
		}
	})
	const isLoading = setLimitApi.isLoading

	const _handleSubmit = (values: InitFormDataProps) => setLimitApi.mutateAsync(values)

	return (
		<Transition.Root show={show} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={_handleClose}>
				<Transition.Child as={Fragment} enter="ease-in-out duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto relative w-screen max-w-lg">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-500"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-500"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
											<button type="button" className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" onClick={_handleClose}>
												<span className="absolute -inset-2.5" />
												<span className="sr-only">Close panel</span>
												<XMarkIcon className="h-6 w-6" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									<div className="flex h-full right-0 flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="px-4 sm:px-6">
											<Dialog.Title className="text-base font-semibold leading-6 text-gray-900">Set Limit</Dialog.Title>
										</div>

										<Formik initialValues={InitFormData} onSubmit={_handleSubmit} validationSchema={setLimitValidatorScheme}>
											{({ handleChange, values }) => (
												<Form className="space-y-6 xs:p-3 md:p-5">
													<div className="">
														<p className="font-bold text-md text-blue-800 text-center italic">Change your transaction limit. This option has an impact on the user order mapped to you.</p>
													</div>
													<div className="grid grid-cols-1 gap-3">
														<div className="form-group">
															<label className="form-label w-100 input-label">
																<strong>Min Amount</strong>
															</label>
															<Field
																name="min_trade_amount"
																type="number"
																step="any"
																value={values?.min_trade_amount}
																onChange={handleChange}
																className="form-control form-control-lg form-control-user"
															/>
															<ErrorMessage name="min_trade_amount" component={"p"} className="text-red-600 mt-2 p-2" />
														</div>

														<div className="form-group ">
															<label className="form-label w-100 input-label">
																<strong>Max Amount</strong>
															</label>
															<Field
																name="max_trade_amount"
																type="number"
																step="any"
																value={values?.max_trade_amount}
																onChange={handleChange}
																className="form-control form-control-lg form-control-user"
															/>
															<ErrorMessage name="max_trade_amount" component={"p"} className="text-red-600 mt-2 p-2" />
														</div>
													</div>

													<button
														disabled={isLoading}
														className=" bg-blue-500 disabled:bg-blue-500/50 mb-3 inline-block w-full text-center rounded p-3 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
														type="submit"
														data-te-ripple-init
														data-te-ripple-color="light"
													>
														{isLoading && <ShowSpinner />}
														{!isLoading && "Done"}
													</button>
												</Form>
											)}
										</Formik>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
