import { GET, POST, PUT, DROP } from "configs/api"
import { handleError } from "services/utility/Utils/Utils"
import { notifyEndpoints } from "./notify.endpoints"

export const FetchNotifications = async () => {
	try {
		return await GET(notifyEndpoints.GET)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchNotificationCounts = async () => {
	try {
		return await GET(notifyEndpoints.FETCHT_COUNT)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const UpdateNotification = async (params: FormData | object) => {
	try {
		return await POST(notifyEndpoints.UPDATE, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}
