import { mainMobileNavContanerRef } from "flus/constants/refs.const"
import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { Link } from "react-router-dom"
import {
	handleMainMobileNavContainerBodyToggle,
	stopImmediatePropagation
} from "services/utility/Utils/appNav"
import { isActive } from "services/utility/Utils/_authServices"

export default function MainMobileNav() {
	// const {
	// 	LOGO,
	// 	FBFooterIcon,
	// 	linkedInFooterIcon,
	// 	TWTFooterIcon,
	// 	IGFooterIcon,


	// } = useFlusStores()

	const openSocialLink = (url: string | URL | undefined) => {
		window.open(url, "_blank", "noreferrer");
	}

	const { footerLogo, LOGO, youtubeIcon, xlogo, linkin, ig, facebook } = useFlusStores()

	return (

		<div
			className="bp_mob-nav-container hide overlay"
			ref={mainMobileNavContanerRef}
			onClick={handleMainMobileNavContainerBodyToggle}
		>
			<nav
				className="navbar navbar-light navbar-expand bp_mob-nav-content"
				onClick={stopImmediatePropagation}
			>
				<div className="container-fluid">
					<Link className="navbar-brand" to="/">
						<img className="bp_logo" src={LOGO} alt="Beekle" />
					</Link>
					<div id="navcol-3" className="collapse visible navbar-collapse">
						<span className="navbar-text actions show-sm-screen w-100 border-bottom">

							{!isActive() && (
								<>
									<Link className="login my-3 bp_login-cta" to="/auth">
										<strong>Log In</strong>
									</Link>
									<Link
										className="btn btn-light action-button bp_signup-cta"
										role="button"
										to="/auth/signup"
									>
										Sign Up
									</Link>
								</>
							)}


							{isActive() && (
								<Link
									className="btn btn-light action-button bp_signup-cta"
									role="button"
									to="/dashboard"
								>
									Dashboard
								</Link>
							)}

						</span>



						<ul className="navbar-nav navbar-nav-scroll">

							<li className="nav-item text-capitalize ">
								<Link className="nav-link active" to="/about">
									About Us
								</Link>
							</li>

							{/* <li className="nav-item text-capitalize">
								<Link className="nav-link active" to="/dashboard/create-offer?tb=cof">
									Create Offer
								</Link>
							</li>
							<li className="nav-item text-capitalize">
								<Link className="nav-link active" to="/dashboard/marketplace?tb=mrk">
									Marketplace
								</Link>
							</li> */}

							<li className="nav-item text-capitalize">
								<Link className="nav-link active" to="/contact-us">
									Contact Us
								</Link>
							</li>
							<li className="nav-item text-capitalize">
								<Link className="nav-link active" to="/faqs">
									FAQs
								</Link>
							</li>
						</ul>

						<span className="navbar-text actions hide-sm-screen w-100 border-bottom">
							{!isActive() && (
								<>

									<Link className="login bp_login-cta" to="/auth">
										<strong>Log In</strong>
									</Link>

									<Link
										className="btn btn-light action-button bp_signup-cta"
										role="button"
										to="/auth/signup"
									>
										Sign Up
									</Link>

								</>
							)}

							{isActive() && (
								<Link
									className="btn btn-light action-button bp_signup-cta"
									role="button"
									to="/dashboard"
								>
									Dashboard
								</Link>
							)}
						</span>

						<div className="w-100 border-top">

							<span className="text-muted community-text d-block w-100 mb-3">
								Community
							</span>

							<ul className="list-inline footer_social_icons space-x-4">

								<li onClick={() => { openSocialLink("https://www.facebook.com/profile.php?id=100068625292579&mibextid=LQQJ4d") }} className="cursor-pointer list-inline-item">
									<img src={facebook} alt="Facebook" width="30" />
								</li>

								<li onClick={() => { openSocialLink("https://www.linkedin.com/company/beekle-technologies/") }} className="cursor-pointer  list-inline-item">
									<img src={linkin} alt="LinkedIn" width="30" />
								</li>

								<li onClick={() => { openSocialLink("https://www.x.com/beeklehq") }} className="cursor-pointer list-inline-item">
									<img src={xlogo} alt="Twitter" width="30" />
								</li>

								<li onClick={() => { openSocialLink("https://instagram.com/beeklehq?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr") }} className="cursor-pointer list-inline-item">
									<img src={ig} alt="Instagram" width="30" />
								</li>
							</ul>

							{/* <div className="xs:flex xs:gap-4 xs:items-center xs:justify-between">
								<span className="me-2">
									<img src={facebook} alt="" width={25} />
								</span>
								<span className="me-2">
									<img src={linkin} alt="" />
								</span>
								<span className="me-2">
									<img src={xlogo} alt="" />
								</span>
								<span>
									<img src={ig} alt="" />
								</span>
							</div> */}
						</div>
					</div>
				</div>
			</nav>
		</div>
	)
}
