import DashDesktopContainer from "components/DashDesktopContainer"
import EmptyContentMessage from "components/EmptyContentMessage"
import React from "react"
import { userTransactionApi } from "services/Api/transactions/transactions.index"
import { uniqueId } from "services/utility/Utils/Utils"
import { TransactionDetailsProps } from "types/app.types"
import TransactionFilterHeader from "./TransactionFilterHeader"
import { useMutation } from "react-query"
import AwaitResponse from "components/AwaitResponse"
import { ShowServerErrors } from "services/utility/Utils/_toaster"
import { TRANS_DETAILS } from "flus/constants/modals.id.const"
import TransactionDetail from "./TransactionDetails"
import NextPrevPagination from "components/misc/NextPrevPagination"
import { useDispatch, useSelector } from "react-redux"
import { getTransaction, setTransaction, setTransactionDetails } from "redux/reducers/dashboardSlice"

export default function TransactionHistory() {
	const transactions = useSelector(getTransaction)
	const dispatcher = useDispatch()

	const { GetTransactions, FetchTransactionDetails } = userTransactionApi()
	const getTransApi = useMutation(GetTransactions, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(setTransaction(res?.data))
			}
		}
	})

	const fetchTransDetails = useMutation(FetchTransactionDetails, {
		onSuccess: (res: any) => {
			if (res?.status) {
				let tranxDetails: TransactionDetailsProps | object = {}

				switch (res?.data?.mode?.toLowerCase()) {
					case "transfer":
						tranxDetails = { ...res?.data, ...res?.data?.transfer }
						break
					case "receive":
						tranxDetails = { ...res?.data, ...res?.data?.receive }
						break
					case "sell":
						tranxDetails = { ...res?.data, ...res?.data?.sell }
						break

					case "send":
						tranxDetails = { ...res?.data, ...res?.data?.send }
						break

					case "buy":
						tranxDetails = { ...res?.data, ...res?.data?.buy }
						break
					default:
				}

				dispatcher(setTransactionDetails(tranxDetails))
			} else ShowServerErrors("Error", res)
		}
	})

	const data = transactions?.data
	const pagination = transactions?.pagination

	const handleTransDetailsFetching = (e: React.MouseEvent<HTMLAnchorElement>, transId: string) => {
		e.preventDefault()

		const fd = new FormData()
		fd.append("transaction_id", transId)

		fetchTransDetails.mutateAsync(fd)
	}

	return (
		<DashDesktopContainer title="Transaction History" showOnMobile>
			<section>
				<TransactionFilterHeader />
				<section className="my-4 p-2 bg-white">
					<AwaitResponse api={getTransApi.mutateAsync}>
						<div className="table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th>Date</th>
										<th>Mode</th>
										<th>Description</th>
										<th>Amount</th>
										<th>Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{data && (
										<>
											{data?.length > 0 && (
												<>
													{data?.map(item => (
														<tr key={uniqueId()}>
															<td className="text-truncate">{item?.date}</td>
															<td>{item?.mode}</td>
															<td className="text-truncate">{item?.description}</td>
															<td>{item?.amount}</td>
															<td className="bk-text-success">{item?.status}</td>
															<td className="text-primary text-truncate">
																<a data-bs-toggle="modal" href={`#${TRANS_DETAILS}`} onClick={e => handleTransDetailsFetching(e, item?.transaction_id)}>
																	View Details
																</a>
															</td>
														</tr>
													))}
												</>
											)}
										</>
									)}
								</tbody>
							</table>

							{(!data || data?.length) === 0 && <EmptyContentMessage text="No Transactions found" />}
						</div>

						<NextPrevPagination pagination={pagination} paginator={getTransApi} />
					</AwaitResponse>
				</section>
			</section>

			<TransactionDetail />
		</DashDesktopContainer>
	)
}
