import React, { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { CloudArrowDownIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { useDispatch, useSelector } from "react-redux"
import { getOrderPanel, toggleOrderPanel } from "redux/reducers/panelsSlice"
import { OrderHistoryInterface, OrderStatusInterface } from "types"
import { NAIRA_SIGN } from "libs/currency"
import { useMutation } from "react-query"
import { AcceptDepositRequest, CheckDepositRequestStatus, DeclineDepositRequest } from "services/Api/merchant/merchant.apis"
import { ShowError, ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import ShowSpinner from "components/misc/Spinner"
import IconSpinner from "components/icons/IconSpinner"
import { changeMerchantStep, getMerchanCurrentStep, setMerchantDepositTimer, stopMerchantDepositTimer } from "redux/reducers/depositSlice"
import { uniqueId } from "services/utility/Utils/Utils"
import ReleaseDepositForm from "./ReleaseDepositForm"
import { sleep } from "services/utility/Utils/_sleep"

export default function OrderPanel() {
	const { data, params, show } = useSelector(getOrderPanel)
	const currentStep = useSelector(getMerchanCurrentStep)
	const _data: OrderHistoryInterface = data

	const [depositStatus, setDepositStatus] = React.useState<OrderStatusInterface>()

	const dispatcher = useDispatch()

	const _handleClose = () => {
		dispatcher(stopMerchantDepositTimer(null))

		dispatcher(toggleOrderPanel({ status: false }))
	}

	const checkDepositRequestStatusApi = useMutation(CheckDepositRequestStatus, {
		onSuccess(data, variables, context) {
			if (data?.status) {
				switch (data?.data?.status_code?.toString()) {
					case "0":
						dispatcher(stopMerchantDepositTimer(null))
						dispatcher(changeMerchantStep("accept_order"))
						break

					case "1":
						dispatcher(changeMerchantStep("awaiting_deposit"))
						break

					case "2":
						dispatcher(stopMerchantDepositTimer(null))
						ShowError("Declined", data?.data?.status)
						dispatcher(changeMerchantStep("decline"))
						_handleClose()
						break
					case "3":
						dispatcher(stopMerchantDepositTimer(null))
						dispatcher(changeMerchantStep("confirm_order"))
						break
					case "5":
						ShowSuccess("Completed", "Transaction has been completed.")

						dispatcher(stopMerchantDepositTimer(null))
						_handleClose()
						dispatcher(changeMerchantStep("completed"))
						break
					default:
				}

				setDepositStatus(data?.data)
			}
		}
	})
	const isCheckingStatus = checkDepositRequestStatusApi.isLoading

	const _handleStatusChecking = (id: any) => {
		const _timerId = setInterval(() => {
			checkDepositRequestStatusApi.mutateAsync({ txn_id: id })
		}, 9000)

		dispatcher(setMerchantDepositTimer(_timerId))
	}

	const acceptOrderApi = useMutation(AcceptDepositRequest, {
		onSuccess(res: any) {
			if (res?.status) {
				setDepositStatus(res?.data)
				ShowSuccess("Success", res?.message)

				dispatcher(changeMerchantStep("awaiting_deposit"))
				_handleStatusChecking(res?.data?.txn_id)
			} else ShowServerErrors("Error", res)
		}
	})
	const isAccepting = acceptOrderApi.isLoading
	const _acceptDepositRequest = () => acceptOrderApi.mutateAsync({ txn_id: _data?.txn_id })

	// -----------------------------------------> [Decline request]

	const declineOrderApi = useMutation(DeclineDepositRequest, {
		onSuccess(res: any) {
			if (res?.status) {
				ShowSuccess("Success", res?.message)

				sleep(900).then(() => {
					window.location.reload()
				})
			} else ShowServerErrors("Error", res)
		}
	})
	const isDeclining = declineOrderApi.isLoading
	const _declineDepositRequest = () => declineOrderApi.mutateAsync({ txn_id: _data?.txn_id })

	const _handleReleaseDepositClick = () => {
		dispatcher(changeMerchantStep("release"))
	}

	React.useEffect(() => {
		if (params) {
			checkDepositRequestStatusApi.mutateAsync({ txn_id: params })

			const timer = setInterval(() => {
				checkDepositRequestStatusApi.mutateAsync({ txn_id: params })
			}, 9000)
			dispatcher(setMerchantDepositTimer(timer))
		}

		return () => {
			dispatcher(stopMerchantDepositTimer(null))
		}
	}, [params])

	return (
		<Transition.Root show={show} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={_handleClose}>
				<Transition.Child as={Fragment} enter="ease-in-out duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto relative w-screen max-w-lg">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-500"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-500"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
											<button type="button" className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" onClick={_handleClose}>
												<span className="absolute -inset-2.5" />
												<span className="sr-only">Close panel</span>
												<XMarkIcon className="h-6 w-6" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									<div className="flex h-full right-0 flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="px-4 sm:px-6">
											<Dialog.Title className="text-base font-semibold leading-6 text-gray-900 uppercase">Transaction Detail</Dialog.Title>
										</div>
										{(currentStep === "accept_order" || currentStep === "decline") && (
											<div className="relative my-6 flex-1 px-4 sm:px-6">
												<div className="border-t border-gray-100">
													<dl className="divide-y divide-gray-100">
														<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
															<dt className="text-sm font-semibold leading-6 text-gray-500">Order ID:</dt>
															<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{_data?.txn_id}</dd>
														</div>
													</dl>
												</div>

												<div className="border-t border-gray-100">
													<dl className="divide-y divide-gray-100">
														<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
															<dt className="text-sm font-semibold leading-6 text-gray-500">User's Name:</dt>
															<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{_data?.client_name}</dd>
														</div>
													</dl>
												</div>

												<div className="border-t border-gray-100">
													<dl className="divide-y divide-gray-100">
														<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
															<dt className="text-sm font-semibold leading-6 text-gray-500">Amount:</dt>
															<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
																{NAIRA_SIGN}
																{_data?.amount}
															</dd>
														</div>
													</dl>
												</div>

												<div className="border-t border-gray-100">
													<dl className="divide-y divide-gray-100">
														<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
															<dt className="text-sm font-semibold leading-6 text-gray-500">Date:</dt>
															<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{_data?.date}</dd>
														</div>
													</dl>
												</div>

												{currentStep === "decline" && <p className="text-red-500 italic">This transaction has been declined.</p>}
												<div className="p-2 flex items-center gap-4">
													<button disabled={isAccepting || currentStep === "decline"} className="p-3 w-full rounded-md bg-blue-500 text-white disabled:bg-blue-500/50" onClick={_acceptDepositRequest}>
														{!isAccepting && "Accept"}
														{isAccepting && <ShowSpinner />}
													</button>
													<button
														disabled={isDeclining || currentStep === "decline"}
														onClick={_declineDepositRequest}
														className="p-3 w-full rounded-md border-1 border-red-500 text-red-500 transition ease-in duration-300 hover:bg-red-500 hover:text-white disabled:border-red-500/50 disabled:hover:bg-red-500/50"
													>
														{!isDeclining && "Decline"}
														{isDeclining && <ShowSpinner />}
													</button>
												</div>
											</div>
										)}

										{currentStep === "awaiting_deposit" && (
											<div className="flex flex-col gap-4 items-center py-4">
												<IconSpinner width="5em" height="5em" className="animate-spin duration-500" />
												<p className="p-3 italic text-gray-400">Please wait while the deposit is being made. {isCheckingStatus && "Checking..."}</p>
											</div>
										)}

										{currentStep === "confirm_order" && (
											<div className="grid grid-cols-1 gap-4 divide-y">
												<div className="p-2 flex items-center xs:flex-col md:flex-row gap-2">
													{/* <button className="border-1 w-full border-blue-500 text-blue-500 p-3 rounded-md">Escalate</button> */}

													<button className="bg-blue-500 w-full text-white p-3 rounded-md" onClick={_handleReleaseDepositClick} type="button">
														Release
													</button>
												</div>
												<div className="p-2">
													<h1 className="text-gray-600 text-md font-semibold px-3 mb-2">Deposit Proof</h1>
													<ul className="divid-y p-3">
														{depositStatus?.proof_of_payment && (
															<>
																{depositStatus?.proof_of_payment?.map(file => (
																	<li className="py-1" key={uniqueId()}>
																		<a href={file} target="_blank" className="text-blue-500 flex items-center gap-1 hover:underline">
																			<CloudArrowDownIcon className="w-5 h-5" /> View Proof
																		</a>
																	</li>
																))}
															</>
														)}
													</ul>
												</div>
											</div>
										)}

										{currentStep === "release" && <ReleaseDepositForm transId={depositStatus?.txn_id} />}
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
