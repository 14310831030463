import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import SelfieWebcam from "./SelfieWebcam"

export default function UPloadIDSelfie() {
	const { IDSelfie } = useFlusStores()
	const [showWebCamp, toggleWebCamp] = React.useState(false)

	const webcamToggler = () => toggleWebCamp(!showWebCamp)

	return (
		<div>
			{!showWebCamp && <img onClick={webcamToggler} className="img-fluid m-auto d-block rounded pointer" src={IDSelfie} alt="" width="200" loading="lazy" />}

			{showWebCamp && (
				<section className="bk__selfie-video-container">
					<SelfieWebcam onClose={webcamToggler} />
				</section>
			)}
		</div>
	)
}
