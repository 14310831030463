import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"

function SafestPlaceToTradeSection() {
	const { secureTranx, disputeResol, verifiedUser } = useFlusStores()

	return (
		<section className="bp_home-section-002 mb-4 pb-5 py-5">

			<div>
				
			</div>
			
			{/* <div className="container">
				<h1 className="text-center mb-5 h-title">Safest Place to Trade</h1>
				<div className="row bp_home-section-002-row">
					<div className="col-md-4 mb-3">
						<div className="card">
							<div className="card-body">
								<img className="block m-auto" src={secureTranx} alt="Secure Transaction" width="72" loading="lazy" />

								<h6 className="text-center text-muted card-title mb-2">Secured Transaction</h6>
								<p className="card-text">Our system infrastructure is built with our customer’s funds safety as our topmost priority. </p>
							</div>
						</div>
					</div>
					<div className="col-md-4 mb-3">
						<div className="card">
							<div className="card-body">
								<img className="block m-auto" src={disputeResol} alt="Dispute Resolution" width="72" />

								<h6 className="text-center text-muted card-title mb-2">
									<strong>Dispute Resolution</strong>
								</h6>
								<p className="card-text">24/7 support team to resolve every dispute from our esteemed customers. </p>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="card">
							<div className="card-body">
								<img className="block m-auto" src={verifiedUser} alt="Verified User" width="72" loading="lazy" />

								<h6 className="text-center text-muted card-title mb-2">
									<strong>Verified Users</strong>
								</h6>
								<p className="card-text">All our merchants have been duly verified with a KYC verification process.</p>
							</div>
						</div>
					</div>
				</div>
				<div id="carousel-1" className="carousel slide bp_home-section-002-slider" data-bs-ride="carousel">
					<div className="carousel-inner">
						<div className="carousel-item active">
							<div className="card">
								<div className="card-body">
									<img className="block m-auto" src={secureTranx} width="72" alt="" loading="lazy" />

									<h6 className="text-center text-muted card-title mb-2">Secured Transaction</h6>
									<p className="card-text">Our system infrastructure is built with our customer’s funds safety as our topmost priority.</p>
								</div>
							</div>
						</div>
						<div className="carousel-item">
							<div className="card">
								<div className="card-body">
									<img className="block m-auto" src={disputeResol} width="72" loading="lazy" alt="" />

									<h6 className="text-center text-muted card-title mb-2">
										<strong>Dispute Resolution</strong>
									</h6>
									<p className="card-text">24/7 support team to resolve every dispute from our esteemed customers.</p>
								</div>
							</div>
						</div>
						<div className="carousel-item">
							<div className="card">
								<div className="card-body">
									<img className="block m-auto" src={verifiedUser} width="72" loading="lazy" alt="" />

									<h6 className="text-center text-muted card-title mb-2">
										<strong>Verified Users</strong>
									</h6>
									<p className="card-text">All our merchants have been duly verified with a KYC verification process.</p>
								</div>
							</div>
						</div>
					</div>
					<div>
						<a className="carousel-control-prev" href="#carousel-1" role="button" data-bs-slide="prev">
							<span className="carousel-control-prev-icon"></span>
							<span className="visually-hidden">Previous</span>
						</a>
						<a className="carousel-control-next" href="#carousel-1" role="button" data-bs-slide="next">
							<span className="carousel-control-next-icon"></span>
							<span className="visually-hidden">Next</span>
						</a>
					</div>
					<ol className="carousel-indicators">
						<li className="active" data-bs-target="#carousel-1" data-bs-slide-to="0"></li>
						<li data-bs-target="#carousel-1" data-bs-slide-to="1"></li>
						<li data-bs-target="#carousel-1" data-bs-slide-to="2"></li>
					</ol>
				</div>
			</div> */}


		</section>
	)
}

export default SafestPlaceToTradeSection
