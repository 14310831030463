import {
	FetchSpotWallet,
	FetchFundingWallet,
	TransferCoin,
	ReceiveCoinAsset,
	FindAsset,
	FetchGasFee,
	WalletWithdrawal,
	FetchAssets,
	FetchCoinAssets
} from "./wallet.apis"

export const userWalletApis = () => ({
	FetchSpotWallet,
	FetchFundingWallet,
	TransferCoin,
	ReceiveCoinAsset,
	FindAsset,
	FetchGasFee,
	WalletWithdrawal,
	FetchAssets,
	FetchCoinAssets
})
