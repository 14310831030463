import { GET, POST, PUT, DROP } from "configs/api"
import { handleError } from "services/utility/Utils/Utils"
import { profileEndpoints } from "./profile.endpoints"

export const CheckUsername = async (params: object | FormData) => {
	try {
		return await POST(profileEndpoints.CHECK_USERNAME, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const UpdatePersonalInfo = async (params: object | FormData) => {
	try {
		return await POST(profileEndpoints.UPDATE_PROFILE, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const UpdateContactInfo = async (params: object | FormData) => {
	try {
		return await POST(profileEndpoints.UPDATE_CONTACT, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const UpdateResidentInfo = async (params: object | FormData) => {
	try {
		return await POST(profileEndpoints.UPDATE_RESIDENT, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const AcceptPrivacyPolicy = async (params: object | FormData) => {
	try {
		return await POST(profileEndpoints.ACCEPT_PRIVACY, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const UpdateProfilePhoto = async (params: object | FormData) => {
	try {
		return await POST(profileEndpoints.UPDATE_PROFILE_PHOTO, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const SetAccountPIN = async (params: object | FormData) => {
	try {
		return await POST(profileEndpoints.SET_PIN, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const UpdatePassword = async (params: object | FormData) => {
	try {
		return await POST(profileEndpoints.UPDATE_PASSWORD, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const ToggleSettings = async (params: object | FormData) => {
	try {
		return await POST(profileEndpoints.TOGGLE_SETTINGS, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchProfile = async () => {
	try {
		return await GET(profileEndpoints.FETCH_PROFILE)
			.then(res => res)
			.then()
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

// const LogIn_TawkTO = async () => {



// }

export const PrefetchProfile = async () => {
	try {
		return await GET(profileEndpoints.PRE_FETCH_PROFILE)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}