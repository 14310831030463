import DashDesktopContainer from "components/DashDesktopContainer"
import OrderPanel from "components/merchant/panels/OrderPanel"
import WithdrawalOrderPanel from "components/merchant/panels/WithdrawalOrderPanel"
import { NAIRA_SIGN } from "libs/currency"
import React from "react"
import { useDispatch } from "react-redux"
import { useLoaderData } from "react-router-dom"
import { toggleOrderPanel, toggleWithdrawalOrderPanel } from "redux/reducers/panelsSlice"
import { uniqueId } from "services/utility/Utils/Utils"
import { OrderHistoryInterface } from "types"

export default function MerchantOrders() {
	const dispatcher = useDispatch()

	const orderData: any = useLoaderData()
	const orderList: Array<OrderHistoryInterface> = orderData?.data ?? []

	const _togglePanel = (_data: any) => dispatcher(toggleOrderPanel({ status: true, data: _data, params: _data?.txn_id }))
	const _toggleWithdrawPanel = (_data: any) => dispatcher(toggleWithdrawalOrderPanel({ status: true, data: _data, params: _data?.txn_id }))

	return (
		<>
			<DashDesktopContainer title="Merchant Orders" showSearch={false} showOnMobile>
				<div className="bg-white xs:p-1 md:p-5">
					<div className="flex flex-col">
						<div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
								<div className="overflow-hidden">
									<table className="min-w-full text-left text-sm font-light">
										<thead className="border-b bg-blue-900 text-white font-medium">
											<tr>
												<th scope="col" className="px-6 font-bold py-4">
													Type
												</th>
												<th scope="col" className="px-6 font-bold py-4">
													Order ID
												</th>
												<th scope="col" className="px-6 font-bold py-4">
													User
												</th>
												<th scope="col" className="px-6 font-bold py-4">
													Amount
												</th>
												<th scope="col" className="px-6 font-bold py-4">
													Date
												</th>

												<th scope="col" className="px-6 font-bold py-4">
													Action
												</th>
											</tr>
										</thead>
										<tbody>
											{orderList?.length > 0 && (
												<>
													{orderList?.map(order => (
														<tr key={uniqueId()} className="border-b">
															<td className="whitespace-nowrap px-6 py-4 font-medium">{order?.type?.toUpperCase()}</td>
															<td className="whitespace-nowrap px-6 py-4">{order?.txn_id}</td>
															<td className="whitespace-nowrap px-6 py-4">{order?.client_name}</td>
															<td className="whitespace-nowrap px-6 py-4">
																{NAIRA_SIGN}
																{order?.amount}
															</td>
															<td className="whitespace-nowrap px-6 py-4">{order?.date}</td>
															<td className="whitespace-nowrap px-6 py-4">
																<span onClick={() => (order?.type === "withdrawal" ? _toggleWithdrawPanel(order) : _togglePanel(order))} className="text-primary cursor-pointer">
																	View
																</span>
															</td>
														</tr>
													))}
												</>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</DashDesktopContainer>

			<OrderPanel />
			<WithdrawalOrderPanel />
		</>
	)
}
