import { dashMobileNavContanerRef } from "flus/constants/refs.const"
import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { Link, useLoaderData, useLocation } from "react-router-dom"
import { handleDashNavToggle } from "services/utility/Utils/appNav"
import { useUrlQuery } from "services/utility/hooks/useURL"
import classNames from "classnames"
import { useDispatch } from "react-redux"
import {
	toggleCashBridgeModal,
	toggleDeactivateAccountModal
} from "redux/reducers/modalSlice"
import IconWaveSawTool from "components/icons/IconWaveSawTool"
import { ROUTES } from "routes"
import { UserProfileProps } from "types/app.types"
import IconEcommerceWallet from "components/icons/IconEcommerce_wallet"
import IconHourglassSplit from "components/icons/IconHourglassSplit"
import IconBank from "components/icons/IconBank"
import { toggleBankPanel, toggleLimitPanel } from "redux/reducers/panelsSlice"
import IconFilter from "components/icons/IconFilter"
import IconClockHistory from "components/icons/IconClockHistory"
import IconReturnUpBack from "components/icons/IconReturnUpBack"
import { UserCircleIcon } from "@heroicons/react/24/outline"
import IconTrash from "components/icons/IconTrash"
import Switch from "components/input/Switch"
import { ToggleMerchantPresence } from "services/Api/merchant/merchant.apis"
import { useMutation } from "react-query"
import {
	ShowInfo,
	ShowServerErrors,
	ShowSuccess
} from "services/utility/Utils/_toaster"
import { sleep } from "services/utility/Utils/_sleep"
import { GiftIcon } from "@heroicons/react/24/solid"

const DefaultMenu = () => {
	const {
		dashNavIconWallet,
		dashNavIconFunding,
		dashNavIconMarket,
		dashNavIconTrans,
		dashNavIconMarketNews,
		dashNavIconDisputeZone,
		dashNavIconCreateOffer,
		dashNavIconMyOffer
	} = useFlusStores()
	const _data = useLoaderData() as any
	const user: UserProfileProps = _data?.data

	const { tb } = useUrlQuery()

	const dispatcher = useDispatch()

	const _toggleCashbridge = () => {
		handleDashNavToggle()
		dispatcher(toggleCashBridgeModal(true))
	}

	return (
		<div className="list-group list-group-flush nav-group">

			<Link
				onClick={handleDashNavToggle}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true,
					active: tb === "gfc"
				})}
				to="/dashboard/giftcard">
					{React.createElement(GiftIcon, {
                                                className: `w-7 h-7 text-grey-500 mr-1`,
                                            })}
				<span>
					<strong>Gift Card </strong>
				</span>
			</Link>

			<Link
				onClick={handleDashNavToggle}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true,
					active: tb === "spw"
				})}
				to="/dashboard/spot-wallet?tb=spw"
			>
				<img className="me-2" src={dashNavIconWallet} alt="" width="25" />
				<span>
					<strong>Spot Wallet</strong>
				</span>
			</Link>
			<Link
				onClick={handleDashNavToggle}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true,
					active: tb === "fdw"
				})}
				to="/dashboard/funding-wallet?tb=fdw"
			>
				<img className="me-2" src={dashNavIconFunding} alt="" width="25" />
				<span>
					<strong>Funding</strong>
				</span>
			</Link>

			{/* <Link
				to="/dashboard/marketplace?tb=mrk"
				onClick={handleDashNavToggle}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true,
					active: tb === "mrk"
				})}
			>
				<img className="me-2" src={dashNavIconMarket} alt="" width="25" />
				<span>
					<strong>Market</strong>
				</span>
			</Link> */}

			<Link
				to="/dashboard/history?tb=trh"
				onClick={handleDashNavToggle}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true,
					active: tb === "trh"
				})}
			>
				<img className="me-2" src={dashNavIconTrans} alt="" width="25" />
				<span>
					<strong>Transaction History</strong>
				</span>
			</Link>
			<Link
				to="/dashboard/market-news?tb=mkn"
				onClick={handleDashNavToggle}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true,
					active: tb === "mkn"
				})}
			>
				<img className="me-2" src={dashNavIconMarketNews} alt="" width="25" />
				<span>
					<strong>Market News</strong>
				</span>
			</Link>
			{/* <Link
				to="/dashboard/disputes?tb=dpz"
				onClick={handleDashNavToggle}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true,
					active: tb === "dpz"
				})}
			>
				<img className="me-2" src={dashNavIconDisputeZone} alt="" width="25" />
				<strong>Dispute Zone</strong>
			</Link>
			<Link
				to="/dashboard/create-offer?tb=cof"
				onClick={handleDashNavToggle}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true,
					active: tb === "cof"
				})}
			>
				<img className="me-2" src={dashNavIconCreateOffer} alt="" width="25" />
				<span>
					<strong>Create Offer</strong>
				</span>
			</Link>
			<Link
				to="/dashboard/my-offer?tb=mof"
				onClick={handleDashNavToggle}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true,
					active: tb === "mof"
				})}
			>
				<img className="me-2" src={dashNavIconMyOffer} alt="" width="25" />
				<span>
					<strong>My Offer</strong>
				</span>
			</Link>

			<Link
				to="/dashboard/my-offer?tb=mof"
				onClick={handleDashNavToggle}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true,
					active: tb === "mof"
				})}
			>
				<img className="me-2" src={dashNavIconMyOffer} alt="" width="25" />
				<span>
					<strong>My Offer</strong>
				</span>
			</Link> */}

			<Link
				to="#"
				onClick={_toggleCashbridge}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true
				})}
			>
				<IconWaveSawTool width={"2em"} height={"2em"} />
				<span>
					<strong>Cash-Bridge</strong>
				</span>
			</Link>
			<a
				href="/"
				className="list-group-item list-group-item-action d-flex items-center gap-2"
			>
				<i className="fas fa-home me-3"></i>
				<span>
					<strong>Main Site</strong>
				</span>
			</a>

			{parseInt(user?.isMerchant as string) === 0 && (
				<Link
					to="/dashboard/register"
					style={{ color: "#2C881D" }}
					onClick={handleDashNavToggle}
					className={classNames({
						"list-group-item list-group-item-action d-flex items-center gap-2": true
					})}
				>
					<span>
						Become a <strong>MERCHANT</strong>
					</span>
				</Link>
			)}

			{parseInt(user?.isMerchant as string) === 1 && (
				<Link
					to={ROUTES.merchant.index}
					onClick={handleDashNavToggle}
					className={classNames({
						"list-group-item list-group-item-action d-flex items-center gap-2": true
					})}
				>
					<span>
						<strong className="text-blue">Switch to merchant</strong>
					</span>
				</Link>
			)}
		</div>
	)
}

const MerchantMenu = () => {
	const { active } = useUrlQuery()
	const [label, setLabel] = React.useState<string>("Go Online")

	const dispatcher = useDispatch()

	const _toggleSetbankPanel = (e: React.MouseEvent<HTMLElement>) => {
		handleDashNavToggle()
		dispatcher(toggleBankPanel({ status: true }))
	}

	const _toggleSetlimitPanel = (e: React.MouseEvent<HTMLElement>) => {
		handleDashNavToggle()
		dispatcher(toggleLimitPanel({ status: true }))
	}

	const _toggleDeactivateAccount = (e: React.MouseEvent<HTMLElement>) => {
		handleDashNavToggle()
		dispatcher(toggleDeactivateAccountModal(true))
	}

	// -------------------> Toggle online presence
	const toggleActiveStateApi = useMutation(ToggleMerchantPresence, {
		onSuccess(res: any) {
			if (res?.status) {
				if (res?.data?.status === "online") {
					setLabel("Go Offline")
					ShowSuccess("Success", "You're now online")
				} else {
					ShowInfo("Success", "You're offline.")

					setLabel("Go Online")
				}
			} else ShowServerErrors("Error", res)
		}
	})
	const isLoading = toggleActiveStateApi.isLoading

	const _handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleDashNavToggle()

		toggleActiveStateApi.mutateAsync()
	}
	return (
		<div className="list-group list-group-flush nav-group">
			<Link
				onClick={handleDashNavToggle}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true,
					active: active === "wallet"
				})}
				to={ROUTES?.merchant?.index}
			>
				<IconEcommerceWallet
					width={"2em"}
					height={"2em"}
					className={classNames(
						"inline mr-2",
						active !== "wallet" ? "text-gray-500/50" : "text-gray-100"
					)}
				/>
				<span>
					<strong>Wallet</strong>
				</span>
			</Link>

			<Link
				onClick={handleDashNavToggle}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true,
					active: active === "orders"
				})}
				to={ROUTES?.merchant?.orders}
			>
				<IconHourglassSplit
					width={"2em"}
					height={"2em"}
					className={classNames(
						"inline mr-2",
						active !== "orders" ? "text-gray-500/50" : "text-gray-100"
					)}
				/>
				<span>
					<strong>Order</strong>
				</span>
			</Link>

			<Link
				onClick={_toggleSetbankPanel}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true
				})}
				to={"#"}
			>
				<IconBank
					width={"2em"}
					height={"2em"}
					className={classNames("inline mr-2", "text-gray-500")}
				/>
				<span>
					<strong>Set Bank</strong>
				</span>
			</Link>

			<Link
				onClick={_toggleSetlimitPanel}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true
				})}
				to={"#"}
			>
				<IconFilter
					width={"2em"}
					height={"2em"}
					className={classNames("inline mr-2", "text-gray-500")}
				/>
				<span>
					<strong>Set Limit</strong>
				</span>
			</Link>

			<Link
				onClick={handleDashNavToggle}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true,
					active: active === "withdrawal"
				})}
				to={ROUTES?.merchant?.withrawalHistory}
			>
				<IconClockHistory
					width={"2em"}
					height={"2em"}
					className={classNames(
						"inline mr-2",
						active !== "withdrawal" ? "text-gray-500/50" : "text-gray-100"
					)}
				/>
				<span>
					<strong>Withdrawal History</strong>
				</span>
			</Link>

			<Link
				onClick={handleDashNavToggle}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true,
					active: active === "deposit"
				})}
				to={ROUTES?.merchant?.depositHistory}
			>
				<IconClockHistory
					width={"2em"}
					height={"2em"}
					className={classNames(
						"inline mr-2",
						active !== "deposit" ? "text-gray-500/50" : "text-gray-100"
					)}
				/>
				<span>
					<strong>Deposit History</strong>
				</span>
			</Link>

			<Link
				onClick={handleDashNavToggle}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true,
					active: active === "funding"
				})}
				to={ROUTES?.merchant?.fundingHistory}
			>
				<IconClockHistory
					width={"2em"}
					height={"2em"}
					className={classNames(
						"inline mr-2",
						active !== "funding" ? "text-gray-500/50" : "text-gray-100"
					)}
				/>
				<span>
					<strong>Funding History</strong>
				</span>
			</Link>

			<hr className="my-2" />

			<Link
				onClick={handleDashNavToggle}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true
				})}
				to={"/dashboard"}
			>
				<UserCircleIcon
					width={"2em"}
					height={"2em"}
					className={classNames("inline mr-2", "text-gray-500")}
				/>
				<span>
					<strong>User Dashboard</strong>
				</span>
			</Link>

			<Link
				onClick={_toggleDeactivateAccount}
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true
				})}
				to={"#deactivate"}
			>
				<IconTrash
					width={"2em"}
					height={"2em"}
					className={classNames("inline mr-2", "text-gray-500")}
				/>
				<span>
					<strong>Deactivate Account</strong>
				</span>
			</Link>

			<span
				className={classNames({
					"list-group-item list-group-item-action d-flex items-center gap-2": true
				})}
			>
				<Switch label={label} onChange={_handleChange} disable={isLoading} />
			</span>
		</div>
	)
}
export default function AppMenu() {
	const { BluredLogo } = useFlusStores()

	const location = useLocation()
	const isMerchant = location.pathname.includes("merchant")

	return (
		<section className="app-mob-menu " ref={dashMobileNavContanerRef}>
			<header className="user-header mb-4">
				<div className="header-group">
					<button
						className="btn-close close-menu"
						onClick={handleDashNavToggle}
					></button>
				</div>
			</header>
			{!isMerchant && <DefaultMenu />}
			{isMerchant && <MerchantMenu />}
			<img
				className="img-fluid m-auto d-block my-3"
				src={BluredLogo}
				alt="Beekle"
				width="200"
			/>
		</section>
	)
}
