import React from "react"
import { Link, useLoaderData, useNavigate } from "react-router-dom"
import { useFlusStores, useFlusDispatcher } from "@bilmapay/react-flux-storage"
import { USER_LOGOUT } from "flus/constants/auth.const"
import { useAuthApis } from "services/Api/auth/auth.apis.index"
import { useMutation } from "react-query"
import ShowSpinner from "components/misc/Spinner"
import { sleep } from "services/utility/Utils/_sleep"
import { userNotificationApis } from "services/Api/notification/notify.index"
import AwaitResponse from "components/AwaitResponse"
import { useDispatch } from "react-redux"
import { LOGOUT } from "redux/reducers/appSlice"

export default function DashDesktopNav() {
	const { nameLogo } = useFlusStores()

	const userProfile: any = useLoaderData()
	const dispatcher = useDispatch()
	const navigate = useNavigate()
	const [notifCount, setNotifCount] = React.useState<string>("0")

	const { LogoutAccount } = useAuthApis()

	const logoutAccountApi = useMutation(LogoutAccount, {
		onSuccess: res => {
			dispatcher(LOGOUT())

			sleep(200).then(() => navigate("/"))
		}
	})

	const isLoggingOut = logoutAccountApi.isLoading
	const handleLogout = (e: any) => logoutAccountApi.mutateAsync()

	const { FetchNotificationCounts } = userNotificationApis()

	const fetchNotifCountApi = useMutation(FetchNotificationCounts, {
		onSuccess: (res: any) => {
			if (res?.status) {
				setNotifCount(res?.data)
			}
		}
	})

	return (
		<nav className="navbar navbar-light navbar-expand-md navigation-clean-button bp-desktop-navbar">
			<div className="container-fluid">
				<Link className="navbar-brand" to="/dashboard?tb=mrk">
					<img className="bp_logo" src={nameLogo} alt="" />
				</Link>
				<button
					className="navbar-toggler bp_mob-toggler"
					data-bs-toggle="collapse"
					data-bs-target="#navcol-1"
				>
					<span className="visually-hidden">Toggle navigation</span>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div id="navcol-1" className="bs-collapse navbar-collapse">
					<ul className="navbar-nav navbar-nav-scroll me-auto">

						<li className="nav-item text-capitalize">
							<Link className="nav-link" to="/dashboard/giftcard">
								Gift Card
							</Link>
						</li>


						<li className="nav-item dropdown">
							<Link
								className="dropdown-toggle nav-link"
								aria-expanded="false"
								data-bs-toggle="dropdown"
								to="#w"
							>
								Wallet
							</Link>
							<div className="dropdown-menu">
								<Link
									className="dropdown-item"
									to="/dashboard/spot-wallet?tb=spw"
								>
									Spot Wallet
								</Link>
								<Link
									className="dropdown-item"
									to="/dashboard/funding-wallet?tb=fdw"
								>
									Funding
								</Link>
							</div>
						</li>

						{/* <li className="nav-item text-capitalize">
							<Link className="nav-link" to="/dashboard/create-offer?tb=cof">
								Create Offer
							</Link>
						</li> */}

						<li className="nav-item text-capitalize">
							<Link className="nav-link" to="/contact-us">
								Help
							</Link>
						</li>
						<li className="nav-item text-capitalize">
							<Link className="nav-link" to="/">
								Main Site
							</Link>
						</li>
					</ul>

					<ul className="navbar-nav navbar-nav-scroll ms-auto">
						<li className="nav-item dropdown">

							<Link
								className="dropdown-toggle nav-link d-flex items-center gap-2"
								aria-expanded="false"
								data-bs-toggle="dropdown"
								to="#a"
							>
								<strong>{userProfile?.data?.username}</strong>
								<img
									className="ms-2 rounded-circle img-fluid bk__nav-profile-avatar"
									src={
										!userProfile?.data?.photo?.includes("uploads/placeholder")
											? userProfile?.data?.photo
											: userProfile?.data?.avatar
									}
									alt="user"
									width="36"
								/>
							</Link>

							<div className="dropdown-menu">
								<h6 className="dropdown-header">Header</h6>
								<div className="dropdown-divider"></div>
								<Link className="dropdown-item" to="/dashboard/profile/edit">
									<i className="far fa-user-circle me-2"></i>My Profile
								</Link>
								<Link className="dropdown-item" to="/dashboard/verification">
									<i className="fas fa-user-check me-2"></i>Verify account
								</Link>
								<Link
									className="dropdown-item"
									to="/dashboard/profile/payments"
								>
									<i className="far fa-credit-card me-2"></i>Payment Method
								</Link>
								<Link
									className="dropdown-item"
									to="/dashboard/profile/settings"
								>
									<i className="far fa-sun me-2"></i>Settings
								</Link>
								<Link className="dropdown-item" to="/dashboard/profile/invite">
									<i className="fas fa-user-plus me-2"></i>Invite a Friend
								</Link>
								<Link className="dropdown-item" to="/about">
									<i className="fas fa-info-circle me-2"></i>About Us
								</Link>
								<span className="dropdown-item pointer" onClick={handleLogout}>
									<i className="fas fa-sign-out-alt me-2"></i>
									{!isLoggingOut && "Logout"}
									{isLoggingOut && <ShowSpinner />}
								</span>
							</div>
						</li>

						<li className="nav-item dash-push-notification">
							<Link className="nav-link" to="/dashboard/notification">
								<AwaitResponse api={fetchNotifCountApi.mutateAsync}>
									<svg
										className="bi bi-bell-fill"
										xmlns="http://www.w3.org/2000/svg"
										width="1em"
										height="1em"
										fill="currentColor"
										viewBox="0 0 16 16"
									>
										<path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"></path>
									</svg>
									<span className="badge rounded-pill bg-danger push-notification-ping">
										{notifCount}
									</span>
								</AwaitResponse>
							</Link>
						</li>


					</ul>
				</div>
			</div>
		</nav>
	)
}
