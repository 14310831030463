import classNames from "classnames"
import React from "react"
import { generalApis } from "services/Api/general/gen.index"
import { useMutation } from "react-query"
import { SearchResultsInterface } from "types/app.types"
import ShowSpinner from "./misc/Spinner"
import { Link } from "react-router-dom"
import { uniqueId } from "services/utility/Utils/Utils"
import { encryptString } from "services/utility/Utils/utilities"
import { SwapperInterface } from "./wallet/WalletTransferSwapcard"
import { userOfferApis } from "services/Api/offer/offer.index"
import { FLUX_DYNAMIC_STORE, useFlusDispatcher } from "@bilmapay/react-flux-storage"
import MyofferMobileItem, { MyofferMobileItemProps } from "./misc/MyofferMobileItem"

interface DashDesktopContainerInterface {
	title?: string
	children: React.ReactNode
	showOnMobile?: boolean
	showTitle?: boolean
	showSearch?: boolean
	page?: "dispute_zone" | "market_news" | "my_offer" | "spot_wallet" | "funding_wallet" | "market_place"
}

const formRef = React.createRef<HTMLFormElement>()

export default function DashDesktopContainer({ title, showSearch = true, showOnMobile = false, showTitle = true, page = "market_place", children }: DashDesktopContainerInterface) {
	const [searchStatus, setSearchStatus] = React.useState<boolean>(false)
	const [searchResult, setSearchResult] = React.useState<Array<SearchResultsInterface>>([])

	const onSearchFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchStatus(true)
	}

	const onSearchBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target?.value && !searchResult?.length) {
			setSearchStatus(false)
		}
	}

	const closeSearchMenu = () => setSearchStatus(false)

	const { Search } = generalApis()
	const searchApi = useMutation(Search, {
		onSuccess(res: any) {
			if (res?.status) {
				setSearchResult(res?.data)
			}
		}
	})

	const isSearching = searchApi.isLoading

	const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const fd = new FormData(e.target)

		searchApi.mutateAsync(fd)
	}

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (formRef?.current) {
			const fd = new FormData(formRef?.current)

			searchApi.mutateAsync(fd)
		}
	}

	const tradeP2PSwapperConfig: SwapperInterface = {
		up: {
			name: "Spot",
			value: "spot"
		},
		down: {
			name: "Funding",
			value: "funding"
		}
	}

	const transferSwapperConfig: SwapperInterface = {
		up: {
			name: "Funding",
			value: "funding"
		},
		down: {
			name: "Spot",
			value: "spot"
		}
	}

	// -----------------------> [My Offer]
	const { ToggleOfferStatus, FetchMyOffers } = userOfferApis()
	const dispatcher = useFlusDispatcher()

	const handleOfferToggling = (offerId: string) => {
		const fd = new FormData()
		fd.append("offer_id", offerId)

		toggleOfferApi.mutateAsync(fd)
	}

	const fetchOfferApis = useMutation(FetchMyOffers, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher({ type: FLUX_DYNAMIC_STORE, payload: { store: "myOffersRes", data: res } })
			}
		}
	})

	const toggleOfferApi = useMutation(ToggleOfferStatus, {
		onSuccess: (res: any) => {
			if (res?.status) {
				fetchOfferApis.mutateAsync()

				if (formRef?.current) {
					const fd = new FormData(formRef?.current)

					searchApi.mutateAsync(fd)
				}
			}
		}
	})

	const isToggling = toggleOfferApi.isLoading

	return (
		<section className={classNames({ "dash-desktop-content": showOnMobile === false })}>
			{showTitle && (
				<header className="pt-2 mb-4">
					<div className="row">
						<div className="col-lg-4 col-xl-4 col-xxl-4">
							<h2 className="section-header">
								<strong>{title}</strong>
							</h2>
						</div>
						{showSearch && (
							<div
								className={classNames({
									"col d-flex justify-content-start align-items-center": true,
									"bk__hide-on-mobile": showOnMobile
								})}
							>
								<form ref={formRef} className="w-100" onSubmit={handleSubmit}>
									<div className="input-group input-group-lg search-input">
										<input onChange={handleSearchChange} onFocus={onSearchFocus} onBlur={onSearchBlur} name="search" required className="form-control" type="search" placeholder="Search anything..." />
										<button className="btn btn-light" type="submit">
											<i className="fa fa-search"></i>
										</button>
									</div>

									<input hidden name="page" defaultValue={page} className="form-control form-control-lg" type="text" />
									<section className="bk__custom_dropdown ">
										<section className={classNames({ "bk__dropdown_menu  search-menu": true, show: searchStatus })}>
											<button type="button" onClick={closeSearchMenu} className="btn-close mb-3 "></button>
											{(isSearching || isToggling) && (
												<section className="text-center">
													<ShowSpinner />
												</section>
											)}
											<div className="list-group list-group-flush nav-group bk__dropdown_items">
												{/* For dispute search results */}
												{page === "dispute_zone" && (
													<>
														{searchResult?.length > 0 && (
															<>
																{searchResult?.map(ticket => (
																	<li key={uniqueId()} className="list-group-item list-group-item-action bk__dropdown_item">
																		<div className="row">
																			<div className="col">
																				<h6 className="text-muted">
																					<strong>{ticket?.ticket_number}</strong>
																					{ticket?.status?.toLocaleLowerCase() === "open" && (
																						<Link to={`/tickets/room/${ticket?.ticket_token}`} className="badge rounded-pill bg-primary text-light ms-3">
																							Open
																						</Link>
																					)}
																				</h6>

																				<small className="text-muted">{ticket?.date}</small>
																			</div>
																		</div>
																	</li>
																))}
															</>
														)}
													</>
												)}

												{/* For makrtet news search results */}
												{page === "market_news" && (
													<>
														{searchResult?.length > 0 && (
															<>
																{searchResult?.map(news => (
																	<li key={uniqueId()} className="list-group-item list-group-item-action bk__dropdown_item">
																		<Link to={`/dashboard/market-news/${news?.slug}?tb=mrk`}>
																			<div className="row">
																				<div className="col-sm-3 col-md-2 col-lg-3 col-xl-2 col-xxl-2 text-center d-flex justify-content-center align-items-center mb-2">
																					<img className="rounded img-fluid d-block m-auto" src={news?.photo} alt="" loading="lazy" width="100" style={{ width: "100%", height: "100px" }} />
																				</div>
																				<div className="col-sm-9 col-md-10 col-lg-9 col-xl-10 col-xxl-10 d-flex justify-content-start align-items-center">
																					<div>
																						<h6>
																							<strong>{news?.title}</strong>
																						</h6>
																						<p className="text-muted">{news?.excerpt}</p>
																						<ul className="list-inline d-flex">
																							<li className="list-inline-item text-muted px-1 border-end">{news?.date}</li>
																							<li className="list-inline-item text-primary">creator: {news?.creator}</li>
																						</ul>
																					</div>
																				</div>
																			</div>
																		</Link>
																	</li>
																))}
															</>
														)}
													</>
												)}

												{/* For sport wallet search result */}
												{page === "spot_wallet" && (
													<>
														{searchResult?.length > 0 && (
															<>
																{searchResult?.map((coin: any) => (
																	<li key={uniqueId()} className="list-group-item list-group-item-action bk__dropdown_item">
																		<div className="card">
																			<div className="card-header">
																				<div className="row">
																					<div className="col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
																						<img src={coin?.icon} alt="" className="img-fluid img-icon-md" width="100" />
																						<strong className="ms-2">{coin?.name}</strong>
																					</div>
																					<div className="col-md-6 col-xl-6">
																						<p className="mb-0">
																							<strong>Market Value:</strong>
																						</p>
																						<p>{coin?.market_value} NGN</p>
																					</div>
																				</div>
																			</div>
																			<div className="card-body">
																				<h4 className="card-title mb-4">Coin Balance:</h4>
																				<h4 className="text-dark card-subtitle mb-2">
																					<strong>
																						{coin?.amount} {coin?.symbol}
																					</strong>
																					{/* <Link
																						className="btn btn-primary float-end shadow-sm text-light"
																						role="button"
																						to={`/dashboard/spot-wallet/transfer/${coin?.asset_id}?opt=${encryptString(JSON.stringify(tradeP2PSwapperConfig))}`}
																					>
																						Trade P2P
																					</Link>
																					<br /> */}
																				</h4>
																				<p className="card-text mt-3">~ {coin?.naira_value} NGN</p>
																				<div className="text-center d-flex text-center">
																					<Link className="btn btn-light w-100 me-4 shadow-sm bg-blue text-light" role="button" to={`/dashboard/spot-wallet/send-coin/${coin?.asset_id}`}>
																						Send
																					</Link>

																					<Link className="btn btn-light w-100 me-4 shadow-sm bg-blue text-light" role="button" to={`/dashboard/spot-wallet/receive-coin/${coin?.asset_id}`}>
																						Receive
																					</Link>
																				</div>
																			</div>
																		</div>
																	</li>
																))}
															</>
														)}
													</>
												)}

												{/* For funding wallet search results */}
												{page === "funding_wallet" && (
													<>
														{searchResult?.length > 0 && (
															<>
																{searchResult?.map((coin: any) => (
																	<li key={uniqueId()} className="list-group-item list-group-item-action bk__dropdown_item">
																		<div className="card">
																			<div className="card-header">
																				<div className="row">
																					<div className="col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
																						<img src={coin?.icon} alt="" className="img-fluid img-icon-md" width="100" />
																						<strong className="ms-2">{coin?.name}</strong>
																					</div>
																					<div className="col-md-6 col-xl-6">
																						<p className="mb-0">
																							<strong>Market Value:</strong>
																						</p>
																						<p>{coin?.market_value} NGN</p>
																					</div>
																				</div>
																			</div>
																			<div className="card-body">
																				<h4 className="card-title mb-4">Coin Balance:</h4>
																				<h4 className="text-dark card-subtitle mb-2">
																					<strong>
																						{coin?.amount} {coin?.symbol}
																					</strong>
																				</h4>
																				<p className="card-text mt-3">~ {coin?.naira_value} NGN</p>
																				<div className="text-center d-flex text-center">
																					<Link
																						className="btn btn-primary w-100 me-4 shadow-sm text-light"
																						role="button"
																						to={`/dashboard/funding-wallet/transfer/${coin?.asset_id}?opt=${encryptString(JSON.stringify(transferSwapperConfig))}`}
																					>
																						Transfer
																					</Link>
																				</div>
																			</div>
																		</div>
																	</li>
																))}
															</>
														)}
													</>
												)}

												{/* For my offer search results */}
												{page === "my_offer" && (
													<>
														{searchResult?.length > 0 && (
															<>
																{searchResult?.map((item: MyofferMobileItemProps) => (
																	<li key={uniqueId()} className="list-group-item list-group-item-action bk__dropdown_item">
																		<MyofferMobileItem toggler={handleOfferToggling} item={item} />
																	</li>
																))}
															</>
														)}
													</>
												)}

												{/* For marketplace search results */}
												{page === "market_place" && (
													<>
														{searchResult?.length > 0 && (
															<>
																{searchResult?.map(offer => (
																	<li key={uniqueId()} className="list-group-item list-group-item-action bk__dropdown_item">
																		{offer?.type === "buy" && (
																			<article className="mob-assets-object">
																				<section className="w-50 right">
																					<img className="rounded-circle img-fluid m-auto d-block" width="50" alt="" src={offer?.user_photo} />
																					<strong className="text-center w-100 d-block">{offer?.user_name}</strong>
																					<p className="text-center mb-0 text-primary coin-value-details">
																						{offer?.remaining_quantity} {offer?.asset_symbol}
																					</p>
																					<p className="text-center text-primary coin-value-details">
																						{offer?.total_order} Orders ({offer?.percentage_score})
																					</p>
																				</section>
																				<section className="w-100 left">
																					<p className="text-primary">
																						<strong>{offer?.payment_method}</strong>
																					</p>
																					<ul className="list-inline d-flex mb-0">
																						<li className="list-inline-item w-100">
																							<strong>Rate</strong>
																						</li>
																						<li className="list-inline-item w-100">
																							<strong>Limit</strong>
																						</li>
																					</ul>
																					<ul className="list-inline d-flex assets-info-list">
																						<li className="list-inline-item w-100">
																							{offer?.rate_per_coin} NGN
																							<br />
																						</li>
																						<li className="list-inline-item w-100">
																							{offer?.trade_limit}
																							<br />
																						</li>
																					</ul>
																					<Link to={`/dashboard/buy-coin?pl=${encryptString(JSON.stringify(offer))}`} className="btn btn-primary w-100 bg-blue" type="button">
																						<strong>Buy {offer?.asset_symbol}</strong>
																					</Link>
																				</section>
																			</article>
																		)}

																		{offer?.type === "sell" && (
																			<article className="mob-assets-object">
																				<section className="w-50 right">
																					<img className="rounded-circle img-fluid m-auto d-block" width="50" alt="" src={offer?.user_photo} />
																					<strong className="text-center w-100 d-block">{offer?.user_name}</strong>
																					<p className="text-center mb-0 text-primary coin-value-details">
																						{offer?.remaining_quantity} {offer?.asset_symbol}
																					</p>
																					<p className="text-center text-primary coin-value-details">
																						{offer?.total_order} Orders ({offer?.percentage_score})
																					</p>
																				</section>
																				<section className="w-100 left">
																					<p className="text-primary">
																						<strong>{offer?.payment_method}</strong>
																					</p>
																					<ul className="list-inline d-flex mb-0">
																						<li className="list-inline-item w-100">
																							<strong>Rate</strong>
																						</li>
																						<li className="list-inline-item w-100">
																							<strong>Limit</strong>
																						</li>
																					</ul>
																					<ul className="list-inline d-flex assets-info-list">
																						<li className="list-inline-item w-100">
																							{offer?.rate_per_coin} NGN
																							<br />
																						</li>
																						<li className="list-inline-item w-100">
																							{offer?.trade_limit}
																							<br />
																						</li>
																					</ul>
																					<Link to={`/dashboard/sell-coin?pl=${encryptString(JSON.stringify(offer))}`} className="btn btn-primary w-100" type="button">
																						<strong>Sell {offer?.asset_symbol}</strong>
																					</Link>
																				</section>
																			</article>
																		)}
																	</li>
																))}
															</>
														)}
													</>
												)}
											</div>
										</section>
									</section>
								</form>
							</div>
						)}
					</div>
				</header>
			)}

			{children}
		</section>
	)
}
