import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { Link } from "react-router-dom"

export default function OnboardingInit() {
	const { CheckSuccess } = useFlusStores()

	return (
		<section>
			<img className="img-fluid m-auto d-block" src={CheckSuccess} alt="" width="100" />
			<h1 className="text-center mt-2">
				<strong>Email Confirmed Successfully</strong>
			</h1>
			<p className="text-center my-4 p-3 w-75 m-auto d-block">Thank you for choosing Beekle. Buy, Sell & Trade Bitcoin, Ethereum, XRP, Litecoin and more cryptocurrencies in Nigeria. Start trading now!</p>
			<Link className="btn btn-primary btn-lg m-auto d-block bk-btn-light-blue w-fit" role="button" to="/onboard/personal-info">
				Complete Registration
			</Link>
		</section>
	)
}
