import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { Link } from "react-router-dom"
import {
	ArrowUpIcon,
	ArrowRightIcon,

} from "@heroicons/react/24/outline";
// import "../.morecss.css"

export default function AboutHeader() {
	const { about_us_banner, aboutus_safety, aboutus_mission, aboutus_eco, aboutus_seamless, } = useFlusStores()


	return (

		<section className="bg-white items-center sm:space-x-4 sm:h-screen pb-5 mb-6 pt-6 flex flex-row flex-wrap-reverse justify-center bg-fixed firstSectionBG bg-no-repeat">

		<div className="w-full sm:w-1/2 mt-8 sm:mt-0  flex flex-column items-center sm:items-start pl-4 sm:pl-20">

			<p className=" mb-4 text-blue-700 sm:w-[100%] tracking-wide sm:leading-tight text-5xl sm:text-7xl font-link">About Us</p>

			<div className="w-full sm:w-2/3 mb-6">
				<p className=" openFont forcegrey text-lg  ">At Beekle, we believe that everyone should have the freedom to earn, hold, spend, and share their money - no matter who you are or where you come from. We believe in the democratization of money.</p>
			</div>

			<Link
				role="button"
				to="/auth/signup" className="bg-blue-600  hover:bg-blue-700 items-center space-x-4 justify-center text-white w-120 h-12 openFont flex flex-row mt-4 font-bold py-2 px-4 rounded" >
				Get Started <ArrowRightIcon className="ml-2 mt-[1px]" height={20} width={20} />
			</Link>

		</div>




		<div className="w-5/6 sm:w-2/5 ">
			<img className="img-fluid block m-auto" src={about_us_banner} alt="" />
		</div>

	</section>

	)
}
