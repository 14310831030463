import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"

export default function EmptyContentMessage({ text }: { text?: string }) {
	const { emptyContent } = useFlusStores()
	return (
		<div className="text-center py-5">
			<img className="img-fluid" src={emptyContent} alt="" width="50" height="75" />
			<p className="my-2 text-muted py-3">
				<strong>
					{text && text}
					{!text && "No content yet"}
				</strong>
			</p>
		</div>
	)
}
