import AppFooter from "components/layout/AppFooter"
import DashHeader from "components/layout/DashHeader"
import DashSidebar from "components/layout/DashSidebar"
import React from "react"
import {
	Outlet,
	useLoaderData,
	useLocation,
	useNavigate,
	useNavigation
} from "react-router-dom"

import ShowSpinner from "components/misc/Spinner"
import { ShowInfo } from "services/utility/Utils/_toaster"
import { useUrlQuery } from "services/utility/hooks/useURL"

import { sleep } from "services/utility/Utils/_sleep"
import { useDispatch } from "react-redux"
import { toggleActiveStatus } from "redux/reducers/appSlice"

import SetBankPanel from "../panels/SetBankPanel"
import SetLimitPanel from "../panels/SetLimitPanel"
import DeactivateAccountModal from "../modals/DeactivateAccountModal"
import TransHistoryPanel from "../panels/TransHistoryPanel"

export default function MerchantDashboardLayout() {
	const location = useLocation()
	const navigate = useNavigate()
	const navigation = useNavigation()
	const { u } = useUrlQuery()
	const dispatcher = useDispatch()

	const profileResponse: any = useLoaderData()

	React.useEffect(() => {
		switch (profileResponse?.code) {
			case 424:
				dispatcher(toggleActiveStatus("disable"))

				ShowInfo("Verification", "Please verify your account.")
				sleep(1000).then(() =>
					navigate(`/auth/verify?email=${u}&redirect=${false}`)
				)
				break

			default:
		}
	}, [location])

	return (
		<>
			<DashHeader />
			<div className="container-fluid bg-gray">
				<div className="row">
					<DashSidebar user={profileResponse?.data} />
					<div
						className="col-md-9 col-lg-9 col-xl-10"
						id="bk__dash-main-content-area"
					>
						{navigation.state === "loading" && (
							<section className="text-center d-flex h-100 w-100 justify-content-center align-items-center p-4">
								<ShowSpinner size={100} />
							</section>
						)}
						{!(navigation.state === "loading") && <Outlet />}
						<AppFooter />
					</div>
				</div>
			</div>

			<SetBankPanel />
			<SetLimitPanel user={profileResponse?.data} />
			<DeactivateAccountModal />
			<TransHistoryPanel />
		</>
	)
}
