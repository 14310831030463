import { FlusMiddlewares } from "flus/middlewares/flus.middlewares"

/** The application authentication storage actions handles */
export const AuthActions = {
	"app/auth/login-account": FlusMiddlewares.auth.login,
	"app/auth/set-api-token": FlusMiddlewares.auth.setApiToken,
	"app/auth/logout": FlusMiddlewares.auth.logout,
	"app/auth/update-user": FlusMiddlewares.auth.updateUser,
	"app/auth/toggle-active-state": FlusMiddlewares.auth.toggleActiveState
}
