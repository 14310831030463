import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"

const previewIDFrontRef = React.createRef<HTMLImageElement>()
const inputFrontFileRef = React.createRef<HTMLInputElement>()

export default function UploadIDFront() {
	const { IDFront } = useFlusStores()

	const handleUserFileSelection = () => {
		if (inputFrontFileRef?.current) {
			inputFrontFileRef?.current?.click()
		}
	}

	React.useEffect(() => {
		const fileInput = document.querySelector("#kyc_upl_idFrontFileInput")

		if (fileInput) {
			fileInput?.addEventListener("change", function (e: any) {
				const uploadedFile = e.target.files[0]

				if (uploadedFile) {
					const fileReader = new FileReader()

					fileReader.onload = (ev: any) => {
						if (previewIDFrontRef?.current) {
							previewIDFrontRef.current.src = ev?.target?.result
						}
					}

					fileReader.readAsDataURL(uploadedFile)
				}
			})
		}
	})

	return (
		<>
			<img
				ref={previewIDFrontRef}
				className="img-fluid m-auto d-block pointer"
				src={IDFront}
				alt=""
				width="200"
				loading="lazy"
				onClick={handleUserFileSelection}
			/>

			<input
				ref={inputFrontFileRef}
				type="file"
				name="id_front"
				id="kyc_upl_idFrontFileInput"
				hidden
				accept="image/png,image/jpg,image/jpeg"
			/>
		</>
	)
}
