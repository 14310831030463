import CountryDailCodeSelector from "components/CountryDailCodeSelector"
import PageInfo from "components/misc/Page"
import ShowSpinner from "components/misc/Spinner"
import React from "react"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { userProfileApi } from "services/Api/profile/profile.index"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"

export default function ContactInfo() {
	const { UpdateContactInfo } = userProfileApi()
	const navigate = useNavigate()

	const updateContactInfoApi = useMutation(UpdateContactInfo, {
		onSuccess: (res: any) => {
			if (res?.status) {
				ShowSuccess("Contact Info", "Contact information saved successfully!")

				navigate("/onboard/review")
			} else {
				ShowServerErrors("Validation", res)
			}
		}
	})

	const isProcessing = updateContactInfoApi.isLoading

	const handleUserSubmitAction = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		updateContactInfoApi.mutateAsync(formData)
	}

	return (
		<section>
			<PageInfo titleText="Update Contact Information" />
			<header>
				<ol className="bk-onboarding-stepper">
					<li className="stepper__item completed" data-step="1"></li>
					<li className="stepper__item completed" data-step="2"></li>
					<li className="stepper__item completed" data-step="3"></li>
					<li className="stepper__item" data-step="4"></li>
				</ol>
				<div className="text-center mt-5">
					<h1>
						<strong>Other Data</strong>
					</h1>
					<p>
						Take note of the fields <sup className="text-danger">*</sup>
					</p>
					<p className="my-5 text-primary m-auto d-block w-75">
						<strong>
							We recognize both the rights of individuals to protect their personal data , including rights of access and correction, and the needs of organizations to collect, use or disclose
							personal data for legitimate and reasonable purposes.
						</strong>
					</p>
				</div>
			</header>
			<section className="d-md-flex justify-content-md-center align-items-md-center">
				<form className="border rounded my-4 shadow-sm" onSubmit={handleUserSubmitAction}>
					<header className="border-dark p-2 mb-3">
						<h2>
							<strong>Contact Address</strong>
							<br />
						</h2>
					</header>
					<section className="p-3">
						<div className="mb-4">
							<div className="row">
								<div className="col-md-6 mb-3">
									<div className="form-group">
										<label className="form-label w-100 input-label">
											<strong>Country Code </strong>
											<sup className="text-danger">*</sup>
										</label>

										<CountryDailCodeSelector inputName="country_code" defaultValue="+234" />
									</div>
								</div>
								<div className="col-md-6 mb-3">
									<div className="form-group">
										<label className="form-label w-100 input-label">
											<strong>Mobile Number</strong>
											<sup className="text-danger">*</sup>
										</label>
										<input className="form-control form-control-lg form-control-user" type="tel" name="phone_number" />
									</div>
								</div>
							</div>
						</div>
						<div className="mb-4">
							<div className="form-group">
								<label className="form-label w-100 input-label">
									<strong>Alternative Email</strong>
								</label>
								<input className="form-control form-control-lg form-control-user" type="email" placeholder="example@gmail.com" name="alternative_email" />
							</div>
						</div>
					</section>
					<header className="border-dark p-2 mb-3">
						<h2>
							<strong>Security Question</strong>
							<br />
						</h2>
					</header>
					<section className="p-3">
						<div className="mb-4">
							<div className="row">
								<div className="col-md-6 mb-3">
									<div className="form-group">
										<label className="form-label w-100 input-label">
											<strong>Question</strong>
											<sup className="text-danger">*</sup>
										</label>
										<input className="form-control form-control-lg form-control-user" type="text" name="security_question" />
									</div>
								</div>
								<div className="col-md-6 mb-3">
									<div className="form-group">
										<label className="form-label w-100 input-label">
											<strong>Answer</strong>
											<sup className="text-danger">*</sup>
										</label>
										<input className="form-control form-control-lg form-control-user" type="text" name="security_answer" />
									</div>
								</div>
							</div>
						</div>
						<div className="text-end mb-4">
							<button disabled={isProcessing} className="btn btn-primary btn-lg" type="submit">
								{!isProcessing && "Next"}
								{isProcessing && <ShowSpinner color="text-light" />}
							</button>
						</div>
					</section>
				</form>
			</section>
		</section>
	)
}
