import DefaultNavigation from "components/layout/DefaultNavigation"
import React from "react"
import AboutContent from "./AboutContent"
import BreadCrumb from "components/BreadCrumb"
import PageInfo from "components/misc/Page"
import { ArrowRightIcon } from "@heroicons/react/24/outline"
import { Link } from "react-router-dom"
import { useFlusStores } from "@bilmapay/react-flux-storage"

const navItems = [
	{
		active: false,
		title: "Home",
		link: "/"
	},
	{
		active: true,
		title: "About Us",
		link: "/about"
	}
]
export default function AboutPages() {
	const { about_us_banner, aboutus_safety, aboutus_mission, aboutus_eco, bitcoins, } = useFlusStores()


	return (
		<>
			{/* <PageInfo titleText="About Us" /> */}

			<DefaultNavigation includeHeader="about" />

			<section className="bg-white flex-wrap-reverse sm:space-x-4 sm:h-1/2  pt-6 flex flex-row flex-wrap justify-center bg-fixed firstSectionBG bg-no-repeat">

				<div className="w-5/6 sm:w-2/5 ">
					<img className="img-fluid block m-auto" src={aboutus_mission} alt="" />
				</div>

				<div className="w-full sm:w-1/2  flex flex-column items-center sm:items-start pl-4 sm:pl-20">

					<p className=" mb-4 text-blue-700 text-4xl sm:text-6xl sm:w-[100%] tracking-wide sm:leading-tight font-link">Our Mission</p>

					<div className="w-full sm:w-2/3 mb-6">
						<strong className="text-justify font-black text-lg openFont2 forcegrey text-center ">Our Mission is to be the most reliable and trusted digital asset service provider in Africa.</strong>
					</div>

				</div>

			</section>


			<div className="bg-fixed firstSectionBG bg-no-repeat h-20">

			</div>


			<section className="bg-white sm:space-x-4 sm:h-1/2 mb-6 pt-6 flex flex-row flex-wrap justify-center bg-fixed firstSectionBG bg-no-repeat">

				<div className="w-full sm:w-1/2  flex flex-column items-center sm:items-start pl-4 sm:pl-20">

					<p className=" mb-4 text-blue-700 text-4xl sm:text-6xl sm:w-[100%] tracking-wide sm:leading-tight font-link">Our Ecosystem</p>

					<div className="w-full sm:w-2/3 mb-6">
						<p className=" openFont forcegrey text-lg  ">Our platform is trusted by hundreds of thousands of people worldwide with several features that are unique.</p>
					</div>

				</div>


				<div className="w-5/6 sm:w-2/5 ">
					<img className="img-fluid block m-auto" src={aboutus_eco} alt="" />
				</div>

			</section>

			<section className="bg-blue-700 sm:space-x-4 py-14 sm:h-1/2 items-center mb-6 flex flex-row flex-wrap justify-center bg-fixed firstSectionBG bg-no-repeat">

				<div className="w-5/6 sm:w-2/5 hide_small">
					<img className="img-fluid block m-auto" src={bitcoins} alt="" />
				</div>

				<div className="w-full sm:w-1/2 flex flex-column items-center sm:items-start pl-4 sm:pl-20">
					<p className=" mb-4 text-white text-4xl sm:text-6xl sm:w-[100%] tracking-wide sm:leading-tight font-link">Seamless Trade on Beekle P2P</p>
					<div className="w-full mb-6">
						<p className=" openFont text-white text-lg  ">At Beekle, we believe that everyone should have the freedom to earn, hold, spend, and share their money - no matter who you are or where you come from. Beekle can handle Millions of transactions simultaneously. Our system infrastructure can scale up in a few seconds to match surging demand. Our platform is trusted by hundreds of thousands of people worldwide with several features that are unique.</p>
					</div>
				</div>

			</section>



			<section className=" bg-white-700 sm:space-x-6 py-14 sm:h-1/2 mb-6 flex flex-row flex-wrap justify-around bg-fixed firstSectionBG bg-no-repeat">

				<div className="w-full sm:w-1/2 flex flex-column items-center sm:items-start pl-4">

					<p className=" mb-4 text-blue text-4xl sm:text-6xl sm:w-[100%] tracking-wide sm:leading-tight font-link">ASSET SAFETY</p>

					<div className="w-full mb-6">
						<p className=" openFont2 forcegrey text-lg  ">Absolutely! Beekle has an escrow system to make the entire transaction secure so neither party can cheat the other. In the escrow system, Beekle will hold the seller's Digital asset until the transaction is successfully completed and the payment is confirmed. Beekle will hold the seller's Digital asset while you make a payment to the seller's bank account. Our robust Dispute Resolution system detects and resolves any dispute between a buyer and a seller. We also verify the KYC details of every user before allowing them to trade on Beekle, and keep a record of each and every transaction that occurs on our exchange. Hence, you don’t need to worry about the legitimacy of the person you’re transacting with — it’s all taken care of!</p>
					</div>

				</div>

				<div className="w-5/6 sm:w-1/4 ">
					<img className="img-fluid block m-auto  "  src={aboutus_safety} alt="" />
				</div>

			</section>

		</>
	)
}
