import React from "react"

export const userMobileMenuRef = React.createRef<HTMLElement>()
export const mainMobileNavContanerRef = React.createRef<HTMLDivElement>()
export const dashMobileNavContanerRef = React.createRef<HTMLDivElement>()
export const dashSearchFormRef = React.createRef<HTMLDivElement>()

export const availableBalanceCardRef = React.createRef<HTMLSpanElement>()

export const releaseCoinModalRef = React.createRef<HTMLDivElement>()
