import LOGO from "assets/img/logo/logo.png"
import nameLogo from "assets/img/clipboard-image-22.png"
import footerLogo from "assets/img/clipboard-image-77.png"
import facebookIcon from "assets/img/clipboard-image/clipboard-image-40.png"
import youtubeIcon from "assets/img/clipboard-image/clipboard-image-41.png"
import linkedinIcon from "assets/img/clipboard-image/clipboard-image-42.png"
import twitterIcon from "assets/img/clipboard-image/clipboard-image-43.png"
import instergramIcon from "assets/img/clipboard-image/clipboard-image-44.png"
import btcinputIcon from "assets/img/assets/asset002.png"
import bankExchangeIcon from "assets/img/assets/asset003.png"
import secureTranx from "assets/img/assets/asset004.png"
import disputeResol from "assets/img/assets/asset005.png"
import verifiedUser from "assets/img/assets/asset006.png"

import instantDeposit from "assets/img/clipboard-image/clipboard-image-21.png"
import securedTrading from "assets/img/clipboard-image/clipboard-image-22.png"
import swiftWithdrawal from "assets/img/clipboard-image/clipboard-image-23.png"

import TestifyerSlide1 from "assets/img/clipboard-image/clipboard-image-35.png"
import TestifyerSlide2 from "assets/img/clipboard-image/clipboard-image-37.png"
import TestifyerSlide3 from "assets/img/clipboard-image/clipboard-image-38.png"

import AboutHeaderBg from "assets/img/clipboard-image-10.png"
import seamlessTrade from "assets/img/clipboard-image-11.png"
import networkOfNodes from "assets/img/clipboard-image-12.png"
import assetsSafty from "assets/img/clipboard-image-13.png"

import contactUsBg from "assets/img/clipboard-image-14.png"
import worldMapBg from "assets/img/clipboard-image-15.png"
import socialPlatform from "assets/img/clipboard-image-17.png"
import emailBox from "assets/img/clipboard-image-18.png"
import teamSupport from "assets/img/clipboard-image-19.png"

import faqsBg from "assets/img/clipboard-image-20.png"
import faqsIcon from "assets/img/clipboard-image-21.png"

import LoginImg from "assets/img/clipboard-image-3.png"
import SignupImg from "assets/img/clipboard-image-5.png"
import GoogleIcon from "assets/img/clipboard-image-2.png"
import VerificationImg from "assets/img/clipboard-image-7.png"
import RecapchaImg from "assets/img/clipboard-image-8.png"
import ForgotPwdImg from "assets/img/clipboard-image-6.png"

import CheckSuccess from "assets/img/clipboard-image-56.png"

import FBFooterIcon from "assets/img/icons/fb-icon.png"
import YTFooterIcon from "assets/img/icons/yt-icon.png"
import linkedInFooterIcon from "assets/img/icons/linkin-icon.png"
import TWTFooterIcon from "assets/img/icons/twt-icon.png"
import IGFooterIcon from "assets/img/icons/ig-icon.png"

import Dashbanner from "assets/img/clipboard-image-25.png"
import BluredLogo from "assets/img/clipboard-image-74.png"
import dashNavIconWallet from "assets/img/clipboard-image-26.png"
import dashNavIconFunding from "assets/img/clipboard-image-27.png"
import dashNavIconMarket from "assets/img/clipboard-image-28.png"
import dashNavIconTrans from "assets/img/clipboard-image-29.png"
import dashNavIconMarketNews from "assets/img/clipboard-image-30.png"
import dashNavIconDisputeZone from "assets/img/clipboard-image-31.png"
import dashNavIconCreateOffer from "assets/img/clipboard-image-32.png"
import dashNavIconMyOffer from "assets/img/clipboard-image-75.png"

import emptyContent from "assets/img/clipboard-image-34.png"
import tellAFriend from "assets/img/clipboard-image-65.png"
import IDPlaceholder from "assets/img/clipboard-image-67.png"
import ProofOfResidentPlaceholder from "assets/img/clipboard-image-66.png"

import StampImg from "assets/img/clipboard-image-68.png"
import IDFront from "assets/img/clipboard-image-69.png"
import IDBack from "assets/img/clipboard-image-70.png"
import IDSelfie from "assets/img/clipboard-image-71.png"
import LocationIcon from "assets/img/clipboard-image-73.png"
import UploadImg from "assets/img/clipboard-image-72.png"

import NairaFlag from "assets/img/clipboard-image-36.png"
import GiftImg from "assets/img/clipboard-image-47.png"
import swapCoinImg from "assets/img/clipboard-image-46.png"
import qrcodePlaceholder from "assets/img/clipboard-image-48.png"
import DisputeIcon from "assets/img/clipboard-image-54.png"
import BlockedIcon from "assets/img/clipboard-image-55.png"
import MarketNews_Item1 from "assets/img/clipboard-image-50.png"
import MarketNews_Item2 from "assets/img/clipboard-image-51.png"
import MarketNews_Item3 from "assets/img/clipboard-image-52.png"
import MarketNews_Item4 from "assets/img/clipboard-image-53.png"
import AwaitPaymentRelease from "assets/img/clipboard-image-59.png"
import StaticSpinner from "assets/img/clipboard-image-62.png"
import MakePaymentIcon from "assets/img/clipboard-image-60.png"
import CheckMark from "assets/img/clipboard-image-79.png"
import CheckGood from "assets/img/clipboard-image-81.png"
import LockIcon from "assets/img/clipboard-image-80.png"
import AwaitCoinRelease from "assets/img/clipboard-image-61.png"

import socialplatform from "assets/img/assets/usersfour.png"
import toptier_security from "assets/img/assets/emailedit.png"
import support from "assets/img/assets/bx_support.png"

import padlock from "assets/img/padlock.png"

import rocket from "assets/img/rocket.png"
import clipboard from "assets/img/clipboard.png"

import sell_crypto from "assets/img/sell_crypto.png"
import firstsectionimage from "assets/img/firstsectionimage.png"

import about_us_banner from "assets/img/assets/about_us_banner.png"
import aboutus_mission from "assets/img/assets/aboutus_mission.png"
import aboutus_eco from "assets/img/assets/aboutus_eco.png"
import aboutus_seamless from "assets/img/assets/aboutus_seamless.png"
import aboutus_safety from "assets/img/assets/aboutus_safety.png"
import bitcoins from "assets/img/assets/bitcoins.png"
import contactusbg from "assets/img/assets/contactusbg.png"

import facebook from "assets/img/devicon_facebook.png"
import ig from "assets/img/instagram.png"
import linkin from "assets/img/linkedin.png"
import xlogo from "assets/img/xlogo.png"

import WhatsAppBg from "assets/img/bg/wpbg.jpg"

export const AppImages = {
	LOGO,
	footerLogo,
	facebookIcon,
	youtubeIcon,
	linkedinIcon,
	twitterIcon,
	instergramIcon,
	btcinputIcon,
	bankExchangeIcon,
	secureTranx,
	disputeResol,
	verifiedUser,

	instantDeposit,
	securedTrading,
	swiftWithdrawal,

	TestifyerSlide1,
	TestifyerSlide2,
	TestifyerSlide3,

	socialplatform,
	toptier_security,
	support,



	AboutHeaderBg,
	seamlessTrade,
	networkOfNodes,
	assetsSafty,

	contactUsBg,
	worldMapBg,
	socialPlatform,
	emailBox,
	teamSupport,
	faqsBg,
	faqsIcon,
	LoginImg,
	GoogleIcon,
	SignupImg,
	VerificationImg,
	RecapchaImg,
	ForgotPwdImg,
	CheckSuccess,
	FBFooterIcon,
	YTFooterIcon,
	linkedInFooterIcon,
	TWTFooterIcon,
	IGFooterIcon,
	// -------------------------
	// Dashboard
	nameLogo,
	contactusbg,

	Dashbanner,
	BluredLogo,
	dashNavIconWallet,
	dashNavIconFunding,
	dashNavIconMarket,
	dashNavIconTrans,
	dashNavIconMarketNews,
	dashNavIconDisputeZone,
	dashNavIconCreateOffer,
	dashNavIconMyOffer,
	emptyContent,
	tellAFriend,
	IDPlaceholder,
	ProofOfResidentPlaceholder,
	StampImg,
	IDFront,
	IDBack,
	IDSelfie,
	LocationIcon,
	UploadImg,
	NairaFlag,
	GiftImg,
	swapCoinImg,
	qrcodePlaceholder,
	DisputeIcon,
	BlockedIcon,
	MarketNews_Item1,
	MarketNews_Item2,
	MarketNews_Item3,
	MarketNews_Item4,
	AwaitPaymentRelease,
	StaticSpinner,
	MakePaymentIcon,
	CheckMark,
	LockIcon,
	CheckGood,
	AwaitCoinRelease,
	WhatsAppBg,
	padlock,
	rocket,
	clipboard,
	sell_crypto,
	firstsectionimage,
	facebook,
	ig,
	linkin,
	bitcoins,
	xlogo, about_us_banner, aboutus_safety, aboutus_mission, aboutus_eco, aboutus_seamless,

}
