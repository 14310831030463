import DashDesktopContainer from "components/DashDesktopContainer"
import { MyOfferAssetCol, MyOfferStatus, ViewMyOfferDetails } from "components/misc/myofferComponents"
import React from "react"
import { useLoaderData } from "react-router-dom"
import DataTable from "react-data-table-component"
import DashMobileContainer from "components/DashMobileContainer"
import MyofferMobileItem, { MyofferMobileItemProps } from "components/misc/MyofferMobileItem"
import { uniqueId } from "services/utility/Utils/Utils"
import MyOfferDetailsModal from "./MyOfferDetailsModal"
import { userOfferApis } from "services/Api/offer/offer.index"
import { useMutation } from "react-query"
import { FLUX_DYNAMIC_STORE, useFlusDispatcher, useFlusStores } from "@bilmapay/react-flux-storage"
import ShowSpinner from "components/misc/Spinner"
import EmptyContentMessage from "components/EmptyContentMessage"
import DashSearchForm from "components/layout/nav/DashSearchForm"
import { useDispatch, useSelector } from "react-redux"
import { getMyOfferResponse, setMyofferResponse } from "redux/reducers/dashboardSlice"

export default function MyOffer() {
	const myOffers: any = useLoaderData()
	const myOffersRes = useSelector(getMyOfferResponse)
	const dispatcher = useDispatch()

	const data = myOffersRes ? myOffersRes : myOffers?.data

	const { ToggleOfferStatus, FetchMyOffers } = userOfferApis()

	const fetchOfferApis = useMutation(FetchMyOffers, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(setMyofferResponse(res?.data))
			}
		}
	})

	const toggleOfferApi = useMutation(ToggleOfferStatus, {
		onSuccess: (res: any) => {
			if (res?.status) {
				fetchOfferApis.mutateAsync()
			}
		}
	})

	const handleOfferToggling = (offerId: string) => {
		const fd = new FormData()
		fd.append("offer_id", offerId)

		toggleOfferApi.mutateAsync(fd)
	}

	const isLoading = toggleOfferApi.isLoading || fetchOfferApis.isLoading

	const tableColumns = [
		{
			id: uniqueId(),
			name: "Date",
			selector: (row: any) => row?.created_date
		},
		{
			id: uniqueId(),
			name: "Asset",
			selector: (row: any) => <MyOfferAssetCol icon={row?.icon} text={row?.asset_name} />
		},
		{
			id: uniqueId(),
			name: "State",
			selector: (row: any) => row?.type
		},
		{
			id: uniqueId(),
			name: "Balance",
			selector: (row: any) => row?.remaining_amount
		},
		{
			id: uniqueId(),
			name: "Action",
			selector: (row: any) => <MyOfferStatus toggler={handleOfferToggling} status={row?.status} offerId={row?.offer_id} />
		},
		{
			id: uniqueId(),
			name: "Track Offer",
			selector: (row: any) => <ViewMyOfferDetails offerId={row?.offer_id} type={row?.type} />
		}
	]

	return (
		<>
			<DashMobileContainer>
				{Array.isArray(data) && (
					<>
						{data.map((item: MyofferMobileItemProps) => (
							<MyofferMobileItem toggler={handleOfferToggling} item={item} key={uniqueId()} />
						))}
					</>
				)}

				{isLoading && (
					<section className="d-flex py-5 justify-content-center align-align-items-center">
						<ShowSpinner />
					</section>
				)}
			</DashMobileContainer>
			<DashDesktopContainer title="My Offer" page="my_offer" showOnMobile={false}>
				<DataTable className="shadow-sm pb-3 bg-white mb-5" columns={tableColumns} data={data} />
				{isLoading && (
					<section className="d-flex py-5 justify-content-center align-align-items-center">
						<ShowSpinner />
					</section>
				)}
			</DashDesktopContainer>

			{(!data || data?.length <= 0) && <EmptyContentMessage text="You haven't created any offers yet." />}

			<MyOfferDetailsModal />

			<DashSearchForm page="my_offer" />
		</>
	)
}
