import { availableBalanceCardRef } from "flus/constants/refs.const"
import React from "react"

// Toggle available balance visibility
export const handleAvailableBalanceToggler = (
	e: React.MouseEvent<HTMLElement>,
	balanceValue: string | null
) => {
	const visibility = "fa-eye"
	const notVisibility = "fa-eye-slash"

	if (availableBalanceCardRef?.current) {
		const target: any = e.target

		if (target.classList.contains(notVisibility)) {
			availableBalanceCardRef.current.textContent = "******"
			target.classList.remove(notVisibility)
			target.classList.add(visibility)
		} else {
			availableBalanceCardRef.current.textContent = balanceValue
			target.classList.remove(visibility)
			target.classList.add(notVisibility)
		}
	}
}
