import { useFlusStores } from "@bilmapay/react-flux-storage"
import React from "react"
import { useMutation } from "react-query"
import { Link } from "react-router-dom"
import { userMarketplaceApis } from "services/Api/marketplace/marketplace.index"
import { userTicketApis } from "services/Api/ticket/ticket.index"
import { ShowServerErrors } from "services/utility/Utils/_toaster"
import ShowSpinner from "./Spinner"

export interface FixChatNavigatorInterface {
	children?: React.ReactNode
	/**
	 * The return link that when users click will return them
	 * to previus ui component
	 */
	returnLink: string

	handleSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void
	isLoading?: boolean
	Ref?: React.RefObject<HTMLFormElement>
	ticketToken?: string
	refreshChats?: () => void
	title?: string
}

const fileAttachmentRef = React.createRef<HTMLInputElement>()

export default function FixChatNavigation({ refreshChats, title, ticketToken, handleSubmit, isLoading, Ref, children, returnLink }: FixChatNavigatorInterface) {
	const { PostAppealFileChat } = userTicketApis()
	const { WhatsAppBg } = useFlusStores()

	const postMediaFileApi = useMutation(PostAppealFileChat, {
		onSuccess: (res: any) => {
			if (res?.status) {
				if (typeof refreshChats === "function") {
					refreshChats()
				}
			} else ShowServerErrors("Error", res)
		}
	})

	const isPosting = postMediaFileApi.isLoading

	const handleAttachmentClick = () => {
		if (fileAttachmentRef?.current) {
			fileAttachmentRef?.current.click()
		}
	}

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target?.files) {
			const file = e.target?.files[0]
			const fd = new FormData()

			fd.append("file", file)
			/* @ts-ignore */
			fd.append("appeal_token", ticketToken)
			fd.append("message_type", "media")
			postMediaFileApi.mutateAsync(fd)
		}
	}

	const handlGoBack = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()

		window?.history?.back()
	}

	return (
		<div className="card bk__chat-room group-chatroom fix-chat-card-navigation">
			<div className="card-header d-flex justify-content-start align-items-center">
				<h3 className="me-3 w-100">
					<a onClick={handlGoBack} className="navigate-back-icon" href={returnLink && returnLink}>
						<i className="fas fa-arrow-circle-left"></i>
					</a>{" "}
					<small>{title}</small>
				</h3>
			</div>
			<div className="card-body fix-chatroom bk__chatroom-body" style={{ backgroundImage: `url(${WhatsAppBg})` }}>
				{children}
			</div>
			<div className="card-footer px-1 pb-4">
				<form onSubmit={handleSubmit} ref={Ref}>
					<div className="input-group input-group-lg chatroom-message-composer">
						{/* Files attachment */}
						<input onChange={handleFileChange} type="file" name="upl_file" style={{ display: "none" }} ref={fileAttachmentRef} />
						<span className="input-group-text attach-icon" onClick={handleAttachmentClick}>
							{!isPosting && (
								<svg className="bi bi-paperclip" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
									<path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"></path>
								</svg>
							)}

							{isPosting && <ShowSpinner color="text-primary" />}
						</span>
						<input required name="message" className="form-control input-area" type="text" placeholder="Type Something" />

						<input type="hidden" name="message_type" defaultValue={"text"} />
						<button className="btn btn-light mx-2 emoji-icon" type="button">
							<i className="fas fa-smile"></i>
						</button>
						<button disabled={isLoading} className="btn btn-primary btn-lg send-btn p-2 px-3" type="submit">
							{!isLoading && <i className="icon ion-android-send"></i>}
							{isLoading && <ShowSpinner size={16} color="text-light" />}
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}
