import EmptyContentMessage from "components/EmptyContentMessage"
import React from "react"
import MarketplaceTabs from "./MarketplaceTabs"
import DataTable from "./DataTable"
import { userTransactionApi } from "services/Api/transactions/transactions.index"
import { useMutation } from "react-query"
import AwaitResponse from "components/AwaitResponse"
import { uniqueId } from "services/utility/Utils/Utils"
import { useDispatch, useSelector } from "react-redux"
import { getRecentTransactions, setRecentTransactions } from "redux/reducers/dashboardSlice"

export default function DashDesktopContent() {
	const dispatcher = useDispatch()

	const { GetRecentTransactions } = userTransactionApi()

	const fetchRecentTransApi = useMutation(GetRecentTransactions, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(setRecentTransactions(res?.data))
			}
		}
	})

	const data = useSelector(getRecentTransactions)

	return (
		<>
			<section className="bg-white p-3">
				<div className="dash-tabs-main">
					<ul className="nav nav-pills nav-fill" role="tablist">
						<li className="nav-item" role="presentation">
							<a className="nav-link active buy-tab-link " role="tab" data-bs-toggle="pill" href="#tab-1">
								Buy
							</a>
						</li>
						<li className="nav-item" role="presentation">
							<a className="nav-link sell-tab-link" role="tab" data-bs-toggle="pill" href="#tab-2">
								Sell
							</a>
						</li>
					</ul>
					<div className="tab-content">
						<div id="tab-1" className="tab-pane fade show active buy-tab-content" role="tabpanel">
							<MarketplaceTabs type="buy-tab-item" />

							<DataTable />
						</div>
						<div id="tab-2" className="tab-pane fade sell-tab-content" role="tabpanel">
							<MarketplaceTabs type="sell-tab-item" />

							<DataTable type="sell-btn" />
						</div>
					</div>
				</div>
			</section>
			<section className="bg-white p-3 mb-5">
				<h4>
					<strong>Recent Transactions</strong>
				</h4>
				<div className="table-responsive">
					<AwaitResponse api={fetchRecentTransApi.mutateAsync}>
						<table className="table">
							<thead className="bg-gray">
								<tr>
									<th>Date</th>
									<th>Amount</th>
									<th>Mode</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								{data && (
									<>
										{data?.length > 0 && (
											<>
												{data.map(item => (
													<tr key={uniqueId()}>
														<td className="text-truncate">{item?.date}</td>
														<td className="text-truncate">{item?.amount}</td>
														<td className="text-truncate">{item?.mode}</td>
														<td className="text-truncate">{item?.status}</td>
													</tr>
												))}
											</>
										)}
									</>
								)}
							</tbody>
						</table>
						{(!data || data?.length <= 0) && <EmptyContentMessage text="No Transactions yet" />}
					</AwaitResponse>
				</div>
			</section>
		</>
	)
}
