import { GET, POST, PUT, DROP } from "configs/api"
import { handleError } from "services/utility/Utils/Utils"
import { walletEndpoints } from "./wallet.endpoints"

export const FetchSpotWallet = async () => {
	try {
		return await GET(walletEndpoints.FETCH_SPOT_WALLET)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchFundingWallet = async () => {
	try {
		return await GET(walletEndpoints.FETCH_FUNDING_WALLET)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const TransferCoin = async (params: FormData | object) => {
	try {
		return await POST(walletEndpoints.TRANSFER_COIN, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const ReceiveCoinAsset = async (params: FormData | object) => {
	try {
		return await POST(walletEndpoints.RECEIVE_ASSET, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FindAsset = async (params: FormData | object) => {
	try {
		return await POST(walletEndpoints.FIND_ASSET, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchGasFee = async (params: FormData | object) => {
	try {
		return await POST(walletEndpoints.GET_GASE_FEE, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const WalletWithdrawal = async (params: FormData | object) => {
	try {
		return await POST(walletEndpoints.WALLET_WITHDRAWAL, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchAssets = async () => {
	try {
		return await GET(walletEndpoints.FETCH_ASSETS)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchCoinAssets = async () => {
	try {
		return await GET(walletEndpoints.FETCH_COIN_ASSETS)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}
