import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"

function HowItWorksSection() {
	const { padlock, clipboard, rocket } = useFlusStores()

	return (
		<section className=" pt-16 pb-24 space-y-12 bg-gray flex flex-column items-center">

			<div className="flex flex-column items-center">
				<strong className="text-center font-link text-3xl sm:text-4xl text-blue-700 mb-1">Unleash Your Entire Cryptocurrency Experience.</strong>
				{/* <strong className="text-center font-link text-3xl sm:text-4xl text-blue-700 mb-4">Cryptocurrency Experience.</strong> */}
				<p className="text-center text-lg sm:text-xl ">why you should choose us</p>
							
			</div>


			<div className="container">

				<div className="row">

					<div className="col-md-4 mb-3">
						<div className="card">
							<div className="card-body flex flex-column items-center ">
								<img src={padlock} alt="INSTANT DEPOSIT" width="50" className="block m-auto" />
								<strong className="mb-2 font-link text-justify text-blue-700">Top-Tier Security</strong>
								<p className="card-text">We've gone to great lengths to create Beekle as Nigeria’s most secure exchange. Our dedication includes periodic security audits to ensure a highly secure trading platform for the Nigerian market.</p>
							</div>
						</div>
					</div>

					<div className="col-md-4 mb-3">
						<div className="card">
							<div className="card-body flex flex-column items-center">
								<img className="block m-auto" src={clipboard} alt="SECURED TRADING" width="50" />

								<strong className="mb-2 text-blue-700 font-link text-center">Super Fast KYC</strong>
								<p className="card-text">
									After sign-up, our advanced identity verification swiftly completes your KYC, ensuring adherence to proper protocols. Our system's robustness significantly reduces verification times.
								</p>
							</div>
						</div>
					</div>

					<div className="col-md-4 pb-3">
						<div className="card">
							<div className="card-body flex flex-column items-center">
								<img className="block m-auto" src={rocket} alt="SWIFT WITHDRAWAL" width="50" />

								<strong className="mb-2 text-blue-700 font-link text-center">Quick Transaction</strong>
								<p className="card-text">Beekle is capable of handling millions of transactions. To meet surge demand, our system infrastructure can be scaled up in a matter of seconds.</p>
							</div>
						</div>
					</div>
				</div>

			</div>
		</section>
	)
}

export default HowItWorksSection
