import PageInfo from "components/misc/Page"
import ShowSpinner from "components/misc/Spinner"
import React from "react"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { userProfileApi } from "services/Api/profile/profile.index"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { useDispatch } from "react-redux"
import { toggleActiveStatus } from "redux/reducers/appSlice"

export default function ReviewInfo() {
	const { AcceptPrivacyPolicy } = userProfileApi()
	const dispatcher = useDispatch()

	const navigate = useNavigate()

	const acceptPrivacyPolicyApi = useMutation(AcceptPrivacyPolicy, {
		onSuccess: (res: any) => {
			if (res.status) {
				dispatcher(toggleActiveStatus("enable"))

				ShowSuccess("Review & Privacy Policy", "Thank you for taking these review!")

				navigate("/dashboard")
			} else {
				ShowServerErrors("Validation", res)
			}
		}
	})

	const isProcessing = acceptPrivacyPolicyApi.isLoading

	const handleUserSubmitAction = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		acceptPrivacyPolicyApi.mutateAsync(formData)
	}

	return (
		<section>
			<PageInfo titleText="Review & Privacy Policy" />
			<header>
				<ol className="bk-onboarding-stepper">
					<li className="stepper__item completed" data-step="1"></li>
					<li className="stepper__item completed" data-step="2"></li>
					<li className="stepper__item completed" data-step="3"></li>
					<li className="stepper__item completed" data-step="4"></li>
				</ol>
				<div className="text-center mt-5">{/* <h1>
						<strong>Review Data</strong>
					</h1>
					<p>
						Take note of the fields <sup className="text-danger">*</sup>
					</p> */}</div>
			</header>
			<section className="d-md-flex justify-content-md-center align-items-md-center">
				<form className="border rounded my-4 shadow-sm" onSubmit={handleUserSubmitAction}>
					{/* <header className="border-dark p-2 mb-3">
						<h2>
							<strong>Select one or more options as applicable</strong>
							<br />
						</h2>
					</header>
					<section className="p-3">
						<div className="mb-3">
							<div className="form-check">
								<input name="review[]" id="formCheck-1" className="form-check-input" type="checkbox" value={"Do you know anything about Bitcoin/crypto-currencies?"} />
								<label className="form-check-label" htmlFor="formCheck-1">
									Do you know anything about Bitcoin/crypto-currencies?
								</label>
							</div>
						</div>
						<div className="mb-3">
							<div className="form-check">
								<input name="review[]" id="formCheck-2" className="form-check-input" type="checkbox" value={"Are you an addicted lover of politics?"} />
								<label className="form-check-label" htmlFor="formCheck-2">
									Are you an addicted lover of politics?
								</label>
							</div>
						</div>
						<div className="mb-3">
							<div className="form-check">
								<input name="review[]" id="formCheck-3" className="form-check-input" type="checkbox" value={"Do you think authorities should be responsible for monetary policies regulation ?"} />
								<label className="form-check-label" htmlFor="formCheck-3">
									Do you think authorities should be responsible for monetary policies regulation ?
								</label>
							</div>
						</div>
						<div className="mb-3">
							<div className="form-check">
								<input name="review[]" id="formCheck-4" className="form-check-input" type="checkbox" value={"Are you a full tier trader with no other source of income?"} />
								<label className="form-check-label" htmlFor="formCheck-4">
									Are you a full tier trader with no other source of income?
								</label>
							</div>
						</div>
						<div className="mb-3">
							<div className="form-check">
								<input name="review[]" id="formCheck-5" className="form-check-input" type="checkbox" value={"Do you have crypto assets in other wallets?"} />
								<label className="form-check-label" htmlFor="formCheck-5">
									Do you have crypto assets in other wallets?
								</label>
							</div>
						</div>
						<div className="mb-3">
							<div className="form-check">
								<input name="review[]" id="formCheck-6" className="form-check-input" type="checkbox" value={"Are you pertinent about learning more about the use of crypto asset"} />
								<label className="form-check-label" htmlFor="formCheck-6">
									Are you pertinent about learning more about the use of crypto asset
								</label>
							</div>
						</div>
					</section> */}
					<header className="border-dark p-2 mb-3">
						<h2>
							<strong>Data privacy reminder</strong>
							<br />
						</h2>
					</header>
					<section className="p-3">
						<p>
							We assure you of our continuous commitment in ensuring and protecting of every of your personal data that we process and to provide a compliant and consistent approach to data story and
							manipulation.
						</p>
						<div className="form-check">
							<input id="formCheck-7" className="form-check-input" type="checkbox" name="privacy_status" value={1} />
							<label className="form-check-label" htmlFor="formCheck-7">
								<strong>I agree to the Terms and Conditions guiding the usage of Beekle.</strong>
							</label>
						</div>
						<div className="text-end my-5">
							<button className="btn btn-primary btn-lg" type="submit" disabled={isProcessing}>
								{!isProcessing && "Submit"}
								{isProcessing && <ShowSpinner color="text-light" />}
							</button>
						</div>
					</section>
				</form>
			</section>
		</section>
	)
}
