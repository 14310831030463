import React, { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { useDispatch, useSelector } from "react-redux"
import { getWithdrawalOrderPanel, toggleOrderPanel, toggleWithdrawalOrderPanel } from "redux/reducers/panelsSlice"
import { OrderHistoryInterface } from "types"

import { changeMerchantWithdrawStep, getMerchanCurrentWithdrawStep, setMerchantWithdrawalTimerId, stopMerchantWithdrawalTimer } from "redux/reducers/withdrawalSlice"
import WithdrawalRequest from "./withdrawal/WithdrawalRequest"
import WithdrawalRecipientDetails from "./withdrawal/WithdrawalRecipientDetails"
import WithdrawalProofUploader from "./withdrawal/WithdrawalProofUploader"
import WithdrawalAwaitingRelease from "./withdrawal/WithdrawalAwaitingRelease"
import { CheckWithdrawalRequestStatus } from "services/Api/merchant/merchant.apis"
import { useMutation } from "react-query"
import { ShowError, ShowSuccess } from "services/utility/Utils/_toaster"

export default function WithdrawalOrderPanel() {
	const { data, params, show } = useSelector(getWithdrawalOrderPanel)
	const currentStep = useSelector(getMerchanCurrentWithdrawStep)

	const dispatcher = useDispatch()

	const _handleClose = () => {
		dispatcher(toggleWithdrawalOrderPanel({ status: false }))
	}

	const _data: OrderHistoryInterface = data

	const withdrawalListenerApi = useMutation(CheckWithdrawalRequestStatus, {
		onSuccess(res: any) {
			if (res?.status) {
				switch (res?.data?.status_code?.toString()) {
					case "1":
						dispatcher(stopMerchantWithdrawalTimer(res?.data))
						dispatcher(changeMerchantWithdrawStep("payment_request"))
						break

					case "2":
						dispatcher(stopMerchantWithdrawalTimer(res?.data))
						dispatcher(changeMerchantWithdrawStep("decline"))

						ShowError("Declined", "This transaction has been declined.")
						break

					case "3":
						dispatcher(stopMerchantWithdrawalTimer(res?.data))
						dispatcher(changeMerchantWithdrawStep("payment_details"))
						break

					case "5":
						dispatcher(stopMerchantWithdrawalTimer(res?.data))
						dispatcher(changeMerchantWithdrawStep("completed"))
						_handleClose()
						ShowSuccess("Completed", "This transaction has been completed and closed.")
						break
					default:
				}
			}
		}
	})

	React.useEffect(() => {
		if (params) {
			withdrawalListenerApi.mutateAsync({ txn_id: params })
			const timer = setInterval(() => {
				withdrawalListenerApi.mutateAsync({ txn_id: params })
			}, 9000)
			dispatcher(setMerchantWithdrawalTimerId(timer))
		}
	}, [params])

	return (
		<Transition.Root show={show} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={_handleClose}>
				<Transition.Child as={Fragment} enter="ease-in-out duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto relative w-screen max-w-lg">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-500"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-500"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
											<button type="button" className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" onClick={_handleClose}>
												<span className="absolute -inset-2.5" />
												<span className="sr-only">Close panel</span>
												<XMarkIcon className="h-6 w-6" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									<div className="flex h-full right-0 flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="px-4 sm:px-6">
											<Dialog.Title className="text-base font-semibold leading-6 text-gray-900 uppercase">Transaction Detail</Dialog.Title>
											<hr className="my-3 bg-gray-300" />
										</div>

										{currentStep === "payment_request" && <WithdrawalRequest data={_data} />}

										{currentStep === "payment_details" && <WithdrawalRecipientDetails />}

										{currentStep === "upload_pop" && <WithdrawalProofUploader />}

										{currentStep === "awaiting_release" && <WithdrawalAwaitingRelease />}
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
