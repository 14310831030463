import BKSelectCurrencyPair from "components/input/BKSelectCurrencyPair"
import BKSelectResponseTime from "components/input/BKSelectResponseTime"
import ShowSpinner from "components/misc/Spinner"
import React from "react"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { userOfferApis } from "services/Api/offer/offer.index"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"

export default function BuyOfferTab() {
	const navigate = useNavigate()
	const formRef = React.createRef<HTMLFormElement>()

	const { CreateBuyOffer } = userOfferApis()

	const createBuyOfferApi = useMutation(CreateBuyOffer, {
		onSuccess: (res: any) => {
			if (res?.status) {
				if (formRef?.current) {
					formRef?.current?.reset()
				}

				ShowSuccess("Create Offer", res?.message)

				navigate("/dashboard")
			} else ShowServerErrors("Create Offer", res)
		}
	})

	const isPosting = createBuyOfferApi.isLoading

	const handleFormSubmittion = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		createBuyOfferApi.mutateAsync(formData)
	}

	return (
		<div id="tab-2" className="tab-pane fade" role="tabpanel">
			<form className="my-3" onSubmit={handleFormSubmittion} ref={formRef}>
				<section className="p-3 border-bottom">
					<div className="row">
						<div className="col-lg-4 col-xl-4 col-xxl-4 mb-3">
							<span className="d-block w-100 mb-2">Currency Pair</span>

							<BKSelectCurrencyPair inputName="asset_id" />
						</div>
						<div className="col-lg-4 col-xl-4 col-xxl-4 mb-3">
							<span className="d-block w-100 mb-2">Response Time LImit</span>
							<BKSelectResponseTime inputName="response_time" />
						</div>
						<div className="col-lg-4 col-xl-4 col-xxl-4 mb-3">
							<span className="d-block w-100 mb-2">Payment Method</span>
							<select className="form-select form-select-lg" name="payment_method">
								<option value="bank transfer">Bank Transfer</option>
							</select>
						</div>
					</div>
				</section>
				<section className="p-3 border-top border-bottom">
					<div className="row">
						<div className="col-lg-4 col-xl-4 col-xxl-4">
							<span className="d-block w-100 mb-2">Rate Per USD</span>
							<input name="rate" className="form-control form-control-lg" type="number" step="any" placeholder="Rate" />
						</div>
						<div className="col-lg-8 col-xl-8 col-xxl-8">
							<span className="d-block w-100 mb-2">Trade Limit</span>
							<div className="d-flex justify-content-start align-items-center">
								<div className="w-100 form-input-group">
									<input name="min_amount" className="form-control form-control-lg" type="number" step="any" placeholder="Min" />
									<span className="overlap-text">
										<strong>NGN</strong>
									</span>
								</div>
								<span className="mx-3" style={{ fontSize: "xx-large" }}>
									<strong>~</strong>
								</span>
								<div className="w-100 form-input-group">
									<input name="max_amount" className="form-control form-control-lg" type="number" step="any" placeholder="Max" />
									<span className="overlap-text">
										<strong>NGN</strong>
									</span>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="p-3 border-top border-bottom">
					<div className="row">
						<div className="col mb-3">
							<span className="d-block w-100 mb-2">Total Quantity</span>
							<div className="w-100 form-input-group">
								<input name="total_qty" className="form-control form-control-lg" type="number" step="any" placeholder="Total" />
								<span className="overlap-text">
									<strong>All</strong>
								</span>
							</div>
						</div>
					</div>
				</section>
				<section className="p-3 border-top border-bottom">
					<div className="row">
						<div className="col-lg-6 col-xl-6 col-xxl-6 mb-3">
							<span className="d-block w-100 mb-2">
								<strong>Trading Terms(s)</strong>
							</span>
							<textarea name="terms" className="form-control" rows={5}></textarea>
							<p className="mt-2 text-danger">Note: You can also include a phone number and email to your terms for customers ease of use. Read the Terms &amp; Conditions for details you should not use.</p>
						</div>
						<div className="col-lg-6 col-xl-6 col-xxl-6 mb-3">
							<div className="mb-4"></div>
							<div className="form-check mb-3">
								<input name="avatar_status" value="1" id="formCheck-4" className="form-check-input" type="checkbox" />
								<label className="form-check-label" htmlFor="formCheck-4">
									Show Initial instead of Avatar
								</label>
							</div>
							<div className="form-check mb-3">
								<input name="real_name_status" value="1" id="formCheck-5" className="form-check-input" type="checkbox" />
								<label className="form-check-label" htmlFor="formCheck-5">
									Display my Real Names
								</label>
							</div>
							<div className="form-check mb-3">
								<input name="accept_terms" value="1" id="formCheck-6" className="form-check-input" type="checkbox" />
								<label className="form-check-label" htmlFor="formCheck-6">
									Agree to Terms &amp; Conditions
								</label>
							</div>
						</div>
					</div>
				</section>
				<section className="p-3 text-center">
					<div className="btn-group w-75" role="group">
						<button className="btn btn-light btn-lg me-3" type="button" onClick={() => window.history.back()}>
							Cancel
						</button>
						<button disabled={isPosting} className="btn btn-primary btn-lg bk-navy-blue" type="submit">
							{!isPosting && "Post"}
							{isPosting && <ShowSpinner color="text-light" />}
						</button>
					</div>
				</section>
			</form>
		</div>
	)
}
