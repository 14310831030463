import { GET, POST, PUT, DROP } from "configs/api"
import { handleError } from "services/utility/Utils/Utils"
import { ticketEndpoints } from "./ticket.endpoints"

export const CreateAppeal = async (params: FormData) => {
	try {
		return await POST(ticketEndpoints.CREATE_APPEAL, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const PostAppealChat = async (params: FormData) => {
	try {
		return await POST(ticketEndpoints.CREATE_APPEAL_CHAT, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const PostAppealFileChat = async (params: FormData) => {
	try {
		return await POST(ticketEndpoints.CREATE_APPEAL_FILE_CHAT, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchAppealChat = async (params: FormData) => {
	try {
		return await POST(ticketEndpoints.FETCH_APPEAL_CHAT, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchTickets = async () => {
	try {
		return await GET(ticketEndpoints.FETCH_TICKETS)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}
