import classNames from "classnames"
import React from "react"
import { Link } from "react-router-dom"
import { uniqueId } from "services/utility/Utils/Utils"

type breadCrumbTypesItems = {
	active?: boolean
	title: string
	link: string
}
type breadCrumbTypes = {
	nav: breadCrumbTypesItems[]
}

export default function BreadCrumb({ nav }: breadCrumbTypes) {
	return (
		<section className="p-3">
			<ol className="breadcrumb">
				{nav.length > 0 && (
					<>
						{nav.map(item => (
							<li
								key={uniqueId()}
								className={classNames({ "breadcrumb-item": true, active: item?.active === true })}
							>
								<Link to={item?.link}>
									<span>
										<strong>{item?.title}</strong>
									</span>
								</Link>
							</li>
						))}
					</>
				)}
			</ol>
		</section>
	)
}
