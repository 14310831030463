import { createSlice } from "@reduxjs/toolkit"
import { AppState } from "redux/appGlobalStore"
import {
	destorySession,
	getToken,
	isActive,
	saveToken,
	saveUser,
	setIsActive
} from "services/utility/Utils/_authServices"
import { GeneralContentProps } from "types/app.types"

type AppSliceState = {
	user?: any
	isActive?: boolean
	configs?: GeneralContentProps
	api_token?: string
}
const initialState: AppSliceState = {
	isActive: isActive()
}

export const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		setApiToken(state, action) {
			state.api_token = action?.payload
		},

		setUser(state, action) {
			state.user = action?.payload
		},

		LOGIN(state, action) {
			setIsActive()

			state.isActive = true

			if (action?.payload?.token) {
				state.api_token = action?.payload?.token

				saveToken(action?.payload?.token)
			}

			if (action?.payload?.user) {
				state.user = action?.payload?.user

				saveUser(action?.payload?.user)
			}
		},

		LOGOUT(state) {
			state.api_token = undefined
			state.user = undefined
			state.isActive = false

			destorySession()
		},

		clearToken(state, action) {
			state.api_token = undefined
		},

		/** Set the application config */
		setConfig(state, action) {
			state.configs = action?.payload
		},

		toggleActiveStatus(state, action) {
			const token = getToken()

			switch (action?.payload) {
				case "disable":
					state.isActive = false
					destorySession()

					saveToken(token)
					break
				case "enable":
					state.isActive = true
					setIsActive()

					saveToken(token)
					break
				default:
			}
		}
	}
})

export const {
	setApiToken,
	clearToken,
	setConfig,
	setUser,
	LOGIN,
	LOGOUT,
	toggleActiveStatus
} = appSlice.actions

export const getApiToken = (state: AppState) => state?.app?.api_token
export const getActiveUser = (state: AppState) => state?.app?.user
export const getUserActiveState = (state: AppState) => state?.app?.isActive
export const getConfigs = (state: AppState) => state?.app?.configs
