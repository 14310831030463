import React from "react"
import { Link, useLoaderData, useNavigate } from "react-router-dom"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import {
	handleDashNavToggle,
	handleDashSearchFormToggle,
	handleUserMobileMenuToggle
} from "services/utility/Utils/appNav"
import { userNotificationApis } from "services/Api/notification/notify.index"
import { useMutation } from "react-query"
import AwaitResponse from "components/AwaitResponse"

export default function DashMobileNav() {
	const { nameLogo } = useFlusStores()
	const [notifCount, setNotifCount] = React.useState<string>("0")

	const userProfile: any = useLoaderData()

	const navigate = useNavigate()

	const { FetchNotificationCounts } = userNotificationApis()

	const fetchNotifCountApi = useMutation(FetchNotificationCounts, {
		onSuccess: (res: any) => {
			if (res?.status) {
				setNotifCount(res?.data)
			}
		}
	})

	return (
		<div className="dash-mob-navbar ">
			<section className="nav-items">
				<img className="mob-logo" src={nameLogo} alt="Beekle" loading="lazy" />
				<div
					className="notify-badge-container"
					onClick={() => navigate("/dashboard/notification")}
				>
					<AwaitResponse api={fetchNotifCountApi.mutateAsync}>
						<i className="fas fa-bell mob-nav-icon"></i>
						<span className="badge rounded-pill bg-danger notify-badge">
							{notifCount}
						</span>
					</AwaitResponse>
				</div>
				{/* Toggle user mobile menu by clicking on the image */}
				<img
					className="mob-user-avatar toggle-user-menu pointer bk__nav-profile-avatar"
					src={
						!userProfile?.data?.photo?.includes("uploads/placeholder")
							? userProfile?.data?.photo
							: userProfile?.data?.avatar
					}
					alt="user"
					onClick={handleUserMobileMenuToggle}
				/>

				{/* Toggle Navigation menu by clicking on the nav icon */}
				<i
					className="fa fa-navicon mob-nav-icon toggle-app-menu"
					onClick={handleDashNavToggle}
				></i>
			</section>
			<section>
				<ul className="nav nav-tabs nav-justified navtabs ">
					{/* <li className="nav-item">
						<Link className="nav-link" to="/dashboard/marketplace?tb=mrk">
							P2P Market
						</Link>
					</li> */}
					
					<li className="nav-item">
						<Link className="nav-link" to="/dashboard/giftcard">
							Gift Card
						</Link>
					</li>

					<li className="nav-item">
						<Link className="nav-link" to="/dashboard/spot-wallet?tb=spw">
							Spot Wallet
						</Link>
					</li>

					<li className="nav-item">
						<Link className="nav-link" to="/dashboard/funding-wallet?tb=fdw">
							Funding
						</Link>
					</li>

					{/* <li className="nav-item">
						<i
							className="fa fa-search search-icon dash-search-form-toggler pointer"
							onClick={handleDashSearchFormToggle}
						></i>
					</li> */}
				</ul>
			</section>
		</div>
	)
}
