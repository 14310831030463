import { createSlice } from "@reduxjs/toolkit"
import { AppState } from "redux/appGlobalStore"

// Type for our state

export interface ModalsStateProps {
	deactivateAccountModel: boolean
	cashbridgeModal: boolean
	buyAssetModal: boolean
	sellAssetModal: boolean
}

const initialState: ModalsStateProps = {
	deactivateAccountModel: false,
	cashbridgeModal: false,
	buyAssetModal: false,
	sellAssetModal: false
}

// Actual Slice
export const ModalsSlice = createSlice({
	name: "modals",
	initialState,
	reducers: {
		toggleDeactivateAccountModal(state, action) {
			state.deactivateAccountModel = action?.payload
		},

		toggleCashBridgeModal(state, action) {
			state.cashbridgeModal = action?.payload
		},
		toggleBuyAssetModal(state, action) {
			state.buyAssetModal = action?.payload
		},
		toggleSellAssetModal(state, action) {
			state.sellAssetModal = action?.payload
		}
	}
})

export const { toggleBuyAssetModal, toggleSellAssetModal, toggleDeactivateAccountModal, toggleCashBridgeModal } = ModalsSlice.actions

export const getDeactivateAccountModal = (state: AppState) => state?.modals?.deactivateAccountModel
export const getCashBridgeModal = (state: AppState) => state?.modals?.cashbridgeModal
export const getBuyAssetModal = (state: AppState) => state?.modals?.buyAssetModal
export const getSellAssetModal = (state: AppState) => state?.modals?.sellAssetModal

export default ModalsSlice
