import { AxiosResponse } from "axios"
import { UseMutationResult } from "react-query"
import { SimplePaginationProps } from "types/app.types"
import ShowSpinner from "./Spinner"

type NextPrevPaginationProps = {
	pagination?: SimplePaginationProps
	paginator?: UseMutationResult<any | AxiosResponse<any, any>, unknown, any, unknown>
	onNext?: (params: string | null) => string | null | FormData | any
	onPrev?: (params: string | null) => string | null | FormData | any
}

export default function NextPrevPagination({ pagination, paginator, onNext, onPrev }: NextPrevPaginationProps) {
	const handleNext = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()

		if (pagination && paginator)
			if (pagination.next_page_url) {
				/* Get the last item in the link which is the next url from pagination object */
				/* Retrieve the page not  */
				const PageNo = new URL(pagination.next_page_url).searchParams.get("page")
				/* Fetch new pagination object  */
				if (typeof onNext === "function") {
					const params = onNext(PageNo)
					if (!params) {
						paginator.mutateAsync(PageNo)
					} else {
						paginator.mutateAsync(params)
					}
				} else {
					paginator.mutateAsync(PageNo)
				}
			}
	}

	const handlePrev = (e: React.MouseEvent<HTMLAnchorElement>) => {
		if (pagination && paginator) {
			e.preventDefault()
			/* Grap the previous page url */
			if (pagination.prev_page_url) {
				/* Grape the page no for previous page  */
				const PageNo = new URL(pagination.prev_page_url).searchParams.get("view") ?? new URL(pagination?.prev_page_url).searchParams.get("page")

				if (typeof onPrev === "function") {
					const params = onPrev(PageNo)
					if (!params) {
						paginator.mutateAsync(PageNo)
					} else {
						paginator.mutateAsync(params)
					}
				} else {
					paginator.mutateAsync(PageNo)
				}
			}
		}
	}
	return (
		<>
			{pagination && paginator && (
				<>
					<p className="text-muted my-5 d-block text-center">
						{/* Display to user the current viewing records */}
						showing {pagination.from} to {pagination.to} of {pagination.per_page} item(s)
					</p>

					<section className="d-flex justify-content-center align-items-center">
						<nav aria-label="Pagination" className="bk__pagination">
							<ul className="pagination pagination-lg">
								<li className="page-item pager_prev  ">
									<a className="page-link" href="#a" onClick={handlePrev}>
										Prev
									</a>
								</li>
								<li className="page-item pager_next">
									<a className="page-link" href="#a" onClick={handleNext}>
										Next
									</a>
								</li>
							</ul>
						</nav>
					</section>

					{/* Display loader when fetching items */}
					<div className="text-center">{paginator.isLoading && <ShowSpinner />}</div>
				</>
			)}
		</>
	)
}
