import { FLUX_DYNAMIC_STORE, useFlusDispatcher, useFlusStores } from "@bilmapay/react-flux-storage"
import AwaitResponse from "components/AwaitResponse"
import { ADD_BANK_MODAL } from "flus/constants/modals.id.const"
import React from "react"
import { useMutation } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import { getSavedBanks, setDashboardState } from "redux/reducers/dashboardSlice"
import { userPaymentMethodApis } from "services/Api/payments/payment.index"
import { uniqueId } from "services/utility/Utils/Utils"
import { ShowInfo } from "services/utility/Utils/_toaster"
import { BankProps } from "types/app.types"

export default function BankingDetails() {
	const savedBanks = useSelector(getSavedBanks)
	const dispatcher = useDispatch()

	const { FetchPaymentMethods } = userPaymentMethodApis()

	const fetchBankingDetailsApi = useMutation(FetchPaymentMethods, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(setDashboardState({ key: "savedBanks", value: res?.data }))
			}
		}
	})

	return (
		<AwaitResponse api={fetchBankingDetailsApi.mutateAsync}>
			<section className="p-3 mb-3">
				<header>
					<div className="row">
						<div className="col-md-6 col-lg-6 mb-3 d-flex justify-content-start align-items-center">
							<h4>
								<strong>Banking Details</strong>
							</h4>
						</div>
					</div>
				</header>
				{savedBanks && (
					<>
						{savedBanks.length > 0 && (
							<>
								{savedBanks.map(bank => (
									<div key={uniqueId()} className="row">
										<div className="col mb-3">
											<div className="table-responsive border rounded">
												<table className="table table-borderless">
													<tbody>
														<tr>
															<td>
																<strong>Bank Name:</strong>
															</td>
															<td>{bank.bank_name}</td>
														</tr>
														<tr>
															<td>
																<strong>Account Holder:</strong>
															</td>
															<td>{bank.account_name}</td>
														</tr>
														<tr>
															<td>
																<strong>Account Number:</strong>
															</td>
															<td>
																{bank.account_number}
																<i
																	className="fas fa-copy ms-3 copy-icon"
																	onClick={() => {
																		ShowInfo("Account Number", "Coppied!")

																		navigator.clipboard.writeText(bank?.account_number)
																	}}
																></i>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div className="col-lg-1 d-flex justify-content-center align-items-center mb-3">
											<div className="form-check form-switch form-check-inline">
												<input name="bank[]" value={bank?.payment_method_id} id="formCheck-1" className="form-check-input" type="checkbox" />
												<label className="form-check-label d-none" htmlFor="formCheck-1"></label>
											</div>
										</div>
									</div>
								))}
							</>
						)}
					</>
				)}

				<button className="btn btn-primary ms-auto d-block" type="button" data-bs-toggle="modal" data-bs-target={`#${ADD_BANK_MODAL}`}>
					<i className="fas fa-plus me-2"></i> Add Bank Account
				</button>
			</section>
		</AwaitResponse>
	)
}
