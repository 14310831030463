import CountrySelector from "components/CountrySelector"
import ShowSpinner from "components/misc/Spinner"
import PasswordInput from "components/PasswordInput"
import React from "react"
import { useMutation } from "react-query"
import { useLoaderData, useNavigate } from "react-router-dom"
import { userProfileApi } from "services/Api/profile/profile.index"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { BackendResponse } from "types/app.types"
import DOBPicker from "./DOBPicker"
import UsernameInput from "./UsernameInput"

export default function PersonalInfoForm() {
	const { UpdatePersonalInfo } = userProfileApi()
	const preFillProfile: any = useLoaderData()
	const data = preFillProfile?.data

	const navigate = useNavigate()

	const updatePersonalInfoApi = useMutation(UpdatePersonalInfo, {
		onSuccess: (res: BackendResponse | any) => {
			if (res?.status) {
				ShowSuccess("Profile Info", "Profile details saved successfully!")

				navigate("/onboard/location-info")
			} else {
				ShowServerErrors("Validation", res)
			}
		}
	})

	const isProcessing = updatePersonalInfoApi.isLoading

	const handleUserSubmitAction = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		updatePersonalInfoApi.mutateAsync(formData)
	}

	return (
		<form className="border rounded my-4 shadow-sm" onSubmit={handleUserSubmitAction}>
			<header className="border-dark p-2 mb-3">
				<h2>
					<strong>Personal Details</strong>
				</h2>
			</header>
			<section className="p-3">
				<div className="mb-4">
					<div className="row">
						<div className="col-md-6 mb-3">
							<div className="form-group">
								<label className="form-label w-100 input-label">
									<strong>First Name</strong>
									<sup className="text-danger">*</sup>
								</label>
								<input className="form-control form-control-lg form-control-user" type="text" name="first_name" defaultValue={data?.first_name} />
							</div>
						</div>
						<div className="col-md-6 mb-3">
							<div className="form-group">
								<label className="form-label w-100 input-label">
									<strong>Last Name</strong>
									<sup className="text-danger">*</sup>
								</label>
								<input className="form-control form-control-lg form-control-user" type="text" name="last_name" defaultValue={data?.last_name} />
							</div>
						</div>
					</div>
					<p className="text-danger">
						Note: Please ensure that the provided name is same as written on your ID documents. Be assured that we <br />
						guarantee your personal details privacy
					</p>
				</div>
				<div className="mb-4">
					<UsernameInput />
				</div>

				<div className="mb-4">
					<div className="row form-row mb-3">

						<div className="col-md-6 col-lg-6 mb-3">
							<div>
								<label className="form-label">
									<strong>Gender</strong>
								</label>
								<select className="form-select form-select-lg" name="gender">
									<option defaultValue="">Select Gender</option>
									<option value="male">Male</option>
									<option value="female">Female</option>
								</select>
							</div>
						</div>


						{/* <div className="col-md-6 col-lg-6 mb-3">
							<div>
								<label className="form-label">
									<strong>Marital Status</strong>
								</label>
								<select className="form-select form-select-lg" name="marital_status">
									<option defaultValue="non">Select Status</option>
									<option value="single">Single</option>
									<option value="married">Married</option>
								</select>
							</div>
						</div> */}


					</div>
				</div>

				<div className="mb-4">
					<div className="row">
						<div className="col-md-6 mb-3">
							<div className="form-group">
								<label className="form-label w-100 input-label">
									<strong>Country</strong>
									<sup className="text-danger">*</sup>
								</label>
								<CountrySelector defaultValue="Nigeria" />
							</div>
						</div>
						<div className="col-md-6 mb-3">
							<DOBPicker />
						</div>
					</div>
				</div>


				{data?.password_required && (
					<>
						<div className="mb-3">
							<div className="form-group">
								<label className="form-label w-100 input-label">
									<strong>
										Referral ID (<small>Optional</small>)
									</strong>
								</label>
								<input className="form-control form-control-lg form-control-user" type="text" name="ref_id" />
							</div>
						</div>
						<div className="mb-4">
							<div className="row">
								<div className="col-md-6 mb-3">
									<PasswordInput inputName="password" label="Create Password" />
								</div>
								<div className="col-md-6 mb-3">
									<PasswordInput inputName="password_confirmation" label="Confirm Password" />
								</div>
							</div>
						</div>
					</>
				)}
				<div className="text-end mb-4">
					<button disabled={isProcessing} className="btn btn-primary btn-lg" type="submit">
						{!isProcessing && "Next"}
						{isProcessing && <ShowSpinner color="text-light" />}
					</button>
				</div>
			</section>
		</form>
	)
}
