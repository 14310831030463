import { GET, POST, PUT, DROP } from "configs/api"
import { merchantEndpoints } from "./merchant.endpoints"

export const CreateMerchantAccount = async (params: any) => {
	return await POST(merchantEndpoints.CREATE, params)
}

export const UpdateMerchantLimit = async (params: any) => {
	return await POST(merchantEndpoints.LIMIT, params)
}

export const UpdateMerchantBank = async (params: any) => {
	return await POST(merchantEndpoints.BANK, params)
}

export const ToggleMerchantPresence = async () => {
	return await POST(merchantEndpoints.PRESENCE)
}

export const DeactivateMerchantAccount = async (params: FormData | any) => {
	return await POST(merchantEndpoints.DEACTIVATE, params)
}

export const FetchMerchantDashboardAnalysis = async () => {
	return await GET(merchantEndpoints.DASHBOARD_ANALYSIS)
}

export const FetchMerchantOrders = async () => {
	return await GET(merchantEndpoints.FETCH_ORDERS)
}

export const FetchMerchantDepositHistory = async () => {
	return await GET(merchantEndpoints.FETCH_DEPOSITS)
}

export const FetchMerchantWithdrawalHistory = async () => {
	return await GET(merchantEndpoints.FETCH_WITHDRAWAL)
}

export const FetchMerchantFundingHistory = async () => {
	return await GET(merchantEndpoints.FETCH_FUNDINGS)
}

export const AcceptDepositRequest = async (params: FormData | any) => {
	return await POST(merchantEndpoints.deposit.ACCEPT, params)
}

export const DeclineDepositRequest = async (params: FormData | any) => {
	return await POST(merchantEndpoints.deposit.DECLINE, params)
}

export const CheckDepositRequestStatus = async (params: FormData | any) => {
	return await POST(merchantEndpoints.deposit.LISTEN, params)
}

export const ReleaseDepositRequest = async (params: FormData | any) => {
	return await POST(merchantEndpoints.deposit.RELEASE, params)
}

export const AcceptWithdrawalRequest = async (params: FormData | any) => {
	return await POST(merchantEndpoints.withdrawal.ACCEPT, params)
}

export const DeclineWithdrawalRequest = async (params: FormData | any) => {
	return await POST(merchantEndpoints.withdrawal.DECLINE, params)
}

export const UploadWithdrawalProof = async (params: FormData | any) => {
	return await POST(merchantEndpoints.withdrawal.UPLOAD, params)
}

export const CheckWithdrawalRequestStatus = async (params: FormData | any) => {
	return await POST(merchantEndpoints.withdrawal.LISTEN, params)
}

export const EscalateWithdrawalRequestDispute = async (params: FormData | any) => {
	return await POST(merchantEndpoints.withdrawal.ESCALATE, params)
}
