import { FLUX_DYNAMIC_STORE, useFlusStores } from "@bilmapay/react-flux-storage"
import AwaitResponse from "components/AwaitResponse"
import CardNavigator from "components/CardNavigator"
import { APPEAL_MODAL, RELEASE_COIN_MODAL } from "flus/constants/modals.id.const"
import React from "react"
import { useMutation } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { getConfigs } from "redux/reducers/appSlice"
import { getAssetDetails, setAppealOrderToken, setAssetDetails } from "redux/reducers/dashboardSlice"
import { userMarketplaceApis } from "services/Api/marketplace/marketplace.index"
import { useUrlQuery } from "services/utility/hooks/useURL"
import { ShowInfo, ShowServerErrors } from "services/utility/Utils/_toaster"
import ReleaseCoinModal from "views/modals/ReleaseCoinModal"

interface AwaitPaymentReleaseProps {
	ot: any
	bg: string | "bg-blue"
	txt: "text-primary" | "text-blue"
	s: string
}

const autoActionRef = React.createRef<HTMLElement>()

export default function AwaitPaymentRelease() {
	const generalContent = useSelector(getConfigs)
	const assetDetails = useSelector(getAssetDetails)
	const { CheckGood, AwaitPaymentRelease }: { AwaitPaymentRelease: any; CheckGood: any } = useFlusStores()
	const dispatcher = useDispatch()

	const [modalToggler, setModalToggler] = React.useState<boolean>(false)

	/* Get the payload and order_token */

	const { ot, bg = "", txt = "text-primary", s }: AwaitPaymentReleaseProps = useUrlQuery()

	const { SellerAwaitPaymentRelease } = userMarketplaceApis()

	const assetDetailsApi = useMutation(SellerAwaitPaymentRelease, {
		onSuccess: (res: any) => {
			if (res?.status) {
				/* When payment has been made */
				if (res?.data?.status_code === "0" && res?.data?.payment_status?.toLowerCase() === "completed") {
					setModalToggler(true)
				}

				/* when transaction is canceled or completed */
				if (res?.data?.status_code === "2" || res?.data?.status_code === "1") {
					setModalToggler(true)
				}

				dispatcher(setAssetDetails(res?.data))
			} else ShowServerErrors("Error", res)
		}
	})

	const handleAssetDetailsFetching = async () => {
		const fd = new FormData()
		fd.append("order_token", ot)
		return assetDetailsApi.mutateAsync(fd)
	}

	React.useEffect(() => {
		let timer = setInterval(() => {
			handleAssetDetailsFetching()
		}, 10000)

		if (modalToggler) {
			clearInterval(timer)
		}

		if (autoActionRef?.current) {
			if (assetDetails?.status_code === "0" && assetDetails?.payment_status?.toLowerCase() === "completed") autoActionRef?.current?.click()
		}

		// clean up
		return () => clearInterval(timer)
	})

	const handleOrderTokenDispatch = () => {
		dispatcher(setAppealOrderToken(ot))
	}

	return (
		<CardNavigator returnLink="/dashboard">
			<AwaitResponse api={handleAssetDetailsFetching}>
				<section>
					<header className="mb-5">
						<h3 className={`mb-3 ${txt}`}>
							<strong>
								{assetDetails?.status_code === "1" && "Funds Released"}
								{assetDetails?.status_code === "2" && "Transaction Canceled"}
								{assetDetails?.status_code === "0" && "Awaiting Fund Release"}
							</strong>
							<br />
						</h3>
						{assetDetails?.payment_status?.toLowerCase() === "completed" ? (
							<img className="img-fluid m-auto d-block my-5" src={CheckGood} alt="" width="150" />
						) : (
							<img className="img-fluid m-auto d-block my-5" src={AwaitPaymentRelease} alt="" width="150" />
						)}
						<h3 className="text-center">
							<strong>{assetDetails?.payment_status?.toLowerCase() === "completed" ? "Payment Completed" : "Order Pending !"}</strong>
						</h3>

						{assetDetails?.payment_status?.toLowerCase() !== "completed" && (
							<p className="text-center text-danger">
								<strong>Waiting for the buyer to make payment and mark this order as paid</strong>
							</p>
						)}
					</header>
					<form className="trade-form">
						<div className="py-5 px-2 my-5 table-responsive border rounded">
							<table className="table table-borderless">
								<tbody>
									<tr>
										<td>
											<strong>Rate per USD:</strong>
										</td>
										<td className="text-end">{assetDetails?.rate_per_coin} NGN</td>
									</tr>
									<tr>
										<td>
											<strong>Response Time (Avg):</strong>
										</td>
										<td className="text-end">{assetDetails?.response_time}</td>
									</tr>
									<tr>
										<td>
											<strong>Asset Amount: </strong>
										</td>
										<td className="text-end">{assetDetails?.asset_amount}</td>
									</tr>
									<tr>
										<td>
											<strong>Amount Payable:</strong>
										</td>
										<td className="text-end">{assetDetails?.amount_payable} NGN</td>
									</tr>
									<tr>
										<td>
											<strong>Payment Status:</strong>
										</td>
										<td className="text-end">{assetDetails?.payment_status}</td>
									</tr>
									<tr>
										<td>
											<strong>Order ID:</strong>
										</td>
										<td className="text-end">
											{assetDetails?.order_no}
											<i
												className="fa fa-copy ms-3 pointer"
												onClick={() => {
													navigator.clipboard.writeText(assetDetails?.order_no as string)
													ShowInfo("Order ID", "Coppied!")
												}}
											></i>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<section className="p-3 text-center my-5 py-5">
							<div className="btn-group btn-group-lg w-100 mb-5" role="group">
								{assetDetails?.status_code === "1" || assetDetails?.status_code === "2" ? (
									<button disabled={assetDetails?.payment_status?.toLowerCase() === "completed"} className="btn btn-light btn-lg me-3 w-100" type="button">
										Cancel
									</button>
								) : (
									<Link to={`/dashboard/cancel-order?ot=${ot}`} className="btn btn-light btn-lg me-3 w-100" type="button">
										Cancel
									</Link>
								)}

								<button
									disabled={assetDetails?.status_code === "1"}
									className={`btn btn-primary w-100 ${bg}`}
									type="button"
									data-bs-toggle="modal"
									data-bs-target={`#${APPEAL_MODAL}`}
									onClick={handleOrderTokenDispatch}
								>
									Appeal
								</button>
							</div>
							<span className="p-2 bg-danger d-none">
								<strong ref={autoActionRef} data-bs-toggle="modal" data-bs-target={`#${RELEASE_COIN_MODAL}`}>
									Auto Action
								</strong>
							</span>
						</section>

						{assetDetails?.status_code !== "1" && (
							<a href={`tel:${assetDetails?.buyer_phone_number}`} className="badge bg-primary traders-chat-toggler text-white justify-content-center align-content-center pt-3">
								<i className="fas fa-phone"></i>
							</a>
						)}
					</form>
					<article className="mb-4 mt-4 p-3 bg-gray">
						<h5 className="text-success">
							<strong>Notice</strong>
						</h5>
						<p dangerouslySetInnerHTML={{ __html: generalContent?.await_fund_release_notice as string }}></p>
					</article>
				</section>
			</AwaitResponse>

			<ReleaseCoinModal />
		</CardNavigator>
	)
}
