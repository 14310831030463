import { useFlusStores } from "@bilmapay/react-flux-storage"
import AwaitResponse from "components/AwaitResponse"
import CardNavigator from "components/CardNavigator"
import SellerPaymentChannels from "components/misc/SellerPaymentChannels"
import ShowSpinner from "components/misc/Spinner"
import React from "react"
import { useMutation } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import { userMarketplaceApis } from "services/Api/marketplace/marketplace.index"
import { useUrlQuery } from "services/utility/hooks/useURL"
import { secondsCountdownTimer } from "services/utility/Utils/utilities"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { useDispatch, useSelector } from "react-redux"
import { getBuyAssetDetails, setBuyAssetDetails } from "redux/reducers/dashboardSlice"
import { getConfigs } from "redux/reducers/appSlice"

const timerRef = React.createRef<HTMLElement>()

export default function MakePayment() {
	const { MakePaymentIcon }: { MakePaymentIcon: any } = useFlusStores()
	const _buyAssetDetails = useSelector(getBuyAssetDetails)
	const generalContent = useSelector(getConfigs)

	const dispatcher = useDispatch()

	/* Get the payload and order_token */
	const { ot } = useUrlQuery()

	const navigate = useNavigate()

	const { BuyAssetDetails, PayAsset } = userMarketplaceApis()

	const buyAssetDetailsApi = useMutation(BuyAssetDetails, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(setBuyAssetDetails(res?.data))
			} else ShowServerErrors("Error", res)
		}
	})

	const handleAssetDetailsFetching = async () => {
		const fd = new FormData()
		fd.append("order_token", ot)
		return buyAssetDetailsApi.mutateAsync(fd)
	}

	// ---------------------------------------------------------------------------

	const payViaBankApi = useMutation(PayAsset, {
		onSuccess: (res: any) => {
			if (res?.status) {
				ShowSuccess("Success", res?.message)

				navigate(`/dashboard/await-fund-release?ot=${res?.data?.order_token}`)
			} else ShowServerErrors("Error", res)
		}
	})

	const isLoadingBankApi = payViaBankApi.isLoading

	const handlePayViaBank = () => {
		const fd = new FormData()

		fd.append("order_token", ot)

		payViaBankApi.mutateAsync(fd)
	}

	React.useEffect(() => {
		if (_buyAssetDetails?.remaining_minutes) {
			const timer: unknown = _buyAssetDetails?.remaining_minutes
			secondsCountdownTimer(timer as number, (isFinished, timeLef) => {
				if (timerRef?.current) {
					const seconds: unknown = timeLef
					const timerSec = seconds as string
					timerRef.current.innerHTML = `${timerSec}s`
				}
			})
		}
	})

	return (
		<CardNavigator returnLink="/dashboard">
			<AwaitResponse api={handleAssetDetailsFetching}>
				<section>
					<header className="mb-4">
						<h3 className="mb-3 text-blue">
							<strong>Make Payment</strong>
							<br />
						</h3>
						<img className="img-fluid m-auto d-block my-5" src={MakePaymentIcon} alt="" width="150" />
					</header>
					<form className="trade-form">
						<section className="p-3 mb-3">
							<header className="my-4"></header>
							<header>
								<div className="row">
									<div className="col-md-6 col-lg-6 mb-3 d-flex justify-content-start align-items-center">
										<h4 className="text-success">
											<strong>Banking Details</strong>
										</h4>
									</div>
									<div className="col-md-6 col-lg-6 mb-3 d-flex justify-content-start align-items-center">
										<h5>
											Order end in:{" "}
											<span className="text-danger" ref={timerRef}>
												0
											</span>
										</h5>
									</div>
								</div>
							</header>
							<div className="row">
								<div className="col mb-3">
									<SellerPaymentChannels paymentChannels={_buyAssetDetails?.account_details as []} />
								</div>
							</div>
						</section>
						<section className="p-3 text-center">
							<div className="btn-group btn-group-lg w-100" role="group">
								{_buyAssetDetails?.status_code !== "1" && (
									<Link to={`/dashboard/cancel-order?ot=${ot}`} className="btn btn-light btn-lg me-3 w-100" type="button">
										Cancel Order
									</Link>
								)}
								<button disabled={_buyAssetDetails?.status_code === "1"} className="btn btn-primary w-100 bg-blue" type="button" onClick={handlePayViaBank}>
									{!isLoadingBankApi && "Pay via Bank"}
									{isLoadingBankApi && <ShowSpinner color="text-light" />}
								</button>
							</div>
						</section>

						{_buyAssetDetails?.status_code !== "1" && (
							<a href={`tel:${_buyAssetDetails?.seller_phone_number}`} className="badge bg-primary traders-chat-toggler bg-blue text-white justify-content-center align-content-center pt-3">
								<i className="fas fa-phone"></i>
							</a>
						)}
					</form>
					<article className="mb-4 mt-4 p-3 bg-gray">
						<h5 className="text-success">
							<strong>Notice</strong>
						</h5>
						<p dangerouslySetInnerHTML={{ __html: generalContent?.make_payment_notice as string }}></p>
					</article>
				</section>
			</AwaitResponse>
		</CardNavigator>
	)
}
