import BreadCrumb from "components/BreadCrumb"
import DefaultNavigation from "components/layout/DefaultNavigation"
import React from "react"
import ContactUsContent from "./ContactUsContent"

const navItems = [
	{
		active: false,
		title: "home",
		link: "/"
	},
	{ active: true, title: "Contact Us", link: "contact-us" }
]
export default function ContactUsPage() {
	return (
		<>
			<DefaultNavigation includeHeader="contact-us" />
			<BreadCrumb nav={navItems} />
			<ContactUsContent />
		</>
	)
}
