import Swal from "sweetalert2"
import { toast } from "react-toastify"
import { uniqueId } from "./Utils"

const toastInit = Swal.mixin({
	toast: true,
	showClass: {
		popup: "animate__animated animate__slideOutDown"
	},
	hideClass: {
		popup: "animate__animated animate__slideInUp"
	},
	timer: 1500,
	timerProgressBar: true,
	position: "top-end",
	showConfirmButton: false
})

export const ShowSuccess = (title?: string, message?: string) => {
	toast(
		({ closeToast }) => (
			<div className="flex flex-col gap-4">
				<h6 className="font-bold text-md black w-full">{title}</h6>
				<p className="text-gray-600 p-2">{message}</p>
			</div>
		),
		{ type: "success" }
	)
}

export const ShowError = (title?: string, message?: string) => {
	toast(
		({ closeToast }) => (
			<div className="flex flex-col gap-4">
				<h6 className="font-bold text-md black w-full">{title}</h6>
				<p className="text-gray-600 p-2">{message}</p>
			</div>
		),
		{ type: "error" }
	)
}

export const ShowServerErrors = (title?: string, res?: any) => {
	if (res?.errors) {
		const keys = Object.keys(res?.errors)

		toast(
			({ closeToast }) => (
				<div className="flex flex-col gap-4">
					<h6 className="font-bold text-md black w-full">{title}</h6>
					<div className="text-gray-600 p-2">
						<ul>
							{keys?.map((field: string) => (
								<li key={uniqueId()} className="text-rose-400">
									{/* @ts-ignore */}
									{res.errors[field]?.toString()}
								</li>
							))}
						</ul>
					</div>
				</div>
			),
			{ type: "error" }
		)
	} else {
		toast(
			({ closeToast }) => (
				<div className="flex flex-col gap-4">
					<h6 className="font-bold text-md black w-full">{title}</h6>
					<p className="text-gray-600 p-2">{res?.message}</p>
				</div>
			),
			{ type: "error" }
		)
	}
}

export const ShowInfo = (title?: string, message?: string) => {
	toast(
		({ closeToast }) => (
			<div className="flex flex-col gap-4">
				<h6 className="font-bold text-md black w-full">{title}</h6>
				<p className="text-gray-600 p-2">{message}</p>
			</div>
		),
		{ type: "info" }
	)
}

export const ShowWarning = (title?: string, message?: string) => {
	toast(
		({ closeToast }) => (
			<div className="flex flex-col gap-4">
				<h6 className="font-bold text-md black w-full">{title}</h6>
				<p className="text-gray-600 p-2">{message}</p>
			</div>
		),
		{ type: "warning" }
	)
}
