import { Fragment, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import { useDispatch, useSelector } from "react-redux"
import {
	getCashBridgeModal,
	toggleCashBridgeModal
} from "redux/reducers/modalSlice"
import { useNavigate } from "react-router-dom"
import {
	toggleDepositCashBridgePanel,
	toggleWithdrawalCashBridgePanel
} from "redux/reducers/panelsSlice"
import { useMutation } from "react-query"
import {
	CheckActiveDeposit,
	CheckActiveWithdrawal,
	CheckWithdrawalStatus
} from "services/Api/cashbridge/cashbridge.apis"
import ShowSpinner from "components/misc/Spinner"
import {
	changeClientWithdrawStep,
	stopClientWithdrawalTimer
} from "redux/reducers/withdrawalSlice"
import { changeClientStep } from "redux/reducers/depositSlice"

export default function CashbridgeModal() {
	const dispatcher = useDispatch()
	const open = useSelector(getCashBridgeModal)
	const router = useNavigate()
	const setOpen = (state: boolean) => dispatcher(toggleCashBridgeModal(state))

	const cancelButtonRef = useRef(null)

	const checkActiveWithdrawalApi = useMutation(CheckActiveWithdrawal, {
		onSuccess: async (res: any) => {
			if (res?.status) {
				let _subRes = null
				/* we have to determined the status code and take user to the right stage */
				switch (res?.data?.status_code?.toString()) {
					case "1":
						dispatcher(changeClientWithdrawStep("verify_payment"))
						dispatcher(
							toggleWithdrawalCashBridgePanel({
								status: true,
								data: res?.data,
								params: res?.data?.txn_id
							})
						)

						setOpen(false)
						break

					case "3":
						_subRes = await CheckWithdrawalStatus({ txn_id: res?.data?.txn_id })

						if (_subRes?.status) {
							dispatcher(stopClientWithdrawalTimer(_subRes?.data))

							dispatcher(
								toggleWithdrawalCashBridgePanel({
									status: true,
									data: res?.data,
									params: res?.data?.txn_id
								})
							)
							dispatcher(changeClientWithdrawStep("view_pop"))

							setOpen(false)
						}
						break
					default:
				}
			} else {
				/* start transaction afresh */

				setOpen(false)
				dispatcher(toggleWithdrawalCashBridgePanel({ status: true }))
			}
		}
	})
	const isCheckingWithdrawal = checkActiveWithdrawalApi.isLoading

	const checkActiveDepositApi = useMutation(CheckActiveDeposit, {
		onSuccess(res: any) {
			if (res?.status) {
				/* we have to determined the status code and take user to the right stage */
				switch (res?.data?.status_code?.toString()) {
					case "1":
						dispatcher(
							toggleDepositCashBridgePanel({
								status: true,
								data: res?.data,
								params: res?.data?.txn_id
							})
						)

						dispatcher(changeClientStep("awaiting_acceptance"))
						setOpen(false)
						break
					default:
				}
			} else {
				/* start transaction afresh */

				setOpen(false)
				dispatcher(toggleDepositCashBridgePanel({ status: true }))
			}
		}
	})
	const isCheckingDeposit = checkActiveDepositApi.isLoading

	const _toggleDepositCashbridgePanel = () =>
		checkActiveDepositApi.mutateAsync()

	const _toggleWithdrawalCashbridgePanel = () =>
		checkActiveWithdrawalApi.mutateAsync()

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				initialFocus={cancelButtonRef}
				onClose={setOpen}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
								<div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
									<div className="sm:flex sm:items-start">
										<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
											<Dialog.Title
												as="h3"
												className="text-start font-semibold leading-6 text-gray-900 text-center mb-3"
											>
												Cash-Bridge
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-lg text-gray-500 text-center">
													This service allows users to transact their
													traditional currency on{" "}
													<strong className="text-primary font-bold">
														Beekle
													</strong>{" "}
													using a merchant's platform.
												</p>

												<div className="flex gap-4 items-center mt-5">
													<button
														disabled={isCheckingDeposit}
														onClick={_toggleDepositCashbridgePanel}
														className="p-3 w-full font-bold   rounded-md bg-blue-500 text-white transition ease-in duration-300 hover:bg-blue-500 hover:text-white"
														type="button"
													>
														{!isCheckingDeposit && "Deposit"}
														{isCheckingDeposit && <ShowSpinner />}
													</button>
													<button
														disabled={isCheckingWithdrawal}
														onClick={_toggleWithdrawalCashbridgePanel}
														className="p-3 w-full font-bold  rounded-md bg-blue-500 text-white transition ease-in duration-300 hover:bg-blue-500 hover:text-white"
														type="button"
													>
														{!isCheckingWithdrawal && "Withdraw"}
														{isCheckingWithdrawal && <ShowSpinner />}
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
										onClick={() => setOpen(false)}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
