import { availableBalanceCardRef } from "flus/constants/refs.const"
import React from "react"
import { handleAvailableBalanceToggler } from "services/utility/Utils/_apptheme"

export default function AvailableBalanceCard({ balance }: { balance?: string }) {
	return (
		<div className="card mt-4 mb-3">
			<div className="card-body">
				<h4 className="card-title mb-3">
					<strong>Available Balance</strong>
				</h4>
				<h2 className="text-start text-dark card-title mb-2 text-blue ps-3">
					<span
						ref={availableBalanceCardRef}
						className="font-monospace avail-balance-span justify-content-center"
					>
						{balance ? balance : "0.00"}
					</span>{" "}
					NGN
					<i
						onClick={e => handleAvailableBalanceToggler(e, balance ? balance : "0.00")}
						className="fas fa-eye-slash ms-3 toggle-avail-balance-visibility"
					></i>
				</h2>
			</div>
		</div>
	)
}
