import React from "react"
import { uniqueId } from "services/utility/Utils/Utils"
import { ShowInfo } from "services/utility/Utils/_toaster"
import { AssetDetailsBankProps } from "types/app.types"

interface SellerPaymentChannelsProps {
	paymentChannels: Array<AssetDetailsBankProps>
}
export default function SellerPaymentChannels({ paymentChannels }: SellerPaymentChannelsProps) {
	return (
		<>
			{paymentChannels && (
				<>
					{paymentChannels.length > 0 && (
						<>
							{paymentChannels.map(bank => (
								<div key={uniqueId()} className="table-responsive border rounded mb-3">
									<table className="table table-borderless">
										<tbody>
											<tr>
												<td>
													<strong>Bank Name:</strong>
												</td>
												<td>{bank?.bank_name}</td>
											</tr>
											<tr>
												<td>
													<strong>Account Holder:</strong>
												</td>
												<td>{bank?.account_name}</td>
											</tr>
											<tr>
												<td>
													<strong>Account Number:</strong>
												</td>
												<td>
													{bank?.account_number}
													<i
														className="fas fa-copy ms-3 copy-icon"
														onClick={() => {
															navigator.clipboard.writeText(bank?.account_number)

															ShowInfo("Account Number", "Coppied!")
														}}
													></i>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							))}
						</>
					)}
				</>
			)}
		</>
	)
}
