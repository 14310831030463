import React from "react"
import countries from "i18n-iso-countries"
import langs_en from "i18n-iso-countries/langs/en.json"
import { uniqueId } from "services/utility/Utils/Utils"
countries.registerLocale(langs_en)

export default function CountrySelector({
	inputName,
	defaultValue
}: {
	inputName?: string
	defaultValue?: string
}) {
	const countryList = countries.getNames("en")

	return (
		<select className="form-select form-select-lg" name={inputName ? inputName : "country"}>
			{Object.keys(countryList).map((countryCode: any) => (
				<option
					key={uniqueId()}
					value={countryList[countryCode]}
					selected={countryList[countryCode] === defaultValue}
				>
					{countryList[countryCode]}
				</option>
			))}
		</select>
	)
}
