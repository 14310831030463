import { destorySession, getToken, saveToken, saveUser, setIsActive } from "services/utility/Utils/_authServices"

interface StateTypes {
	[key: string]: any
}

interface PayloadTypes extends StateTypes {
	data: {
		[key: string]: string | number | object | string[] | boolean
	}
	user: {
		[key: string]: string | number | object | string[] | boolean
	}
	token: string
}

export const AuthMiddleware = {
	auth: {
		login(state: StateTypes, payload: PayloadTypes) {
			/* perform the state logic here! */

			state.isActive = true
			setIsActive()

			if (typeof payload?.user !== "undefined") {
				state.user = payload?.user
				saveUser(payload?.user)
			}

			if (typeof payload?.token !== "undefined") {
				saveToken(payload?.token)
			}

			/* return the new state data */
			return {
				...state
			}
		},

		toggleActiveState(state: StateTypes, payload: PayloadTypes) {
			/* perform the state logic here! */

			const token = getToken()

			if (payload?.data) {
				switch (payload?.data?.state) {
					case "disable":
						state.isActive = false
						destorySession()

						saveToken(token)
						break
					case "enable":
						state.isActive = true
						setIsActive()

						saveToken(token)
						break
					default:
				}
			}

			/* return the new state data */
			return {
				...state
			}
		},

		setApiToken(state: StateTypes, payload: PayloadTypes) {
			/* perform the state logic here! */

			saveToken(payload?.tokens)

			/* return the new state data */
			return {
				...state
			}
		},

		updateUser(state: StateTypes, payload: PayloadTypes) {
			if (!state.user) {
				state.user = {}
			}

			/* check if there is data to update  */
			if (payload?.data && Object.keys(payload?.data ?? {}).length > 0) {
				/* loop through to get the new user data  */
				Object.keys(payload?.data).forEach((key: string) => {
					state.user[key] = payload?.data[key]
				})
			}

			/* save user data */
			saveUser(state?.user)

			return { ...state }
		},

		logout(state: StateTypes) {
			destorySession()

			state.isActive = false
			state.user = null

			return {
				...state
			}
		}
	}
}
