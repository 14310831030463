import React from "react"

interface PasswordInputInterface {
	inputName?: string
	label?: string
	placeholder?: string
}
export default function PasswordInput({ inputName, label, placeholder = "Password" }: PasswordInputInterface) {
	const passwordTogglerRef = React.createRef<HTMLElement>()
	const passwordInputRef = React.createRef<HTMLInputElement>()

	const handlePasswordToggle = (e: React.MouseEvent<HTMLElement>) => {
		const classOpts: any = e.target
		const visibility = "fa-eye"
		const notVisibility = "fa-eye-slash"

		if (classOpts.classList.contains(notVisibility)) {
			if (passwordInputRef?.current) {
				passwordInputRef.current.type = "text"
			}

			classOpts.classList.remove(notVisibility)
			classOpts.classList.add(visibility)
		} else {
			if (passwordInputRef?.current) {
				passwordInputRef.current.type = "password"
			}

			classOpts.classList.remove(visibility)
			classOpts.classList.add(notVisibility)
		}
	}

	return (
		<div className="form-group mb-3">
			<label className="form-label w-100 input-label">
				<strong>{label ? label : "Password"}</strong>
			</label>
			<input className="form-control form-control-lg password-toggle-input" type="password" placeholder={placeholder} name={inputName ? inputName : "password"} ref={passwordInputRef} />
			<i ref={passwordTogglerRef} className="fas fa-eye-slash img-icon toggle-password" onClick={handlePasswordToggle}></i>
		</div>
	)
}
