import { createSlice } from "@reduxjs/toolkit"
import { AppState } from "redux/appGlobalStore"
import {
	AssetDetailsProps,
	AssetQuantityProps,
	BankListInterface,
	BankProps,
	ChatMessageObject,
	CurrencyPairInterface,
	DropdownOptionsInterface,
	MarketNewsProps,
	MarketplaceAssetsProps,
	PaginationResponse,
	TicketMessagesProps,
	TransactionDetailsProps,
	TransactionObjectProps,
	TransactionProps
} from "types/app.types"

type DashboardSliceState = {
	bankList?: Array<BankListInterface>
	transactions?: TransactionObjectProps
	tranxDetails?: TransactionDetailsProps
	newsListItems?: PaginationResponse<MarketNewsProps>
	buyAssetDetails?: AssetDetailsProps
	sellAssetDetails?: AssetDetailsProps
	assetQuantity?: AssetQuantityProps
	groupChatMessages?: TicketMessagesProps
	p2pchatMessage?: ChatMessageObject
	assetDetails?: AssetDetailsProps
	appealOrderToken?: string
	mk_dsk_assetId?: string
	myOffersResponse?: Array<object>
	offerDetailsParams?: any
	recentTrans?: Array<TransactionProps>
	marketplaceBuyAssets?: MarketplaceAssetsProps
	marketplaceSellAssets?: MarketplaceAssetsProps
	currencyPairs?: Array<CurrencyPairInterface>
	responseTimeStore?: Array<DropdownOptionsInterface>
	savedBanks?: Array<BankProps>
}
const initialState: DashboardSliceState = {
	bankList: []
}

export const dashboardSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		setBankList(state, action) {
			state.bankList = action?.payload
		},

		/* set transaction */
		setTransaction(state, action) {
			state.transactions = action?.payload
		},

		/* set transaction details */
		setTransactionDetails(state, action) {
			state.tranxDetails = action?.payload
		},

		/* Market news */
		setMarketNews(state, action) {
			state.newsListItems = action?.payload
		},

		/* Asset */
		setBuyAssetDetails(state, action) {
			state.buyAssetDetails = action?.payload
		},

		setSellAssetDetails(state, action) {
			state.sellAssetDetails = action?.payload
		},

		setAssetQuantity(state, action) {
			state.assetQuantity = action?.payload
		},
		setAssetDetails(state, action) {
			state.assetDetails = action?.payload
		},

		/* Chat */
		setGroupChatMessages(state, action) {
			state.groupChatMessages = action?.payload
		},
		setP2PchatMessages(state, action) {
			state.p2pchatMessage = action?.payload
		},

		setAppealOrderToken(state, action) {
			state.appealOrderToken = action?.payload
		},

		/* Offer */
		setMyofferResponse(state, action) {
			state.myOffersResponse = action?.payload
		},
		setOfferDetailsParam(state, action) {
			state.offerDetailsParams = action?.payload
		},

		/* Transactions */
		setRecentTransactions(state, action) {
			state.recentTrans = action?.payload
		},

		/* dynamic settings */
		setDashboardState(state, action) {
			if (action?.payload) {
				// @ts-ignore
				if (typeof state[action?.payload?.key] !== "undefined") {
					// @ts-ignore
					state[action?.payload?.key] = action?.payload?.value
				}
			}
		}
	}
})

export const {
	setBankList,
	setTransaction,
	setTransactionDetails,
	setMarketNews,
	setBuyAssetDetails,
	setAssetQuantity,
	setGroupChatMessages,
	setP2PchatMessages,
	setAssetDetails,
	setSellAssetDetails,
	setAppealOrderToken,
	setMyofferResponse,
	setOfferDetailsParam,
	setRecentTransactions,
	setDashboardState
} = dashboardSlice.actions

export const getBankList = (state: AppState) => state?.dashboard?.bankList
export const getTransaction = (state: AppState) => state?.dashboard?.transactions
export const getTransactionDetails = (state: AppState) => state?.dashboard?.tranxDetails
export const getMarketNews = (state: AppState) => state?.dashboard?.newsListItems
export const getBuyAssetDetails = (state: AppState) => state?.dashboard?.buyAssetDetails
export const getSellAssetDetails = (state: AppState) => state?.dashboard?.sellAssetDetails
export const getAssetQuantity = (state: AppState) => state?.dashboard?.assetQuantity
export const getGroupChatMessages = (state: AppState) => state?.dashboard?.groupChatMessages
export const getP2PchatMessages = (state: AppState) => state?.dashboard?.p2pchatMessage
export const getAssetDetails = (state: AppState) => state?.dashboard?.assetDetails
export const getAppealOrderToken = (state: AppState) => state?.dashboard?.appealOrderToken
export const getMyOfferResponse = (state: AppState) => state?.dashboard?.myOffersResponse
export const getOfferDetailsParams = (state: AppState) => state?.dashboard?.offerDetailsParams
export const getRecentTransactions = (state: AppState) => state?.dashboard?.recentTrans
export const getMarketplaceBuyAsset = (state: AppState) => state?.dashboard?.marketplaceBuyAssets
export const getMarketplaceSellAsset = (state: AppState) => state?.dashboard?.marketplaceSellAssets
export const getMK_DSK_AssetId = (state: AppState) => state?.dashboard?.mk_dsk_assetId
export const getCurrencyPairs = (state: AppState) => state?.dashboard?.currencyPairs
export const getResponseTimeStore = (state: AppState) => state?.dashboard?.responseTimeStore
export const getSavedBanks = (state: AppState) => state?.dashboard?.savedBanks
