import DashDesktopContainer from "components/DashDesktopContainer"
import OrderPanel from "components/merchant/panels/OrderPanel"
import React from "react"
import { useDispatch } from "react-redux"
import { useLoaderData } from "react-router-dom"
import { toggleOrderPanel, toggleTransHistoryPanel } from "redux/reducers/panelsSlice"
import { uniqueId } from "services/utility/Utils/Utils"
import { DepositHistoryInterface } from "types"

export default function MerchantWithdrawalHistory() {
	const dispatcher = useDispatch()
	const _data: any = useLoaderData()
	const historyData: Array<DepositHistoryInterface> = _data?.data ? _data?.data : []

	const _togglePanel = (panelData: any) => dispatcher(toggleTransHistoryPanel({ status: true, data: panelData, params: "deposit" }))

	return (
		<>
			<DashDesktopContainer title="Merchant Withdrawal History" showSearch={false} showOnMobile>
				<div className="bg-white xs:p-1 md:p-5">
					<div className="flex flex-col">
						<div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
								<div className="overflow-hidden">
									<table className="min-w-full text-left text-sm font-light">
										<thead className="border-b bg-blue-900 text-white font-medium">
											<tr>
												<th scope="col" className="px-6 font-bold py-4">
													Order ID
												</th>
												<th scope="col" className="px-6 font-bold py-4">
													Client
												</th>
												<th scope="col" className="px-6 font-bold py-4">
													Amount
												</th>
												<th scope="col" className="px-6 font-bold py-4">
													Date
												</th>

												<th scope="col" className="px-6 font-bold py-4">
													Action
												</th>
											</tr>
										</thead>
										<tbody>
											{historyData?.map(item => (
												<tr key={uniqueId()} className="border-b">
													<td className="whitespace-nowrap px-6 py-4 font-medium">{item?.txn_id}</td>
													<td className="whitespace-nowrap px-6 py-4">{item?.client_name}</td>
													<td className="whitespace-nowrap px-6 py-4">{item?.amount}</td>
													<td className="whitespace-nowrap px-6 py-4">{item?.date}</td>
													<td className="whitespace-nowrap px-6 py-4">
														<span onClick={() => _togglePanel(item)} className="text-primary cursor-pointer">
															View
														</span>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</DashDesktopContainer>
		</>
	)
}
