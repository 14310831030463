import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { getUserActiveState } from "redux/reducers/appSlice"

export default function AuthRoutes({
	children
}: {
	children?: React.ReactNode
}) {
	const isActive = useSelector(getUserActiveState)

	if (isActive) {
		return <Navigate to={"/dashboard"} />
	}

	return children
}
