import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { Link, useLoaderData, useNavigate } from "react-router-dom"
import { uniqueId } from "services/utility/Utils/Utils"

export default function UserVerification() {
	const { IDPlaceholder, ProofOfResidentPlaceholder } = useFlusStores()

	const kycsLevelsResponse: any = useLoaderData()

	const data = kycsLevelsResponse?.data

	const navigate = useNavigate()
	const gotoLevelOneVerification = () => navigate("/dashboard/verification/id")
	const gotoLevelTwoVerification = () => navigate("/dashboard/verification/por")

	return (
		<section className="p-3 bg-white">
			<header className="mb-3">
				<h1>
					<strong>Verification</strong>
					<br />
				</h1>
			</header>
			<section>
				<div className="card verif-card">
					<div className="card-body">
						<div className="row">
							{/* Level 1 */}
							<div className="col-md-6 col-lg-6 mb-3">
								<h1 className="level-h">
									<strong>{data?.level_1?.title}</strong>
								</h1>
								<p>{data?.level_1?.description}</p>
								<h6>
									<strong>Feature</strong>
								</h6>
								<p>
									Wallet Withdrawal Limit -{" "}
									<strong>{data?.level_1?.feature?.p2p_transaction_limit}</strong>
								</p>
								<p>
									P2P Transaction Limits -{" "}
									<strong>{data?.level_1?.feature?.wallet_withdrawal_limit}</strong>
									<br />
								</p>
								<button
									onClick={gotoLevelOneVerification}
									disabled={data?.level_1?.button_disable}
									className="btn btn-primary btn-lg bg-blue "
									type="button"
								>
									{data?.level_1?.button_text}
								</button>
							</div>
							{/* End */}

							<div className="col-md-6 col-lg-6 mb-3">
								<h5 className="text-center">
									<strong>{data?.level_1?.caption}</strong>
									<br />
								</h5>
								<img
									className="img-fluid m-auto d-flex my-3"
									src={IDPlaceholder}
									alt=""
									width="200"
									height="200"
									loading="lazy"
								/>

								{data?.level_1?.document?.map((allowed: string) => (
									<p key={uniqueId()}>
										<i className="fa fa-check me-2 icon-lg"></i>
										{allowed}
									</p>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="card verif-card">
					<div className="card-body">
						<div className="row">
							<div className="col-md-6 col-lg-6 mb-3">
								<h1 className="level-h">
									<strong>{data?.level_2?.title}</strong>
								</h1>
								<p>{data?.level_2?.description}</p>
								<h6>
									<strong>Feature</strong>
								</h6>
								<p>
									Wallet Withdrawal Limit -{" "}
									<strong>{data?.level_2?.feature?.p2p_transaction_limit}</strong>
								</p>
								<p>
									P2P Transaction Limits -
									<strong>{data?.level_2?.feature?.wallet_withdrawal_limit}</strong>
									<br />
								</p>
								<button
									onClick={gotoLevelTwoVerification}
									className="btn btn-primary btn-lg bg-blue"
									type="button"
									disabled={data?.level_2?.button_disable}
								>
									{data?.level_2?.button_text}
								</button>
							</div>
							<div className="col-md-6 col-lg-6 mb-3">
								<h5 className="text-center">
									<strong>{data?.level_2?.caption}</strong>
									<br />
								</h5>
								<img
									className="img-fluid m-auto d-flex my-3"
									src={ProofOfResidentPlaceholder}
									alt=""
									width="100"
									height="100"
									loading="lazy"
								/>

								{data?.level_2?.document?.map((allowed: string) => (
									<p key={uniqueId()}>
										<i className="fa fa-check me-2 icon-lg"></i>
										{allowed}
									</p>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>
		</section>
	)
}
