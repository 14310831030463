import { ClipboardIcon } from "@heroicons/react/24/outline"
import { copyText } from "libs/copy"
import { NAIRA_SIGN } from "libs/currency"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getWithdrawalOrderPanel } from "redux/reducers/panelsSlice"
import { changeMerchantWithdrawStep } from "redux/reducers/withdrawalSlice"
import { formatAmount } from "services/utility/Utils/utilities"

export default function WithdrawalRecipientDetails() {
	const { data, params } = useSelector(getWithdrawalOrderPanel)

	const dispatcher = useDispatch()

	const _handleMakePayment = () => dispatcher(changeMerchantWithdrawStep("upload_pop"))
	return (
		<div>
			<div className="px-4 sm:px-0">
				<h3 className="text-2xl text-center font-semibold  text-gray-900">{data?.client_name}</h3>
				<p className="max-w-2xl text-sm text-center text-gray-500">Ensure to very the user account details before making payments.</p>
			</div>
			<div className="mt-6 border-t border-gray-100">
				<dl className="divide-y divide-gray-100">
					<div className="px-4 py-1 sm:grid sm:gap-4 sm:px-0">
						<dd className="text-lg font-bold leading-6 text-gray-700">{data?.account_name}</dd>
					</div>
					<div className="px-4 py-1 sm:grid sm:gap-4 sm:px-0">
						<dd className="text-lg font-bold leading-6 text-gray-700">{data?.bank_name}</dd>
					</div>

					<div className="px-4 py-1 sm:grid sm:gap-4 sm:px-0">
						<dd className="text-lg font-bold leading-6 text-gray-700 flex gap-2 items-center">
							{data?.account_number}
							<ClipboardIcon className="w-5 h-5 cursor-pointer" onClick={() => copyText(data?.account_number as string)} />
						</dd>
					</div>

					<div className="px-4 py-6 sm:grid sm:gap-4 sm:px-0">
						<dd className="text-sm font-medium italic  text-red-700">
							Make a transfer of {NAIRA_SIGN}
							{formatAmount(data?.sub_amount)} to the user account to proceed.
						</dd>
					</div>

					<div className="px-4 py-6 sm:grid sm:gap-4 sm:px-0">
						<button
							onClick={_handleMakePayment}
							className="border-1 border-blue-500 text-blue-500 rounded-md  transition ease-in duration-300 p-3 w-fit hover:bg-blue-500 hover:text-white active:bg-blue-500 active:text-white"
						>
							I've made payment
						</button>
					</div>
				</dl>
			</div>
		</div>
	)
}
