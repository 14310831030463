import DashDesktopContainer from "components/DashDesktopContainer"
import React from "react"
import DesktopSellCoin from "./DesktopSellCoin"

export default function SellCoin() {
	return (
		<>
			<DashDesktopContainer showTitle={false} showOnMobile>
				<DesktopSellCoin />
			</DashDesktopContainer>
		</>
	)
}
