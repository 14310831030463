import IconSpinner from "components/icons/IconSpinner"
import React from "react"
import { useDispatch } from "react-redux"
import { changeClientStep, stopClientDepositTimer } from "redux/reducers/depositSlice"

export default function AwaitClientDepositRelease() {
	const dispatcher = useDispatch()

	const _handleEscalateClick = () => {
		dispatcher(changeClientStep("escalate"))
		dispatcher(stopClientDepositTimer(null))
	}
	return (
		<div className="flex flex-col gap-4 items-center py-4">
			<IconSpinner width="5em" height="5em" className="animate-spin duration-500" />
			<p className="p-3 italic text-gray-400">Awaiting merchant release. </p>
			<button onClick={_handleEscalateClick} type="button" className="border-1 border-blue-500 rounded-md p-2 text-blue-500">
				Escalate
			</button>
		</div>
	)
}
