import { GET, POST, PUT, DROP } from "configs/api"
import { handleError } from "services/utility/Utils/Utils"
import { firebaseEndpoints } from "./firebase.endpoints"

export const SubmitToken = async (params: FormData) => {
	try {
		return await POST(firebaseEndpoints.TOKEN, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}
