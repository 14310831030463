import IconSpinner from "components/icons/IconSpinner"
import React from "react"

export default function WithdrawalAwaitingRelease() {
	return (
		<div className="flex flex-col gap-4 items-center py-4">
			<IconSpinner width="5em" height="5em" className="animate-spin duration-500" />
			<p className="p-3 italic text-gray-400">Please wait while the deposit is being made.</p>
		</div>
	)
}
