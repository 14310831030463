import { FLUX_DYNAMIC_STORE, useFlusDispatcher, useFlusStores } from "@bilmapay/react-flux-storage"
import AwaitResponse from "components/AwaitResponse"
import React from "react"
import { useMutation } from "react-query"
import { userPaymentMethodApis } from "services/Api/payments/payment.index"
import { uniqueId } from "services/utility/Utils/Utils"
import Multiselect from "multiselect-react-dropdown"
import { useDispatch, useSelector } from "react-redux"
import { getPaymentMethods, setPaymentMethods } from "redux/reducers/walletSlice"
import { PaymentMethodsInterface } from "types/app.types"

interface BKPaymentMethodSelectionProps {
	inputName?: string
}

export default function BKPaymentMethodSelection({ inputName }: BKPaymentMethodSelectionProps) {
	const userPaymentMethods = useSelector(getPaymentMethods)
	const dispatcher = useDispatch()
	const inputRef = React.createRef<HTMLInputElement>()

	const { FetchPaymentMethods } = userPaymentMethodApis()

	const fetchUserPaymentMethodsApi = useMutation(FetchPaymentMethods, {
		onSuccess: (res: any) => {
			if (res?.status) {
				let formattedMethods: Array<PaymentMethodsInterface> = []
				const { data } = res

				if (Array.isArray(data)) {
					data.map((bank: any): void => {
						if (bank) {
							formattedMethods.push({
								key: uniqueId(),
								name: `${bank?.bank_name}(${bank?.account_number})`,
								paymentMethod_id: bank?.payment_method_id
							})
						}
					})

					dispatcher(setPaymentMethods(formattedMethods))
				}
			}
		}
	})

	const _onSelect = (selectedList: any[], selectedItem: any) => {
		if (selectedList) {
			if (inputRef?.current) {
				const values: any = selectedList.map(selectedItem => selectedItem?.paymentMethod_id)
				inputRef.current.value = values
			}
		}
	}

	return (
		<AwaitResponse api={fetchUserPaymentMethodsApi.mutateAsync}>
			<Multiselect options={userPaymentMethods} onSelect={_onSelect} displayValue="name" />
			<input type="hidden" name={inputName && inputName} ref={inputRef} />
		</AwaitResponse>
	)
}
