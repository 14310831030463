import { createSlice } from "@reduxjs/toolkit"
import { AppState } from "redux/appGlobalStore"
import { TransactionHistoryPanelData } from "types"

export interface PanelState {
	show?: boolean
	params?: string
	data?: TransactionHistoryPanelData | any
}

// Type for our state

export interface PanelsStateProps {
	orderPanel: PanelState
	withdrawOrderPanel: PanelState
	bankPanel: PanelState
	limitPanel: PanelState
	depositCashbridgePanel: PanelState
	withdrawalCashbridgePanel: PanelState
	transHistoryPanel: PanelState
}

// Initial state
const panelInitState: PanelState = {
	show: false,
	params: "",
	data: undefined
}
const initialState: PanelsStateProps = {
	orderPanel: panelInitState,
	withdrawOrderPanel: panelInitState,
	bankPanel: panelInitState,
	limitPanel: panelInitState,
	depositCashbridgePanel: panelInitState,
	withdrawalCashbridgePanel: panelInitState,
	transHistoryPanel: panelInitState
}

// Actual Slice
export const PanelsSlice = createSlice({
	name: "panels",
	initialState,
	reducers: {
		toggleOrderPanel(state, action) {
			const { params, data, status } = action?.payload

			if (typeof status === "boolean") state.orderPanel.show = status

			if (params) state.orderPanel.params = params

			if (data) state.orderPanel.data = data
		},

		toggleTransHistoryPanel(state, action) {
			const { params, data, status } = action?.payload

			if (typeof status === "boolean") state.transHistoryPanel.show = status

			if (params) state.transHistoryPanel.params = params

			if (data) state.transHistoryPanel.data = data
		},

		toggleBankPanel(state, action) {
			const { params, data, status } = action?.payload

			if (typeof status === "boolean") state.bankPanel.show = status

			if (params) state.bankPanel.params = params

			if (data) state.bankPanel.data = { ...state.bankPanel.data, ...data }
		},
		toggleLimitPanel(state, action) {
			const { params, data, status } = action?.payload

			if (typeof status === "boolean") state.limitPanel.show = status

			if (params) state.limitPanel.params = params

			if (data) state.limitPanel.data = { ...state.limitPanel.data, ...data }
		},
		toggleDepositCashBridgePanel(state, action) {
			const { params, data, status } = action?.payload

			if (typeof status === "boolean") state.depositCashbridgePanel.show = status

			if (params) state.depositCashbridgePanel.params = params

			if (data) state.depositCashbridgePanel.data = { ...state.depositCashbridgePanel.data, ...data }
		},

		toggleWithdrawalCashBridgePanel(state, action) {
			const { params, data, status } = action?.payload

			if (typeof status === "boolean") state.withdrawalCashbridgePanel.show = status

			if (params) state.withdrawalCashbridgePanel.params = params

			if (data) state.withdrawalCashbridgePanel.data = { ...state.withdrawalCashbridgePanel.data, ...data }
		},

		toggleWithdrawalOrderPanel(state, action) {
			const { params, data, status } = action?.payload

			if (typeof status === "boolean") state.withdrawOrderPanel.show = status

			if (params) state.withdrawOrderPanel.params = params

			if (data) state.withdrawOrderPanel.data = { ...state.withdrawOrderPanel.data, ...data }
		}
	}
})

export const {
	toggleTransHistoryPanel,
	toggleWithdrawalOrderPanel,
	toggleDepositCashBridgePanel,
	toggleOrderPanel,
	toggleBankPanel,
	toggleLimitPanel,
	toggleWithdrawalCashBridgePanel
} = PanelsSlice.actions

export const getOrderPanel = (state: AppState) => state?.panels?.orderPanel
export const getTransHistoryPanel = (state: AppState) => state?.panels?.transHistoryPanel
export const getBankPanel = (state: AppState) => state?.panels?.bankPanel
export const getLimitPanel = (state: AppState) => state?.panels?.limitPanel
export const getDepositCashbridgePanel = (state: AppState) => state?.panels?.depositCashbridgePanel
export const getWithdrawalCashbridgePanel = (state: AppState) => state?.panels?.withdrawalCashbridgePanel
export const getWithdrawalOrderPanel = (state: AppState) => state?.panels?.withdrawOrderPanel

export default PanelsSlice
