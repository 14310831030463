import PageInfo from "components/misc/Page"
import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { useNavigate, useParams } from "react-router-dom"
import { useAuthApis } from "services/Api/auth/auth.apis.index"
import { useMutation } from "react-query"
import { extractToken } from "services/utility/Utils/utilities"
import { sleep } from "services/utility/Utils/_sleep"
import AwaitResponse from "components/AwaitResponse"
import { useDispatch } from "react-redux"
import { LOGIN } from "redux/reducers/appSlice"

export default function GoogleAuth() {
	const { LoginImg, LOGO } = useFlusStores()
	const { authToken } = useParams()
	const dispatcher = useDispatch()
	const navigator = useNavigate()

	const { FinalizeGoogleAuth } = useAuthApis()

	const finalizeGAuthApi = useMutation(FinalizeGoogleAuth, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(LOGIN({ token: extractToken(res?.data?.token) }))

				sleep(100).then(() => navigator("/dashboard"))
			}
		}
	})

	const googleAuthFinisher = async () => {
		const fd = new FormData()
		// @ts-ignore
		fd.append("google_token", authToken)

		return await finalizeGAuthApi.mutateAsync(fd)
	}

	return (
		<>
			<PageInfo titleText="Google authentication flow" />
			<section>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-9 col-lg-12 col-xl-10">
							<div className="card o-hidden border-0 my-5">
								<div className="card-body p-0">
									<div className="row">
										<div className="col-lg-6 d-none d-lg-flex p-4 bg-light-blue">
											<div className="d-flex flex-grow-1 justify-content-center align-items-center auth-bg-image">
												<img src={LoginImg} alt="" width="350" />
											</div>
										</div>
										<div className="col-lg-6">
											<div className="p-5">
												<div className="text-center">
													<img src={LOGO} alt="" width="50" />
													<h4 className="text-dark mb-4 mt-3">Authenticating...</h4>
													<p className="text-start p-2 bg-light-blue text-blue">Please wait while we authenticate your request.</p>

													<AwaitResponse api={googleAuthFinisher}>
														<form className="user"></form>
													</AwaitResponse>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
