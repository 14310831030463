import { GET, POST, PUT, DROP } from "configs/api"
import { handleError } from "services/utility/Utils/Utils"
import { paymentEndpoints } from "./payment.endpoints"

export const AddPaymentMethod = async (params: object | FormData) => {
	try {
		return await POST(paymentEndpoints.ADD_PAYMENT_METHOD, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchPaymentMethods = async () => {
	try {
		return await GET(paymentEndpoints.FETCH_PAYMENT_METHOD)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const DeletePaymentMethods = async (params: object | FormData) => {
	try {
		return await POST(paymentEndpoints.DELETE_PAYMENT_METHOD, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchBanks = async () => {
	try {
		return await GET(paymentEndpoints.FETCH_BANKS)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}
