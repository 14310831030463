import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { ArrowRightIcon } from "@heroicons/react/24/outline"
import { Link } from "react-router-dom"

export default function ContactusHeader() {
	const { contactusbg, bitcoins } = useFlusStores()

	return (

		
		<section className="bg-white items-center sm:space-x-4 sm:h-screen pb-5 mb-6 pt-6 flex flex-row flex-wrap justify-center bg-fixed firstSectionBG bg-no-repeat">

		<div className="w-full sm:w-1/2  flex flex-column items-center sm:items-start pl-4 sm:pl-20">

			<p className=" mb-4 text-blue-700 sm:w-[100%] tracking-wide sm:leading-tight text-5xl sm:text-7xl font-link">Contact Us</p>

			<div className="w-full sm:w-2/3 mb-6">
				<p className=" openFont forcegrey text-lg "> Ready to elevate your crypto journey? Contact us now and let's navigate the future of finance together.</p>
			</div>

		</div>

		<div className="w-5/6 sm:w-2/5 ">
			<img className="img-fluid block m-auto" src={bitcoins} alt="" />
		</div>

	</section>


	)
}
