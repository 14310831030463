import React from "react"
import { useMutation } from "react-query"
import { Link } from "react-router-dom"
import { userWalletApis } from "services/Api/wallet/wallet.index"
import { useFlusStores, useFlusDispatcher, FLUX_DYNAMIC_STORE } from "@bilmapay/react-flux-storage"
import AwaitResponse from "components/AwaitResponse"
import { uniqueId } from "services/utility/Utils/Utils"
import { SwapperInterface } from "./WalletTransferSwapcard"
import { encryptString } from "services/utility/Utils/utilities"
import { useDispatch, useSelector } from "react-redux"
import { getFundingWalletCoins, setFundingWalletCoins } from "redux/reducers/walletSlice"

export default function FundingwalletCoinCard() {
	const { FetchFundingWallet } = userWalletApis()
	const fundingWalletCoins = useSelector(getFundingWalletCoins)
	const dispatcher = useDispatch()

	const fetchFundingWalletCoinApi = useMutation(FetchFundingWallet, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(setFundingWalletCoins(res?.data))
			}
		}
	})

	const transferSwapperConfig: SwapperInterface = {
		up: {
			name: "Funding",
			value: "funding"
		},
		down: {
			name: "Spot",
			value: "spot"
		}
	}

	return (
		<AwaitResponse api={fetchFundingWalletCoinApi.mutateAsync}>
			{Array.isArray(fundingWalletCoins?.coin) && (
				<>
					{fundingWalletCoins?.coin.map((coin: any) => (
						<React.Fragment key={uniqueId()}>
							{coin && (
								<>
									<div className="col-lg-6 col-xl-6 mb-3">
										<div className="card">
											<div className="card-header">
												<div className="row">
													<div className="col-md-6 col-xl-6 d-flex justify-content-start align-items-center">
														<img src={coin?.icon} alt="" className="img-fluid img-icon-md" width="100" />
														<strong className="ms-2">{coin?.name}</strong>
													</div>
													<div className="col-md-6 col-xl-6">
														<p className="mb-0">
															<strong>Market Value:</strong>
														</p>
														<p>{coin?.market_value} NGN</p>
													</div>
												</div>
											</div>
											<div className="card-body">
												<h4 className="card-title mb-4">Coin Balance:</h4>
												<h4 className="text-dark card-subtitle mb-2">
													<strong>
														{coin?.amount} {coin?.symbol}
													</strong>
												</h4>
												<p className="card-text mt-3">~ {coin?.naira_value} NGN</p>
												<div className="text-center d-flex text-center">
													<Link
														className="btn btn-primary w-100 me-4 shadow-sm text-light"
														role="button"
														to={`/dashboard/funding-wallet/transfer/${coin?.asset_id}?opt=${encryptString(JSON.stringify(transferSwapperConfig))}`}
													>
														Transfer
													</Link>
												</div>
											</div>
										</div>
									</div>
								</>
							)}
						</React.Fragment>
					))}
				</>
			)}
		</AwaitResponse>
	)
}
