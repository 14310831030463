import AwaitResponse from "components/AwaitResponse"
import ChatCardNavigator from "components/misc/ChatCardNavigation"
import React from "react"
import { useMutation } from "react-query"
import { userMarketplaceApis } from "services/Api/marketplace/marketplace.index"
import { useUrlQuery } from "services/utility/hooks/useURL"
import { uniqueId } from "services/utility/Utils/Utils"
import { TextAvatar } from "services/utility/Utils/_avatar"
import { ShowServerErrors } from "services/utility/Utils/_toaster"
import { sleep } from "services/utility/Utils/_sleep"
import { scrollOptions } from "./GroupChat"
import { secondsCountdownTimer } from "services/utility/Utils/utilities"
import { useDispatch, useSelector } from "react-redux"
import { getAssetDetails, getP2PchatMessages, setP2PchatMessages } from "redux/reducers/dashboardSlice"

interface QueryParamsProps {
	rm: string
}

const chatFormRef = React.createRef<HTMLFormElement>()
const peerChatAreaRef = React.createRef<HTMLUListElement>()
const timerRef = React.createRef<HTMLElement>()

export default function P2PChat() {
	const assetDetails = useSelector(getAssetDetails)
	const chatMessages = useSelector(getP2PchatMessages)

	const { rm }: QueryParamsProps = useUrlQuery()
	const messages = chatMessages?.message

	const dispatcher = useDispatch()
	const { getChat, CreateChat } = userMarketplaceApis()

	const fetchChatApi = useMutation(getChat, {
		onSuccess(res: any, variables, context) {
			if (res.status) {
				dispatcher(setP2PchatMessages(res?.data))

				sleep(200).then(() => {
					if (peerChatAreaRef?.current) {
						peerChatAreaRef?.current?.scrollIntoView(scrollOptions)
					}
				})
			} else ShowServerErrors("error", res)
		}
	})

	const handleChatFetching = async () => {
		const fd = new FormData()
		fd.append("room_id", rm)
		return await fetchChatApi.mutateAsync(fd)
	}

	// -------------------------------------------------------------------------------

	const createChatApi = useMutation(CreateChat, {
		onSuccess: (res: any) => {
			if (res?.status) {
				/* reset the form and refresh chat messages */
				handleChatFetching()

				if (chatFormRef?.current) {
					chatFormRef?.current.reset()
				}
			} else ShowServerErrors("Error", res)
		}
	})

	const isFetchingChats = fetchChatApi.isLoading
	const isCreatingChat = createChatApi.isLoading

	const handleChatMessagesPosting = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)
		formData.append("room_id", rm)

		createChatApi.mutateAsync(formData)
	}

	React.useEffect(() => {
		if (chatMessages?.remaining_minutes) {
			secondsCountdownTimer(chatMessages?.remaining_minutes, (isFinished, timeLef) => {
				if (timerRef?.current) {
					const seconds: unknown = timeLef
					const timerSec = seconds as string
					timerRef.current.innerHTML = `${timerSec}s`
				}
			})
		}
	})

	/* Message fetching */
	React.useEffect(() => {
		const fetchInterval = window.setInterval(handleChatFetching, 60000)

		return () => {
			window.clearInterval(fetchInterval)
		}
	})

	return (
		<ChatCardNavigator refreshChats={handleChatFetching} roomId={rm} returnLink="/dashboard" Ref={chatFormRef} isLoading={isCreatingChat} handleSubmit={handleChatMessagesPosting}>
			<AwaitResponse api={handleChatFetching}>
				<section>
					<header className="mb-4">
						<div className="row">
							<div className="col-md-6 col-lg-6 mb-3 d-flex justify-content-start align-items-center">
								<h4 className="seller-object-name text-truncate">
									<br />
									<strong className="name-icon">{chatMessages?.nickname[0]}</strong> {chatMessages?.nickname}
								</h4>
							</div>
							<div className="col-md-6 col-lg-6 mb-3 d-flex justify-content-start align-items-center">
								<h5>
									Order end in:
									<span className="text-danger" ref={timerRef}>
										0
									</span>
								</h5>
							</div>
						</div>
						<h2 className="text-center mb-3 text-blue">
							<strong>{chatMessages?.label}</strong>
							<br />
						</h2>
					</header>
					<section>
						<ul className="list-inline d-flex mb-4">
							<li className="list-inline-item w-100 text-blue">
								<strong>Transaction Amount:</strong>
							</li>
							<li className="list-inline-item w-100 text-end text-blue">
								<strong>{chatMessages?.transaction_amount} NGN</strong>
							</li>
						</ul>

						<ul className="list-group chat-messages">
							{messages && (
								<>
									{messages?.length > 0 && (
										<>
											{messages?.map(message => (
												<>
													{message?.position === "right" && (
														<li key={uniqueId()} className="list-group-item sender">
															{message?.type === "text" && <span>{message?.message}</span>}
															{message?.type === "media" && (
																<span>
																	<svg className="bi bi-paperclip me-2" xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" viewBox="0 0 16 16">
																		<path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"></path>
																	</svg>
																	<a className="text-light" href={message?.message} download target="_blank" rel="noreferrer">
																		Click to download file
																	</a>
																</span>
															)}
														</li>
													)}

													{message?.position === "left" && (
														<li key={uniqueId()} className="list-group-item receiver d-flex">
															<img className="user-avatar" src={TextAvatar(assetDetails?.user_name)} alt="" width="50" />
															<p className="message-object">
																{message?.type === "text" && <span>{message?.message}</span>}
																{message?.type === "media" && (
																	<span>
																		<svg className="bi bi-paperclip me-2" xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" viewBox="0 0 16 16">
																			<path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"></path>
																		</svg>
																		<a className="text-black" href={message?.message} download target="_blank" rel="noreferrer">
																			Download file
																		</a>
																	</span>
																)}
															</p>
														</li>
													)}
												</>
											))}
										</>
									)}
								</>
							)}

							{(!messages || messages?.length <= 0) && (
								<li className="list-group-item text-center mt-4">
									<span className="text-muted">
										<strong>Start conversation</strong>
									</span>
								</li>
							)}

							{isFetchingChats && (
								<li className="list-group-item text-center mt-4" style={{ backgroundColor: "transparent" }}>
									<span className="text-info">
										<strong>
											<em>Loading...</em>
										</strong>
									</span>
								</li>
							)}
						</ul>
					</section>
				</section>
			</AwaitResponse>
		</ChatCardNavigator>
	)
}
