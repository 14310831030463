import React from "react"
import ReactDOM from "react-dom/client"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
// import reportWebVitals from "./reportWebVitals"

// Font
// import './fonts/Goldman/Goldman-Bold.ttf';

/* Project designs stylesheets */

import "assets/fonts/fontawesome-all.min.css"
import "assets/fonts/font-awesome.min.css"
import "assets/fonts/ionicons.min.css"
import "assets/fonts/fontawesome5-overrides.min.css"
import "assets/fonts/fontawesome5-overrides.min.css"
import 'assets/fonts/RussoOneRegular.ttf';

/* End */

import "@popperjs/core"
import "bootstrap/dist/js/bootstrap.bundle"

/* Main app design */
import "app.scss"
import "sweetalert2/dist/sweetalert2.css"
import "animate.css"
/* End */

import App from "./App"
import { FlusActions } from "flus/actions/flus.actions"
import { FlusStores } from "flus/stores/flus.stores"
import { FlusAppServiceProvider } from "@bilmapay/react-flux-storage"
import { userFirebaseApi } from "services/Api/firebase/firebase.inde"
import { requestFBToken } from "configs/FirebaseProvider"
import AppealModal from "views/modals/AppealModal"
import AddBankAccountModal from "views/dashboard/create-offer/AddBankAccountModal"
import CoinReleasedSuccessModel from "views/modals/CoinReleasedSuccessModel"
import { Provider } from "react-redux"
import { AppGlobalStore } from "redux/appGlobalStore"



const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
	<React.StrictMode>
		<Provider store={AppGlobalStore}>
			<FlusAppServiceProvider actions={FlusActions} stores={FlusStores}>
				<App />

				<AppealModal />
				<AddBankAccountModal />
				<CoinReleasedSuccessModel />
			</FlusAppServiceProvider>
		</Provider>
	</React.StrictMode>
)

/* service  */
const { SubmitToken } = userFirebaseApi()

const swConfig: serviceWorkerRegistration.SWConfig = {
	onSuccess(registration) {
		if ("Notification" in window) {
			Notification?.requestPermission().then(permission => {
				if (permission === "granted") {
					/* Request firebase token */
					requestFBToken(registration).then(currentToken => {
						if (currentToken) {
							const fd = new FormData()

							fd.append("token", currentToken)
							return SubmitToken(fd)
						}
					})
				}
			})
		}
	},
	onUpdate(registration) {
		if ("Notification" in window) {
			Notification?.requestPermission().then(permission => {
				if (permission === "granted") {
					/* Request firebase token */
					requestFBToken(registration).then(currentToken => {
						if (currentToken) {
							const fd = new FormData()

							fd.append("token", currentToken)
							return SubmitToken(fd)
						}
					})
				}
			})
		}
	}
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(swConfig)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
