import { FLUX_DYNAMIC_STORE, useFlusDispatcher, useFlusStores } from "@bilmapay/react-flux-storage"
import CustomModal from "components/misc/CustomModal"
import { APPEAL_MODAL, CONFIRM_PIN_MODAL, RELEASE_COIN_MODAL } from "flus/constants/modals.id.const"
import React from "react"
import { userMarketplaceApis } from "services/Api/marketplace/marketplace.index"
import { useUrlQuery } from "services/utility/hooks/useURL"
import ConfirmPinModal from "./ConfirmPinModal"
import { useMutation } from "react-query"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import ShowSpinner from "components/misc/Spinner"
import { useDispatch } from "react-redux"
import { setAppealOrderToken } from "redux/reducers/dashboardSlice"

export default function ReleaseCoinModal() {
	const { CheckMark } = useFlusStores()
	const { ot } = useUrlQuery()

	const dispatcher = useDispatch()

	const { ReleaseAsset } = userMarketplaceApis()
	const releaseAssetApi = useMutation(ReleaseAsset, {
		onSuccess: (res: any) => {
			if (res?.status) {
				ShowSuccess("Success", res?.message)

				window.location.reload()
			} else ShowServerErrors("Error", res)
		}
	})

	const isLoading = releaseAssetApi.isLoading

	const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)
		formData.append("order_token", ot)

		releaseAssetApi.mutateAsync(formData)
	}

	const handleOrderTokenDispatch = () => {
		dispatcher(setAppealOrderToken(ot))
	}

	return (
		<>
			<CustomModal modalId={RELEASE_COIN_MODAL}>
				<div>
					<img className="m-auto d-block" src={CheckMark} alt="" width="50" height="50" />
					<h4 className="text-center mt-3">
						<strong>Buyer marked Order as Paid</strong>
					</h4>
					<p className="lead text-center text-danger">Ensure you carefully confirm the receipt of payment before you release coin..</p>
					<div className="btn-group m-auto d-block text-center" role="group">
						<button
							disabled={isLoading}
							className="btn btn-primary me-4"
							data-bs-dismiss="modal"
							type="button"
							data-bs-toggle="modal"
							data-bs-target={`#${APPEAL_MODAL}`}
							onClick={handleOrderTokenDispatch}
						>
							Appeal
						</button>
						<button disabled={isLoading} className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target={`#${CONFIRM_PIN_MODAL}`} data-bs-dismiss="modal">
							{!isLoading && "Release"}
							{isLoading && <ShowSpinner color="text-light" />}
						</button>
					</div>
				</div>
			</CustomModal>

			<ConfirmPinModal params={ot} handleSubmit={handleSubmit} />
		</>
	)
}
