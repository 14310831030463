import FileUpload from "components/misc/FileUpload"
import React from "react"
import { useMutation } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import { getWithdrawalOrderPanel } from "redux/reducers/panelsSlice"
import { changeMerchantWithdrawStep, setMerchantWithdrawalTimerId, stopMerchantWithdrawalTimer } from "redux/reducers/withdrawalSlice"
import { CheckWithdrawalRequestStatus, UploadWithdrawalProof } from "services/Api/merchant/merchant.apis"
import { sleep } from "services/utility/Utils/_sleep"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"

export default function WithdrawalProofUploader() {
	const { data, params } = useSelector(getWithdrawalOrderPanel)

	const dispatcher = useDispatch()

	const withdrawalListenerApi = useMutation(CheckWithdrawalRequestStatus, {
		onSuccess(res: any) {
			if (res?.status) {
				if (res?.data?.status_code?.toString() === "5") {
					dispatcher(stopMerchantWithdrawalTimer(res?.data))

					ShowSuccess("Success", res?.data?.status)

					sleep(900).then(() => {
						window.location.reload()
					})
				}
			}
		}
	})

	const _startListener = () => {
		const timer = setInterval(() => {
			withdrawalListenerApi.mutateAsync({ txn_id: data?.txn_id })
		}, 9000)
		dispatcher(setMerchantWithdrawalTimerId(timer))
	}

	const UploadWithdrawalProofApi = useMutation(UploadWithdrawalProof, {
		onSuccess(res: any) {
			if (res?.status) {
				ShowSuccess("Success", res?.message)

				_startListener()

				dispatcher(changeMerchantWithdrawStep("awaiting_release"))
			} else ShowServerErrors("Error", res)
		}
	})

	const isLoading = UploadWithdrawalProofApi.isLoading

	const _handleUpload = (data: FormData) => UploadWithdrawalProofApi.mutateAsync(data)

	return <FileUpload name="pop" transactionId={data?.txn_id} handleUpload={_handleUpload} isLoading={isLoading} />
}
