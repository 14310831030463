import { GET, POST, PUT, DROP } from "configs/api"
import { handleError } from "services/utility/Utils/Utils"
import { marketnewsEndpoints } from "./marketnews.endpoints"

export const FetchNewsDetails = async (slug: string) => {
	try {
		return await GET(marketnewsEndpoints.GET(slug))
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FetchNews = async (page: string = "1") => {
	try {
		return await GET(marketnewsEndpoints.FETCH(page))
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}
