import React from "react"
import { FLUX_DYNAMIC_STORE, useFlusDispatcher, useFlusStores } from "@bilmapay/react-flux-storage"
import { userOfferApis } from "services/Api/offer/offer.index"
import { useMutation } from "react-query"
import { uniqueId } from "services/utility/Utils/Utils"
import AwaitResponse from "components/AwaitResponse"
import Multiselect from "multiselect-react-dropdown"
import { useDispatch, useSelector } from "react-redux"
import { DropdownOptionsInterface } from "types/app.types"
import { getResponseTimeStore, setDashboardState } from "redux/reducers/dashboardSlice"

interface BKSelectResponseTimeProp {
	inputName?: string
}
export default function BKSelectResponseTime({ inputName }: BKSelectResponseTimeProp) {
	const responseTimeStore = useSelector(getResponseTimeStore)
	const dispatcher = useDispatch()
	const inputRef = React.createRef<HTMLInputElement>()

	const { FetchResponseTime } = userOfferApis()

	const fetchResponseTimeApi = useMutation(FetchResponseTime, {
		onSuccess: (res: any) => {
			if (res?.status) {
				const respTime: Array<DropdownOptionsInterface> = []
				const { data } = res

				if (Array.isArray(data)) {
					data.forEach((pair: any) => {
						if (pair) {
							respTime.push({
								key: uniqueId(),
								name: pair?.label,
								value: pair?.value
							})
						}
					})
				}

				dispatcher(setDashboardState({ key: "responseTimeStore", value: respTime }))
			}
		}
	})

	const _onSelect = (selectedList: any, selectedItem: any) => {
		if (selectedItem) {
			if (inputRef?.current) {
				inputRef.current.value = selectedItem?.value
			}
		}
	}

	return (
		<AwaitResponse api={fetchResponseTimeApi.mutateAsync}>
			<Multiselect singleSelect options={responseTimeStore} onSelect={_onSelect} displayValue="name" />
			<input type="hidden" name={inputName && inputName} ref={inputRef} />
		</AwaitResponse>
	)
}
