import React from "react"
import { useMutation } from "react-query"
import { userTransactionApi } from "services/Api/transactions/transactions.index"
import ShowSpinner from "components/misc/Spinner"
import { useDispatch } from "react-redux"
import { setTransaction } from "redux/reducers/dashboardSlice"

export default function TransactionFilterHeader() {
	const { FilterTransaction } = userTransactionApi()
	const dispatcher = useDispatch()

	const filterFormRef = React.createRef<HTMLFormElement>()

	const filterTransApi = useMutation(FilterTransaction, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(setTransaction(res?.data))
			}
		}
	})

	const isFetchingFilter = filterTransApi.isLoading

	const handleChange = () => {
		if (filterFormRef?.current) {
			const formData = new FormData(filterFormRef?.current)

			filterTransApi.mutateAsync(formData)
		}
	}

	return (
		<section className="my-3 mt-5">
			<form ref={filterFormRef}>
				<div className="row">
					<div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex justify-content-start align-items-center mb-3">
						<span className="me-2">
							<strong>From</strong>
						</span>
						<input name="from" className="form-control form-control-lg bk__qa_0001-filter-input" type="date" />
					</div>
					<div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex justify-content-start align-items-center mb-3">
						<span className="me-2">
							<strong>To</strong>
						</span>
						<input name="to" className="form-control form-control-lg bk__qa_0001-filter-input" type="date" onChange={handleChange} />
					</div>
					<div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex justify-content-start align-items-center mb-3">
						<span className="me-2">
							<i className="fas fa-filter"></i>
						</span>
						<select name="mode" className="form-select form-select-lg bk__qa_0001-filter-input" onChange={handleChange}>
							<optgroup label="Filter">
								<option value="all">All</option>
								<option value="send">Send</option>
								<option value="receive">Receive</option>
								<option value="transfer">Transfer</option>
								<option value="buy">Buy</option>
								<option value="sell">Sell</option>
							</optgroup>
						</select>
					</div>
				</div>
			</form>
			<div className="text-center mt-5">{isFetchingFilter && <ShowSpinner />}</div>
		</section>
	)
}
