import BKCustomSelect, { BKCustomSelectItems } from "components/input/BKCustomSelect"
import React from "react"

const generateYears = (startYear?: number) => {
	const currentYear = new Date().getFullYear(),
		years = []
	startYear = startYear || 1900
	while (startYear <= currentYear) {
		years.push(startYear++)
	}
	return years
}

const years = generateYears()
const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
const days = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"]

type DOBPickerProps = {
	defaultY?: string | number
	defaultM?: string | number
	defaultD?: string | number
}
export default function DOBPicker({ defaultD, defaultM, defaultY }: DOBPickerProps) {
	const [dob, setDOB] = React.useState<string>("")
	const [year, setYear] = React.useState<string>("")
	const [month, setMonth] = React.useState<string>("")
	const [day, setDay] = React.useState<string>("")

	React.useEffect(() => {
		if (year && month && day) setDOB(`${year}-${month}-${day}`)
	}, [dob, year, month, day])

	// -----------------------> [For Day Select Options]
	const daysOpts: Array<BKCustomSelectItems> = days?.map(
		(day): BKCustomSelectItems => ({
			name: day,
			value: day,
			icon: ""
		})
	)

	const onSelectDay = (selected: BKCustomSelectItems) => {
		setDay(selected?.value)
	}
	// ----------------------> [END]

	// ----------------------> [For Month Select Options]
	const monthsOpts: Array<BKCustomSelectItems> = months?.map(
		(month): BKCustomSelectItems => ({
			name: month,
			value: month,
			icon: ""
		})
	)
	const onSelectMonth = (selected: BKCustomSelectItems) => {
		setMonth(selected?.value)
	}
	// ----------------------> [END]

	// ----------------------> [For Year Select Options]
	const yearOpts: Array<BKCustomSelectItems> = years?.map(
		(year: unknown): BKCustomSelectItems => ({
			name: year as string,
			value: year as string,
			icon: ""
		})
	)
	const onSelectYear = (selected: BKCustomSelectItems) => {
		setYear(selected?.value)
	}
	// ----------------------> [END]

	return (
		<div className="form-group">
			<label className="form-label w-100 input-label">
				<strong>Date of Birth (DD/MM/YYYY)</strong>
				<sup className="text-danger">*</sup>
			</label>
			<div className="row">
				<div className="col">
					<BKCustomSelect defaultValue={defaultD} displayText={defaultD} options={daysOpts} showIcon={false} onChange={onSelectDay} name="d" />
				</div>
				<div className="col">
					<BKCustomSelect defaultValue={defaultM} displayText={defaultM} options={monthsOpts} showIcon={false} onChange={onSelectMonth} name="m" />
				</div>
				<div className="col">
					<BKCustomSelect defaultValue={defaultY} displayText={defaultY} options={yearOpts} showIcon={false} onChange={onSelectYear} name="y" />
				</div>
			</div>
			<input hidden className="form-control form-control-lg form-control-user" type="text" name="dob" placeholder="DD/MM/YYYY" value={dob} onChange={() => {}} />
		</div>
	)
}
