import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { generalApis } from "services/Api/general/gen.index"
import { useMutation } from "react-query"
import AwaitResponse from "components/AwaitResponse"
import { uniqueId } from "services/utility/Utils/Utils"
import { Link } from "react-router-dom"

type SupportedCoints = {
	asset_id: number
	name: string
	symbol: string
	icon: string
}
function SupportedCoinsSection() {
	const [supportedCoints, setSupportedCoins] = React.useState<Array<SupportedCoints>>()

	const { FetchSupportedCoins } = generalApis()

	const fetchSpportedCoinsApi = useMutation(FetchSupportedCoins, {
		onSuccess: (res: any) => {
			if (res?.status) {
				setSupportedCoins(res?.data)
			}
		}
	})

	return (
		<section className="bg-blue-600 h-[20rem] sm:h-[26rem] items-center justify-center flex flex-column ">

			{/* <h1 className="my-3 text-center mb-5">Trade Now</h1> */}

			<strong className="text-white  mb-4 text-center text-3xl sm:text-4xl font-link">Trade Now</strong>

			<p className=" text-lg sm:text-xl my-8 w-[80%] sm:w-[100%] text-center text-white" >Create your Beekle account now to start trading</p>

			{/* <button className="bg-white hover:bg-blue-700 text-blue font-link mt-4 font-bold py-2 px-4 rounded">
				Get Started
			</button> */}

			<Link to="/auth/signup" role="button" className="bg-white hover:bg-blue-700 text-blue font-link mt-4 font-bold py-2 px-4 rounded">
				Get Started
			</Link>

			
		</section>
	)
}

export default SupportedCoinsSection
