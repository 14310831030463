import { AxiosResponse } from "axios"
import EmptyContentMessage from "components/EmptyContentMessage"
import NextPrevPagination from "components/misc/NextPrevPagination"
import React from "react"
import { UseMutationResult } from "react-query"
import { Link } from "react-router-dom"
import { useUrlQuery } from "services/utility/hooks/useURL"
import { decryptString, encryptString } from "services/utility/Utils/utilities"
import { uniqueId } from "services/utility/Utils/Utils"
import { MarketplaceAssetsProps } from "types/app.types"

interface DataTableMobileProps {
	type?: "buy" | "sell"
	payload?: string
	paginator: UseMutationResult<void | AxiosResponse<any, any>, unknown, string, unknown>
}
export default function DataTableMobile({ paginator, type, payload }: DataTableMobileProps) {
	const assetData: MarketplaceAssetsProps = payload ? JSON.parse(decryptString(payload)) : {}
	const { data, pagination } = assetData

	const { asset } = useUrlQuery()

	const onNext = (_page: string | null) => ({ assetId: asset, page: _page })
	const onPrev = (_page: string | null) => ({ assetId: asset, page: _page })

	return (
		<>
			{/* Buy object */}
			{type === "buy" && (
				<>
					{data && (
						<>
							{data?.length > 0 && (
								<>
									{data.map(offer => (
										<article key={uniqueId()} className="mob-assets-object">
											<section className="w-50 right">
												<img className="rounded-circle img-fluid m-auto d-block" width="50" alt="" src={offer?.user_photo} />
												<strong className="text-center w-100 d-block">{offer?.user_name}</strong>
												<p className="text-center mb-0 text-primary coin-value-details">
													{offer?.remaining_unit} {offer?.asset_symbol}
												</p>
												<p className="text-center text-primary coin-value-details">
													{offer?.total_order} Orders ({offer?.percentage_score})
												</p>
											</section>
											<section className="w-100 left">
												<p className="text-primary">
													<strong>{offer?.payment_method}</strong>
												</p>
												<ul className="list-inline d-flex mb-0">
													<li className="list-inline-item w-100">
														<strong>Rate</strong>
													</li>
													<li className="list-inline-item w-100">
														<strong>Limit</strong>
													</li>
												</ul>
												<ul className="list-inline d-flex assets-info-list">
													<li className="list-inline-item w-100">
														{offer?.rate_per_coin} NGN
														<br />
													</li>
													<li className="list-inline-item w-100">
														{offer?.trade_limit}
														<br />
													</li>
												</ul>
												<Link to={`/dashboard/buy-coin?pl=${encryptString(JSON.stringify(offer))}`} className="btn btn-primary w-100 bg-blue" type="button">
													<strong>Buy {offer?.asset_symbol}</strong>
												</Link>
											</section>
										</article>
									))}
								</>
							)}
						</>
					)}

					{(!data || data?.length <= 0) && <EmptyContentMessage text="Not content" />}
				</>
			)}

			{/* Sell Object */}
			{type === "sell" && (
				<>
					{data && (
						<>
							{data?.length > 0 && (
								<>
									{data.map(offer => (
										<article key={uniqueId()} className="mob-assets-object">
											<section className="w-50 right">
												<img className="rounded-circle img-fluid m-auto d-block" width="50" alt="" src={offer?.user_photo} />
												<strong className="text-center w-100 d-block">{offer?.user_name}</strong>
												<p className="text-center mb-0 text-primary coin-value-details">
													{offer?.remaining_unit} {offer?.asset_symbol}
												</p>
												<p className="text-center text-primary coin-value-details">
													{offer?.total_order} Orders ({offer?.percentage_score})
												</p>
											</section>
											<section className="w-100 left">
												<p className="text-primary">
													<strong>{offer?.payment_method}</strong>
												</p>
												<ul className="list-inline d-flex mb-0">
													<li className="list-inline-item w-100">
														<strong>Rate</strong>
													</li>
													<li className="list-inline-item w-100">
														<strong>Limit</strong>
													</li>
												</ul>
												<ul className="list-inline d-flex assets-info-list">
													<li className="list-inline-item w-100">
														{offer?.rate_per_coin} NGN
														<br />
													</li>
													<li className="list-inline-item w-100">
														{offer?.trade_limit}
														<br />
													</li>
												</ul>
												<Link to={`/dashboard/sell-coin?pl=${encryptString(JSON.stringify(offer))}`} className="btn btn-primary w-100" type="button">
													<strong>Sell {offer?.asset_symbol}</strong>
												</Link>
											</section>
										</article>
									))}
								</>
							)}
						</>
					)}

					{(!data || data?.length <= 0) && <EmptyContentMessage text="Not content" />}
				</>
			)}

			<NextPrevPagination onNext={onNext} onPrev={onPrev} pagination={pagination} paginator={paginator} />
		</>
	)
}
