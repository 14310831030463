import CountryDailCodeSelector from "components/CountryDailCodeSelector"
import CountrySelector from "components/CountrySelector"
import PageInfo from "components/misc/Page"
import ShowSpinner from "components/misc/Spinner"
import PasswordInput from "components/PasswordInput"
import React from "react"
import { useMutation } from "react-query"
import { useLoaderData } from "react-router-dom"
import { userProfileApi } from "services/Api/profile/profile.index"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { UserProfileProps } from "types/app.types"
import DOBPicker from "views/onboarding/personalinfo/DOBPicker"
import ProfileHeader from "./ProfileHeader"

export default function EditProfile() {
	const userProfile: any = useLoaderData()
	const { data }: { data?: UserProfileProps } = userProfile
	const { UpdatePersonalInfo, UpdateResidentInfo, UpdateContactInfo } = userProfileApi()

	// -------------------------------------------------
	const updatePersonalInfoApi = useMutation(UpdatePersonalInfo, {
		onSuccess: res => {
			if (res?.status) {
				ShowSuccess("Profile", "Profile info saved successfully!")
			} else {
				ShowServerErrors("Profile", res)
			}
		}
	})

	const isUpdatingProfile = updatePersonalInfoApi.isLoading
	const handleUserProfileUpdateAction = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)
		// @ts-ignore
		formData.append("username", data?.username)

		updatePersonalInfoApi.mutateAsync(formData)
	}

	// --------------------------------------

	const updateResidentInfoApi = useMutation(UpdateResidentInfo, {
		onSuccess: res => {
			if (res?.status) {
				ShowSuccess("Resident Info", "Resident Info updated successfully!")
			} else {
				ShowServerErrors("Resident Info", res)
			}
		}
	})

	const isUpdateResidentInfo = updateResidentInfoApi.isLoading

	const handleResidentInfoUpdate = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		updateResidentInfoApi.mutateAsync(formData)
	}

	// -----------------------------------------------

	const updateContactInfoApi = useMutation(UpdateContactInfo, {
		onSuccess: res => {
			if (res?.status) {
				ShowSuccess("Profile", "Contact info updated successfully!")
			} else {
				ShowServerErrors("Profile", res)
			}
		}
	})

	const isUpdateingContactInfo = updateContactInfoApi.isLoading

	const handleContactInfoUpdating = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)
		updateContactInfoApi.mutateAsync(formData)
	}

	/* split the date of birth */
	const dobSplit = data?.dob?.split("-")
	const year = dobSplit ? dobSplit[0] : 0
	const month = dobSplit ? dobSplit[1] : 0
	const day = dobSplit ? dobSplit[2] : 0

	return (
		<section className="p-3 bg-white">
			<PageInfo titleText="Profile" />
			<header className="mb-3">
				<h1>Edit Profile</h1>
			</header>
			<section>
				<ProfileHeader />
				<section className="py-4 border-bottom">
					<form onSubmit={handleUserProfileUpdateAction}>
						<div className="row form-row mb-3">
							<div className="col-md-6 col-lg-6 mb-3">
								<div>
									<label className="form-label">
										<strong>First Name</strong>
									</label>
									<input defaultValue={data?.first_name} className="form-control form-control-lg" type="text" placeholder="Sean" name="first_name" />
								</div>
							</div>
							<div className="col-md-6 col-lg-6 mb-3">
								<div>
									<label className="form-label">
										<strong>Last Name</strong>
									</label>
									<input defaultValue={data?.last_name} className="form-control form-control-lg" type="text" placeholder="Tom" name="last_name" />
								</div>
							</div>
						</div>
						<div className="row form-row mb-3">
							<div className="col-md-6 col-lg-6 mb-3">
								<div>
									<label className="form-label">
										<strong>Username</strong>
									</label>
									<input readOnly defaultValue={data?.username} className="form-control form-control-lg" type="text" placeholder="example22" />
								</div>
							</div>
							<div className="col-md-6 col-lg-6 my-3">
								<div>
									<label className="form-label">
										<strong>Date of Birth</strong>
									</label>

									<DOBPicker defaultD={day} defaultM={month} defaultY={year} />
								</div>
							</div>
						</div>
						<div className="row form-row mb-3">
							<div className="col-md-6 col-lg-6 mb-3">
								<div>
									<label className="form-label">
										<strong>Gender</strong>
									</label>
									<select className="form-select form-select-lg" name="gender">
										<option value="">Select Gender</option>
										{data?.gender?.toString() === "male" && (
											<>
												<option value="male" selected={true}>
													Male
												</option>
												<option value="female">Female</option>
											</>
										)}

										{data?.gender?.toString() === "female" && (
											<>
												<option value="male">Male</option>
												<option value="female" selected={true}>
													Female
												</option>
											</>
										)}
									</select>
								</div>
							</div>

							{/* <div className="col-md-6 col-lg-6 mb-3">
								<div>
									<label className="form-label">
										<strong>Marital Status</strong>
									</label>
									<select className="form-select form-select-lg" name="marital_status">
										<option value="">Select Status</option>
										{data?.marital_status === "single" && (
											<>
												<option value="single" selected={true}>
													Single
												</option>
												<option value="married">Married</option>
											</>
										)}

										{data?.marital_status === "married" && (
											<>
												<option value="single">Single</option>
												<option value="married" selected={true}>
													Married
												</option>
											</>
										)}
									</select>
								</div>
							</div> */}

						</div>
						<div className="row form-row mb-3">
							<div className="col-md-12 col-lg-12 mb-3">
								<div>
									<label className="form-label">
										<strong>Email</strong>
									</label>
									<input className="form-control form-control-lg" type="email" placeholder={data?.email} readOnly defaultValue={data?.email} />
								</div>
							</div>
						</div>
						<div className="row form-row mb-3">
							<div className="col-md-12 col-lg-12 mb-3">
								<div>
									<label className="form-label">
										<strong>Country</strong>
									</label>
									<CountrySelector defaultValue={data?.country} />
								</div>
							</div>
						</div>
						<button disabled={isUpdatingProfile} className="btn btn-primary btn-lg d-block ms-auto bg-blue" type="submit">
							{!isUpdatingProfile && "Update"}
							{isUpdatingProfile && <ShowSpinner color="text-light" />}
						</button>
					</form>
				</section>
				<section className="py-4 border-bottom border-top">
					<form onSubmit={handleResidentInfoUpdate}>
						<div className="row form-row mb-3">
							<div className="col-md-6 col-lg-6 mb-3">
								<div>
									<label className="form-label">
										<strong>Residential Country</strong>
										<br />
									</label>
									<CountrySelector defaultValue={data?.residential_country} inputName="residential_country" />
								</div>
							</div>

							{/* <div className="col-md-6 col-lg-6 mb-3">
								<div>
									<label className="form-label">
										<strong>House Number</strong>
										<br />
									</label>
									<input defaultValue={data?.house_number} className="form-control form-control-lg" type="text" placeholder="7" name="house_number" />
								</div>
							</div> */}

						</div>
						<div className="row form-row mb-3">
							<div className="col-md-12 col-lg-12 mb-3">
								<div>
									<label className="form-label">
										<strong>Street Address</strong>
									</label>
									<input className="form-control form-control-lg" type="text" placeholder="Tom Sean Estate Parch" defaultValue={data?.street_address} name="street_address" />
								</div>
							</div>
						</div>
						<div className="row form-row mb-3">
							<div className="col-md-4 col-lg-4 mb-3">
								<div>
									<label className="form-label">
										<strong>City</strong>
										<br />
									</label>
									<input className="form-control form-control-lg" type="text" placeholder="Abuja FCT" defaultValue={data?.city} name="city" />
								</div>
							</div>
							<div className="col-md-4 col-lg-4 mb-3">
								<div>
									<label className="form-label">
										<strong>State/Region/Province</strong>
										<br />
									</label>
									<input defaultValue={data?.state} className="form-control form-control-lg" type="text" placeholder="Kubwa" name="state" />
								</div>
							</div>
							{/* <div className="col-md-4 col-lg-4 mb-3">
								<div>
									<label className="form-label">
										<strong>Postal Code</strong>
										<br />
									</label>
									<input defaultValue={data?.postal_code} className="form-control form-control-lg" type="text" placeholder="110101" name="postal_code" />
								</div>
							</div> */}
						</div>
						<button disabled={isUpdateResidentInfo} className="btn btn-primary btn-lg d-block ms-auto bg-blue" type="submit">
							{!isUpdateResidentInfo && "Update"}
							{isUpdateResidentInfo && <ShowSpinner color="text-light" />}
						</button>
					</form>
				</section>
				<section className="py-4 border-bottom border-top">
					<form onSubmit={handleContactInfoUpdating}>
						<div className="row form-row mb-3">
							<div className="col-md-6 col-lg-6 mb-3">
								<div>
									<label className="form-label">
										<strong>Country code</strong>
										<br />
									</label>

									<CountryDailCodeSelector defaultValue={data?.country_code} inputName="country_code" />
								</div>
							</div>
							<div className="col-md-6 col-lg-6 mb-3">
								<div>
									<label className="form-label">
										<strong>Phone number</strong>
										<br />
									</label>
									<input className="form-control form-control-lg" type="text" placeholder="07000008890" defaultValue={data?.phone_number} name="phone_number" />
								</div>
							</div>
						</div>
						<div className="row form-row mb-3">
							<div className="col-md-12 col-lg-12 mb-3">
								<div>
									<label className="form-label">
										<strong>Alternative Email</strong>
									</label>
									<input className="form-control form-control-lg" type="email" placeholder="example@gmail.com" defaultValue={data?.alternative_email} name="alternative_email" />
								</div>
							</div>
						</div>
						<div className="row form-row mb-3">
							<div className="col-md-12 col-lg-12 mb-3">
								<div>
									<label className="form-label">
										<strong>Security Question</strong>
									</label>
									<input name="security_question" defaultValue={data?.security_question} className="form-control form-control-lg" type="text" />
								</div>
							</div>
						</div>
						<div className="row form-row mb-3">
							<div className="col-md-12 col-lg-12 mb-3">
								<PasswordInput inputName="security_answer" label="Security Answer" />
							</div>
						</div>
						<button disabled={isUpdateingContactInfo} className="btn btn-primary btn-lg d-block ms-auto bg-blue" type="submit">
							{!isUpdateingContactInfo && "Update"}
							{isUpdateingContactInfo && <ShowSpinner color="text-light" />}
						</button>
					</form>
				</section>
			</section>
		</section>
	)
}
