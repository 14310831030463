import BreadCrumb from "components/BreadCrumb"
import DefaultNavigation from "components/layout/DefaultNavigation"
import PageInfo from "components/misc/Page"
import React from "react"
import FaqsContent from "./FaqsContent"

const navItems = [
	{
		active: false,
		title: "Home",
		link: "/"
	},
	{ active: true, title: "Frequently Asked Questions", link: "/faqs" }
]
export default function FaqsPage() {
	return (
		<>
			<PageInfo titleText="Frequently Asked Questions" />
			<DefaultNavigation includeHeader="faq" />
			<BreadCrumb nav={navItems} />
			<FaqsContent />
		</>
	)
}
