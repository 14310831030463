import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { generalApis } from "services/Api/general/gen.index"
import { useMutation } from "react-query"
import { FAQsInterface } from "types/app.types"
import { uniqueId } from "services/utility/Utils/Utils"
import AwaitResponse from "components/AwaitResponse"

export default function FaqsContent() {
	const { faqsIcon } = useFlusStores()
	const [faqs, setFaqs] = React.useState<Array<FAQsInterface>>([])
	const { FetchFAQs } = generalApis()

	const fetchFaqsApi = useMutation(FetchFAQs, {
		onSuccess(res: any) {
			if (res?.status) {
				setFaqs(res?.data)
			}
		}
	})

	return (
		<section className="content-area">
			<section className="bp_faq-section-001 mb-5">
				<div className="container">
					<div className="row">
						<div className="col-sm-9 col-md-10 col-lg-10 d-flex justify-content-center align-items-center">
							<h1>
								<strong>Have questions? We're here to help</strong>
							</h1>
						</div>
						<div className="col d-flex justify-content-center align-items-center">
							<img src={faqsIcon} alt="" width="100" />
						</div>
					</div>
				</div>
			</section>
			<section className="bp_faq-section-002 mb-4">
				<AwaitResponse api={fetchFaqsApi.mutateAsync}>
					<div className="container">
						<div id="accordion-1" className="accordion" role="tablist">
							{faqs?.length > 0 && (
								<>
									{faqs?.map((faq, index) => (
										<div key={uniqueId()} className="accordion-item">
											<h2 className="accordion-header" role="tab">
												<button
													className="accordion-button"
													data-bs-toggle="collapse"
													data-bs-target={`#accordion-1 .item-${index}`}
													aria-expanded={index === 0 ? "true" : "false"}
													aria-controls={`accordion-1 .item-${index}`}
												>
													{faq?.question}
												</button>
											</h2>
											<div className={`accordion-collapse collapse visible item-${index}`} role="tabpanel" data-bs-parent="#accordion-1">
												<div className="accordion-body">
													<p className="mb-0" dangerouslySetInnerHTML={{ __html: faq?.answer }}></p>
												</div>
											</div>
										</div>
									))}
								</>
							)}
						</div>
					</div>
				</AwaitResponse>
			</section>
		</section>
	)
}
