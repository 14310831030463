import PageInfo from "components/misc/Page"
import ShowSpinner from "components/misc/Spinner"
import PasswordInput from "components/PasswordInput"
import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { useAuthApis } from "services/Api/auth/auth.apis.index"
import { useUrlQuery } from "services/utility/hooks/useURL"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"

export default function CreateNewPassword() {
	const { ForgotPwdImg, LOGO } = useFlusStores()
	const { token } = useUrlQuery()
	const navigate = useNavigate()

	const { ResetAccountPassword } = useAuthApis()

	const resetAccountPasswordApi = useMutation(ResetAccountPassword, {
		onSuccess: res => {
			if (res?.status) {
				ShowSuccess("Password Reset", "Success! Your account password has been reset successfully!")

				navigate("/auth")
			} else ShowServerErrors("Password Reset", res)
		}
	})

	const isProcessing = resetAccountPasswordApi.isLoading

	const handleUserSubmitAction = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)
		formData.append("reset_token", token)

		resetAccountPasswordApi.mutateAsync(formData)
	}

	return (
		<section>
			<PageInfo titleText="Create New Password" />
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-9 col-lg-12 col-xl-10">
						<div className="card o-hidden border-0 my-5">
							<div className="card-body p-0">
								<div className="row">
									<div className="col-lg-6 d-none d-lg-flex p-4 bg-light-blue">
										<div className="d-flex flex-grow-1 justify-content-center align-items-center auth-bg-image">
											<img src={ForgotPwdImg} alt="" width="350" />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="p-5">
											<div className="text-center">
												<img src={LOGO} alt="Beekle" width="50" />
												<h4 className="text-dark mb-4 mt-3">Create New Password</h4>
												<p className="text-start p-2 bg-light-blue">Fill in new unqiue password.</p>
											</div>
											<form className="user" onSubmit={handleUserSubmitAction}>
												<PasswordInput />

												<PasswordInput label="Confirm Password" inputName="password_confirmation" />
												<button
													className="btn btn-primary btn-lg d-block login-btn w-100 bg-blue"
													type="submit"
													disabled={isProcessing}
												>
													{!isProcessing && "Change Password"}
													{isProcessing && <ShowSpinner color="text-light" />}
												</button>
												<small className="form-text my-2 w-100 d-block p-1">
													Already have an account? <a href="/auth">Log in</a>
												</small>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
