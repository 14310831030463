import CardNavigator from "components/CardNavigator"
import React from "react"
import { useLoaderData } from "react-router-dom"
import { NewsDetailsProps } from "types/app.types"

type MarketnewsDetailsTypes = {
	data: NewsDetailsProps
}
export default function MarketnewsDetails() {
	const newsDetails: any = useLoaderData()

	const { data }: MarketnewsDetailsTypes = newsDetails

	return (
		<CardNavigator returnLink="/dashboard/market-news?tb=mrk">
			<img src={data?.photo} alt="" className="img-fluid bk__mkn-img" />
			<header className="my-3 card-header">
				<h4>{data?.title}</h4>
				<hr />
				<span className="text-muted">{data?.date}</span> |{"  "}
				<span className="text-muted">
					<em>By</em> {data?.creator}
				</span>
			</header>
			<article dangerouslySetInnerHTML={{ __html: data?.body }}></article>
		</CardNavigator>
	)
}
