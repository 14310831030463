import {
	FetchBuyMarketplace,
	FetchQuantity,
	BuyAsset,
	BuyAssetDetails,
	PayAsset,
	CancelOrder,
	SellAsset,
	SellAssetDetails,
	ReleaseAsset,
	BuyerAwaitFundRelease,
	SellerAwaitPaymentRelease,
	CreateChat,
	getChat,
	FetchSellMarketplace,
	CreateMediaChat
} from "./marketplace.apis"

export const userMarketplaceApis = () => ({
	FetchBuyMarketplace,
	FetchQuantity,
	BuyAsset,
	BuyAssetDetails,
	PayAsset,
	CancelOrder,
	SellAsset,
	SellAssetDetails,
	ReleaseAsset,
	BuyerAwaitFundRelease,
	SellerAwaitPaymentRelease,
	CreateChat,
	getChat,
	CreateMediaChat,
	FetchSellMarketplace
})
