import { GET, POST, PUT, DROP } from "configs/api"
import { handleError } from "services/utility/Utils/Utils"
import { kycEndpoints } from "./kyc.endpoints"

export const LoadKYCLevels = async () => {
	try {
		return await GET(kycEndpoints.LOAD_KYC)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const KYCLevelOneUpload = async (params: object | FormData) => {
	try {
		return await POST(kycEndpoints.KYC_LV1, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const KYCLevelTwoUpload = async (params: object | FormData) => {
	try {
		return await POST(kycEndpoints.KYC_LV2, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}
