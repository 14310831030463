import DashDesktopContainer from "components/DashDesktopContainer"
import DashSearchForm from "components/layout/nav/DashSearchForm"
import AvailableBalanceCard from "components/wallet/AvailableBalanceCard"
import FundingwalletCoinCard from "components/wallet/FundingwalletCoinCard"
import React from "react"
import { useLoaderData } from "react-router-dom"

export default function FundingWallet() {
	const fundingWalet: any = useLoaderData()

	const data = fundingWalet?.data

	return (
		<>
			<DashDesktopContainer title="Funding Wallet" page="funding_wallet" showOnMobile>
				<section className="bk__spot-wallet-content">
					<AvailableBalanceCard balance={data?.balance} />
					{/* TODO: Display fait balance here */}
					<div className="row">
						<FundingwalletCoinCard />
					</div>
				</section>
			</DashDesktopContainer>

			<DashSearchForm page="funding_wallet" />
		</>
	)
}
