import React from "react"

type SwitchProps = {
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
	name?: string
	// @ts-ignore
	label?: string | React.ReactNode
	disable?: boolean
	isChecked?: boolean
}
export default function Switch({ isChecked = false, disable = false, label, onChange, ...rest }: SwitchProps) {
	const [check, setCheck] = React.useState<boolean>(isChecked)

	const _handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (typeof onChange !== "undefined") onChange(e)

		setCheck(check ? false : true)
	}
	return (
		<>
			<form action="">
				<div className="form-check form-switch">
					<input onChange={_handleCheck} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" {...rest} checked={check} disabled={disable} />
					<label className="form-check-label" htmlFor="flexSwitchCheckDefault">
						{label}
					</label>
				</div>
			</form>
		</>
	)
}
