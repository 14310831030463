import { FLUX_DYNAMIC_STORE, useFlusDispatcher } from "@bilmapay/react-flux-storage"
import classNames from "classnames"
import { MY_OFFER_DETAILS } from "flus/constants/modals.id.const"
import React from "react"
import { useMutation } from "react-query"
import { userOfferApis } from "services/Api/offer/offer.index"
import ShowSpinner from "./Spinner"
import { useDispatch } from "react-redux"
import { setOfferDetailsParam } from "redux/reducers/dashboardSlice"

export interface MyofferMobileItemProps {
	type?: string
	icon?: string
	status?: string
	offer_id?: string | number | any
	created_date?: string
	asset_name?: string
	remaining_amount?: string
	[k: string]: any
}
interface MyofferMobileItemTypes {
	item?: MyofferMobileItemProps
	toggler?: (param: string) => void
}

export default function MyofferMobileItem({ toggler, item }: MyofferMobileItemTypes) {
	const dispatcher = useDispatch()

	const { FetchMyOfferDetails } = userOfferApis()

	const fetchOfferDetailsApi = useMutation(FetchMyOfferDetails, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(setOfferDetailsParam(res?.data))
			}
		}
	})

	const isFetching = fetchOfferDetailsApi.isLoading

	const toggleOfferDetailsModal = () => {
		const fd = new FormData()
		fd.append("offer_id", item?.offer_id)

		fetchOfferDetailsApi.mutateAsync(fd)
	}

	return (
		<div className="row bg-white m-1 mb-3 shadow-sm">
			<div className="col-sm-12 col-md-12" style={{ background: "var(--bs-gray-300)" }}>
				<div className="text-center">
					<h3>{item?.type}</h3>
				</div>
			</div>
			<div className="col-sm-12 col-md-12">
				<div className="row">
					<div className="col">
						<span className="d-block w-100 mb-2 px-2">{item?.created_date}</span>
						<span className="d-block w-100 px-2">{item?.remaining_amount}</span>
					</div>
					<div className="col">
						<div className="text-center">
							<img className="rounded-circle img-fluid me-2" src={item?.icon} width="25" height="25" alt="" />
							<span className="text-center">{item?.asset_name}</span>
						</div>
						<div>
							<span onClick={toggleOfferDetailsModal} className="text-center d-block pointer text-primary" data-bs-target={`#${MY_OFFER_DETAILS}`} data-bs-toggle="modal">
								{!isFetching && "View Details"}
								{isFetching && <ShowSpinner />}
							</span>
						</div>
						<div className="text-center">
							<span
								onClick={() => {
									if (toggler) {
										toggler(item?.offer_id)
									}
								}}
								className={classNames({
									"badge rounded-pill w-50 my-2 pointer": true,
									"bg-success": item?.status?.toLocaleLowerCase() === "active",
									"bg-danger": item?.status?.toLocaleLowerCase() === "inactive",
									"bg-primary": item?.status?.toLocaleLowerCase() === "completed"
								})}
							>
								{item?.status}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
