import { dashMobileNavContanerRef, dashSearchFormRef, mainMobileNavContanerRef, userMobileMenuRef } from "flus/constants/refs.const"

export const handleMainMobileNavToggle = (e: any) => {
	if (mainMobileNavContanerRef?.current) {
		if (mainMobileNavContanerRef?.current.classList.contains("hide")) {
			mainMobileNavContanerRef?.current.classList.remove("hide")
			mainMobileNavContanerRef?.current.classList.add("show")
		} else {
			mainMobileNavContanerRef?.current.classList.remove("show")
			mainMobileNavContanerRef?.current.classList.add("hide")
		}
	}
}

export const handleMainMobileNavContainerBodyToggle = (e: any) => {
	if (mainMobileNavContanerRef?.current) {
		mainMobileNavContanerRef?.current.classList.remove("show")
		mainMobileNavContanerRef?.current.classList.add("hide")
	}
}

export const stopImmediatePropagation = (e: any) => {
	if (e) {
		e.stopPropagation()
	}
}

export const handleDashNavToggle = () => {
	if (dashMobileNavContanerRef?.current) {
		dashMobileNavContanerRef?.current.classList.toggle("show")
	}
}

export const handleUserMobileMenuToggle = (e: React.MouseEvent<HTMLElement>) => {
	// e.preventDefault()

	if (userMobileMenuRef?.current) {
		userMobileMenuRef?.current.classList.toggle("show")
	}
}
export const handleDashSearchFormToggle = () => {
	if (dashSearchFormRef?.current) {
		dashSearchFormRef?.current.classList.toggle("show")
	}
}
