import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import { appSlice } from "./reducers/appSlice"
import { walletSlice } from "./reducers/walletSlice"
import { dashboardSlice } from "./reducers/dashboardSlice"
import { PanelsSlice } from "./reducers/panelsSlice"
import { ModalsSlice } from "./reducers/modalSlice"
import { depositSlice } from "./reducers/depositSlice"
import { withdrawalSlice } from "./reducers/withdrawalSlice"
import { assetSlice } from "./reducers/assetSlice"

const makeStore = () =>
	configureStore({
		reducer: {
			[appSlice.name]: appSlice.reducer,
			[walletSlice.name]: walletSlice.reducer,
			[dashboardSlice.name]: dashboardSlice.reducer,
			[PanelsSlice.name]: PanelsSlice.reducer,
			[ModalsSlice.name]: ModalsSlice.reducer,
			[depositSlice.name]: depositSlice.reducer,
			[withdrawalSlice.name]: withdrawalSlice.reducer,
			[assetSlice.name]: assetSlice.reducer
		},
		devTools: true
	})

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<AppStore["getState"]>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>

export const AppGlobalStore = makeStore()
