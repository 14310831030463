interface ConfigInterface {

	app: {
		name?: string
		version?: string
		about?: string
		baseUrl?: string
		lastReleased?: string
		cpanel?: string
	}

	api: {
		baseUrl?: string
		[key: string]: string | boolean | undefined | any
	}

	env?: string
	isProd: boolean
	isDev: boolean
	googleAuth?: string
	[key: string]: string | boolean | undefined | any
}

const configs: ConfigInterface = {
	app: {
		name: "Beekle",
		// name: process.env.REACT_APP_NAME,
		version: process.env.REACT_APP_VERSION,
		about: process.env.REACT_APP_ABOUT,
		baseUrl: "https://sandbox.beekle.io/api",
		// baseUrl: "https://api.beekle.io/api",
		// baseUrl: process.env.REACT_APP_BASEURL,
		lastReleased: process.env.REACT_APP_RELEASE_DATE,
		cpanel: process.env.REACT_APP_CPANEL
	},

	api: {
		baseUrl: "https://sandbox.beekle.io/api",
		// baseUrl: "https://api.beekle.io/api",
		// baseUrl: process.env.REACT_APP_API,
		paystack: "https://api.paystack.co"
	},
	// env: "development",
	env: process.env.NODE_ENV,
	isProd: process.env.NODE_ENV === "production",
	isDev: process.env.NODE_ENV === "development",
	googleAuth: process.env.REACT_APP_GOOGLE_AUTH
}

/**
 * The config helper function for access config object
 * @param {string} key The key defined the config object you can use the dot accessor for nested object inside key
 * @returns string|object|void
 */
export function config(key: string): any {
	/* use this for dot notation when access config properties e.g:
    app.name as string. So the last value in the array is the real value 
    we're tring to access  */

	const keys: string[] = key.split(".")

	if (keys.length > 1) {
		/* using dot notation access of env properties */
		let targetProperty: unknown = ""

		keys.forEach((key: string) => {
			/* retreive the first accessor assign it to a var, but before that check if 
			the var has a value  */
			if (targetProperty) {
				if (targetProperty?.hasOwnProperty(key)) {
					targetProperty = targetProperty[key as keyof typeof targetProperty]
				}
			} else if (typeof configs[key as keyof ConfigInterface] !== "undefined")
				targetProperty = configs[key as keyof ConfigInterface]
		})

		return targetProperty
	}

	let propIndex: number = keys.length - 1
	let configKey: string = keys[propIndex]

	return configs[configKey as keyof ConfigInterface]
}
