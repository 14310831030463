import React from "react"
import Webcam from "react-webcam"

interface SelfieWebcamInterface {
	onClose: (params: any) => void
}
export default function SelfieWebcam({ onClose }: SelfieWebcamInterface) {
	const webcamRef = React.createRef<any>()
	const selfieInputRef = React.createRef<HTMLInputElement>()
	const [imgSrc, setImgSrc] = React.useState(null)

	const capture = React.useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot()
		if (selfieInputRef?.current) {
			selfieInputRef.current.value = imageSrc
		}

		setImgSrc(imageSrc)
	}, [webcamRef, setImgSrc])

	const reCapture = () => setImgSrc(null)

	return (
		<div className="d-block">
			{!imgSrc && (
				<div className="my-4 text-center selfie-cam-container">
					<Webcam audio={false} ref={webcamRef} screenshotFormat="image/png" />
				</div>
			)}
			{imgSrc && (
				<img
					className="img-fluid m-auto d-block rounded pointer mb-4"
					src={imgSrc}
					alt=""
					width="200"
					loading="lazy"
				/>
			)}

			<section className="w-100 d-flex justify-content-center align-items-center">
				<div className="webcam-controls">
					<button onClick={onClose} className="btn btn-danger" type="button">
						Close
					</button>
					<button onClick={capture} className="btn btn-primary bg-blue" type="button">
						Capture
					</button>
					<button onClick={reCapture} className="btn btn-primary" type="button">
						Re-Capture
					</button>
				</div>
			</section>

			<input type="hidden" name="selfie" ref={selfieInputRef} />
		</div>
	)
}
