import classNames from "classnames"
import Modal from "components/Modal"
import { MY_OFFER_DETAILS } from "flus/constants/modals.id.const"
import React from "react"
import { useSelector } from "react-redux"
import { getOfferDetailsParams } from "redux/reducers/dashboardSlice"
import { uniqueId } from "services/utility/Utils/Utils"

export default function MyOfferDetailsModal() {
	const offerDetailsParams = useSelector(getOfferDetailsParams)

	return (
		<Modal uniqueId={MY_OFFER_DETAILS} title="Offer Details">
			{offerDetailsParams && (
				<>
					<p>This page shows full details of your offer</p>
					<span className="p-3 w-100 d-block mb-3" style={{ backgroundColor: "rgba(0, 122, 255, 0.11)" }}></span>

					<div className="text-end">
						<img className="me-2" src={offerDetailsParams?.icon} style={{ width: "25px", height: "25px", borderRadius: "100%" }} alt="" />

						<strong>{offerDetailsParams?.asset_name}</strong>
					</div>

					<h3 className="text-blue">Details</h3>
					<div className="text-end">
						<p className="text-primary">
							Offer ID: {offerDetailsParams?.offer_id}
							<br />
							Offer Date: {offerDetailsParams?.created_date}
						</p>
					</div>
					<span className="p-3 w-100 d-block" style={{ backgroundColor: "rgba(0, 122, 255, 0.11)" }}></span>
					<div className="text-end">
						<p className="text-primary">
							<span className="text-danger">Rate: {offerDetailsParams?.rate}</span>
							<br />
							Min Limit: {offerDetailsParams?.min_amount}
							<br />
							Max Limit: {offerDetailsParams?.max_amount}
							<br />
							<span className="text-danger">Balance: {offerDetailsParams?.remaining_amount}</span>
						</p>
					</div>
					{offerDetailsParams?.type?.toLowerCase() === "sell" && (
						<>
							<span className="p-3  w-100 d-block" style={{ backgroundColor: "rgba(0, 122, 255, 0.11)" }}></span>
							<h3 className="text-blue">{offerDetailsParams?.payment_method}</h3>

							{Array.isArray(offerDetailsParams?.account_details) && (
								<>
									<ul className="list-group list-group-flush">
										{offerDetailsParams?.account_details?.map((bank: any) => (
											<li key={uniqueId()} className="list-group-item">
												<span className="text-primary">{bank?.bank_name}</span>
												<br />
												<span className="text-primary">{bank?.account_name}</span>
												<br />
												<span className="text-primary">{bank?.account_number}</span>
											</li>
										))}
									</ul>
								</>
							)}
						</>
					)}

					<h3
						className={classNames({
							"text-end": true,
							"text-warning": offerDetailsParams?.status?.toLowerCase() === "active",
							"text-success": offerDetailsParams?.status?.toLowerCase() === "completed",
							"text-danger": offerDetailsParams?.status?.toLowerCase() === "inactive"
						})}
					>
						{offerDetailsParams?.status}
					</h3>
				</>
			)}
		</Modal>
	)
}
