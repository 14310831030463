import { useFlusStores } from "@bilmapay/react-flux-storage"
import CustomModal from "components/misc/CustomModal"
import { COIN_RELEASE_SUCCESS } from "flus/constants/modals.id.const"
import React from "react"

export default function CoinReleasedSuccessModel() {
	const { CheckGood } = useFlusStores()

	return (
		<CustomModal modalId={COIN_RELEASE_SUCCESS}>
			<div>
				<img className="m-auto d-block" src={CheckGood} alt="" width="50" height="50" />
				<h4 className="text-center mt-3">
					<strong>Congrats!</strong>
				</h4>
				<p className="lead text-center text-success">Your coin has been released successfully!</p>
				<div className="btn-group m-auto d-block text-center" role="group">
					<a href="/dashboard/marketplace?tb=mrk" className="btn btn-primary me-4" type="button">
						Okay
					</a>
				</div>
			</div>
		</CustomModal>
	)
}
