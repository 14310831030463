import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { Link } from "react-router-dom"
import { imglogo } from "assets/img/logo/logo.png";
import "../../morecss.css"

export default function DefaultFooter() {
	const { footerLogo, LOGO, youtubeIcon, xlogo, linkin, ig, facebook } = useFlusStores()
	const openSocialLink = (url: string | URL | undefined) => {
		window.open(url, "_blank", "noreferrer");
	}

	return (
		<section className="bp_footer py-4 bg-blue-50 text-dark pt-6">

			<div className="container">

				<div className="row justify-center space-x-6">

					<div className="col-lg-3 foot_logo-col">

						<div className="flex flex-row items-start space-x-4 justify-start mb-3">

							<img src={LOGO} alt="Beekle" width="100" loading="lazy" /> 
							<div>
							<h3 className="font-bold" > Beekle </h3>
							<p> &copy; beekle 2023</p>
							</div>
							 
						</div>



						<ul className="list-inline footer_social_icons space-x-4">

							<li onClick={() => { openSocialLink("https://www.facebook.com/profile.php?id=100068625292579&mibextid=LQQJ4d") }} className="cursor-pointer list-inline-item">
								<img src={facebook} alt="Facebok" width="30" />
							</li>

							<li onClick={() => { openSocialLink("https://www.linkedin.com/company/beekle-technologies/") }} className="cursor-pointer  list-inline-item">
								<img src={linkin} alt="LinkedIn" width="30" />
							</li>

							<li onClick={() => { openSocialLink("https://www.x.com/beeklehq") }} className="cursor-pointer list-inline-item">
								<img src={xlogo} alt="Twitter" width="30" />
							</li>

							<li onClick={() => { openSocialLink("https://instagram.com/beeklehq?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr") }} className="cursor-pointer list-inline-item">
								<img src={ig} alt="Instagram" width="30" />
							</li>
						</ul>

					</div>


					<div className="col-lg-2 mt-4">
						<h4 >
							<strong className="font-link">Sell Gift Cards </strong>
						</h4>
						<ul className="list-unstyled space-y-5 openFont">
							<li>
								<Link className="text-black underline underline-offset-4" to="">Sell Xbox</Link>
							</li>

							<li>
								<Link className="text-black underline underline-offset-4" to="">Sell Google Play</Link>
							</li>

							<li>
								<Link className="text-black underline underline-offset-4" to="">Sell Itunes</Link>
							</li>

							<li>
								<Link className="text-black underline underline-offset-4" to="">Sell Steam</Link>
							</li>

							<li>
								<Link className="text-black underline underline-offset-4" to="">Sell Vanilla</Link>
							</li>

							<li>
								<Link className="text-black underline underline-offset-4" to="">Sell Razor Gold</Link>
							</li>

							<li>
								<Link className="text-black underline underline-offset-4" to="">Sell Amex</Link>
							</li>

							<li>
								<Link className="text-black underline underline-offset-4" to="">Sell Footlocker</Link>
							</li>
						</ul>
					</div>

					<div className="col-lg-2  mt-4">
						<h4 >
							<strong className="font-link">Quick Links</strong>
						</h4>
						<ul className="list-unstyled space-y-5 openFont">
							<li>
								<Link className="text-black underline underline-offset-4" to="/faqs">What is P2P</Link>
							</li>
							<li>
								<Link className="text-black underline underline-offset-4" to="/faqs">How it Works</Link>
							</li>
							<li>
								<Link className="text-black underline openFont underline-offset-4" to="/about">About Us</Link>
							</li>
							<li>
								<Link className="text-black underline underline-offset-4" to="/contact-us">Contact Us</Link>
							</li>
							<li>
								<Link className="text-black underline openFont underline-offset-4" to="/auth">Login</Link>
							</li>
							<li>
								<Link className="text-black underline underline-offset-4" to="/auth/signup">Register</Link>
							</li>
							<li>
								<Link className="text-black underline underline-offset-4" to="/dashboard/marketplace?tb=mrk">Marketplace</Link>
							</li>
						</ul>
					</div>

					<div className="col-lg-2  mt-4">
						<h4 >
							<strong className="RussoOne">Support</strong>
						</h4>
						<ul className="list-unstyled space-y-5 text-black">
							<li>
								<Link className="text-black underline underline-offset-4" to="#">Feedback</Link>
							</li>
							<li>
								<Link className="text-black underline underline-offset-4" to="#">Settings &amp; Privacy</Link>
							</li>
							<li>
								<Link className="text-black underline underline-offset-4" to="#">User Protection Policy</Link>
							</li>
							<li>
								<Link className="text-black underline underline-offset-4" to="#">Terms &amp; Conditions</Link>
							</li>
							<li>
								<Link className="text-black underline underline-offset-4" to="#">24/7 Support</Link>
							</li>
							<li>
								<Link className="text-black underline underline-offset-4" to="#">Investor Guide</Link>
							</li>
							<li>
								<Link className="text-black underline underline-offset-4" to="/faqs">FAQ</Link>
							</li>
							<li>
								<Link className="text-black underline underline-offset-4" to="/dashboard/disputes?tb=dpz">Dispute Resolution</Link>
							</li>
						</ul>
					</div>

					<div className="col-lg-2  mt-4">
						<h4>
							<strong className="RussoOne">Buy Crypto</strong>
						</h4>
						<ul className="list-unstyled space-y-5 openFont">

							<li>
								<Link className="text-black underline underline-offset-4" to="/dashboard/marketplace?tb=mrk">Buy Bitcoin</Link>
							</li>

							<li>
								<Link className="text-black underline underline-offset-4" to="/dashboard/marketplace?tb=mrk">Buy Ethereum</Link>
							</li>

							<li>
								<Link className="text-black underline underline-offset-4" to="/dashboard/marketplace?tb=mrk">Buy Tether</Link>
							</li>

							<li>
								<Link className="text-black underline underline-offset-4" to="/dashboard/marketplace?tb=mrk">Buy XRP</Link>
							</li>

							<li>
								<Link className="text-black underline underline-offset-4" to="/dashboard/marketplace?tb=mrk">Buy BNB</Link>
							</li>

							<li>
								<Link className="text-black underline underline-offset-4" to="/dashboard/marketplace?tb=mrk">Buy Dogecoin</Link>
							</li>

							<li>
								<Link className="text-black underline underline-offset-4" to="/dashboard/marketplace?tb=mrk">Buy Litecoin</Link>
							</li>

							<li>
								<Link className="text-black underline underline-offset-4" to="/dashboard/marketplace?tb=mrk">Buy Chainlink</Link>
							</li>

							<li>
								<Link className="text-black underline underline-offset-4" to="/dashboard/marketplace?tb=mrk">Buy Pundix</Link>
							</li>

						</ul>
					</div>

				</div>

			</div>
		</section>
	)
}
