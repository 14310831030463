import axios, { AxiosRequestConfig } from "axios"
import { config } from "configs/config"
import { getToken } from "services/utility/Utils/_authServices"

const BaseUrl: string = config("api.baseUrl")

const HTTPClient = axios.create({
	responseType: "json"
})

// request interceptors
HTTPClient.interceptors.request.use((configs) => {
	// check if authorization token is in session

	if (!configs?.url?.includes("csrf-cookie")) {
		configs.params = {
			appVerion: config("app.version"),
			appName: config("app.name"),
			appRelease: config("app.lastReleased")
		}

		if (typeof configs?.url !== "undefined") {
			if (configs?.url?.split(":")?.length > 1) {
				const urlSegment: string[] = configs?.url?.split(":")
				const baseName = urlSegment[0]
				const endpoint = urlSegment[1]

				/* re-assign the endpoint removing the seperator */
				configs.url = endpoint

				switch (baseName) {
					case "paystack":
						/* Set the proposed endpoint base on seperator value */
						configs.baseURL = config("api.paystack")
						break
					default:
				}
			} else {
				configs.baseURL = BaseUrl
			}
		} else {
			configs.baseURL = BaseUrl
		}
	}

	if (configs?.url?.includes("csrf-cookie") && config("env") === "production") {
		configs.baseURL = config("app.baseUrl")
	}

	if (getToken()) {
		if (typeof configs?.headers != "undefined") {
			configs.headers.Authorization = `Bearer ${getToken()}`
		}
	}

	return configs
})

/* response interceptors */
HTTPClient.interceptors.response.use(
	response => {
		return Promise.resolve(response.data)
	},
	error => {
		return Promise.reject(error.response.data)
	}
)

/* export  */
const { get: GET, post: POST, put: PUT, delete: DROP } = HTTPClient
export { GET, POST, PUT, DROP }
