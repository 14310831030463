import DashDesktopContainer from "components/DashDesktopContainer"
import React from "react"
import AddBankAccountModal from "./AddBankAccountModal"
import BuyOfferTab from "./BuyOfferTab"
import SellOfferTab from "./SellOfferTab"

export default function CreateOffer() {
	return (
		<>
			<DashDesktopContainer title="Create Offer" showOnMobile>
				<section className="my-4 bg-white">
					<div>
						<ul className="nav nav-tabs" role="tablist">
							<li className="nav-item" role="presentation">
								<a
									className="nav-link active sell-tab"
									role="tab"
									data-bs-toggle="tab"
									href="#tab-1"
								>
									Selling
								</a>
							</li>
							<li className="nav-item" role="presentation">
								<a className="nav-link buy-tab" role="tab" data-bs-toggle="tab" href="#tab-2">
									Buying
								</a>
							</li>
						</ul>
						<div className="tab-content">
							<SellOfferTab />
							<BuyOfferTab />
						</div>
					</div>
				</section>
			</DashDesktopContainer>

			<AddBankAccountModal />
		</>
	)
}
