import AwaitResponse from "components/AwaitResponse"
import ShowSpinner from "components/misc/Spinner"
import Modal from "components/Modal"
import { ADD_BANK_MODAL } from "flus/constants/modals.id.const"
import React from "react"
import { useMutation } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import { getBankList, setBankList } from "redux/reducers/dashboardSlice"
import { userPaymentMethodApis } from "services/Api/payments/payment.index"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"

export default function AddBankAccountModal() {
	const bankList = useSelector(getBankList)
	const dispatcher = useDispatch()

	const { FetchBanks, AddPaymentMethod } = userPaymentMethodApis()

	const fetchBanksApis = useMutation(FetchBanks, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(setBankList(res?.data))
			}
		}
	})

	const addPaymentMethodApi = useMutation(AddPaymentMethod, {
		onSuccess: (res: any) => {
			if (res?.status) {
				ShowSuccess("Payment Method", "Your payment method has been added successfully!")
			} else ShowServerErrors("Payment Method", res)
		}
	})

	const isAdding = addPaymentMethodApi.isLoading

	const handleFormSubmittion = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		addPaymentMethodApi.mutateAsync(formData)

		e.target.reset()
	}

	return (
		<Modal uniqueId={ADD_BANK_MODAL} title="Add Bank Details">
			<form className="user" onSubmit={handleFormSubmittion}>
				<div className="mb-3">
					<label className="form-label">
						<strong>Bank Name</strong>
					</label>
					<AwaitResponse api={fetchBanksApis.mutateAsync}>
						<select className="form-select form-select-lg" required name="bank_name">
							<option></option>
							{Array.isArray(bankList) && (
								<>
									{bankList.map((bank?: any) => (
										<option value={bank?.name} key={bank?.code}>
											{bank?.name}
										</option>
									))}
								</>
							)}
						</select>
					</AwaitResponse>
				</div>

				<div className="mb-3">
					<label className="form-label">
						<strong>Account Holder</strong>
					</label>
					<input className="form-control form-control-lg form-control-user" type="text" name="account_name" />
				</div>

				<div className="mb-4">
					<label className="form-label">
						<strong>Account Number</strong>
					</label>
					<input className="form-control form-control-lg form-control-user" type="tel" name="account_number" />
				</div>

				<button disabled={isAdding} className="btn btn-primary btn-lg d-block login-btn w-100" type="submit">
					{!isAdding && "Add Bank"}
					{isAdding && <ShowSpinner color="text-light" />}
				</button>
			</form>
		</Modal>
	)
}
