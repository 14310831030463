import PageInfo from "components/misc/Page"
import React from "react"
import PersonalInfoForm from "./PersonalInfoForm"

export default function PersonalInfo() {
	return (
		<section>
			<PageInfo titleText="Update Profile Information" />
			<header>
				<ol className="bk-onboarding-stepper">
					<li className="stepper__item completed" data-step="1"></li>
					<li className="stepper__item" data-step="2"></li>
					<li className="stepper__item" data-step="3"></li>
					<li className="stepper__item" data-step="4"></li>
				</ol>
				<div className="text-center mt-5">
					<h1>
						<strong>Personal Data</strong>
					</h1>
					<p>
						Take note of the required fields <sup className="text-danger">*</sup>
					</p>
					<p className="my-5 text-primary m-auto d-block w-75">
						<strong>
							We recognize both the rights of individuals to protect their personal data , including
							rights of access and correction, and the needs of organizations to collect, use or
							disclose personal data for legitimate and reasonable purposes.
						</strong>
					</p>
				</div>
			</header>
			<section className="d-md-flex justify-content-md-center align-items-md-center">
				<PersonalInfoForm />
			</section>
		</section>
	)
}
