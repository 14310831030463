export const walletEndpoints = {
	FETCH_SPOT_WALLET: "/spot-wallet",
	FETCH_FUNDING_WALLET: "/funding-wallet",
	TRANSFER_COIN: "/coin-transfer",
	RECEIVE_ASSET: "/receive-asset",
	FIND_ASSET: "/find-asset",
	GET_GASE_FEE: "/gas-fee",
	WALLET_WITHDRAWAL: "/place-withdrawal",
	FETCH_ASSETS: "/asset-list",
	FETCH_COIN_ASSETS: "/coin-asset-list"
}
