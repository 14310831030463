const stripString = (url: string): string => {
	return url.split("%20").join(" ").split("+").join(" ")
}
/**
	handle query params
*/
function useUrlQuery(decode: boolean = true): any {
	const search = new URL(window.location.href).searchParams.toString()
	if (!search) return {}

	const params = stripString(decodeURI(search)).split("&")

	const resoults = params.map(query => {
		const splited = query.split("=")
		return { name: splited[0], value: splited[1] }
	})

	const queryString: {
		[key: string]: any
	} = {}

	resoults.forEach(query => {
		if (typeof query.name != "undefined") {
			queryString[query.name] = decode ? decodeURIComponent(query.value) : query.value
		}
	})

	return queryString
}

export { useUrlQuery }
