export const merchantEndpoints = {
	CREATE: "/become-merchant",
	LIMIT: "/set-limit",
	BANK: "/set-bank",
	PRESENCE: "/toggle-online-presence",
	DEACTIVATE: "/deactivate-account",
	// lISTING
	FETCH_ORDERS: "/merchant-order-list",
	FETCH_DEPOSITS: "/merchant-deposit-list",
	FETCH_FUNDINGS: "/merchant-funding-list",
	FETCH_WITHDRAWAL: "/merchant-withdrawal-list",
	DASHBOARD_ANALYSIS: "/merchant-dashboard-analytics",

	deposit: {
		ACCEPT: "/merchant-accept-deposit-order",
		DECLINE: "/merchant-decline-deposit-order",
		LISTEN: "/merchant-listen-deposit-status",
		RELEASE: "/release-deposit-by-merchant"
	},

	withdrawal: {
		ACCEPT: "/merchant-accept-withdrawal-order",
		DECLINE: "/merchant-decline-withdrawal-order",
		UPLOAD: "/merchant-upload-withdrawal-proof",
		LISTEN: "/merchant-listen-withdrawal-status",
		ESCALATE: "/merchant-escalate-withdrawal"
	}
}
