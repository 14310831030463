import DefaultFooter from "components/layout/DefaultFooter"
import React from "react"
import { Outlet } from "react-router-dom"

export default function DefaultLayout() {
	return (
		<>
			<Outlet />
			<DefaultFooter />
		</>
	)
}
