import CardNavigator, { CardNavigatorInterface } from "components/CardNavigator"
import React from "react"
import { useLoaderData, useNavigate, useParams } from "react-router-dom"
import { userWalletApis } from "services/Api/wallet/wallet.index"
import { useFlusStores, useFlusDispatcher, FLUX_DYNAMIC_STORE, FLUX_DYNAMIC_EVENT } from "@bilmapay/react-flux-storage"
import { useMutation } from "react-query"
import ShowSpinner from "components/misc/Spinner"
import { sleep } from "services/utility/Utils/_sleep"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { AssetDetailsTypes, AssetsListItemProps, NetworkProps } from "types/app.types"
import AwaitResponse from "components/AwaitResponse"
import AssetDropdown, { AssetDropdownItems } from "components/input/AssetDropdown"
import NetworkDropdown from "components/input/NetworkDropdown"

const minimumAmountRef = React.createRef<HTMLSpanElement>()

export default function WalletSendCoin({ returnLink, showHistory }: CardNavigatorInterface) {
	const assetList: any = useLoaderData()
	const amountInputRef = React.createRef<HTMLInputElement>()

	const [displayDest, setDisplayDest] = React.useState<boolean>(false)
	const [selectedNetwork, setSelectedNetwork] = React.useState<NetworkProps>()
	const [selectedNetworkAsssetDetails, setselectedNetworkAssetDetails] = React.useState<AssetDetailsTypes>()
	const [userSelectedAsset, setSelectedAsset] = React.useState<AssetDropdownItems>()

	const { assetId } = useParams()

	let assets: Array<AssetDropdownItems> = assetList?.data

	const defaultAsset: AssetDropdownItems = assetList?.data?.find((asset: AssetsListItemProps) => (asset?.asset_id as number) === parseInt(assetId as string))

	const { selectedAsset, assetGasFee, fetchGas } = useFlusStores()
	const { FindAsset, FetchGasFee, WalletWithdrawal } = userWalletApis()
	const dispatcher = useFlusDispatcher()
	const navigate = useNavigate()

	// ---------------------------------------------------------------------------

	const findAssetApi = useMutation(FindAsset, {
		onSuccess: (res: any) => {
			if (res?.status) {
				setselectedNetworkAssetDetails(res?.data)
				setSelectedNetwork(res?.data?.network[0])

				// set min balance
				if (minimumAmountRef?.current) {
					minimumAmountRef.current.innerHTML = res?.data?.network[0]?.minimum_withdrawal
				}

				const network: Array<NetworkProps> = res?.data?.network

				if (network?.length > 0) {
					setDisplayDest(network[0]?.require_dest_tag === "1")
				}

				// dispatche gase fee fetching after 3s
				sleep(300).then(() => {
					dispatcher({
						type: FLUX_DYNAMIC_EVENT,
						payload: { event: "fetchGas", data: true }
					})
				})
			}
		}
	})

	const isFetchingAssets = findAssetApi.isLoading

	const onSelectAssetCallback = React.useCallback((selected: AssetDropdownItems) => {
		setSelectedAsset(selected)

		/* Fetch asset details for the selected asset */
		const fd = new FormData()
		fd.append("asset_id", selected.asset_id)
		findAssetApi.mutateAsync(fd)
	}, [])

	// -------------------------------------------------------------

	const fetchGasFeeApi = useMutation(FetchGasFee, {
		onSuccess: res => {
			if (res?.status) {
				dispatcher({ type: FLUX_DYNAMIC_STORE, payload: { store: "assetGasFee", data: res?.data } })
			}
		}
	})

	const isFetchinGasFee = fetchGasFeeApi.isLoading

	const handleGasfeeFetching = () => {
		if (amountInputRef?.current?.value) {
			if (userSelectedAsset && selectedNetwork) {
				const network: unknown = selectedNetwork?.network_id
				const assetId = userSelectedAsset?.asset_id
				const amount = amountInputRef?.current?.value

				const fd = new FormData()

				fd.append("asset_id", assetId)
				fd.append("amount", amount)
				fd.append("network_id", network as string)

				fetchGasFeeApi.mutateAsync(fd)
			}
		}
	}

	React.useEffect(() => {
		if (fetchGas) {
			handleGasfeeFetching()
			dispatcher({ type: FLUX_DYNAMIC_EVENT, payload: { event: "fetchGas", data: false } })
		}
	})

	const onUserEnterAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e?.target?.value) {
			handleGasfeeFetching()
		}
	}

	// Handle network dropdown select
	const onNetworkChangeCallback = React.useCallback((selected: NetworkProps) => {
		// Set the selected network to state
		setSelectedNetwork(selected)

		setDisplayDest(selected?.require_dest_tag === "1")

		// dispatch gase fee fetching
		sleep(300).then(() => {
			dispatcher({
				type: FLUX_DYNAMIC_EVENT,
				payload: { event: "fetchGas", data: true }
			})
		})

		// Filter through and update min sending amount
		const networkId: number = selected?.network_id

		if (selectedAsset) {
			if (Array.isArray(selectedAsset?.network)) {
				const filteredNetwork = selectedAsset?.network?.find((network: any) => network?.network_id === networkId)

				if (filteredNetwork) {
					if (minimumAmountRef?.current) {
						minimumAmountRef.current.innerHTML = filteredNetwork?.minimum_withdrawal
					}
				}
			}
		}
	}, [])

	// ---------------------------------------------------------------------------

	const placeWithdrawalApi = useMutation(WalletWithdrawal, {
		onSuccess: res => {
			if (res?.status) {
				ShowSuccess("Success", "Your transaction was successfully sent!")

				navigate("/dashboard/spot-wallet")
			} else ShowServerErrors("Error", res)
		}
	})

	const isWithdrawing = placeWithdrawalApi.isLoading

	const handleWithdrawal = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		const networkId: unknown = selectedNetwork?.network_id
		formData.append("asset_id", userSelectedAsset?.asset_id)
		formData.append("network_id", networkId as string)

		placeWithdrawalApi.mutateAsync(formData)
	}

	const handleMaxButton = () => {
		if (amountInputRef?.current) {
			amountInputRef.current.value = selectedAsset?.spot_balance
		}
	}

	// ------------------------------------------------------------------------

	const handleDefaultAssetFetching = async () => {
		const fd = new FormData()
		fd.append("asset_id", defaultAsset?.asset_id)

		onSelectAssetCallback(defaultAsset)

		return await findAssetApi.mutateAsync(fd)
	}

	return (
		<CardNavigator returnLink={returnLink} historyParams={assetId} showHistory={showHistory}>
			<section>
				<h3 className="mb-3">
					<strong>Send</strong>
				</h3>

				<form className="mt-3" onSubmit={handleWithdrawal}>
					<div className="mb-3">
						<span className="text-muted mb-2 d-block">
							<strong className="">Choose a coin to Transfer {isFetchingAssets && <ShowSpinner size={20} />}</strong>
						</span>
						<AssetDropdown onChange={onSelectAssetCallback} options={assets} displayIcon={defaultAsset?.icon} displayText={defaultAsset?.symbol} defaultValue={defaultAsset?.asset_id} />
					</div>

					<div className="mb-3">
						<span className="text-muted mb-2 d-block">
							<strong>Network</strong>
						</span>
						<AwaitResponse api={handleDefaultAssetFetching}>
							<NetworkDropdown onChange={onNetworkChangeCallback} options={selectedNetworkAsssetDetails?.network} displayText={selectedNetworkAsssetDetails?.network[0]?.network_name} defaultValue={selectedNetworkAsssetDetails?.network[0]?.network_id} />
						</AwaitResponse>
					</div>

					{displayDest && (
						<div className="mb-3">
							<span className="text-muted mb-2 d-block">
								<strong>Destination Tag</strong>
							</span>
							<input name="dest_tag" className="form-control form-control-lg bk-bg-light" type="text" />
						</div>
					)}

					<div className="mb-3">
						<span className="text-muted mb-2 d-block">
							<strong>Address</strong>
						</span>
						<input name="address" className="form-control form-control-lg bk-bg-light" type="text" />
					</div>

					<div>
						<div className="form-group">
							<label className="form-label w-100 input-label text-muted">
								<strong>Amount</strong>
								<br />
							</label>
							<input className="form-control form-control-lg bk-bg-light" type="number" step={"any"} name="amount" onBlur={onUserEnterAmount} ref={amountInputRef} />
							<span className="img-icon" onClick={handleMaxButton}>
								<strong>Max</strong>
							</span>
						</div>
						<ul className="list-inline d-flex mt-2">
							<li className="list-inline-item w-100">Balance</li>
							<li className="list-inline-item text-end w-100">
								{selectedNetworkAsssetDetails?.spot_balance} {selectedNetworkAsssetDetails?.symbol}
							</li>
						</ul>
					</div>
					<div className="my-4">
						<h4>
							<strong>Details</strong>
						</h4>
						<p className="text-danger">
							<strong>
								Minimum sending amount is <span ref={minimumAmountRef}>{selectedAsset?.network[0]?.minimum_withdrawal}</span> {selectedAsset && selectedAsset?.symbol} . Sending the amount smaller than this might hinder your coin from reaching the destination wallet.
							</strong>
						</p>
						<p>
							Do not send asset other than the selected asset as it might lead to loosing your coin.
							<br />
							<br />
							Ensure you select the right network to avoid lose of asset.
							<br />
							<br />
							Sending a token from your wallet requires you to enter your pin.
							<br />
						</p>
						{isFetchinGasFee && <ShowSpinner />}
						{!isFetchinGasFee && (
							<div className="table-responsive">
								<table className="table">
									<tbody>
										<tr>
											<td>
												<strong>Receive Amount</strong>
											</td>
											<td>{assetGasFee?.receiving_amount}</td>
										</tr>
										<tr>
											<td>
												<strong>Gas Fee</strong>
											</td>
											<td>
												{assetGasFee?.gas_fee} {selectedAsset?.symbol}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						)}
					</div>
					<div className="my-4">
						<button disabled={isWithdrawing} className="btn btn-primary btn-lg w-100 py-3" type="submit">
							<strong>
								{!isWithdrawing && "Send"}
								{isWithdrawing && <ShowSpinner color="text-light" />}
							</strong>
						</button>
					</div>
				</form>
			</section>
		</CardNavigator>
	)
}
