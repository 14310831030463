import React from "react"
import { Link } from "react-router-dom"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { isActive } from "services/utility/Utils/_authServices"

export default function MainDesktopNav() {
	const { LOGO } = useFlusStores()

	return (
		<nav className="navbar navbar-light navbar-expand-md navigation-clean-button mt-6 bp-desktop-navbar">

			<div className="container flex flex-row items-stretch">

				<div>
					<Link className="navbar-brand flex flex-row items-center justify-center" to="/">
						<img className="w-16" src={LOGO} alt="Beekle" /> <strong className="ml-3 text-3xl">Beekle</strong>
					</Link>

					<button
						className="navbar-toggler bp_mob-toggler"
						data-bs-toggle="collapse"
						data-bs-target="#navcol-1"
					>
						<span className="visually-hidden">Toggle navigation</span>
						<span className="navbar-toggler-icon"></span>
					</button>
				</div>

				<div id="navcol-1" className=" bs-collapse navbar-collapse">
					<ul className="navbar-nav space-x-4 sm:space-x-4 navbar-nav-scroll me-auto">

						<li className="nav-item text-lg">
							<Link className="nav-link" to="/">
								Home
							</Link>
						</li>

						<li className="nav-item text-lg">
							<Link className="nav-link whitespace-nowrap " to="/about">
								About Us
							</Link>
						</li>

						<li className="nav-item text-lg">
							<Link className="nav-link whitespace-nowrap " to="/contact-us">
								Contact Us
							</Link>
						</li>
						<li className="nav-item text-lg">
							<Link className="nav-link " to="/faqs">
								FAQs
							</Link>
						</li>
					</ul>


				</div>

				<span className="navbar-text actions lg-scrren">
					{!isActive() && (
						<>

							<Link
								className="openFont bg-blue-600 whitespace-nowrap  hover:bg-blue-700 w-36 text-white mt-4 font-bold py-[14px] px-12 rounded"
								role="button"
								to="/auth"
							>  Sign in
							</Link>


						</>
					)}

					{isActive() && (

						<Link
							className="btn btn-light action-button bp_signup-cta"
							role="button"
							to="/dashboard"
						>
							Dashboard
						</Link>
					)}
				</span>


			</div>

		</nav>
	)
}
