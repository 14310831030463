import React from "react"
import { useLoaderData } from "react-router-dom"
import { userMarketplaceApis } from "services/Api/marketplace/marketplace.index"
import { toggleMarketplaceTab } from "services/utility/Utils/utilities"
import { uniqueId } from "services/utility/Utils/Utils"
import { AssetsListItemProps, BackendResponse } from "types/app.types"
import { useMutation } from "react-query"
import { ShowServerErrors } from "services/utility/Utils/_toaster"
import { FLUX_DYNAMIC_STORE, useFlusDispatcher } from "@bilmapay/react-flux-storage"
import { useDispatch } from "react-redux"
import { setDashboardState } from "redux/reducers/dashboardSlice"

interface MarketplaceTabsProps {
	type: "buy-tab-item" | "sell-tab-item"
}
export default function MarketplaceTabs({ type = "buy-tab-item" }: MarketplaceTabsProps) {
	const assetList: any = useLoaderData()
	const dispatcher = useDispatch()

	const { data }: { data: Array<AssetsListItemProps> } = assetList

	const { FetchBuyMarketplace, FetchSellMarketplace } = userMarketplaceApis()

	const fetchBuyAssets = useMutation(FetchBuyMarketplace, {
		onSuccess: (res: BackendResponse | any) => {
			if (res?.status) {
				dispatcher(setDashboardState({ key: "marketplaceBuyAssets", value: res?.data }))
			} else ShowServerErrors("Error", res)
		}
	})

	const fetchSellAssets = useMutation(FetchSellMarketplace, {
		onSuccess: (res: BackendResponse | any) => {
			if (res?.status) {
				dispatcher(
					setDashboardState({
						key: "marketplaceSellAssets",
						value: res?.data
					})
				)
			} else ShowServerErrors("Error", res)
		}
	})

	const isFetching = fetchBuyAssets.isLoading || fetchSellAssets.isLoading

	const handleClick = (e: React.ChangeEvent<HTMLButtonElement>, assetId?: string) => {
		toggleMarketplaceTab(e)
		dispatcher(setDashboardState({ key: "mk_dsk_assetId", value: assetId }))

		if (type === "buy-tab-item") {
			fetchBuyAssets.mutateAsync({ assetId })
		}

		if (type === "sell-tab-item") {
			fetchSellAssets.mutateAsync({ assetId })
		}
	}

	return (
		<section className="bk__tab-items">
			{data?.map((asset: AssetsListItemProps) => (
				<button disabled={isFetching} key={uniqueId()} className={`btn btn-light bk__tab-item ${type}`} type="button" onClick={(e: any) => handleClick(e, asset?.asset_id)}>
					{asset.symbol}
				</button>
			))}
		</section>
	)
}
