import AvailableBalanceCard from "components/wallet/AvailableBalanceCard"
import DashDesktopContainer from "components/DashDesktopContainer"
import SpotwalletCoinCard from "components/wallet/SpotwalletCoinCard"
import React from "react"
import { useLoaderData } from "react-router-dom"
import DashSearchForm from "components/layout/nav/DashSearchForm"
import FiatBalanceCard from "components/wallet/FiatBalanceCard"
import BuyAssetModal from "components/wallet/BuyAssetModal"
import { useDispatch } from "react-redux"
import { toggleBuyAssetModal, toggleSellAssetModal } from "redux/reducers/modalSlice"
import SellAssetModal from "components/wallet/SellAssetModal"

export default function SpotWallet() {
	const spotWalletData: any = useLoaderData()

	const data = spotWalletData?.data

	const dispatcher = useDispatch()

	const _toggleBuyAssetModal = () => dispatcher(toggleBuyAssetModal(true))
	const _toggleSellAssetModal = () => dispatcher(toggleSellAssetModal(true))
	return (
		<>
			<DashDesktopContainer title="Spot Wallet" page="spot_wallet" showOnMobile>
				<section className="bk__spot-wallet-content">
					<AvailableBalanceCard balance={data?.balance} />
					<FiatBalanceCard balance={data?.fait} />
					<div className="p-3 bg-white rounded shadow-sm my-3 grid xs:grid-cols-1 md:grid-cols-3 gap-4">
						<div className="col-span-2 text-center py-3">
							<h1 className="p-1 font-extrabold xs:text-md md:text-lg text-primary">Instant Trade Channel</h1>
						</div>
						<div className="col-span-1 flex items-center gap-2">
							<button onClick={_toggleBuyAssetModal} type="button" className="border-1 border-blue-500 text-blue-500 p-3 w-full hover:bg-blue-500 hover:text-white transition ease-in duration-300">
								BUY
							</button>
							<button onClick={_toggleSellAssetModal} type="button" className="border-1 border-blue-500 text-blue-500 p-3 w-full hover:bg-blue-500 hover:text-white transition ease-in duration-300">
								SELL
							</button>
						</div>
					</div>
					<div className="row">
						<SpotwalletCoinCard />
					</div>
				</section>
			</DashDesktopContainer>

			{/* <DashSearchForm page="spot_wallet" /> */}
			<BuyAssetModal />
			<SellAssetModal />
		</>
	)
}
