import { Fragment, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import { useDispatch, useSelector } from "react-redux"
import { getDeactivateAccountModal, toggleDeactivateAccountModal } from "redux/reducers/modalSlice"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { merchantAccDeactivateValidatorScheme } from "libs/validator/miscValidators"
import { useMutation } from "react-query"
import { DeactivateMerchantAccount } from "services/Api/merchant/merchant.apis"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { useNavigate } from "react-router-dom"
import ShowSpinner from "components/misc/Spinner"

type InitFormDataProps = {
	reason: string
	password: string
}
export default function DeactivateAccountModal() {
	const dispatcher = useDispatch()
	const open = useSelector(getDeactivateAccountModal)
	const router = useNavigate()
	const setOpen = (state: boolean) => dispatcher(toggleDeactivateAccountModal(state))

	const cancelButtonRef = useRef(null)

	const deactivateAccountApi = useMutation(DeactivateMerchantAccount, {
		onSuccess(res: any) {
			if (res?.status) {
				ShowSuccess("Success", res?.message)
				router("/dashboard")
			} else ShowServerErrors("Error", res)
		}
	})
	const isLoading = deactivateAccountApi.isLoading

	const InitFormData: InitFormDataProps = {
		reason: "",
		password: ""
	}

	const _handleSubmit = (values: InitFormDataProps) => deactivateAccountApi.mutateAsync(values)

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
				<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
								<div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
									<div className="sm:flex sm:items-start">
										<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
										</div>
										<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
											<Dialog.Title as="h3" className="text-start font-semibold leading-6 text-gray-900">
												Deactivate account
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-sm text-gray-500">
													Your account will be disabled and you will no longer be matched to any user’s order. Please note that you might need to Contact Us or visit our office to reactivate your
													account.
												</p>
											</div>
										</div>
									</div>
									<hr className="my-3" />

									<Formik initialValues={InitFormData} onSubmit={_handleSubmit} validationSchema={merchantAccDeactivateValidatorScheme}>
										{({ handleChange, values }) => (
											<Form className="space-y-6">
												<div className="relative mb-4">
													<label htmlFor="form_reasonInput" className="form-label w-100 input-label">
														Reason
													</label>

													<Field id="form_reasonInput" name="reason" as="textarea" value={values?.reason} onChange={handleChange} className="form-control form-control-lg form-control-user" />
													<ErrorMessage name="reason" component={"p"} className="text-red-600 mt-2 p-2" />
												</div>

												<div className="relative mb-4" data-te-input-wrapper-init>
													<label htmlFor="form_passwordInput" className="form-label w-100 input-label">
														Password
													</label>
													<Field id="form_passwordInput" name="password" type="password" value={values?.password} onChange={handleChange} className="form-control form-control-lg form-control-user" />

													<ErrorMessage name="password" component={"p"} className="text-red-600 mt-2 p-2" />
												</div>

												<button
													disabled={isLoading}
													className="bg-red-500 disabled:bg-red-500/50 mb-3 inline-block w-fit rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
													type="submit"
													data-te-ripple-init
													data-te-ripple-color="light"
												>
													{isLoading && <ShowSpinner />}
													{!isLoading && "Deactivate"}
												</button>
											</Form>
										)}
									</Formik>
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
										onClick={() => setOpen(false)}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
