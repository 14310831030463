import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { generalApis } from "services/Api/general/gen.index"
import { useMutation } from "react-query"
import { TestimonialInterface } from "types/app.types"
import AwaitResponse from "components/AwaitResponse"
import { uniqueId } from "services/utility/Utils/Utils"
import classNames from "classnames"
import "../../morecss.css"

function ReviewsSection() {
	const { TestifyerSlide1, ig, facebook } = useFlusStores()
	
	const [testimonials, setTestimonials] = React.useState<Array<TestimonialInterface>>([])

	const { FetchTestimonials } = generalApis()
	const fetchTestimonialsApi = useMutation(FetchTestimonials, {
		onSuccess(res: any) {
			if (res?.status) {
				setTestimonials(res?.data)
			}
		}
	})
	return (
		<section className="bp_home-section-006 py-4 bg-gray h-[38rem]">
			<div className="container">
				<h3 className="text-capitalize my-3 text-lg sm:text-xl text-center">Testimonials</h3>
				<p className=" mb-4 text-center text-3xl sm:text-4xl font-link">People Who Already Love us</p>
				
				<AwaitResponse api={fetchTestimonialsApi.mutateAsync}>
					{testimonials?.length > 0 && (
						<>
							{/* Desktop display */}
							<div id="carousel-2" className="carousel slide testimony-slider" data-bs-ride="carousel">

								<div className="carousel-inner">
									{testimonials?.map((item, index) => (
										<div key={uniqueId()} className={classNames({ "carousel-item": true, active: index === 0 })}>
											<div className="card card-bordered card-shadow">
												<div className="card-body">
													<img className="rounded-circle img-fluid block m-auto" src={item?.img ? item?.img : TestifyerSlide1} alt="" width="75" height="75" />
													<h4 className="text-center openFont mb-0">{item?.testifier_name}</h4>
													<h6 className="mb-2 text-muted openFont text-center">{item?.caption}</h6>
													<p className="card-text openFont text-center">{item?.article}</p>
												</div>
											</div>
										</div>
									))}
								</div>

								<div>
									<a className="carousel-control-prev" href="#carousel-2" role="button" data-bs-slide="prev">
										<span className="carousel-control-prev-icon" aria-hidden="true"></span>
										<span className="visually-hidden">Previous</span>
									</a>
									<a className="carousel-control-next" href="#carousel-2" role="button" data-bs-slide="next">
										<span className="carousel-control-next-icon" aria-hidden="true"></span>
										<span className="visually-hidden">Next</span>
									</a>
								</div>

								<ol className="carousel-indicators ">
									{testimonials?.map((slide, to) => (
										<li key={uniqueId()} className={classNames({ active: to === 0 })} data-bs-target="#carousel-2" data-bs-slide-to={to}></li>
									))}
								</ol>

							</div>

							{/* Desktop display */}


							<div className="row d-lg-flex space-x-12 justify-content-lg-center align-items-lg-center testimony-row w-100">
								
								{testimonials.map(item => (

									<div key={uniqueId()} className="p-10 bg-white w-full md:w-1/3 transform hover:scale-110  shadow-xl transition duration-300">
										{/* <div className="card-body"> */}
											<img className="mb-4 rounded-circle img-fluid block m-auto" src={item?.img} alt="" width="75" height="75" />

											
											{/* <h6 className="mb-2 text-muted text-center">{item?.caption}</h6> */}
											<p className="h-36 px-10 openFont card-text text-center">{item?.article}</p>
											<p className="text-center openFont mb-0">{item?.testifier_name}</p>
										{/* </div> */}
									</div>

								))}
							</div>

						</>
					)}
				</AwaitResponse>
			</div>
		</section>
	)
}

export default ReviewsSection
