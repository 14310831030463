import { ClipboardIcon } from "@heroicons/react/24/outline"
import { copyText } from "libs/copy"
import { NAIRA_SIGN } from "libs/currency"
import React from "react"
import { formatAmount } from "services/utility/Utils/utilities"
import { DepositStatusInterface } from "types"

type PayeeDetailsProps = {
	data?: DepositStatusInterface
	onMadePayment?: () => void
}
export default function PayeeDetails({ data, onMadePayment }: PayeeDetailsProps) {
	return (
		<div>
			<div className="px-4 sm:px-0">
				<p className="text-3xl font-semibold text-gray-900 text-center">{data?.merchant_name} Merchant</p>
				<p className="max-w-2xl text-sm leading-6 text-gray-500 text-center">
					You've been successfully matched with one of our <strong className="text-green-500">verified</strong> merchants
				</p>
			</div>
			<div className="mt-6 border-t border-gray-100">
				<dl className="divide-y divide-gray-100">
					<div className="px-4 py-1 sm:grid sm:gap-4 sm:px-0">
						<dd className="text-lg font-bold leading-6 text-gray-700">{data?.account_name}</dd>
					</div>
					<div className="px-4 py-1 sm:grid sm:gap-4 sm:px-0">
						<dd className="text-lg font-bold leading-6 text-gray-700">{data?.bank_name}</dd>
					</div>

					<div className="px-4 py-1 sm:grid sm:gap-4 sm:px-0">
						<dd className="text-lg font-bold leading-6 text-gray-700 flex gap-2 items-center">
							{data?.account_number}
							<ClipboardIcon className="w-5 h-5 cursor-pointer" onClick={() => copyText(data?.account_number as string)} />
						</dd>
					</div>

					<div className="px-4 py-6 sm:grid sm:gap-4 sm:px-0">
						<dd className="text-md font-bold text-red-700 italic">
							Make a transfer of {NAIRA_SIGN}
							{formatAmount(data?.total_amount)} to the merchant account to proceed.
						</dd>
					</div>

					<div className="px-4 py-6 sm:grid sm:gap-4 sm:px-0">
						<button onClick={onMadePayment} className="w-full rounded-md p-3 bg-blue-500 text-white">
							I've made payment
						</button>
					</div>
				</dl>
			</div>
		</div>
	)
}
