import React from "react"

interface ToggleModalPayloadProps {
	ref: React.RefObject<HTMLDivElement>
}
export const AppMiddlewares = {
	app: {
		toggleModal(state: any, payload: ToggleModalPayloadProps) {
			if (payload?.ref) {
				if (payload?.ref?.current) {
					payload?.ref?.current?.classList?.toggle("hide")
				}
			}

			return state
		}
	}
}
