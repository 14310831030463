import React from "react";
import {
    Typography,
    Card,
    CardHeader,
    CardBody,
} from "@material-tailwind/react";

import axios from 'axios';
import { PhotoIcon, InformationCircleIcon } from '@heroicons/react/24/solid'
import { ShowError, ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import {
    ArrowUpIcon,
    GiftIcon,
} from "@heroicons/react/24/outline";

import { useState, useEffect, useRef } from "react";
import emailjs from '@emailjs/browser';

export function GiftCard() {

    const form = useRef();
    const formData = new FormData();

    const [bank_name, setBank_name] = useState('');
    const [acc_number, setacc_number] = useState('');
    const [acc_name, setacc_name] = useState('');

    // const [base_url] = useState('https://api.beekle.io')
    const [base_url] = useState('https://sandbox.beekle.io')

    const [currency, setCurrency] = useState('');
    const [files, setFiles] = useState([]);
    const [ALL_GIFT_CARD, SET_ALL_GIFT_CARD] = useState([]);

    const [Selected_Rate, Set_Selected_Rate] = useState(1)

    const [category_name, setcategory_name] = useState('');
    const [form_name, setform_name] = useState('');
    const [country_name, setcountry_name] = useState('');
    const [gft_name, setgft_name] = useState('GIFT CARD NAME');
    const [comments, setcomments] = useState('');
    const [amount, setamount] = useState('');
    const [total_amount, settotal_amount] = useState('');
    const [payment_method, setpayment_method] = useState('');
    const [subCategory, setSubCategory] = useState([]);

    const [historyData, sethistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [IsUpLoading, setIsUpLoading] = useState(false);

    const [selectedImage, setSelectedImage] = useState();
    const [HasSelectedImage, setHasSelectedImage] = useState(false);
    const [hasBanks, SetHasBanks] = useState(false);

    const [listBanks, SetListBanks] = useState();
    const [showBanksList, SetShowBanksList] = useState(false);





    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0]);
        }
    };

    const getAllBanks = () => {

        const apiUrl = "https://api.paystack.co/bank"

        axios
            .get(
                apiUrl, {
                headers: {
                    'Accept': 'application/json',
                },
            })
            .then((response) => {
                if (response.data.data) {
                    SetShowBanksList(true)

                    SetListBanks(response.data.data);
                }

            })
            .catch((err) => {
            });
    }

    const removeSelectedImage = () => {
        setSelectedImage();
    };



    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        setFiles([...files, ...selectedFiles]);
        setHasSelectedImage(true);
    };

    const handleSubCatSelectChange = (id) => {

        setgft_name(ALL_GIFT_CARD[Number(id) - 1].name); Set_Selected_Rate(ALL_GIFT_CARD[Number(id) - 1].rate);

    };

    useEffect(() => {

        FetchAll();
        FetchSecond();
        getBank();
        getAllBanks();

    }, []);

    const FetchSecond = () => {

        const profileURL = base_url + "/api/profile";

        // Make the GET request using Axios

        axios
            .get(profileURL, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("api_token")}`,
                    'Accept': 'application/json',
                },
            })
            .then((response) => {
                axios
                    .get(`${base_url}/api/yourgiftcard/${response.data.data.user_id}`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem("api_token")}`,
                            'Accept': 'application/json',
                        },
                    })
                    .then((response) => {
                        // sethistoryData(response.data.data.slice((response.data.data.length - 10), response.data.data.length));
                        if (response.data.data !== undefined) {
                            sethistoryData(response.data.data);
                        }

                        setLoading(false);
                    })
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    });
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });

    }

    const FetchAll = () => {
        const apiUrl = "https://65204081906e276284c441ae.mockapi.io/giftcard";

        axios
            .get(
                apiUrl, {
                headers: {
                    'Accept': 'application/json',
                },
            })
            .then((response) => {
                SET_ALL_GIFT_CARD(response.data);
            })
            .catch((err) => {
            });
    }

    const clearFields = () => {
        setFiles([]);

        setcategory_name('');
        setform_name('');
        setcountry_name('');
        setgft_name('');
        setcomments('');
        setamount('');
        settotal_amount('');
        setpayment_method('');
    }

    const changeCardSubCategory = (value) => {

        let stuff_val = value.toLowerCase().replace(/\s/g, '');

        setSubCategory(ALL_GIFT_CARD.filter((card) => card.category?.toLowerCase().includes(stuff_val)));

    }

    const getBank = () => {

        const apiUrl = base_url + "/api/payment-methods"

        axios
            .get(
                apiUrl, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("api_token")}`,
                },
            })
            .then((response) => {
                if (response.data.data) {

                    SetHasBanks(true);
                    setBank_name(response.data.data[0].account_name + "  " + response.data.data[0].bank_name);
                    setacc_number(response.data.data[0].account_number);

                }

            })
            .catch((err) => {
            });
    }


    const submitCard = async (e) => {

        e.preventDefault();

        if (files.length === 0) {
            alert('Please select one or more files to upload.');
            return;
        }

        if (category_name.trim().length === 0 || form_name.trim().length === 0 || country_name.trim().length === 0 || gft_name.trim().length === 0 || payment_method.trim().length === 0) {
            alert('Please Fill All Required Fields');
            return;
        }
        setIsUpLoading(true);

        // const formData = new FormData();

        for (const file of files) {
            formData.append('file_name[]', file);
        }

        formData.append('category_name', category_name);
        formData.append('form_name', form_name);
        formData.append('country_name', country_name);
        formData.append('gft_name', gft_name);
        formData.append('giftcard', gft_name);
        formData.append('comments', comments);
        formData.append('amount', amount);
        
        formData.append('total_amount', total_amount);
        formData.append('payment_method', payment_method);

        formData.append('bank_name', bank_name + " " + acc_name);
        formData.append('bank_account_number', acc_number);


        for (var pair of formData.entries()) {
        }


        try {
            const response = await axios.post(
                base_url + '/api/send-giftcard',

                formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem("api_token").toString()}`,
                    'Accept': 'application/json',
                },
            });


            if (response.status === 200 || response.status === 201) {
                ShowSuccess("Gift Card Uploaded", response.data.message);
                // sendEmailNotification(e);
                clearFields();
                setIsUpLoading(false);
                FetchSecond();
            } else {
                ShowError('Failed to Upload Gift Card');
                setIsUpLoading(false)
            }
        } catch (error) {
            setIsUpLoading(false)
            // console.error('Error uploading file:', error);
        }

    };



    return (

        // <form ref={form}>

        <div className="mb-4 grid grid-cols-1 gap-6 xl:grid-cols-3">
            <Card className="overflow-hidden xl:col-span-2">

                <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className="m-0 flex items-center justify-between p-6"
                >

                    <div>
                        <Typography variant="h3" color="blue-gray" className="mb-1">
                            Trade Gift Card
                        </Typography>
                        <Typography
                            variant="small"
                            className="flex items-center gap-1 font-normal text-blue-gray-600"
                        >
                            <strong>Please kindly provide the information of your gift card </strong>
                        </Typography>
                    </div>


                </CardHeader>

                <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">

                    <form className="px-3 sm:px-4 lg:px-10">
                        <div className="border-b border-gray-900/10 pb-12">

                            <div className="w-full my-4">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Select Category
                                </label>
                                <div className="mt-2">
                                    <select
                                        onChange={(e) => { setcategory_name(e.target.value); changeCardSubCategory(e.target.value) }}
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="px-3 w-full  h-10 rounded-md border-1 py-1.5 text-black shadow-sm ring-2  ring-blue-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    >
                                        <option>Select Category</option>
                                        <option>Amazon</option>
                                        <option>American Express</option>
                                        <option>Amex SERVE</option>
                                        <option>Best Buy</option>
                                        <option>eBay</option>
                                        <option>Footlocker</option>
                                        <option>GameStop</option>
                                        <option>Google Play</option>
                                        <option>iTunes/Apple</option>
                                        <option>JC Penny Sephora</option>
                                        <option>Macy's</option>
                                        <option>Nike</option>
                                        <option>NordStorm</option>
                                        <option>Off Gamers</option>
                                        <option>PlayStation</option>
                                        <option>Razer Gold</option>
                                        <option>Roblox </option>
                                        <option>Saks</option>
                                        <option>Sephora</option>
                                        <option>Steam</option>
                                        <option>Target</option>
                                        <option>Target Visa</option>
                                        <option>Vanilla</option>
                                        <option>Visa Gift card</option>
                                        <option>Walmart</option>
                                        <option>Walmart Visa</option>
                                        <option>XBOX</option>
                                    </select>
                                </div>
                            </div>


                            <div className="my-4">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Select a Sub-Category
                                </label>
                                <div className="mt-2">
                                    <select
                                        onChange={(e) => { handleSubCatSelectChange(e.target.value); }}
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="px-3  h-10  block w-full rounded-md border-1 py-1.5 text-black shadow-sm ring-2 ring-inset ring-blue-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    >
                                        <option key={'XYZ'} value={0}> Select Sub-Category </option>
                                        {subCategory.map(
                                            (value) => (
                                                <option key={value.id} value={value.id}> {value.name} - {value.rate} </option>
                                            ))}
                                    </select>
                                </div>
                            </div>


                            <div className="w-full">
                                <label htmlFor="giftcard-form" className="block text-sm font-medium leading-6 text-gray-900">
                                    Forms
                                </label>
                                <div className="mt-2">
                                    <select
                                        onChange={(e) => setform_name(e.target.value)}
                                        id="giftcard-form"
                                        name="giftcard-form"
                                        autoComplete="giftcard-form"
                                        className="px-3 h-10 block w-full rounded-md border-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    >
                                        <option>Select Gift Card Form</option>
                                        <option>Physical</option>
                                        <option>ECode</option>
                                    </select>
                                </div>
                            </div>



                            <div className="flex-auto flex-row flex-wrap  w-full  ">

                                <div className="my-6">
                                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                        Country
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            onChange={(e) => setcountry_name(e.target.value)}
                                            id="country"
                                            name="country"
                                            autoComplete="country-name"
                                            className="px-3  h-10 block w-full rounded-md border-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                        >
                                            <option>Select Country</option>
                                            <option>USA ($) </option>
                                            <option>UK (£)</option>
                                            <option>EURO (€) </option>
                                            <option>CANADA (CAD)</option>
                                            <option>OTHERS</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            {
                                HasSelectedImage ?
                                    <>
                                        <div className="flex flex-row space-x-2 mb-6" >

                                            {files.map(imgFile => (
                                                <div style={styles.preview}>
                                                    <img
                                                        src={URL.createObjectURL(imgFile)}
                                                        style={styles.image}
                                                        alt="img"
                                                    />
                                                </div>
                                            ))}

                                            {/* <button className="text-lg" onClick={removeSelectedImage} >  Remove </button> */}
                                        </div>
                                    </> : <></>
                            }


                            <div className="flex-auto flex-row flex-wrap  w-full ">

                                <div className="">

                                    <div className="col-span-full">
                                        <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                            Gift Card Photo
                                        </label>
                                        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                            <div className="text-center">
                                                <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                    <label
                                                        htmlFor="file-upload"
                                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                    >
                                                        <span>Upload a file</span>
                                                        <input id="file-upload" name="file-upload" className="sr-only" type="file" multiple onChange={handleFileChange} />
                                                    </label>
                                                    <p className="pl-1">or drag and drop</p>
                                                </div>
                                                <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div className="flex-auto flex-row flex-wrap  w-full sm:w-1/2  ">

                            <div className="my-6">

                                <div className="flex flex-row">
                                    <label htmlFor="amount" className="block text-sm font-medium leading-6 text-gray-900">
                                        Gift Card Amount
                                    </label>
                                </div>

                                <div className="mt-2  border-1">
                                    <input inputmode="numeric" max="100000" onChange={(e) => { setamount(e.target.value); settotal_amount(e.target.value); }} type="number" value={amount} className="w-full  px-3 removearrow block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-inset ring-gray-300  placeholder:text-gray-400 sm:text-sm sm:leading-6" />
                                </div>
                            </div>
                        </div>


                        <div className="flex-auto flex-row flex-wrap  w-full  ">

                            <div className="my-6">

                                <div className="flex flex-row">
                                    <label htmlFor="currency" className="block text-sm font-medium leading-6 text-gray-900">
                                        Payment Method
                                    </label>
                                    {/* <InformationCircleIcon className=" ml-4 mt-1 h-4 w-4 text-black" aria-hidden="true" /> */}
                                </div>

                                <div className="mt-2 ">
                                    <select
                                        onChange={(e) => { setCurrency(e.target.value); setpayment_method(e.target.value); }}
                                        id="currency"
                                        name="currency"
                                        autoComplete="currency"
                                        className="px-3 h-10 block w-full rounded-md border-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    >
                                        <option>Select Payment Method</option>
                                        <option>NAIRA</option>
                                        <option>BITCOIN</option>
                                        <option>USDT</option>
                                    </select>
                                </div>
                            </div>
                        </div>



                        {
                            hasBanks ?
                                <>

                                </> :

                                <>

                                    <div className="mb-3">
                                        <label className="form-label">
                                            <strong>Bank Name</strong>
                                        </label>

                                        {showBanksList ? <select onChange={(e) => { setBank_name(e.target.value); }} className="form-select form-select-lg" required name="bank_name">
                                            <option>Select Bank</option>

                                            {listBanks.map((bank) => (
                                                <option value={bank?.name} key={bank?.id}>
                                                    {bank?.name}
                                                </option>
                                            ))}

                                        </select> : <></>}

                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">
                                            <strong>Account Name</strong>
                                        </label>
                                        <input onChange={(e) => { setacc_name(e.target.value); }} name="account_name" required className="form-control form-control-lg" type="text" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">
                                            <strong>Account Number</strong>
                                        </label>
                                        <input onChange={(e) => setacc_number(e.target.value)} name="account_number" required className="form-control form-control-lg" type="text" />
                                    </div>
                                </>
                        }


                        <div class="content-area card border-primary my-4">
                            <div class="card-header">{gft_name}</div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <strong>Payout Method:</strong>
                                    <span class="float-right"><strong>{payment_method}</strong></span></li>
                                <li class="list-group-item"><strong>Rate * Amount:</strong> <span class="float-right">
                                    {amount} * {Selected_Rate}
                                </span></li>
                                <li class="list-group-item"><strong>Total:</strong> <strong class="float-right">
                                    ₦ {total_amount * Selected_Rate}
                                </strong></li> </ul></div>



                        <div className="flex-auto flex-row flex-wrap  w-full  ">

                            <div className="">

                                <div className="col-span-full">
                                    <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                        Comments (Optional)
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            onChange={(e) => setcomments(e.target.value)}
                                            id="about"
                                            name="about"
                                            rows={3}
                                            className="block p-2 w-full rounded-md border-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            defaultValue={''}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button disabled={IsUpLoading} onClick={(e) => submitCard(e)} type="submit"
                            className="mt-6 w-40 flex flex-end h-12 items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >

                            {IsUpLoading ? "...uploading" : "SUBMIT"}
                        </button>


                    </form>
                </CardBody>

            </Card>

            <Card>
                <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className="m-0 p-6"
                >
                    <Typography variant="h6" color="blue-gray" className="mb-2">
                        Trade Overview
                    </Typography>
                    <Typography
                        variant="small"
                        className="flex items-center gap-1 font-normal text-blue-gray-600"
                    >
                        <ArrowUpIcon
                            strokeWidth={3}
                            className="h-3.5 w-3.5 text-green-500"
                        />

                        Last <strong>ten (10)</strong> transactions
                    </Typography>
                </CardHeader>

                {
                    loading ? <p className="ml-4">Loading Previous Transactions...</p> :

                        historyData.length === 0 ?
                            <p className="ml-4">No Previous Gift Card Transaction</p> :
                            <CardBody className="pt-0">
                                {historyData.map(
                                    ({ country_name, category_name, amount, gft_name, gft_id, created_at, giftcard_status }, key) => (
                                        <div key={gft_id} className="flex items-start gap-4 py-3">

                                            <div
                                                className={`relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] ${key === historyData.length - 1
                                                    ? "after:h-0"
                                                    : "after:h-4/6"
                                                    }`}
                                            >

                                                {React.createElement(GiftIcon, {
                                                    className: `!w-5 !h-5 text-grey-500`,
                                                })}

                                            </div>


                                            <div>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="block font-medium"
                                                >
                                                    {country_name} - {amount} - {category_name} {gft_name}
                                                </Typography>


                                                <p
                                                    as="span"
                                                    variant="small"
                                                    className={`text-xs font-medium text-blue-gray-500 
                                                    ${giftcard_status === 'pending' ? "text-black-700"
                                                            : giftcard_status === 'completed' ? "text-green-700"
                                                                : giftcard_status === 'rejected' ? "text-red-800" : "text-black"
                                                        }`}
                                                // className="text-xs font-medium text-blue-gray-500"
                                                >
                                                    {/* {created_at} -  */}
                                                    {giftcard_status}

                                                </p>
                                            </div>
                                        </div>
                                    )
                                )}
                            </CardBody>

                }

            </Card>



        </div>


        // </form> 
    );
}

export default GiftCard;



const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 50,
    },
    preview: {
        marginTop: 10,
        display: "flex",
        flexDirection: "row",
    },
    image: { maxWidth: "100%", },

};
