import classNames from "classnames"
import React from "react"

interface CustomModalProps {
	children: React.ReactNode

	modalId: string
	title?: string
}

export default function CustomModal({ title, modalId, children }: CustomModalProps) {
	return (
		<div id={modalId} className="modal fade bk__modal" role="dialog" tabIndex={-1}>
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						{title && <h4>{title}</h4>}
						<button
							className="btn-close"
							type="button"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div className="modal-body">{children}</div>
				</div>
			</div>
		</div>
	)
}
