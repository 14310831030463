import DashDesktopContainer from "components/DashDesktopContainer"
import IconCircleInfo from "components/icons/IconCircleInfo"
import { NAIRA_SIGN } from "libs/currency"
import React from "react"
import { useLoaderData } from "react-router-dom"
import { MerchantDashboardAnalysisInterface } from "types"

export default function MerchantDashboard() {
	const data = useLoaderData() as any
	const _data: MerchantDashboardAnalysisInterface = data?.data
	return (
		<DashDesktopContainer title="Merchant Dashboard" showSearch={false} showOnMobile>
			<div className="bg-white p-5">
				<div className="grid gap-4 xs:grid-cols-1 md:grid-cols-2 mb-4">
					{/* Wallet balance  */}
					<div className="px-5 py-4 shadow-sm rounded-md w-full bg-gradient-to-r from-primary to-cyan-500 transition ease-in duration-300">
						<h1 className="font-extrabold text-sm mb-2 text-white">Wallet Balance</h1>
						<h2 className="font-normal text-2xl text-gray-200">
							{NAIRA_SIGN}
							{_data?.wallet_balance}
						</h2>
					</div>
					<div className="group p-3 shadow-sm rounded-md w-full border border-gray-200 hover:bg-gradient-to-r hover:from-primary hover:to-cyan-500 transition ease-in duration-300">
						<h1 className="font-extrabold text-sm mb-2 text-gray-500 group-hover:text-white">Funding</h1>
						<h2 className="font-normal text-2xl text-blue-500 group-hover:text-gray-200">
							{NAIRA_SIGN}
							{_data?.funding}
						</h2>
					</div>

					<div className="group p-3 shadow-sm rounded-md w-full border border-gray-200 hover:bg-gradient-to-r hover:from-primary hover:to-cyan-500 transition ease-in duration-300">
						<h1 className="font-extrabold text-sm mb-2 text-gray-500 group-hover:text-white">Deposit</h1>
						<h2 className="font-normal text-2xl text-blue-500 group-hover:text-gray-200">
							{NAIRA_SIGN}
							{_data?.deposit}
						</h2>
					</div>

					<div className="group p-3 shadow-sm rounded-md w-full border border-gray-200 hover:bg-gradient-to-r hover:from-primary hover:to-cyan-500 transition ease-in duration-300">
						<h1 className="font-extrabold text-sm mb-2 text-gray-500 group-hover:text-white">Withdrawn</h1>
						<h2 className="font-normal text-2xl text-blue-500 group-hover:text-gray-200">
							{NAIRA_SIGN}
							{_data?.withdrawn}
						</h2>
					</div>
				</div>
				<hr className="my-5 bg-gray-400" />

				<div className="">
					<IconCircleInfo width={"1.5em"} height={"1.5em"} className="inline mr-1" />
					<p className="inline text-justify xs:text-sm md:text-md">
						Merchant dashboard Our merchant option allows users to easily transact fiat currency and use it to purchase their desired cryptocurrency. This option provides a simple and secure way to
						deposit and withdraw fiat currency, ensuring that users can easily fund their accounts and access their funds when needed. With this option, users can quickly buy cryptocurrency with their
						fiat currency, enabling them to participate in the exciting world of digital assets. Our goal is to make it as easy as possible for users to enter and exit the cryptocurrency market, while
						ensuring that their transactions are secure and reliable.
					</p>
				</div>
			</div>
		</DashDesktopContainer>
	)
}
