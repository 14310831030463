import React from "react"
import { uniqueId } from "services/utility/Utils/Utils"
import { AssetsListItemProps, NetworkProps } from "types/app.types"

export interface AssetDropdownItems extends AssetsListItemProps {
	network: Array<NetworkProps>
}

export interface AssetDropDownProps<DropdownItem> {
	/**
	 * An array of list options to be display on the dropdown
	 */
	options: Array<DropdownItem>
	/**
	 * The underlaying input name for the dropdown
	 */
	name?: string
	onChange?: (params: DropdownItem) => void
	defaultValue?: string | any
	displayText?: string | any
	displayIcon?: string | any
	showIcon?: boolean
}

export default function AssetDropdown({ displayText, displayIcon, defaultValue, options, name, onChange, showIcon = true }: AssetDropDownProps<AssetDropdownItems>) {
	const dropdownMenuRef = React.createRef<HTMLElement>()
	const dropdownSelectedValueRef = React.createRef<HTMLParagraphElement>()

	const [selectedItem, setSelectedItem] = React.useState<AssetDropdownItems>()

	React.useEffect(() => {
		document.body.addEventListener("click", function (e: any) {
			if ((e.target.classList.contains("bk__custom_dropdown") || e.target.classList.contains("bk__selected_value") || e.target.classList.contains("bk__dropdown_value")) === false) {
				if (dropdownMenuRef?.current) {
					if (dropdownMenuRef?.current?.classList.contains("show")) {
						dropdownMenuRef?.current?.classList.toggle("show")
					}
				}
			}
		})
	})

	const handleDropdownToggle = () => {
		if (dropdownMenuRef?.current) {
			dropdownMenuRef?.current.classList.toggle("show")
		}
	}

	const handleSelect = (selectedItem: AssetDropdownItems) => {
		setSelectedItem(selectedItem)

		if (typeof onChange === "function") {
			onChange(selectedItem)
		}

		handleDropdownToggle()
	}

	return (
		<section className="bk__custom_dropdown">
			<section className="bk__dropdown_value" onClick={handleDropdownToggle}>
				{selectedItem && <>{selectedItem?.icon && <img className="me-2 d-inline" src={selectedItem?.icon} alt="" width="25" />}</>}

				{!selectedItem && <img className="me-2 d-inline" src={displayIcon} alt="" width="25" />}

				<p className="bk__selected_value d-inline" ref={dropdownSelectedValueRef}>
					{selectedItem ? (
						selectedItem?.symbol
					) : (
						<>
							{!displayText && "Select"}
							{displayText && displayText}
						</>
					)}
				</p>
				{selectedItem && <input hidden type="tel" name={name} value={selectedItem?.asset_id} onChange={() => {}} />}

				{!selectedItem && <input hidden type="tel" name={name} defaultValue={defaultValue} />}
			</section>

			<section className="bk__dropdown_menu" ref={dropdownMenuRef}>
				<div className="list-group list-group-flush nav-group bk__dropdown_items">
					{options && (
						<>
							{options?.length > 0 && (
								<>
									{options?.map(option => (
										<li onClick={() => handleSelect(option)} key={uniqueId()} className="list-group-item list-group-item-action bk__dropdown_item">
											<img className="me-2" src={option?.icon} alt="" width="25" />
											<span>
												{/* @ts-ignore */}
												<strong>{option?.symbol}</strong>
											</span>
										</li>
									))}
								</>
							)}
						</>
					)}
				</div>
			</section>
		</section>
	)
}
