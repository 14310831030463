import classNames from "classnames"
import ShowSpinner from "components/misc/Spinner"
import { USER_LOGOUT } from "flus/constants/auth.const"
import { userMobileMenuRef } from "flus/constants/refs.const"
import React from "react"
import { useFlusStores, useFlusDispatcher } from "@bilmapay/react-flux-storage"
import { useMutation } from "react-query"
import { Link, useLoaderData, useMatch, useNavigate } from "react-router-dom"
import { useAuthApis } from "services/Api/auth/auth.apis.index"
import { handleUserMobileMenuToggle } from "services/utility/Utils/appNav"
import { sleep } from "services/utility/Utils/_sleep"
import { useDispatch } from "react-redux"
import { LOGOUT } from "redux/reducers/appSlice"

export default function UserMobileMenu() {
	const { BluredLogo } = useFlusStores()

	const dispatcher = useDispatch()
	const navigate = useNavigate()
	const { LogoutAccount } = useAuthApis()

	const logoutAccountApi = useMutation(LogoutAccount, {
		onSuccess: res => {
			dispatcher(LOGOUT())

			sleep(200).then(() => navigate("/"))
		}
	})

	const isLoggingOut = logoutAccountApi.isLoading
	const handleLogout = (e: any) => logoutAccountApi.mutateAsync()

	const userProfile: any = useLoaderData()

	const data = userProfile?.data

	return (
		<section className="user-mob-drop-opts" ref={userMobileMenuRef}>
			<header className="user-header mb-4">
				<div className="header-group">
					<img className="img-fluid" src={BluredLogo} alt="Beekle" width="180" />
					<button className="btn-close close-user-dropdown-opts" onClick={handleUserMobileMenuToggle}></button>
				</div>
				<div className="header-group mt-3">
					<strong className="user-levels">
						{data?.user_level_type}
						<sup className="ms-2">
							<span className="badge rounded-pill bg-danger">
								<i className="fa fa-times"></i>
							</span>
						</sup>
					</strong>
					<strong>{data?.beekle_no}</strong>
				</div>
			</header>
			<div className="list-group list-group-flush nav-group">
				<Link
					onClick={handleUserMobileMenuToggle}
					to="/dashboard/profile/edit"
					className={classNames({
						"list-group-item list-group-item-action": true,
						active: Boolean(useMatch("/dashboard/profile/edit"))
					})}
				>
					<i className="far fa-user-circle me-2 nav-icon"></i>
					<span>
						<strong>My Profile</strong>
					</span>
				</Link>
				<Link
					onClick={handleUserMobileMenuToggle}
					to="/dashboard/verification"
					className={classNames({
						"list-group-item list-group-item-action": true,
						active: Boolean(useMatch("/dashboard/verification"))
					})}
				>
					<i className="fas fa-user-check me-2 nav-icon"></i>
					<span>
						<strong>Verify Account</strong>
					</span>
				</Link>
				<Link
					onClick={handleUserMobileMenuToggle}
					to="/dashboard/profile/payments"
					className={classNames({
						"list-group-item list-group-item-action": true,
						active: Boolean(useMatch("/dashboard/profile/payments"))
					})}
				>
					<i className="far fa-credit-card me-2 nav-icon"></i>
					<span>
						<strong>Payment Methods</strong>
					</span>
				</Link>
				<Link
					onClick={handleUserMobileMenuToggle}
					to="/dashboard/profile/settings"
					className={classNames({
						"list-group-item list-group-item-action": true,
						active: Boolean(useMatch("/dashboard/profile/settings"))
					})}
				>
					<span>
						<i className="fa fa-gear me-2 nav-icon"></i>
						<strong>Settings</strong>
					</span>
				</Link>
				<Link
					onClick={handleUserMobileMenuToggle}
					to="/dashboard/profile/invite"
					className={classNames({
						"list-group-item list-group-item-action": true,
						active: Boolean(useMatch("/dashboard/profile/invite"))
					})}
				>
					<i className="fas fa-user-plus me-2 nav-icon"></i>
					<span>
						<strong>Invite a Friend</strong>
					</span>
				</Link>
				<Link
					to="/dashboard/notification"
					onClick={handleUserMobileMenuToggle}
					className={classNames({
						"list-group-item list-group-item-action": true,
						active: Boolean(useMatch("/dashboard/notification"))
					})}
				>
					<i className="fas fa-bell me-2 nav-icon"></i>
					<span>
						<strong>Notification</strong>
					</span>
				</Link>
				<li className="list-group-item list-group-item-action" onClick={handleLogout}>
					<i className="fas fa-sign-out-alt me-2 nav-icon"></i>
					<span>
						<strong>
							{!isLoggingOut && "Logout"}
							{isLoggingOut && <ShowSpinner />}
						</strong>
					</span>
				</li>
			</div>
		</section>
	)
}
