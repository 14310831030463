import ShowSpinner from "components/misc/Spinner"
import PasswordInput from "components/PasswordInput"
import { config } from "configs/config"
import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { useMutation } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import { useAuthApis } from "services/Api/auth/auth.apis.index"
import { extractToken } from "services/utility/Utils/utilities"
import { sleep } from "services/utility/Utils/_sleep"

import { ShowServerErrors } from "services/utility/Utils/_toaster"
import { useDispatch } from "react-redux"
import { LOGIN } from "redux/reducers/appSlice"

export default function LoginForm() {
	
	const { GoogleIcon } = useFlusStores()
	const { LoginAccount } = useAuthApis()
	const dispatcher = useDispatch()

	const navigate = useNavigate()

	const googleAuth = config("googleAuth")

	const loginAccountApi = useMutation(LoginAccount, {
		onSuccess: (res: any, params: FormData) => {
			if (res.status) {
				dispatcher(LOGIN({ token: extractToken(res?.data?.token) }))

				sleep(300).then(() => navigate(`/dashboard?u=${params.get("email")}`))
			} else ShowServerErrors("Login", res)
		}
	})

	const isProcessing = loginAccountApi.isLoading

	const handleUserLoginAction = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		loginAccountApi.mutateAsync(formData)
	}

	return (
		<form className="user" onSubmit={handleUserLoginAction}>
			<div className="form-group">
				<label className="form-label w-100 input-label">
					<strong>Email</strong>
				</label>
				<input id="exampleInputPassword" className="form-control form-control-lg form-control-user" type="email" placeholder="Enter your registered email" name="email" />
			</div>
			<PasswordInput />
			<small className="form-text my-2 w-100 d-block text-end p-1">
				<Link to="/auth/forgot-pwd" className="link">
					Forgot Password?
				</Link>
			</small>
			<button disabled={isProcessing} className="btn btn-primary btn-lg d-block login-btn w-100 bg-blue" type="submit">
				{!isProcessing && "Log in"}
				{isProcessing && <ShowSpinner color="text-light" />}
			</button>
			<small className="form-text my-2 w-100 d-block p-1">
				New on Beekle?{" "}
				<Link to="/auth/signup" className="link">
					Create an Account
				</Link>
			</small>
			<div className="or-divider">
				<hr />
				<span className="or-text">OR</span>
			</div>
			<a href={googleAuth} className="btn btn-primary d-block login_with-google btn-user w-100 mb-2 text-dark link" role="button">
				<img src={GoogleIcon} alt="" width="18" /> Continue with Google
			</a>
		</form>
	)
}
