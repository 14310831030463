/**
 * Session object
 */
const AuthServices = {}

/** Get api authorization token  */
AuthServices.getToken = () => {
	return localStorage.getItem("api_token")
}

/** Save api access token  */
AuthServices.saveToken = token => {
	localStorage.setItem("api_token", token)
}

/** Check if the user is active */
AuthServices.isActive = () => {
	const isLoggedin = localStorage.getItem("isLoggedIn")

	return isLoggedin !== null && isLoggedin === "true"
}

/** Set the active state of the current user */
AuthServices.setIsActive = () => {
	localStorage.setItem("isLoggedIn", true)
}

/**
 * Start user session
 * @param {object} user The user data to store in session
 * @param {boolean} loginWithoutUser weither to start session with user that or not
 * @returns boolean
 */
AuthServices.startSession = (user = null, loginWithoutUser = false) => {
	if (loginWithoutUser) {
		AuthServices.setIsActive()

		return true
	}

	if (Object.keys(user).length > 0) {
		localStorage.setItem("pyv_activeUser", JSON.stringify(user))
		AuthServices.setIsActive()

		return true
	} else {
		return false
	}
}

/**
 * Destory user session
 */
AuthServices.destorySession = () => {
	localStorage.clear()
}

/**
 * Save a temprary user in session
 * @param {object} user The user data to save in a temperary session
 * @returns boolean
 */
AuthServices.saveTempUser = user => {
	if (user) {
		localStorage.setItem("pyv_tempUser", JSON.stringify({ type: "temp", temp_user: user }))
		return true
	} else return false
}

/** retrive the temprary user from session  */
AuthServices.getTempUser = () => {
	const tempUser = localStorage.getItem("pyv_tempUser")
	if (tempUser) {
		return JSON.parse(tempUser).temp_user
	} else return null
}

/** Delete the temperary user from storage */
AuthServices.deleteTempUser = () => {
	return localStorage.removeItem("pyv_tempUser")
}

/**
 *get user information from session
 */
AuthServices.getUser = () => {
	const user = localStorage.getItem("pyv_activeUser")

	return !Object.is(user, null) ? JSON.parse(user) : null
}

/**
 *
 * @param {object} newRecord New user data to update session
 * @returns
 */
AuthServices.saveUser = (newRecord = {}) => {
	if (Object.keys(newRecord).length) {
		localStorage.setItem("pyv_activeUser", JSON.stringify(newRecord))

		return true
	} else return false
}

const { getToken, saveToken, saveUser, getUser, destorySession, startSession, isActive,setIsActive, saveTempUser, getTempUser, deleteTempUser } = AuthServices

export { getToken, saveToken, saveUser, getUser, destorySession, startSession, isActive,setIsActive, saveTempUser, getTempUser, deleteTempUser, AuthServices }
