import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { encryptString } from "services/utility/Utils/utilities"
import { FindAssetAPIObjectProps } from "interfaces/PropsTypes"
import { sleep } from "services/utility/Utils/_sleep"

interface SwapperProps {
	name?: "Funding" | "Spot"
	value: "funding" | "spot"
}

interface WalletTransferSwapcardProps {
	configPropsRef?: React.RefObject<HTMLDivElement>
	handlebalanceSwapping?: (asset?: FindAssetAPIObjectProps | any, configOpts?: SwapperInterface | null) => void
	asset?: FindAssetAPIObjectProps
	swapper?: any
	setSwapper?: any
}
export interface SwapperInterface {
	up: SwapperProps
	down: SwapperProps
}
export default function WalletTransferSwapcard({ swapper, setSwapper, handlebalanceSwapping, asset, configPropsRef }: WalletTransferSwapcardProps) {
	const { swapCoinImg } = useFlusStores()

	const handleSwapping = () => {
		if (swapper?.up.value === "funding") {
			setSwapper({
				up: {
					name: "Spot",
					value: "spot"
				},
				down: {
					name: "Funding",
					value: "funding"
				}
			})
		} else {
			setSwapper({
				up: {
					name: "Funding",
					value: "funding"
				},
				down: {
					name: "Spot",
					value: "spot"
				}
			})
		}
	}

	return (
		<div ref={configPropsRef} className="mb-3 p-4 bk-bg-light" data-toggler-config={encryptString(JSON.stringify(swapper))}>
			<div className="row">
				<div className="col-lg-4 d-flex justify-content-center align-items-center">
					<h4>
						<strong>From</strong>
					</h4>
					<input type="hidden" name="from" value={swapper?.up.value} onChange={() => {}} />
				</div>
				<div className="col d-flex justify-content-center align-items-center">
					<button className="btn btn-light btn-lg border rounded ms-4 shadow-sm bg-white w-50" type="button">
						<strong>{swapper?.up.name}</strong>
					</button>
				</div>
			</div>
			<div className="row my-5">
				<div className="col-lg-4 d-flex justify-content-center align-items-center"></div>
				<div className="col d-flex justify-content-center align-items-center">
					<img onClick={handleSwapping} className="img-fluid pointer" src={swapCoinImg} alt="" width="50" />
				</div>
			</div>
			<div className="row">
				<div className="col-lg-4 d-flex justify-content-center align-items-center">
					<h4>
						<strong>TO</strong>
					</h4>
					<input type="hidden" name="to" value={swapper?.down.value} onChange={() => {}} />
				</div>
				<div className="col d-flex justify-content-center align-items-center">
					<button className="btn btn-light btn-lg border rounded ms-4 shadow-sm bg-white w-50" type="button">
						<strong>{swapper?.down.name}</strong>
					</button>
				</div>
			</div>
		</div>
	)
}
