import { CloudArrowDownIcon } from "@heroicons/react/24/outline"
import React from "react"
import { useMutation } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import { changeClientWithdrawStep, getClientWithdrawStatusData } from "redux/reducers/withdrawalSlice"
import { ReleaseWithdrawalAsset } from "services/Api/cashbridge/cashbridge.apis"
import { uniqueId } from "services/utility/Utils/Utils"

export default function WithdrawalReleaseFunds() {
	const withdrawalStatus = useSelector(getClientWithdrawStatusData)

	const dispatcher = useDispatch()

	const _handleWithdrawalRelease = () => dispatcher(changeClientWithdrawStep("release_funds"))

	return (
		<div className="grid grid-cols-1 gap-4 divide-y">
			<div className="p-2 flex items-center xs:flex-col md:flex-row gap-2">
				{/* <button className="border-1 w-full border-blue-500 text-blue-500 p-3 rounded-md">Escalate</button> */}

				<button className="bg-blue-500 w-full text-white p-3 rounded-md" onClick={_handleWithdrawalRelease} type="button">
					Release
				</button>
			</div>
			<div className="p-2">
				<h5 className="text-gray-600 text-md font-semibold px-3 mb-2">Withdrawal Payment Proof</h5>
				<ul className="divid-y p-3">
					{withdrawalStatus?.proof_of_payment && (
						<>
							{withdrawalStatus?.proof_of_payment?.map(file => (
								<li className="py-1" key={uniqueId()}>
									<a href={file} target="_blank" className="text-blue-500 flex items-center gap-1 hover:underline">
										<CloudArrowDownIcon className="w-5 h-5" /> View Proof
									</a>
								</li>
							))}
						</>
					)}
				</ul>
			</div>
		</div>
	)
}
