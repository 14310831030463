import React from "react"
import { uniqueId } from "services/utility/Utils/Utils"
import { NetworkProps } from "types/app.types"

export interface NetworkDropdownItems extends NetworkProps {}

export interface NetworkDropDownProps<DropdownItem> {
	/**
	 * An array of list options to be display on the dropdown
	 */
	options?: Array<DropdownItem>
	/**
	 * The underlaying input name for the dropdown
	 */
	name?: string
	onChange?: (params: DropdownItem) => void
	defaultValue?: string | any
	displayText?: string | any
}

export default function NetworkDropdown({ displayText, defaultValue, options, name, onChange }: NetworkDropDownProps<NetworkDropdownItems>) {
	const dropdownMenuRef = React.createRef<HTMLElement>()
	const dropdownSelectedValueRef = React.createRef<HTMLParagraphElement>()

	const [selectedItem, setSelectedItem] = React.useState<NetworkDropdownItems>()

	React.useEffect(() => {
		document.body.addEventListener("click", function (e: any) {
			if ((e.target.classList.contains("bk__custom_dropdown") || e.target.classList.contains("bk__selected_value") || e.target.classList.contains("bk__dropdown_value")) === false) {
				if (dropdownMenuRef?.current) {
					if (dropdownMenuRef?.current?.classList.contains("show")) {
						dropdownMenuRef?.current?.classList.toggle("show")
					}
				}
			}
		})
	})

	const handleDropdownToggle = () => {
		if (dropdownMenuRef?.current) {
			dropdownMenuRef?.current.classList.toggle("show")
		}
	}

	const handleSelect = (selectedItem: NetworkDropdownItems) => {
		setSelectedItem(selectedItem)

		if (typeof onChange === "function") {
			onChange(selectedItem)
		}

		handleDropdownToggle()
	}

	return (
		<section className="bk__custom_dropdown">
			<section className="bk__dropdown_value" onClick={handleDropdownToggle}>
				<p className="bk__selected_value d-inline" ref={dropdownSelectedValueRef}>
					{selectedItem && selectedItem?.network_name}
					{!selectedItem && displayText}
				</p>
				{selectedItem && <input hidden type="tel" name={name} value={selectedItem?.network_id} onChange={() => {}} />}

				{!selectedItem && <input hidden type="tel" name={name} defaultValue={defaultValue} />}
			</section>

			<section className="bk__dropdown_menu" ref={dropdownMenuRef}>
				<div className="list-group list-group-flush nav-group bk__dropdown_items">
					{options && (
						<>
							{options?.length > 0 && (
								<>
									{options?.map(option => (
										<li onClick={() => handleSelect(option)} key={uniqueId()} className="list-group-item list-group-item-action bk__dropdown_item">
											<span>
												{/* @ts-ignore */}
												<strong>{option?.network_name}</strong>
											</span>
										</li>
									))}
								</>
							)}
						</>
					)}
				</div>
			</section>
		</section>
	)
}
