import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { useLoaderData } from "react-router-dom"
import { ShowInfo } from "services/utility/Utils/_toaster"
import { useSelector } from "react-redux"
import { getConfigs } from "redux/reducers/appSlice"

export default function InviteFriend() {
	const { tellAFriend } = useFlusStores()
	const profileResponse: any = useLoaderData()

	const beekleContent = useSelector(getConfigs)

	const data = profileResponse?.data

	const handleUserRefLinkCopy = () => {
		navigator.clipboard.writeText(data?.ref_link)

		ShowInfo("Success", "Link copied!")
	}

	return (
		<section className="p-3 bg-white">
			<header className="mb-3">
				<h1>
					<strong>{beekleContent?.invite_friend_page?.first_title}</strong>
					<br />
				</h1>
				<p dangerouslySetInnerHTML={{ __html: beekleContent?.invite_friend_page?.first_description as string }}></p>
			</header>
			<section className="p-4">
				<div>
					<div className="input-group input-group-lg copy-link-input-group">
						<input className="form-control" type="text" placeholder={data?.ref_link} defaultValue={data?.ref_link} readOnly />
						<button onClick={handleUserRefLinkCopy} className="btn btn-primary bg-blue" type="button">
							Copy
						</button>
					</div>
				</div>
			</section>
			<section>
				<img className="img-fluid d-block m-auto my-5" src={tellAFriend} alt="" width="300" />
				<h1>
					<strong>{beekleContent?.invite_friend_page?.second_title}</strong>
					<br />
				</h1>
				<p dangerouslySetInnerHTML={{ __html: beekleContent?.invite_friend_page?.second_description as string }}></p>
			</section>
		</section>
	)
}
