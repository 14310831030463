import { ClipboardIcon } from "@heroicons/react/24/outline"
import ShowSpinner from "components/misc/Spinner"
import { copyText } from "libs/copy"
import { NAIRA_SIGN } from "libs/currency"
import React from "react"
import { useMutation } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import { changeClientWithdrawStep, getClientWithdrawTimerId, setClientWithdrawalTimerId, stopClientWithdrawalTimer } from "redux/reducers/withdrawalSlice"
import { CheckWithdrawalStatus, ProcessWithdrawal } from "services/Api/cashbridge/cashbridge.apis"
import { sleep } from "services/utility/Utils/_sleep"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { formatAmount } from "services/utility/Utils/utilities"
import { WithdrawalStatusInterface } from "types"

type PayerDetailsProps = {
	data?: WithdrawalStatusInterface
}
export default function PayerDetails({ data }: PayerDetailsProps) {
	const dispatcher = useDispatch()

	const listenForPaymentApi = useMutation(CheckWithdrawalStatus, {
		onSuccess(res: any) {
			if (res?.status) {
				if (res?.data?.status_code?.toString() === "3") {
					ShowSuccess("Success", res?.message)

					dispatcher(stopClientWithdrawalTimer(res?.data))

					sleep(900).then(() => dispatcher(changeClientWithdrawStep("view_pop")))
				}
			}
		}
	})

	const _startListener = (_txn_id: any) => {
		const timer = setInterval(() => {
			listenForPaymentApi.mutateAsync({ txn_id: _txn_id })
		}, 9000)

		dispatcher(setClientWithdrawalTimerId(timer))
	}

	const proceedToPaymentApi = useMutation(ProcessWithdrawal, {
		onSuccess(res: any) {
			if (res?.status) {
				_startListener(res?.data?.txn_id)

				ShowSuccess("Success", res?.message)
				dispatcher(changeClientWithdrawStep("awaiting_payment"))
			} else ShowServerErrors("Error", res)
		}
	})

	const isLoading = proceedToPaymentApi.isLoading

	const _handleMakePayment = () => proceedToPaymentApi.mutateAsync({ txn_id: data?.txn_id })

	return (
		<div>
			<div className="px-4 sm:px-0">
				<h3 className="font-semibold text-center text-gray-900 text-3xl capitalize">{data?.merchant_name} Merchant</h3>
				<p className="max-w-2xl text-md text-gray-500 text-center">
					You've been successfully matched with one of our <strong className="text-green-500">Verified</strong> merchant.
				</p>
			</div>
			<div className="mt-6 border-t border-gray-100">
				<dl className="divide-y divide-gray-100">
					<div className="px-4 py-1 sm:grid sm:gap-4 sm:px-0">
						<dd className="text-lg font-bold leading-6 text-gray-700">{data?.account_name}</dd>
					</div>
					<div className="px-4 py-1 sm:grid sm:gap-4 sm:px-0">
						<dd className="text-lg font-bold leading-6 text-gray-700">{data?.bank_name}</dd>
					</div>

					<div className="px-4 py-1 sm:grid sm:gap-4 sm:px-0">
						<dd className="text-lg font-bold leading-6 text-gray-700 flex gap-2 items-center">
							{data?.account_number}
							<ClipboardIcon className="w-5 h-5 cursor-pointer" onClick={() => copyText(data?.account_number as string)} />
						</dd>
					</div>

					<div className="px-4 py-6 sm:grid sm:gap-4 sm:px-0">
						<dd className="text-sm font-bold  text-red-700 italic">
							The merchant will make payment of {NAIRA_SIGN}
							{formatAmount(data?.amount)} into this account. Ensure to verify your details and proceed
						</dd>
					</div>

					<div className="px-4 py-6 sm:grid sm:gap-4 sm:px-0">
						<button
							onClick={_handleMakePayment}
							className="border-1 bg-blue-500 text-white rounded-md  transition ease-in duration-300 p-3 w-fit hover:bg-blue-500 hover:text-white active:bg-blue-500 active:text-white"
						>
							{!isLoading && "Proceed Withdrawal"}

							{isLoading && <ShowSpinner color="text-light" />}
						</button>
					</div>
				</dl>
			</div>
		</div>
	)
}
