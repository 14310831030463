export const DOLLAR_SIGN = "\u0024"
export const CENT_SIGN = "\u00A2"
// -
export const POUND_SIGN = "\u00A3"
export const YEN_SIGN = "\u00A5"
export const ARMENIAN_DRAM_SIGN = "\u058F"
export const AFGHANI_SIGN = "\u060B"
export const BENGALI_RUPEE_MARK = "\u09F2"
export const BENGALI_RUPEE_SIGN = "\u09F3"
export const BENGALI_GANDA_MARK = "\u09FB"
export const GUJARATI_RUPEE_SIGN = "\u0AF1"
export const TAMIL_RUPEE_SIGN = "\u0BF9"
export const THAI_CURRENCY_SYMBOL_BAHT = "\u0E3F"
export const KHMER_CURRENCY_SYMBOL_RIEL = "\u17DB"
export const EURO_CURRENCY_SIGN = "\u20A0"
export const COLON_SIGN = "\u20A1"
export const CRUZEIRO_SIGN = "\u20A2"
export const FRENCH_FRANC_SIGN = "\u20A3"
export const LIRA_SIGN = "\u20A4"
export const MILL_SIGN = "\u20A5"
export const NAIRA_SIGN = "\u20A6"
export const PESETA_SIGN = "\u20A7"
export const RUPEE_SIGN = "\u20A8"
export const WON_SIGN = "\u20A9"
export const NEW_SHEQEL_SIGN = "\u20AA"
export const DONG_SIGN = "\u20AB"
export const EURO_SIGN = "\u20AC"
export const KIP_SIGN = "\u20AD"
export const TUGRIK_SIGN = "\u20AE"
export const DRACHMA_SIGN = "\u20AF"
export const GERMAN_PENNY_SIGN = "\u20B0"
export const PESO_SIGN = "\u20B1"
export const GUARANI_SIGN = "\u20B2"
export const AUSTRAL_SIGN = "\u20B3"
export const HRYVNIA_SIGN = "\u20B4"
export const CEDI_SIGN = "\u20B5"
export const LIVRE_TOURNOIS_SIGN = "\u20B6"
export const SPESMILO_SIGN = "\u20B7"
export const TENGE_SIGN = "\u20B8"
export const INDIAN_RUPEE_SIGN = "\u20B9"
export const TURKISH_LIRA_SIGN = "\u20BA"
export const NORDIC_MARK_SIGN = "\u20BB"
export const MANAT_SIGN = "\u20BC"
export const RUBLE_SIGN = "\u20BD"
export const LARI_SIGN = "\u20BE"
export const BITCOIN_SIGN = "\u20BF"
export const NORTH_INDIC_RUPEE_MARK = "\uA838"
export const RIAL_SIGN = "\uFDFC"
export const SMALL_DOLLAR_SIGN = "\uFE69"
export const FULLWIDTH_DOLLAR_SIGN = "\uFF04"
export const FULLWIDTH_CENT_SIGN = "\uFFE0"
export const FULLWIDTH_POUND_SIGN = "\uFFE1"
export const FULLWIDTH_YEN_SIGN = "\uFFE5"
export const FULLWIDTH_WON_SIGN = "\uFFE6"
