import PageInfo from "components/misc/Page"
import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { generalApis } from "services/Api/general/gen.index"
import { useMutation } from "react-query"
import { ShowSuccess } from "services/utility/Utils/_toaster"
import ShowSpinner from "components/misc/Spinner"
import { useSelector } from "react-redux"
import { getConfigs } from "redux/reducers/appSlice"

const formRef = React.createRef<HTMLFormElement>()

export default function ContactUsContent() {
	const { worldMapBg, socialPlatform, emailBox, teamSupport, contactusbg, socialplatform,
		toptier_security,
		support, } = useFlusStores()

	const beekleContent = useSelector(getConfigs)

	const { SendContactUs } = generalApis()

	const postContactUsApi = useMutation(SendContactUs, {
		onSuccess(res: any) {
			if (res?.status) {
				ShowSuccess("Success", "Your message has been sent successfully. You will hear from us shortly via email thank you!")

				if (formRef?.current) {
					formRef?.current?.reset()
				}
			}
		}
	})

	const isPosting = postContactUsApi.isLoading

	const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const fd = new FormData(e.target)

		postContactUsApi.mutateAsync(fd)
	}

	return (
		<>
			{/* <PageInfo titleText="Contact Us" /> */}
			<section className="content-area">

				<section className="flex flex-row space-x-24 px-6 sm:px-12 mb-12">
					{/* <div className="container">
						<div className="row"> */}
							<div className=" w-full sm:w-3/5  sm:shadow-md shadow-none">

								<p className=" mb-4 text-blue-600 text-2xl sm:text-4xl sm:w-[100%] tracking-wide sm:leading-tight font-link">Reach out To Us Today</p>
								<p className=" mb-4 text-grey sm:w-[100%] tracking-wide sm:leading-tight ">Kindly fill out your details in the form and you will get a response from our team within 24hrs.</p>

								<form ref={formRef} className="mt-4" onSubmit={handleSubmit}>
									<div className="form-group input-overlay-label p-1">
										<input className="form-control" placeholder="Enter your full name" type="text" required name="full_name" />
										<label className=" text-blue-600 ">Enter your full name</label>
									</div>
									<div className="form-group input-overlay-label p-1">
										<input className="form-control" placeholder="Enter your email address" type="email" required name="email" />
										<label className=" text-blue-600  text-primary">Enter your email address</label>
									</div>
									<div className="form-group p-1">
										<label className="form-label text-primary">
											Message<span className="ms-1 text-danger">*</span>
										</label>
										<textarea name="message" className="form-control" spellCheck="true" wrap="soft" autoComplete="off" rows={10} required></textarea>
									</div>
									<button disabled={isPosting} className="btn btn-primary btn-lg mt-3 d-block w-100" type="submit">
										{!isPosting && "Submit"}
										{isPosting && <ShowSpinner color="text-light" />}
									</button>
								</form>
							</div>

							<div className=" w-2/5 h-1/2 hide_small">
								<img className=" " src={contactusbg} alt="" />
							</div>

						{/* </div>
					</div> */}

				</section>

				<section className="bp_contactus-section-002 mb-4">

					<div className="container">
						<div className="row">
							<div className="col-md-4">
								<div className="card none">
									<div className="card-body">
										<img className="img-fluid m-auto d-block mb-2" src={socialplatform} alt="" width="100" />
										<h4 className="text-center card-title">
											<strong>Social Platform</strong>
										</h4>
										<p className="text-center card-text">
											We are available 24/7 on <br />
											<a className="text-black" href="#">Facebook</a>, <a className="text-black" href="#">Twitter</a>, <a className="text-black" href="#">Instagram</a>, <a className="text-black" href="#">LinkedIn </a>
										</p>
									</div>
								</div>
							</div>

							<div className="col-md-4">
								<div className="card none">
									<div className="card-body">
										<img className="img-fluid m-auto d-block mb-2" src={toptier_security} alt="" width="100" />
										<h4 className="text-center card-title">
											<strong>Top-Tier Security</strong>
											<br />
										</h4>
										<p className="text-center card-text">
											Send a mail today to our mail and get swift response:
											<br />
											<a className="text-black" href="malto:example@beekle.com">support@beekle.com</a>
											<br />
										</p>
									</div>
								</div>
							</div>

							<div className="col-md-4">
								<div className="card none">
									<div className="card-body">
										<img className="img-fluid m-auto d-block mb-2" src={support} alt="" width="100" />
										<h4 className="text-center card-title">
											<strong>Team Support</strong>
											<br />
										</h4>
										<p className="text-center card-text">
											Our support team welcome you with an easy to use dispute resolution channel
											<br />
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

			</section>
		</>
	)
}
