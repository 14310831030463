import React from "react"
import { useLocation } from "react-router-dom"
import DefaultSidebarItems from "./DefaultSidebarItems"
import ProfileSidebarItems from "./ProfileSidebarItems"
import MerchantSidebarItems from "components/merchant/layout/MerchantSidebarItems"
import { UserProfileProps } from "types/app.types"

function charExists(inputString: string, wordToMatch: string) {
	const regexPattern = new RegExp(`\\b${wordToMatch}\\b`, "i")
	return regexPattern.test(inputString)
}

type DashSidebarProps = {
	user?: UserProfileProps
}
export default function DashSidebar({ user }: DashSidebarProps) {
	const location = useLocation()

	const isProfile: boolean = location?.pathname.includes("dashboard/profile") || location?.pathname.includes("dashboard/verification")
	const isMerchant: boolean = location?.pathname.includes("merchant")

	return (
		<div className="col-md-3 col-lg-3 col-xl-2 col-xxl-2 px-0 bg-white dash-sidebar-col hide">
			<div className="dash-sidenav-wrapper">
				<div className="close-dash-nav">
					<button className="btn-close"></button>
				</div>

				{!isProfile && !isMerchant && <DefaultSidebarItems />}
				{isProfile && <ProfileSidebarItems />}
				{isMerchant && <MerchantSidebarItems user={user} />}
			</div>
		</div>
	)
}
