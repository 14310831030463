import { FetchCurrencyPairs, FetchMyOffers, CreateSellOffer, CreateBuyOffer, FetchResponseTime, FetchMyOfferDetails, ToggleOfferStatus } from "./offer.apis"

export const userOfferApis = () => ({
	FetchCurrencyPairs,
	FetchMyOffers,
	CreateSellOffer,
	CreateBuyOffer,
	FetchResponseTime,
	FetchMyOfferDetails,
	ToggleOfferStatus
})
