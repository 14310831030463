import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { Link, useLoaderData } from "react-router-dom"
import { useUrlQuery } from "services/utility/hooks/useURL"
import classNames from "classnames"
import IconWaveSawTool from "components/icons/IconWaveSawTool"
import { ROUTES } from "routes"
import { useDispatch } from "react-redux"
import { toggleCashBridgeModal } from "redux/reducers/modalSlice"
import { UserProfileProps } from "types/app.types"

export default function DefaultSidebarItems() {
	const {
		dashNavIconWallet,
		dashNavIconFunding,
		dashNavIconMarket,
		dashNavIconTrans,
		dashNavIconMarketNews,
		dashNavIconDisputeZone,
		dashNavIconCreateOffer,
		dashNavIconMyOffer
	} = useFlusStores()

	const _data = useLoaderData() as any
	const user: UserProfileProps = _data?.data

	const { tb } = useUrlQuery()

	const dispatcher = useDispatch()

	const toggleCashbridgeModal = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault()
		dispatcher(toggleCashBridgeModal(true))
	}

	return (
		<>
			<header className="p-2 bg-gray dash-nav-header">
				<h3 className="d-flex justify-content-start align-items-center">
					<svg
						className="bi bi-grid-fill me-1"
						xmlns="http://www.w3.org/2000/svg"
						width="1em"
						height="1em"
						fill="currentColor"
						viewBox="0 0 16 16"
					>
						<path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z"></path>
					</svg>
					<strong>Dashboard</strong>
				</h3>
			</header>
			<ul className="nav nav-tabs flex-column">
				<li
					className={classNames({
						"nav-item px-2 d-flex justify-content-start align-items-center": true,
						active: tb === "spw"
					})}
				>
					<img src={dashNavIconWallet} alt="" width="25" />
					<Link className="nav-link w-100" to="/dashboard/spot-wallet?tb=spw">
						<strong>Spot Wallet</strong>
					</Link>
				</li>
				<li
					className={classNames({
						"nav-item px-2 d-flex justify-content-start align-items-center": true,
						active: tb === "fdw"
					})}
				>
					<img src={dashNavIconFunding} alt="" width="25" />
					<Link
						className="nav-link w-100"
						to="/dashboard/funding-wallet?tb=fdw"
					>
						<strong>Funding</strong>
					</Link>
				</li>
				{/* <li
					className={classNames({
						"nav-item px-2 d-flex justify-content-start align-items-center": true,
						active: tb === "mrk"
					})}
				>
					<img src={dashNavIconMarket} alt="" width="25" />
					<Link className="nav-link w-100" to="/dashboard/marketplace?tb=mrk">
						<strong>Market</strong>
					</Link>
				</li> */}
			</ul>
			<ul className="nav nav-tabs flex-column">
				<li
					className={classNames({
						"nav-item px-2 d-flex justify-content-start align-items-center": true,
						active: tb === "trh"
					})}
				>
					<img src={dashNavIconTrans} alt="" width="25" />
					<Link className="nav-link w-100" to="/dashboard/history?tb=trh">
						<strong>Transaction History</strong>
					</Link>
				</li>
				<li
					className={classNames({
						"nav-item px-2 d-flex justify-content-start align-items-center": true,
						active: tb === "mkn"
					})}
				>
					<img src={dashNavIconMarketNews} alt="" width="25" />
					<Link className="nav-link w-100" to="/dashboard/market-news?tb=mkn">
						<strong>Market News</strong>
					</Link>
				</li>

				{/* <li
					className={classNames({
						"nav-item px-2 d-flex justify-content-start align-items-center": true,
						active: tb === "dpz"
					})}
				>
					<img src={dashNavIconDisputeZone} alt="" width="25" />
					<Link className="nav-link w-100" to="/dashboard/disputes?tb=dpz">
						<strong>Dispute Zone</strong>
					</Link>
				</li>
				<li
					className={classNames({
						"nav-item px-2 d-flex justify-content-start align-items-center": true,
						active: tb === "cof"
					})}
				>
					<img src={dashNavIconCreateOffer} alt="" width="25" />
					<Link className="nav-link w-100" to="/dashboard/create-offer?tb=cof">
						<strong>Create Offer</strong>
					</Link>
				</li>

				<li
					className={classNames({
						"nav-item px-2 d-flex justify-content-start align-items-center": true,
						active: tb === "mof"
					})}
				>
					<img src={dashNavIconMyOffer} alt="" width="25" />
					<Link className="nav-link w-100" to="/dashboard/my-offer?tb=mof">
						<strong>My Offer</strong>
					</Link>
				</li> */}

				<li
					className={classNames({
						"nav-item px-2 d-flex justify-content-start align-items-center": true
					})}
				>
					<IconWaveSawTool width={"2em"} height={"2em"} />
					<Link
						className="nav-link w-100"
						onClick={toggleCashbridgeModal}
						style={{ color: "#888A11" }}
						to="#"
					>
						<strong>Cash-Bridge</strong>
					</Link>
				</li>

				{parseInt(user?.isMerchant as string) === 0 && (
					<li
						className={classNames({
							"nav-item px-2 d-flex justify-content-start align-items-center": true
						})}
					>
						<Link
							className="nav-link w-100 text-xs"
							to="/dashboard/register"
							style={{ color: "#2C881D" }}
						>
							Become a <strong>MERCHANT</strong>
						</Link>
					</li>
				)}

				{parseInt(user?.isMerchant as string) === 1 && (
					<li
						className={classNames({
							"nav-item ": true
						})}
					>
						<Link
							className="nav-link w-100 text-[12px] flex items-center gap-1"
							to={ROUTES.merchant.index}
							style={{ color: "#2C881D" }}
						>
							<strong className="text-blue">Switch to merchant</strong>
						</Link>
					</li>
				)}
			</ul>
		</>
	)
}
