import React from "react"
import { Link } from "react-router-dom"
import { useUrlQuery } from "services/utility/hooks/useURL"
import classNames from "classnames"
import IconEcommerceWallet from "components/icons/IconEcommerce_wallet"
import IconHourglassSplit from "components/icons/IconHourglassSplit"
import IconBank from "components/icons/IconBank"
import IconFilter from "components/icons/IconFilter"
import IconClockHistory from "components/icons/IconClockHistory"
import Switch from "components/input/Switch"
import { ROUTES } from "routes"
import { useDispatch, useSelector } from "react-redux"
import { getBankPanel, getLimitPanel, toggleBankPanel, toggleLimitPanel } from "redux/reducers/panelsSlice"
import IconReturnUpBack from "components/icons/IconReturnUpBack"
import { useMutation } from "react-query"
import { ToggleMerchantPresence } from "services/Api/merchant/merchant.apis"
import { ShowInfo, ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { toggleDeactivateAccountModal } from "redux/reducers/modalSlice"
import IconTrash from "components/icons/IconTrash"
import { UserProfileProps } from "types/app.types"

type MerchantSidebarItemsProps = {
	user?: UserProfileProps
}

export default function MerchantSidebarItems({ user }: MerchantSidebarItemsProps) {
	const { active } = useUrlQuery()
	const [label, setLabel] = React.useState<string>("Go Online")
	const bankPanel = useSelector(getBankPanel)
	const limitPanel = useSelector(getLimitPanel)
	const dispatcher = useDispatch()

	const _toggleSetbankPanel = (e: React.MouseEvent<HTMLElement>) => {
		e?.preventDefault()
		dispatcher(toggleBankPanel({ status: true }))
	}

	const _toggleSetlimitPanel = (e: React.MouseEvent<HTMLElement>) => {
		e?.preventDefault()
		dispatcher(toggleLimitPanel({ status: true }))
	}

	// -------------------> Toggle online presence
	const toggleActiveStateApi = useMutation(ToggleMerchantPresence, {
		onSuccess(res: any) {
			if (res?.status) {
				if (res?.data?.status === "online") {
					setLabel("Go Offline")
					ShowSuccess("Success", "You're now online")
				} else {
					ShowInfo("Success", "You're offline.")

					setLabel("Go Online")
				}
			} else ShowServerErrors("Error", res)
		}
	})
	const isLoading = toggleActiveStateApi.isLoading

	const _handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		toggleActiveStateApi.mutateAsync()
	}

	const _toggleDeactivateAccount = (e: React.MouseEvent<HTMLElement>) => {
		e?.preventDefault()
		dispatcher(toggleDeactivateAccountModal(true))
	}

	return (
		<>
			<header className="p-2 bg-gray dash-nav-header">
				<h3 className="flex items-center gap-1">
					<svg className="bi bi-grid-fill me-1" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
						<path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z"></path>
					</svg>
					<strong>Dashboard</strong>
				</h3>
			</header>
			<ul className="nav nav-tabs flex-column mt-4">
				<li
					className={classNames({
						"nav-item flex items-center gap-2 px-2": true,
						active: active === "wallet"
					})}
				>
					<IconEcommerceWallet width={"2em"} height={"2em"} className="text-gray-500/50" />
					<Link className="w-100" to={ROUTES?.merchant?.index}>
						<strong>Wallet</strong>
					</Link>
				</li>
				<li
					className={classNames({
						"nav-item flex items-center gap-2 px-2": true,
						active: active === "orders"
					})}
				>
					<IconHourglassSplit width={"2em"} height={"2em"} className="text-gray-500/50" />
					<Link className="w-100" to={ROUTES?.merchant?.orders}>
						<strong>Order</strong>
					</Link>
				</li>
				<li
					className={classNames({
						"nav-item flex items-center gap-2 px-2": true,
						active: bankPanel?.show
					})}
				>
					<IconBank width={"2em"} height={"2em"} className="text-gray-500/50" />
					<Link className="w-100" to="#" onClick={_toggleSetbankPanel}>
						<strong>Set Bank</strong>
					</Link>
				</li>

				<li
					className={classNames({
						"nav-item flex items-center gap-2 px-2": true,
						active: limitPanel?.show
					})}
				>
					<IconFilter width={"2em"} height={"2em"} className="text-gray-500/50" />
					<Link className="w-100" to="#" onClick={_toggleSetlimitPanel}>
						<strong>Set Limit</strong>
					</Link>
				</li>
			</ul>
			<hr className="my-2" />
			<ul className="nav nav-tabs flex-column">
				<li
					className={classNames({
						"nav-item flex items-center gap-2 px-2": true,
						active: active === "withdrawal"
					})}
				>
					<IconClockHistory width={"2em"} height={"2em"} className="text-gray-500/50" />
					<Link className="w-100 flex truncate text-sm" to={ROUTES.merchant.withrawalHistory}>
						<strong>Withdrawal History</strong>
					</Link>
				</li>

				<li
					className={classNames({
						"nav-item flex items-center gap-2 px-2": true,
						active: active === "deposit"
					})}
				>
					<IconClockHistory width={"2em"} height={"2em"} className="text-gray-500/50" />
					<Link className="w-100 flex truncate text-sm" to={ROUTES?.merchant?.depositHistory}>
						<strong>Deposit History</strong>
					</Link>
				</li>

				<li
					className={classNames({
						"nav-item flex items-center gap-2 px-2": true,
						active: active === "funding"
					})}
				>
					<IconClockHistory width={"2em"} height={"2em"} className="text-gray-500/50" />
					<Link className="w-100 flex truncate text-sm" to={ROUTES?.merchant?.fundingHistory}>
						<strong>Funding History</strong>
					</Link>
				</li>
			</ul>

			<hr className="my-2" />
			<ul className="nav nav-tabs flex-column">
				<li
					className={classNames({
						"nav-item flex items-center gap-2 px-2": true
					})}
				>
					<IconReturnUpBack width={"2em"} height={"2em"} className="text-gray-500/50" />
					<Link className="w-100 flex truncate text-sm" to="/dashboard">
						<strong>User Dashboard</strong>
					</Link>
				</li>
				<li
					className={classNames({
						"nav-item flex items-center gap-2 px-2": true
					})}
				>
					<IconTrash width={"2em"} height={"2em"} className="text-red-500/50" />
					<Link className="w-100 flex truncate text-sm" to={"#"} onClick={_toggleDeactivateAccount}>
						<strong className="text-red-500">Deactivate Account</strong>
					</Link>
				</li>
				<li
					className={classNames({
						"nav-item flex items-center gap-2 px-2": true
					})}
				>
					<span className="w-100 flex truncate text-sm py-3">
						<Switch isChecked={user?.isMerchantOnline?.toString() === "1"} label={label} onChange={_handleChange} disable={isLoading} />
					</span>
				</li>
			</ul>
		</>
	)
}
