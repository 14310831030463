import { useFlusStores } from "@bilmapay/react-flux-storage"
import CardNavigator from "components/CardNavigator"
import React from "react"
import { useMutation } from "react-query"
import { userMarketplaceApis } from "services/Api/marketplace/marketplace.index"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { useNavigate } from "react-router-dom"
import { useUrlQuery } from "services/utility/hooks/useURL"
import ShowSpinner from "components/misc/Spinner"

export default function OrderCancellation() {
	const { StaticSpinner } = useFlusStores()

	const { CancelOrder } = userMarketplaceApis()
	const navigate = useNavigate()
	const { ot } = useUrlQuery()

	const cancelOrderApi = useMutation(CancelOrder, {
		onSuccess: (res: any) => {
			if (res?.status) {
				ShowSuccess("Order Cancelled!", res?.message)

				navigate("/dashboard/marketplace?tb=mrk")
			} else ShowServerErrors("Order Cancellation", res)
		}
	})

	const isSubmitting = cancelOrderApi.isLoading

	const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		cancelOrderApi.mutateAsync(formData)
	}

	return (
		<CardNavigator returnLink="/dashboard">
			<section>
				<header className="mb-4">
					<h3 className="mb-3 text-primary">
						<strong>Notice of Cancellation</strong>
						<br />
					</h3>
					<img className="img-fluid m-auto d-block my-5" src={StaticSpinner} alt="" width="150" />
				</header>
				<form className="trade-form" onSubmit={handleSubmit}>
					{/* order token  */}
					<input type="hidden" name="order_token" value={ot} onChange={() => {}} />
					<section>
						<h3 className="text-success">
							<strong>What you should know?</strong>
						</h3>
						<ol className="mb-4">
							<li className="lead">
								Ensure you exhaust the use of the chatbox if user does not respond before
								cancellation.
							</li>
							<li className="lead">
								You will not be held responsible for order time out due to unavailability of the
								other party.
							</li>
							<li className="lead">
								Incessant cancellation of trade (5 times) in a day might lead to 24 hours suspension
								of your account.
							</li>
						</ol>
						<h3 className="text-success">
							<strong>Survey on Cancellation </strong>
							<br />
						</h3>
						<p className="lead">Why have you decided to cancel this trade?</p>
						<div className="form-check mb-3">
							<input
								name="cancelled_survey[]"
								value="This is not the right time for me to make this buy"
								id="formCheck-1"
								className="form-check-input"
								type="checkbox"
							/>
							<label className="form-check-label" htmlFor="formCheck-1">
								This is not the right time for me to make this buy
							</label>
						</div>
						<div className="form-check mb-3">
							<input
								name="cancelled_survey[]"
								value={"I have issue with the seller's payment method"}
								id="formCheck-2"
								className="form-check-input"
								type="checkbox"
							/>
							<label className="form-check-label" htmlFor="formCheck-2">
								I have issue with the seller's payment method
							</label>
						</div>
						<div className="form-check mb-3">
							<input
								name="cancelled_survey[]"
								value="I selected the wrong choice of coin"
								id="formCheck-3"
								className="form-check-input"
								type="checkbox"
							/>
							<label className="form-check-label" htmlFor="formCheck-3">
								I selected the wrong choice of coin
							</label>
						</div>
						<div className="form-check mb-3">
							<input
								name="cancelled_survey[]"
								value={"I am not happy with the seller's attitude"}
								id="formCheck-4"
								className="form-check-input"
								type="checkbox"
							/>
							<label className="form-check-label" htmlFor="formCheck-4">
								I am not happy with the seller's attitude
							</label>
						</div>
						<div className="form-check mb-3">
							<input
								name="cancelled_survey[]"
								value={"Others"}
								id="formCheck-5"
								className="form-check-input"
								type="checkbox"
							/>
							<label className="form-check-label" htmlFor="formCheck-5">
								Others
							</label>
						</div>
					</section>
					<textarea
						name="cancelled_message"
						className="form-control"
						placeholder="Type something..."
						rows={5}
					></textarea>
					<section className="p-3 text-center">
						<div className="btn-group btn-group-lg mb-5" role="group">
							<button disabled={isSubmitting} className="btn btn-primary" type="submit">
								{!isSubmitting && <strong>Submit</strong>}
								{isSubmitting && <ShowSpinner color="text-light" />}
							</button>
						</div>
					</section>
				</form>
			</section>
		</CardNavigator>
	)
}
