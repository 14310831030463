import React, { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { useDispatch, useSelector } from "react-redux"
import { getTransHistoryPanel, toggleTransHistoryPanel } from "redux/reducers/panelsSlice"
import { TransactionHistoryPanelData } from "types"
import { NAIRA_SIGN } from "libs/currency"
import { formatAmount } from "services/utility/Utils/utilities"

const DepositDetails = ({ data }: { data?: TransactionHistoryPanelData }) => {
	const dispatcher = useDispatch()

	const _handleClose = () => dispatcher(toggleTransHistoryPanel({ status: false }))

	return (
		<div className="relative mt-6 flex-1 px-4 sm:px-6">
			<div className="mt-3 border-t border-gray-100">
				<dl className="divide-y divide-gray-100">
					<div className="p-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
						<dt className="text-sm font-semibold leading-6 text-blue-500">Order ID:</dt>
						<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data?.txn_id}</dd>
					</div>

					<div className="p-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
						<dt className="text-sm font-semibold leading-6 text-blue-500">Type:</dt>
						<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data?.type?.toUpperCase()}</dd>
					</div>

					{data?.type === "Credit" && (
						<>
							<div className="p-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-semibold leading-6 text-blue-500">Depositor Name:</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data?.depositor_name}</dd>
							</div>

							<div className="p-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-semibold leading-6 text-blue-500">Funded Amount:</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{NAIRA_SIGN}
									{formatAmount(data?.amount)}
								</dd>
							</div>
						</>
					)}

					{(data?.type === "deposit" || data?.type === "withdrawal") && (
						<>
							<div className="p-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-semibold leading-6 text-blue-500">Client Name:</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data?.client_name}</dd>
							</div>

							<div className="p-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-semibold leading-6 text-blue-500">Total Amount:</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{NAIRA_SIGN}
									{formatAmount(data?.amount)}
								</dd>
							</div>

							<div className="p-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-semibold leading-6 text-blue-500">{data?.type === "deposit" ? "Deposited" : "Credited"} Amount:</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{NAIRA_SIGN}
									{data?.type !== "withdrawal" ? formatAmount(data?.debited_amount) : formatAmount(data?.credited_amount)}
								</dd>
							</div>

							<div className="p-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-semibold leading-6 text-blue-500">Commission:</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{NAIRA_SIGN}
									{formatAmount(data?.commission)}
								</dd>
							</div>

							<div className="p-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-semibold leading-6 text-blue-500">Platform Charges:</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{NAIRA_SIGN}
									{formatAmount(data?.platform_charge)}
								</dd>
							</div>
						</>
					)}

					{/* for deposit only */}
					{data?.type === "deposit" && (
						<>
							<div className="p-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-semibold leading-6 text-blue-500">Bank Name:</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data?.bank_name}</dd>
							</div>

							<div className="p-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-semibold leading-6 text-blue-500">Account Name:</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data?.account_name}</dd>
							</div>

							<div className="p-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-semibold leading-6 text-blue-500">Account Number:</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data?.account_number}</dd>
							</div>
						</>
					)}

					<div className="p-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
						<dt className="text-sm font-semibold leading-6 text-blue-500">Date:</dt>
						<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data?.date}</dd>
					</div>
					<div className="p-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
						<dt className="text-sm font-semibold leading-6 text-blue-500">Time:</dt>
						<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data?.time}</dd>
					</div>
				</dl>

				<button onClick={_handleClose} className="p-3 mt-4 w-full font-bold   rounded-md bg-blue-500 text-white transition ease-in duration-300 hover:bg-blue-500 hover:text-white" type="button">
					Okay
				</button>
			</div>
		</div>
	)
}
export default function TransHistoryPanel() {
	const { data, params, show } = useSelector(getTransHistoryPanel)

	const dispatcher = useDispatch()

	const _handleClose = () => dispatcher(toggleTransHistoryPanel({ status: false }))

	return (
		<Transition.Root show={show} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={_handleClose}>
				<Transition.Child as={Fragment} enter="ease-in-out duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto relative w-screen max-w-lg">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-500"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-500"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
											<button type="button" className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" onClick={_handleClose}>
												<span className="absolute -inset-2.5" />
												<span className="sr-only">Close panel</span>
												<XMarkIcon className="h-6 w-6" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									<div className="flex h-full right-0 flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="px-4 sm:px-6">
											<Dialog.Title className="text-base font-semibold leading-6 text-gray-900 uppercase">Transaction Detail</Dialog.Title>
										</div>

										{params === "deposit" && <DepositDetails data={data} />}
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
