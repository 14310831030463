import React from "react"

type spinnerTypes = {
	/**
	 * Float the element
	 */
	float?: string
	/**
	 * The text color of the spinner
	 */
	color?: "text-light" | "text-dark" | "text-primary" | "text-dark"
	/**
	 * The size of the spinner
	 */
	size?: number
	/**
	 * The spinner text that will appear right beside the spinner
	 */
	text?: string
	/**
	 * Weither to display the spinner as a block element
	 */
	block?: boolean
}
/**spin loader  */
export default function ShowSpinner({ float, color, size, text, block }: spinnerTypes) {
	if (size && size.toString().match(/[0-9]/)) {
		return (
			<React.Fragment>
				{block === true && (
					<>
						<section className="d-flex justify-content-center align-items-center w-100">
							<span
								role="status"
								className={`shadow-sm ${color ? color : "text-info"} ${
									typeof float !== "undefined" ? float : ""
								}`}
								style={{ width: `${size}px`, height: `${size}px` }}
							></span>
							<span>{text && text}</span>
						</section>
					</>
				)}

				{!block && (
					<>
						<span
							role="status"
							className={`spinner-border shadow-sm ${color ? color : "text-info"} ${
								typeof float !== "undefined" ? float : ""
							}`}
							style={{ width: `${size}px`, height: `${size}px` }}
						></span>
						<span>{text && text}</span>
					</>
				)}
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			<span
				role="status"
				className={`spinner-border ${color ? color : "text-info"} ${
					typeof float !== "undefined" ? float : ""
				}`}
			></span>{" "}
			<span>{text && text}</span>
		</React.Fragment>
	)
}
