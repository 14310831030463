export const profileEndpoints = {
	CHECK_USERNAME: "/suggest-username",
	UPDATE_PROFILE: "/update-personal-info",
	UPDATE_CONTACT: "/update-contact-info",
	UPDATE_RESIDENT: "/update-residential-info",
	ACCEPT_PRIVACY: "/accept-privacy",
	UPDATE_PROFILE_PHOTO: "/update-profile-photo",
	SET_PIN: "/setup-pin",
	UPDATE_PASSWORD: "/update-password",
	TOGGLE_SETTINGS: "/toggle-security-setting",
	FETCH_PROFILE: "/profile",
	PRE_FETCH_PROFILE: "/pre-profile"
}
