import React, { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { PaperClipIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { useDispatch, useSelector } from "react-redux"
import { getBankPanel, toggleBankPanel } from "redux/reducers/panelsSlice"
import { useMutation } from "react-query"
import { FetchBanks, FetchPaymentMethods } from "services/Api/payments/payment.apis"
import { BankProps } from "types/app.types"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import ShowSpinner from "components/misc/Spinner"
import Switch from "components/input/Switch"
import { UpdateMerchantBank } from "services/Api/merchant/merchant.apis"

export default function SetBankPanel() {
	const { data, params, show } = useSelector(getBankPanel)
	const dispatcher = useDispatch()
	const [banks, setBanks] = React.useState<Array<BankProps>>([])

	const _handleClose = () => dispatcher(toggleBankPanel({ status: false }))

	const fetchBanksApi = useMutation(FetchPaymentMethods, {
		onSuccess(res: any) {
			if (res?.status) {
				setBanks(res?.data)
			} else ShowServerErrors("Error", res)
		}
	})
	const isLoading = fetchBanksApi.isLoading

	React.useEffect(() => {
		if (show) {
			fetchBanksApi.mutateAsync()
		}
	}, [show])

	const updateBankApi = useMutation(UpdateMerchantBank, {
		onSuccess(res: any) {
			if (res?.status) {
				ShowSuccess("Success", res?.message)
				_handleClose()
			} else ShowServerErrors("Error", res)
		}
	})

	const isUpdating = updateBankApi.isLoading

	const _handleUpdate = (bankId: any) => updateBankApi.mutateAsync({ payment_method_id: bankId })

	return (
		<Transition.Root show={show} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={_handleClose}>
				<Transition.Child as={Fragment} enter="ease-in-out duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto relative w-screen max-w-lg">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-500"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-500"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
											<button type="button" className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" onClick={_handleClose}>
												<span className="absolute -inset-2.5" />
												<span className="sr-only">Close panel</span>
												<XMarkIcon className="h-6 w-6" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									<div className="flex h-full right-0 flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="px-4 sm:px-6">
											<Dialog.Title className="text-base font-semibold leading-6 text-gray-900">Update Preferred Bank</Dialog.Title>
										</div>
										<div className="relative mt-6 flex-1 px-4 sm:px-6">
											<div>
												<div className="mt-6 border-t border-gray-100">
													<dl className="divide-y divide-gray-100">
														{isLoading && <ShowSpinner />}

														{!isLoading && (
															<>
																{banks?.length > 0 && (
																	<>
																		{banks?.map(bank => (
																			<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
																				<dt className="text-sm font-semibold leading-6 text-gray-500">{bank?.bank_name}</dt>
																				<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
																					<p className="text-primary">{bank?.account_name}</p>
																					<p className="text-gray-500 text-lg font-bold mb-1">{bank?.account_number}</p>
																					<hr className="my-4" />
																					<Switch
																						isChecked={bank?.default_status?.toString() === "1"}
																						label={isUpdating ? <ShowSpinner size={16} /> : ""}
																						onChange={e => _handleUpdate(bank?.payment_method_id)}
																					/>
																				</dd>
																			</div>
																		))}
																	</>
																)}
															</>
														)}
													</dl>
												</div>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
