import {
	CreateAppeal,
	PostAppealChat,
	PostAppealFileChat,
	FetchAppealChat,
	FetchTickets
} from "./ticket.apis"

export const userTicketApis = () => ({
	CreateAppeal,
	PostAppealChat,
	PostAppealFileChat,
	FetchAppealChat,
	FetchTickets
})
