import DashDesktopContainer from "components/DashDesktopContainer"
import DashSearchForm from "components/layout/nav/DashSearchForm"
import NextPrevPagination from "components/misc/NextPrevPagination"
import React from "react"
import { useMutation } from "react-query"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLoaderData } from "react-router-dom"
import { getMarketNews, setMarketNews } from "redux/reducers/dashboardSlice"
import { userMarketnewsApis } from "services/Api/marketnews/marketnews.index"
import { uniqueId } from "services/utility/Utils/Utils"
import { MarketNewsProps, PaginationResponse } from "types/app.types"

export default function MarketNews() {
	const newsListItems = useSelector(getMarketNews)
	const dispatcher = useDispatch()

	const { FetchNews } = userMarketnewsApis()
	const fetchNewsApi = useMutation(FetchNews, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(setMarketNews(res?.data))
			}
		}
	})

	// @ts-ignore
	const fetchedData: any = useLoaderData()?.data

	const marketNewsData: PaginationResponse<MarketNewsProps> = fetchedData as PaginationResponse<MarketNewsProps>

	const data = newsListItems ? newsListItems?.data : marketNewsData?.data
	const pagination = newsListItems ? newsListItems?.pagination : marketNewsData?.pagination

	const firstNewsItem: MarketNewsProps = data[0]

	return (
		<>
			<DashDesktopContainer title="Market News" page="market_news" showOnMobile>
				<section className="my-4 p-4 bg-white">
					{firstNewsItem && (
						<header className="bk__news-container">
							<section className="bk__news-banner-container" style={{ backgroundImage: `url(${firstNewsItem?.photo}),linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5))` }}>
								<Link to={`/dashboard/market-news/${firstNewsItem?.slug}?tb=mrk`}>
									<h1 className="text-center text-light">{firstNewsItem?.title}</h1>
								</Link>
							</section>
							<p className="my-3 p-2">{firstNewsItem?.excerpt}</p>
						</header>
					)}
					<hr />

					{data && (
						<>
							{data.length > 0 && (
								<>
									{data.map(news => (
										<article key={uniqueId()} className="mb-3">
											<Link to={`/dashboard/market-news/${news?.slug}?tb=mrk`}>
												<div className="row">
													<div className="col-lg-4 col-xl-4 col-xxl-4 mb-2">
														<img className="rounded" src={news?.photo} alt={news?.title} height="200" width="100%" />
													</div>
													<div className="col d-flex justify-content-start align-items-center">
														<div>
															<h6>
																<strong>{news?.title}</strong>
															</h6>
															<p className="text-muted">{news?.excerpt}</p>
															<ul className="list-inline d-flex">
																<li className="list-inline-item text-muted px-1 border-end">{news?.date}</li>
																<li className="list-inline-item text-primary">creator: {news?.creator}</li>
															</ul>
														</div>
													</div>
												</div>
											</Link>
										</article>
									))}
								</>
							)}
						</>
					)}

					<NextPrevPagination pagination={pagination} paginator={fetchNewsApi} />
				</section>
			</DashDesktopContainer>

			<DashSearchForm page="market_news" />
		</>
	)
}
