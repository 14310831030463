import { FLUX_DYNAMIC_STORE, useFlusDispatcher, useFlusStores } from "@bilmapay/react-flux-storage"
import CardNavigator from "components/CardNavigator"
import BankingDetails from "components/misc/BankingDetails"
import ShowSpinner from "components/misc/Spinner"
import React from "react"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { userMarketplaceApis } from "services/Api/marketplace/marketplace.index"
import { useUrlQuery } from "services/utility/hooks/useURL"
import { decryptString } from "services/utility/Utils/utilities"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import { MarketplaceAssetProps } from "types/app.types"
import classNames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { getAssetQuantity, setAssetQuantity } from "redux/reducers/dashboardSlice"

export default function DesktopSellCoin() {
	const assetQuty = useSelector(getAssetQuantity)
	const dispatcher = useDispatch()
	/* Get the paload data */
	const { pl } = useUrlQuery()

	const navigate = useNavigate()

	const data: MarketplaceAssetProps = JSON.parse(decryptString(pl))

	const { SellAsset, FetchQuantity } = userMarketplaceApis()

	const fetchAssetQutyApi = useMutation(FetchQuantity, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(setAssetQuantity(res?.data))
			}
		}
	})

	const handleAssetQntyFetching = (e: React.ChangeEvent<HTMLInputElement>) => {
		const fd = new FormData()

		fd.append("amount", e.target.value)
		fd.append("offer_id", data?.offer_id)
		fd.append("type", "sell")

		fetchAssetQutyApi.mutateAsync(fd)
	}

	// ----------------------------------------------

	const sellAssetApi = useMutation(SellAsset, {
		onSuccess: (res: any) => {
			if (res?.status) {
				ShowSuccess("Sell Asset", res?.message)

				navigate(`/dashboard/sell-coin-details?ot=${res?.data?.order_token}`)
			} else ShowServerErrors("Sell Asset", res)
		}
	})

	const isSubmmiting = sellAssetApi.isLoading

	const handleAssetSeling = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		sellAssetApi.mutateAsync(formData)
	}

	return (
		<section>
			<header className="pt-2">
				<div className="alert alert-warning alert-dismissible shadow-sm" role="alert">
					<button className="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
					<h5 className="alert-heading text-danger">
						<strong>Info</strong>
					</h5>
					<span>Kindly ensure to read through the trading terms before you proceed to avoid loss of asset and finance</span>
				</div>
			</header>

			<CardNavigator returnLink="/dashboard">
				<h3 className="card-title mb-3 text-center text-primary">
					<strong>Sell {data?.asset_symbol}</strong>
				</h3>
				<div>
					<ul className="list-inline d-flex">
						<li className="list-inline-item">Rate per USD:</li>
						<li className="list-inline-item text-primary">{data.rate_per_coin} NGN</li>
						<li className="list-inline-item text-primary">
							<svg className="ms-3 refresh-icon" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
								<path
									d="M4 4V9H4.58152M19.9381 11C19.446 7.05369 16.0796 4 12 4C8.64262 4 5.76829 6.06817 4.58152 9M4.58152 9H9M20 20V15H19.4185M19.4185 15C18.2317 17.9318 15.3574 20 12 20C7.92038 20 4.55399 16.9463 4.06189 13M19.4185 15H15"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								></path>
							</svg>
						</li>
					</ul>
					<ul className="list-inline d-flex">
						<li className="list-inline-item">Limit:</li>
						<li className="list-inline-item text-primary">{data.trade_limit} NGN</li>
					</ul>
					<ul className="list-inline d-flex">
						<li className="list-inline-item">Needed:</li>
						<li className="list-inline-item text-primary">{data.remaining_quantity} NGN</li>
					</ul>
				</div>
				<form className="mt-3" onSubmit={handleAssetSeling}>
					<div>
						<div className="form-group">
							<label className="form-label w-100 input-label text-muted">
								<strong>Amount to sell</strong>
								<br />
							</label>
							<input className="form-control form-control-lg" type="number" step="any" name="amount" onChange={handleAssetQntyFetching} />

							<span className="img-icon">
								<strong>Max</strong>
							</span>
						</div>

						<div className="form-group">
							<input type="hidden" name="quantity" value={assetQuty?.crypto_amount} onChange={() => {}} />
							<input type="hidden" name="offer_id" value={data?.offer_id} onChange={() => {}} />
						</div>
						<span className="text-danger">The amount you enter must be greater than the minimum sell limit</span>
					</div>
					<div className="table-responsive">
						<table className="table table-borderless">
							<tbody>
								<tr>
									<td>Payment Method:</td>
									<td className="text-end text-primary">{data.payment_method}</td>
								</tr>
								<tr>
									<td>Total Quantity:</td>
									<td className="text-end text-primary">
										{assetQuty?.crypto_amount} {data.asset_symbol}
									</td>
								</tr>
								<tr>
									<td>Amount expected:</td>
									<td className="text-end text-primary">{assetQuty?.fait_amount} NGN</td>
								</tr>
							</tbody>
						</table>
					</div>
					<BankingDetails />

					<div className="my-4">
						<button disabled={isSubmmiting} className="btn btn-primary btn-lg w-100 py-3" type="submit">
							{!isSubmmiting && <strong>Sell {data.asset_symbol}</strong>}

							{isSubmmiting && <ShowSpinner color="text-light" />}
						</button>
					</div>
				</form>
				<article className="mb-4 mt-4 bg-gray p-3">
					<h5 className="text-success">
						<strong>Trust Score ({data.percentage_score})</strong>
					</h5>
					<div className="table-responsive">
						<table className="table table-borderless">
							<tbody>
								<tr>
									<td>
										<strong>Seller Nick:</strong>
									</td>
									<td className="text-end text-primary">{data.nick_name}</td>
								</tr>
								<tr>
									<td>
										<strong>Response time (Avg):</strong>
									</td>
									<td className="text-end text-primary">{data.response_time}</td>
								</tr>
								<tr>
									<td>
										<strong>Payment Method:</strong>
									</td>
									<td className="text-end text-primary">{data.payment_method}</td>
								</tr>
								<tr>
									<td>
										<strong>Rating:</strong>
									</td>
									<td className="text-end">
										<i
											className={classNames({
												"fa fa-star rating-star": true,
												stared: parseInt(data?.rating) >= 1
											})}
										></i>
										<i
											className={classNames({
												"fa fa-star rating-star": true,
												stared: parseInt(data?.rating) >= 2
											})}
										></i>
										<i
											className={classNames({
												"fa fa-star rating-star": true,
												stared: parseInt(data?.rating) >= 3
											})}
										></i>
										<i
											className={classNames({
												"fa fa-star rating-star": true,
												stared: parseInt(data?.rating) >= 4
											})}
										></i>
										<i
											className={classNames({
												"fa fa-star rating-star": true,
												stared: parseInt(data?.rating) === 5
											})}
										></i>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<h5 className="text-dark">
						<strong>Trading Terms</strong>
					</h5>
					<p>{data.trading_terms}</p>
				</article>
			</CardNavigator>
		</section>
	)
}
