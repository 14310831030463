import CardNavigator from "components/CardNavigator"
import React from "react"
import { useMutation } from "react-query"
import { userTransactionApi } from "services/Api/transactions/transactions.index"
import { useUrlQuery } from "services/utility/hooks/useURL"
import { FLUX_DYNAMIC_STORE, useFlusDispatcher, useFlusStores } from "@bilmapay/react-flux-storage"
import AwaitResponse from "components/AwaitResponse"
import { TransactionDetailsProps, TransactionProps } from "types/app.types"
import { uniqueId } from "services/utility/Utils/Utils"
import EmptyContentMessage from "components/EmptyContentMessage"
import { ShowServerErrors } from "services/utility/Utils/_toaster"
import { TRANS_DETAILS } from "flus/constants/modals.id.const"
import TransactionDetail from "../history/TransactionDetails"
import { useDispatch, useSelector } from "react-redux"
import { getAssetTransactions, setAssetTransactions } from "redux/reducers/walletSlice"
import { setTransactionDetails } from "redux/reducers/dashboardSlice"

export default function WalletHistory() {
	/* hp ==> History Params */
	const { from, hp } = useUrlQuery()
	const dispatcher = useDispatch()

	const { FetchTransaction, FetchTransactionDetails } = userTransactionApi()

	const fetchTransApis = useMutation(FetchTransaction, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(setAssetTransactions(res?.data))
			}
		}
	})

	const fetchTransDetails = useMutation(FetchTransactionDetails, {
		onSuccess: (res: any) => {
			if (res?.status) {
				let tranxDetails: TransactionDetailsProps | object = {}

				switch (res?.data?.mode?.toLowerCase()) {
					case "transfer":
						tranxDetails = { ...res?.data, ...res?.data?.transfer }
						break
					case "receive":
						tranxDetails = { ...res?.data, ...res?.data?.receive }
						break
					case "sell":
						tranxDetails = { ...res?.data, ...res?.data?.sell }
						break

					case "send":
						tranxDetails = { ...res?.data, ...res?.data?.send }
						break

					case "buy":
						tranxDetails = { ...res?.data, ...res?.data?.buy }
						break
					default:
				}

				dispatcher(setTransactionDetails(tranxDetails))
			} else ShowServerErrors("Error", res)
		}
	})

	const handleTransFetching = async () => {
		return await fetchTransApis.mutateAsync(hp)
	}

	const handleTransDetailsFetching = (e: React.MouseEvent<HTMLAnchorElement>, transId: string) => {
		e.preventDefault()

		const fd = new FormData()
		fd.append("transaction_id", transId)

		fetchTransDetails.mutateAsync(fd)
	}

	const data = useSelector(getAssetTransactions)

	return (
		<CardNavigator returnLink={from}>
			<section>
				<h3 className="mb-3">
					<strong>History</strong>
				</h3>
				<div className="table-responsive">
					<AwaitResponse api={handleTransFetching}>
						<table className="table table-striped">
							<thead>
								<tr>
									<th>Date</th>
									<th>Mode</th>
									<th>Amount</th>
									<th>Status</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{data && (
									<>
										{data?.length > 0 && (
											<>
												{data.map(item => (
													<tr key={uniqueId()}>
														<td className="text-truncate">{item?.date}</td>
														<td className="text-truncate">{item?.mode}</td>
														<td className="text-truncate">{item?.amount}</td>
														<td className="bk-text-success text-truncate">{item?.status}</td>
														<td className="text-primary text-truncate">
															<a data-bs-toggle="modal" href={`#${TRANS_DETAILS}`} onClick={e => handleTransDetailsFetching(e, item?.transaction_id)}>
																View details
															</a>
														</td>
													</tr>
												))}
											</>
										)}
									</>
								)}
							</tbody>
						</table>
						{data ? data?.length <= 0 && <EmptyContentMessage text="No transactions yet" /> : ""}
					</AwaitResponse>
				</div>
			</section>
			<TransactionDetail />
		</CardNavigator>
	)
}
