import { createSlice } from "@reduxjs/toolkit"
import { AppState } from "redux/appGlobalStore"

type DepositSliceState = {
	client: {
		steps: "decline" | "completed" | "deposit" | "awaiting_acceptance" | "deposit_details" | "upload_pop" | "awaiting_release" | "escalate"
		timer?: NodeJS.Timer
		data?: any
	}
	merchant: {
		steps: "decline" | "completed" | "accept_order" | "awaiting_deposit" | "confirm_order" | "release"
		timer?: NodeJS.Timer
		data?: any
	}
}
const initialState: DepositSliceState = {
	client: {
		steps: "deposit"
	},
	merchant: {
		steps: "accept_order"
	}
}

export const depositSlice = createSlice({
	name: "deposit",
	initialState,
	reducers: {
		changeClientStep(state, action) {
			state.client.steps = action?.payload
		},

		changeMerchantStep(state, action) {
			state.merchant.steps = action?.payload
		},

		setMerchantDepositTimer(state, action) {
			state.merchant.timer = action.payload
		},

		stopMerchantDepositTimer(state, action) {
			if (state.merchant.timer) {
				window.clearInterval(state.merchant.timer)
			}

			if (action.payload) {
				state.merchant.data = action?.payload
			}
		},

		setClientDepositTimer(state, action) {
			state.client.timer = action.payload
		},

		stopClientDepositTimer(state, action) {
			if (state.client.timer) {
				window.clearInterval(state.client.timer)
			}

			if (action.payload) {
				state.client.data = action?.payload
			}
		}
	}
})

export const { setClientDepositTimer, stopClientDepositTimer, setMerchantDepositTimer, stopMerchantDepositTimer, changeClientStep, changeMerchantStep } = depositSlice.actions

export const getClientCurrentStep = (state: AppState) => state?.deposit?.client?.steps
export const getMerchanCurrentStep = (state: AppState) => state?.deposit?.merchant?.steps
export const getMerchanDepositData = (state: AppState) => state?.deposit?.merchant?.data
export const getClientDepositData = (state: AppState) => state?.deposit?.client?.data
