import React, { MouseEventHandler } from "react"
import { Link } from "react-router-dom"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { handleMainMobileNavToggle } from "services/utility/Utils/appNav"

export default function MainMobileNavToggler() {
	const { LOGO } = useFlusStores()

	return (
		<nav className="navbar navbar-light navigation-clean-button bp-mob-navbar">
			<div className="container">
				<Link className="navbar-brand" to="/">
					<img className="bp_logo" src={LOGO} alt="Beekle" />
				</Link>
				<button
					className="navbar-toggler bp_mob-toggler"
					data-bs-toggle="collapse"
					data-bs-target="#navcol-2"
					onClick={handleMainMobileNavToggle}
				>
					<span className="visually-hidden">Toggle navigation</span>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div id="navcol-2" className="collapse navbar-collapse"></div>
			</div>
		</nav>
	)
}
