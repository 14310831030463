export const marketplaceEndpoints = {
	FETCH_SELL_MARKETPLACE: (assetId?: string, page: string = "1") => `/market-place/sell/${assetId}?page=${page}`,
	FETCH_BUY_MARKETPLACE: (assetId?: string, page: string = "1") => `/market-place/buy/${assetId}?page=${page}`,
	FETCH_ASSET_QUANTITY: `/fetch-asset-qty`,
	BUY_ASSET: `/buy-asset`,
	BUY_ASSET_DETAILS: `/buy-order-details`,
	PAY_ASSET: `/pay-asset`,
	CANCEL_ORDER: `/cancel-order`,
	SELL_ASSET: `/sell-asset`,
	SELL_ASSET_DETAILS: `/sell-order-details`,
	RELEASE_ASSETS: `/release-asset`,
	AWAIT_FUND_RELEASE: `/awaiting-fund-release`,
	AWAIT_PAYMENT_RELEASE: `/awaiting-payment-release`,
	CREATE_CHAT: `/create-chat`,
	CREATE_FILE_CHAT: `/create-file-chat`,
	GET_CHAT: `/get-chat`
}
