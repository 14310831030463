
import React from "react"
import { sleep } from "services/utility/Utils/_sleep"
import { useFlusStores } from "@bilmapay/react-flux-storage"

const wedgeSrc: string = "https://crypto.com/price/static/widget/index.js"

function ConverterSection() {
    const { sell_crypto } = useFlusStores()
    const { padlock, clipboard, rocket } = useFlusStores()

    React.useEffect(() => {
        const scriptTag = document.createElement("script")
        const bodyTag = document.querySelector<HTMLBodyElement>("body")

        sleep(3000).then(() => {
            scriptTag.src = wedgeSrc
            if (bodyTag) {
                bodyTag.append(scriptTag)
            }
        })
    })

    return (

        <section className="bg-white flex flex-row flex-wrap items-center py-4 ">

            <div className="w-full sm:w-1/2  flex flex-column items-start pl-5 sm:pl-20">

            <p className=" mb-4  text-left text-blue-700 text-4xl sm:w-[75%] tracking-wide leading-tight font-link">Buy and Sell Cryptocurrency and Gift Cards on Beekle.</p>
                
                <div className="">
                    <p className="text-justify w-[98%] sm:w-[75%] tracking-wide leading-relaxed  text-lg sm:text-xl ">Exchange gift cards for instant Naira. Get 3,000 Naira cashback after your first 10 gift card trades. Elevate your life - join us today!</p>
                </div>

            </div>


            <div className="w-full sm:w-1/2 ">
                <img className="img-fluid block m-auto" src={sell_crypto} alt="" />
            </div>

        </section>


    // <section className=" pt-16 pb-24 space-y-12 bg-gray flex flex-column items-center">

    //     <div className="flex flex-column items-center">
    //         <strong className="text-center font-link text-4xl text-blue-700 mb-1">Buy and Sell </strong>
    //         <strong className="text-center font-link text-4xl text-blue-700 mb-4">Cryptocurrency and</strong>
    //         <strong className="text-center font-link text-4xl text-blue-700 mb-4">Gift Cards on Beekle</strong>
    //         <p className="text-center text-xl ">why you should choose us</p>        
    //     </div>


    //     <div className="container">

    //         <div className="row">

    //             <div className="col-md-4 mb-3">
    //                 <div className="card">
    //                     <div className="card-body flex flex-column items-center ">
    //                         <img src={padlock} alt="INSTANT DEPOSIT" width="50" className="block m-auto" />
    //                         <strong className="mb-2 font-link text-justify text-blue-700">Top-Tier Security</strong>
    //                         <p className="card-text">We've gone to great lengths to create Beekle as Nigeria’s most secure exchange. Our dedication includes periodic security audits to ensure a highly secure trading platform for the Nigerian market.</p>
    //                     </div>
    //                 </div>
    //             </div>

    //             <div className="col-md-4 mb-3">
    //                 <div className="card">
    //                     <div className="card-body flex flex-column items-center">
    //                         <img className="block m-auto" src={clipboard} alt="SECURED TRADING" width="50" />

    //                         <strong className="mb-2 text-blue-700 font-link text-center">Super Fast KYC</strong>
    //                         <p className="card-text">
    //                             After sign-up, our advanced identity verification swiftly completes your KYC, ensuring adherence to proper protocols. Our system's robustness significantly reduces verification times.
    //                         </p>
    //                     </div>
    //                 </div>
    //             </div>

    //             <div className="col-md-4 pb-3">
    //                 <div className="card">
    //                     <div className="card-body flex flex-column items-center">
    //                         <img className="block m-auto" src={rocket} alt="SWIFT WITHDRAWAL" width="50" />

    //                         <strong className="mb-2 text-blue-700 font-link text-center">Quick Transaction</strong>
    //                         <p className="card-text">Beekle is capable of handling millions of transactions. To meet surge demand, our system infrastructure can be scaled up in a matter of seconds.</p>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>

    //     </div>
    // </section>
    
    
    )
}

export default ConverterSection
