import ShowSpinner from "components/misc/Spinner"
import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { userKYCVerificatinApi } from "services/Api/kyc/kyc.index"
import { ShowInfo, ShowServerErrors } from "services/utility/Utils/_toaster"

export default function PORVerification() {
	const { LocationIcon, UploadImg } = useFlusStores()

	const { KYCLevelTwoUpload } = userKYCVerificatinApi()
	const navigate = useNavigate()

	const kycLevelTwoUploadApi = useMutation(KYCLevelTwoUpload, {
		onSuccess: res => {
			if (res?.status) {
				navigate("/dashboard/verification")
				ShowInfo(
					"KYC Verification",
					"Your KYC verification documents has been submitted successully!"
				)
			} else ShowServerErrors("KYC Verification", res)
		}
	})

	const isProcessingKYC = kycLevelTwoUploadApi.isLoading

	const handleUserKYCUpload = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		kycLevelTwoUploadApi.mutateAsync(formData)
	}

	const fileInputRef = React.createRef<HTMLInputElement>()
	const fileNamePlaceholder = React.createRef<HTMLParagraphElement>()
	const triggerFileSelectorRef = React.createRef<HTMLImageElement>()

	React.useEffect(() => {
		triggerFileSelectorRef?.current?.addEventListener("click", function () {
			fileInputRef?.current?.click()
		})

		fileInputRef?.current?.addEventListener("change", function (this: HTMLInputElement, e: any) {
			if (e.target?.files) {
				const selectedFile = e?.target?.files[0]

				if (fileNamePlaceholder?.current) {
					fileNamePlaceholder.current.innerText = selectedFile.name
				}
			}
		})
	})

	return (
		<section className="p-3 bg-white">
			<header className="mb-3">
				<img
					className="img-fluid m-auto d-block mb-4"
					src={LocationIcon}
					alt=""
					width="150"
					height="150"
				/>
				<h1 className="text-center">
					<strong>PROOF OF ADDRESS</strong>
					<br />
				</h1>
				<p>
					Provide a residential document that correlates with your profile address to complete this
					stage.
				</p>
				<p className="lead ms-5">
					<i className="fa fa-check me-2 verif-item-icon allowed"></i>Valid Address
				</p>
				<p className="lead ms-5">
					<i className="fa fa-check me-2 verif-item-icon allowed"></i>clearly Written
				</p>
				<p className="lead ms-5">
					<i className="fa fa-check me-2 verif-item-icon allowed"></i>Stamped Document
				</p>
				<p className="lead ms-5">
					<i className="fa fa-times-circle me-2 verif-item-icon not-allowed"></i>Public Address
				</p>
				<p className="lead ms-5">
					<i className="fa fa-times-circle me-2 verif-item-icon not-allowed"></i>Handwritten Address
				</p>
			</header>
			<form className="py-4" onSubmit={handleUserKYCUpload}>
				<div className="mb-4">
					<h2>
						<strong>Document Type</strong>
					</h2>
					<select className="form-select form-select-lg" name="document_type" required>
						<option value="Bank statement">Bank statement</option>
						<option value="Ultility Bill">Ultility Bill</option>
						<option value="Rent Agreement">Rent Agreement</option>
					</select>
				</div>
				<section className="text-center">
					<img
						className="img-fluid m-auto my-3 d-block pointer"
						src={UploadImg}
						alt=""
						width="500"
						ref={triggerFileSelectorRef}
					/>
					<p ref={fileNamePlaceholder} className="text-info mt-3">
						fileName
					</p>

					<input type="file" name="document" hidden ref={fileInputRef} />
				</section>
				<button
					disabled={isProcessingKYC}
					className="btn btn-primary btn-lg m-auto d-block w-75 bg-blue mt-5"
					type="submit"
				>
					{!isProcessingKYC && "Upload"}
					{isProcessingKYC && <ShowSpinner color="text-light" />}
				</button>
			</form>
		</section>
	)
}
