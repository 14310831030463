/** Generate random colors */
const generateRandomColor = () => {
	return `#${Math.floor(Math.random() * 16777215).toString(16)}`
}

/**
 * Get the avatar text
 * @param {string } longText The given text to extract only two chars from as avatar image.
 */
const getAvatarText = (longText = "", length = 2) => {
	const text = longText !== "" && longText !== null ? longText : "Avatar Image"
	// get the only two char from each first words
	const words = text.split(" ", length)
	if (words.length >= length) {
		const chars = words.map(word => {
			// return first char if each word
			return word.length >= length ? word[0] : word[0]
		})

		return chars.join("").toUpperCase()
	} else {
		return words
			.map(word => (word.length >= length ? `${word[0]}` : word[0]))
			.join("")
			.toUpperCase()
	}
}

/**
 * Generate random avatar images
 * @param {stirng} text The text to use as avatar.
 * @param {string} foregroundColor The text color default to white
 * @param {string} backgroundColor The background color to use default to black
 */
export const TextAvatar = (text = "AV", foregroundColor = "white", backgroundColor = "black", len = 2) => {
	const canvas = document.createElement("canvas")
	const context = canvas.getContext("2d")

	canvas.width = 200
	canvas.height = 200

	// Draw background
	context.fillStyle = backgroundColor === "" || backgroundColor === "black" ? generateRandomColor() : backgroundColor
	context.fillRect(0, 0, canvas.width, canvas.height)

	// Draw text
	context.font = "bold 100px Assistant"
	context.fillStyle = foregroundColor === "" || foregroundColor === null ? "white" : foregroundColor
	context.textAlign = "center"
	context.textBaseline = "middle"
	context.fillText(getAvatarText(text, len), canvas.width / 2, canvas.height / 2)

	return canvas.toDataURL("image/png")
}
