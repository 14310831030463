import PageInfo from "components/misc/Page"
import ShowSpinner from "components/misc/Spinner"
import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { useAuthApis } from "services/Api/auth/auth.apis.index"
import { useUrlQuery } from "services/utility/hooks/useURL"
import { ShowError, ShowSuccess } from "services/utility/Utils/_toaster"

export default function LinkPasswordVerificationPage() {
	const { LOGO, VerificationImg } = useFlusStores()

	const navigate = useNavigate()
	const { token } = useUrlQuery()
	const { VerifyPasswordReset } = useAuthApis()

	const verifyUserPasswordResetLinkApi = useMutation(VerifyPasswordReset, {
		onSuccess: (res, params: FormData) => {
			if (res?.status) {
				ShowSuccess("Verification", `Success! Password reset operation verified.`)

				navigate(`/auth/create-new-pwd?token=${params.get("reset_token")}`)
			} else {
				//@ts-ignore
				switch (res?.code) {
					case 422:
						ShowError("Verification", "Password reset token is required.")
						break
					case 423:
						ShowError("Verification", "Password reset link is invalid or has expired.")
						break

					default:
				}
			}
		}
	})

	const isVerifying = verifyUserPasswordResetLinkApi.isLoading

	const handleUserVerifyAction = () => {
		const fd = new FormData()
		fd.append("reset_token", token)
		verifyUserPasswordResetLinkApi.mutate(fd)
	}

	return (
		<section>
			<PageInfo titleText="Reset Password" />
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-9 col-lg-12 col-xl-10">
						<div className="card o-hidden border-0 my-5">
							<div className="card-body p-0">
								<div className="row">
									<div className="col-lg-6 d-none d-lg-flex p-4 bg-light-blue">
										<div className="d-flex flex-grow-1 justify-content-center align-items-center auth-bg-image">
											<img src={VerificationImg} alt="" width="350" />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="p-5">
											<div className="text-center mb-5">
												<img src={LOGO} alt="" width="50" />
												<h4 className="text-dark mb-4 mt-3">Reset Password</h4>
												<p className="text-center p-2">
													Verify your account password reset by clicking "<strong>Verify</strong>" below
												</p>
											</div>

											<div className="mt-3">
												<button className="btn btn-primary btn-lg d-block login-btn w-100 bg-blue" type="button" onClick={handleUserVerifyAction} disabled={isVerifying}>
													{!isVerifying && "Verify"}
													{isVerifying && <ShowSpinner color="text-light" />}
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
