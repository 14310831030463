import DashDesktopContainer from "components/DashDesktopContainer"
import React from "react"
import AddBankAccountModal from "views/dashboard/create-offer/AddBankAccountModal"
import DesktopBuyCoin from "./DesktopBuyCoin"

export default function BuyCoin() {
	return (
		<>
			<DashDesktopContainer showTitle={false} showOnMobile>
				<DesktopBuyCoin />
			</DashDesktopContainer>

			<AddBankAccountModal />
		</>
	)
}
