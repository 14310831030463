import { createSlice } from "@reduxjs/toolkit"
import { AssetDropdownItems } from "components/input/AssetDropdown"
import { AppState } from "redux/appGlobalStore"
import { AssetInterface } from "types"

type AssetState = {
	list?: Array<AssetDropdownItems>
}

const initialState: AssetState = {
	list: []
}

export const assetSlice = createSlice({
	name: "assets",
	initialState,
	reducers: {
		setAssetsList(state, action) {
			const _data = action.payload.map((item: AssetInterface) => ({
				name: item?.name,
				symbol: item?.symbol,
				asset_id: item.asset_id
			}))

			state.list = _data
		}
	}
})

export const { setAssetsList } = assetSlice.actions

export const getAssetList = (state: AppState) => state?.assets.list
