import React from "react"
import { Navigate, useLoaderData } from "react-router-dom"
import { ShowInfo } from "services/utility/Utils/_toaster"
import { useSelector } from "react-redux"
import { getUserActiveState } from "redux/reducers/appSlice"

export default function ProtectedRoute({
	children
}: {
	children?: React.ReactNode
}) {
	const isActive = useSelector(getUserActiveState)

	const profileResponse: any = useLoaderData()

	switch (profileResponse?.code) {
		case 425:
			// personal info not set
			ShowInfo("Profile Info", "Please update your Profile info.")
			return <Navigate to={"/onboard/personal-info"} />
		case 426:
			// residential info not set
			ShowInfo("Residential Info", "Please update your residential info.")

			return <Navigate to={"/onboard/location-info"} />
		case 427:
			// contact info required
			ShowInfo("Contact Info", "Please update your contact info.")

			return <Navigate to={"/onboard/others-info"} />
		case 428:
			// Privacy required.
			ShowInfo("Privacy", "Please accept our User Privacy and Policy.")

			return <Navigate to={"/onboard/review"} />

		default:
	}

	if (!isActive) {
		return <Navigate to={"/auth"} />
	}

	return children
}
