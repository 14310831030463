import React from "react"
import { Link } from "react-router-dom"

export interface CardNavigatorInterface {
	children?: React.ReactNode
	/**
	 * The return link that when users click will return them
	 * to previus ui component
	 */
	returnLink: string
	showHistory?: boolean
	historyParams?: string
}
export default function CardNavigator({ children, returnLink, showHistory = false, historyParams }: CardNavigatorInterface) {
	const goBack = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault()

		window?.history?.back()
	}
	return (
		<div className="card mt-4 mb-3 ">
			<div className="card-header  d-flex justify-content-start align-items-center">
				<h3 className="me-3 w-100">
					<Link className="navigate-back-icon" to={returnLink && returnLink} onClick={goBack}>
						<i className="fas fa-arrow-circle-left"></i>
					</Link>
					{showHistory && (
						<span className="float-end">
							<i className="far fa-clock text-muted bk__history-icon"></i>
							<a href={`/dashboard/wallet-history?from=${returnLink}&hp=${historyParams}`} className="ms-1 bk__history-text">
								History
							</a>
						</span>
					)}
				</h3>
			</div>
			<div className="card-body py-3 px-2">{children}</div>
		</div>
	)
}
