import { FLUX_DYNAMIC_STORE, useFlusDispatcher } from "@bilmapay/react-flux-storage"
import classNames from "classnames"
import { MY_OFFER_DETAILS } from "flus/constants/modals.id.const"
import { useMutation } from "react-query"
import { userOfferApis } from "services/Api/offer/offer.index"
import ShowSpinner from "./Spinner"
import { useDispatch } from "react-redux"
import { setOfferDetailsParam } from "redux/reducers/dashboardSlice"

type MyOfferAssetColProps = { icon: string; text: string }

export const MyOfferAssetCol = ({ icon, text }: MyOfferAssetColProps) => {
	return (
		<div className="d-flex align-statuss-center">
			<img className="img-fluid me-2" src={icon} style={{ width: "25px", height: "25px", borderRadius: "100%" }} alt="" />
			<span>{text}</span>
		</div>
	)
}

type MyOfferStatusPrps = {
	status: string
	offerId: string
	toggler?: (param: string) => void
}
export const MyOfferStatus = ({ toggler, status, offerId }: MyOfferStatusPrps) => (
	<span
		onClick={() => {
			if (toggler) {
				toggler(offerId)
			}
		}}
		className={classNames({
			"badge rounded-pill my-2 pointer": true,
			"bg-success": status?.toLocaleLowerCase() === "active",
			"bg-danger": status?.toLocaleLowerCase() === "inactive",
			"bg-primary": status?.toLocaleLowerCase() === "completed"
		})}
	>
		{status}
	</span>
)

export const ViewMyOfferDetails = ({ offerId, type }: { offerId: string; type?: string }) => {
	const dispatcher = useDispatch()

	const { FetchMyOfferDetails } = userOfferApis()

	const fetchOfferDetailsApi = useMutation(FetchMyOfferDetails, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(setOfferDetailsParam(res?.data))
			}
		}
	})

	const isFetching = fetchOfferDetailsApi.isLoading

	const toggleOfferDetailsModal = () => {
		const fd = new FormData()
		fd.append("offer_id", offerId)

		fetchOfferDetailsApi.mutateAsync(fd)
	}

	return (
		<span onClick={toggleOfferDetailsModal} className="text-primary pointer" data-bs-target={`#${MY_OFFER_DETAILS}`} data-bs-toggle="modal">
			{!isFetching && "View Details"}
			{isFetching && <ShowSpinner />}
		</span>
	)
}
