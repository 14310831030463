import ShowSpinner from "components/misc/Spinner"
import React from "react"
import { useMutation } from "react-query"
import { EscalateDepositDispute } from "services/Api/cashbridge/cashbridge.apis"
import { sleep } from "services/utility/Utils/_sleep"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"

type EscalateDepositFormProps = {
	tranxId?: string
}
export default function EscalateDepositForm({
	tranxId
}: EscalateDepositFormProps) {
	const reloadPage = () => window.location?.reload()

	const escalateDepositFormApi = useMutation(EscalateDepositDispute, {
		onSuccess(res: any) {
			if (res?.status) {
				ShowSuccess("Success", res?.message)

				sleep(300).then(reloadPage)
			} else ShowServerErrors("Error", res)
		}
	})

	const isLoading = escalateDepositFormApi.isLoading

	const _handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const _data = new FormData(e.target)
		_data.append("txn_id", tranxId as string)

		escalateDepositFormApi.mutateAsync(_data)
	}
	return (
		<>
			<form className="space-y-6 xs:p-3 md:p-5" onSubmit={_handleSubmit}>
				<div className="grid grid-cols-1 gap-4">
					<div className="form-group">
						<label
							htmlFor="form_amountInput"
							className="form-label w-100 input-label"
						>
							Deposit Amount
						</label>
						<input
							id="form_amountInput"
							name="amount"
							type="number"
							className="form-control form-control-lg form-control-user"
						/>
					</div>

					<div className="form-group">
						<label
							htmlFor="form_phoneInput"
							className="form-label w-100 input-label"
						>
							Phone Number
						</label>
						<input
							id="form_phoneInput"
							name="phone"
							type="tel"
							className="form-control form-control-lg form-control-user"
						/>
					</div>

					<div className="form-group">
						<label
							htmlFor="form_emailInput"
							className="form-label w-100 input-label"
						>
							Email Address
						</label>
						<input
							id="form_emailInput"
							name="email"
							type="email"
							className="form-control form-control-lg form-control-user"
						/>
					</div>

					<div className="form-group">
						<label
							htmlFor="form_reasonInput"
							className="form-label w-100 input-label"
						>
							Reason
						</label>
						<textarea
							id="form_reasonInput"
							name="reason"
							className="form-control form-control-lg form-control-user"
						></textarea>
					</div>
				</div>

				<button
					className="bg-blue-500 disabled:bg-blue-500/50 mb-3 inline-block w-fit rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
					type="submit"
					data-te-ripple-init
					data-te-ripple-color="light"
				>
					{!isLoading && "Submit"}
					{isLoading && <ShowSpinner />}
				</button>
			</form>
		</>
	)
}
