import React, { Fragment, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { useDispatch, useSelector } from "react-redux"
import {
	getBuyAssetModal,
	toggleBuyAssetModal
} from "redux/reducers/modalSlice"

import * as yup from "yup"
import ShowSpinner from "components/misc/Spinner"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { useMutation } from "react-query"
import {
	BuyAssets,
	FetchBuyCointsAssets,
	FetchBuyRates
} from "services/Api/asset/asset.apis"
import { AssetInterface } from "types"
import { uniqueId } from "services/utility/Utils/Utils"
import {
	ShowInfo,
	ShowServerErrors,
	ShowSuccess
} from "services/utility/Utils/_toaster"
import { NAIRA_SIGN } from "libs/currency"
import { getAssetList, setAssetsList } from "redux/reducers/assetSlice"
import AssetDropdown, {
	AssetDropdownItems
} from "components/input/AssetDropdown"

const amountValidatorScheme = yup.object().shape({
	asset_quantity: yup.string().required("Required."),
	fait_amount: yup.string().required("Required."),
	asset_id: yup.string().required("Required.")
})

type InitFormDataProps = {
	fait_amount: string
	asset_quantity: string
	asset_id: string
}

const fromInputRef = React.createRef<HTMLInputElement>()
const toInputRef = React.createRef<HTMLInputElement>()

export default function BuyAssetModal() {
	const dispatcher = useDispatch()
	const open = useSelector(getBuyAssetModal)
	const setOpen = (state: boolean) => dispatcher(toggleBuyAssetModal(state))

	const [assetId, setAssetId] = React.useState<string>()

	const _onSelect = (item: AssetDropdownItems) => {
		if (fromInputRef?.current && toInputRef?.current) {
			fromInputRef.current.value = ""
			toInputRef.current.value = ""
		}

		setAssetId(item?.asset_id)
	}

	const assets = useSelector(getAssetList)

	const cancelButtonRef = useRef(null)

	// ------------------------------------------>

	const fetchBuyAssetCoinsApi = useMutation(FetchBuyCointsAssets, {
		onSuccess(res: any) {
			if (res?.status) dispatcher(setAssetsList(res?.data))
		}
	})

	React.useEffect(() => {
		if (open) fetchBuyAssetCoinsApi.mutateAsync()
	}, [open])

	const fetchRateApi = useMutation(FetchBuyRates, {
		onSuccess(res: any, data) {
			if (res?.status) {
				const from = data?.get("from")

				switch (from) {
					case "fait":
						if (toInputRef?.current) {
							toInputRef.current.value = res?.data?.asset_quantity
						}
						break
					case "coin":
						if (fromInputRef?.current)
							fromInputRef.current.value = res?.data?.fait_amout
						break
					default:
				}
			}

			if (res?.code !== 200) {
				if (fromInputRef?.current && toInputRef?.current) {
					fromInputRef.current.value = ""
					toInputRef.current.value = ""
				}
			}
		}
	})

	const _onUserType = (from: "fait" | "coin") => {
		if (fromInputRef?.current && toInputRef?.current) {
			if (!assetId) {
				ShowInfo("Field Required", "Please select asset.")
			} else {
				const _data = new FormData()

				switch (from) {
					case "fait":
						_data.append("fait_amount", fromInputRef?.current?.value)
						break

					case "coin":
						_data.append("asset_quantity", toInputRef?.current?.value)
						break
					default:
				}

				_data.append("from", from)
				_data.append("asset_id", assetId)

				fetchRateApi.mutateAsync(_data)
			}
		}
	}

	const buyCryptApi = useMutation(BuyAssets, {
		onSuccess(res: any) {
			if (res?.status) {
				ShowSuccess("Succcess", res?.message)

				setOpen(false)
			} else ShowServerErrors("Error", res)
		}
	})
	const isLoading = buyCryptApi.isLoading

	const _handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const _formData = new FormData(e.target)

		buyCryptApi.mutateAsync(_formData)
	}

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				initialFocus={cancelButtonRef}
				onClose={setOpen}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
								<div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
									<div className="">
										<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
											<Dialog.Title
												as="h3"
												className="text-start font-extrabold leading-6 text-gray-900"
											>
												Buy Crypto
											</Dialog.Title>
										</div>

										<form
											className="space-y-6 xs:p-3 md:p-5"
											onSubmit={_handleSubmit}
										>
											<div className="relative mb-3">
												<label
													htmlFor="form_faitInput"
													className="absolute -top-2 bg-white rounded-md"
												>
													From ({NAIRA_SIGN})
												</label>
												<input
													ref={fromInputRef}
													id="form_faitInput"
													onChange={() => _onUserType("fait")}
													name="fait_amount"
													type="number"
													step="any"
													className="peer block min-h-[50px] w-full rounded border bg-transparent rounded-full px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 text-black data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none  [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
												/>
											</div>

											<div className="grid grid-cols-3 gap-4">
												<div className="relative mb-3 col-span-2">
													<label
														htmlFor="form_quantityInput"
														className="absolute -top-2 bg-white rounded-md"
													>
														To
													</label>
													<input
														ref={toInputRef}
														onChange={() => _onUserType("coin")}
														id="form_quantityInput"
														name="asset_quantity"
														type="number"
														step="any"
														className="peer block min-h-[50px] w-full rounded border bg-transparent rounded-full px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 text-black data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none  [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
													/>
												</div>

												<div className="relative mb-3">
													<AssetDropdown
														name="asset_id"
														options={assets as Array<AssetDropdownItems>}
														onChange={_onSelect}
													/>
												</div>
											</div>
											<button
												disabled={isLoading}
												className="bg-blue-500 disabled:bg-blue-500/50 mb-3 inline-block w-full rounded p-3 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
												type="submit"
												data-te-ripple-init
												data-te-ripple-color="light"
											>
												{!isLoading && "Submit"}
												{isLoading && <ShowSpinner color="text-light" />}
											</button>
										</form>
									</div>
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
										onClick={() => setOpen(false)}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
