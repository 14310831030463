import CountrySelector from "components/CountrySelector"
import PageInfo from "components/misc/Page"
import ShowSpinner from "components/misc/Spinner"
import React, { useState } from "react"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { userProfileApi } from "services/Api/profile/profile.index"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"

export default function LocationInfo() {
	const { UpdateResidentInfo } = userProfileApi()
	const navigate = useNavigate()
	const [isOpen, setIsOpen] = useState(false);

	const updateContactApi = useMutation(UpdateResidentInfo, {
		onSuccess: (res: any) => {
			if (res?.status) {
				ShowSuccess("Location Info", "Your location information has been updated successfully!")

				navigate("/onboard/others-info")
			} else {
				ShowServerErrors("Validation", res)
			}
		}
	})

	const isProcessing = updateContactApi.isLoading

	const handleUserSubmitAction = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		console.log();
		
		updateContactApi.mutateAsync(formData) 
	}

	return (
		<section>
			<PageInfo titleText="Update Location Information" />
			<header>
				<ol className="bk-onboarding-stepper">
					<li className="stepper__item completed" data-step="1"></li>
					<li className="stepper__item completed" data-step="2"></li>
					<li className="stepper__item" data-step="3"></li>
					<li className="stepper__item" data-step="4"></li>
				</ol>
				<div className="text-center mt-5">
					<h1> <strong>Location Data</strong></h1>

					<p> Take note of the fields <sup className="text-danger">*</sup> </p>

					<p className="my-5 text-primary m-auto d-block w-75">
					<strong>We recognize both the rights of individuals to protect their personal data , including rights of access and correction, and the needs of organizations to collect, use or disclose personal data for legitimate and reasonable purposes.</strong>
					</p>

				</div>



			</header>
			<section className="d-md-flex justify-content-md-center align-items-md-center">
				<form className="border rounded my-4 shadow-sm" onSubmit={handleUserSubmitAction}>
					<header className="border-dark p-2 mb-3">
						<h2>
							<strong>Residential Address</strong>
							<br />
						</h2>
					</header>
					<section className="p-3">
						<div className="mb-4">
							<div className="row">
								<div className="col-md-6 mb-3">
									<div className="form-group">
										<label className="form-label w-100 input-label">
											<strong>Residential Country </strong>
											<sup className="text-danger">*</sup>
										</label>

										<CountrySelector inputName="residential_country" defaultValue="Nigeria" />
									</div>
								</div>


								{/* {
									isOpen ?  */}
									
									{/* <div className="col-md-6 mb-3">
										<div className="form-group">
											<label className="form-label w-100 input-label">
												<strong>House number </strong>
											</label>
											<input className="form-control form-control-lg form-control-user" type="tel" name="house_number" />
										</div>
									</div> */}

									 {/* : <div></div>
								} */}



							</div>
							<p className="text-danger">
								<strong>Note:</strong> Please ensure that the provided name is same as written on your ID documents. Be assured that we <br />
								guarantee your personal details privacy
							</p>
						</div>

						<div className="mb-4">
							<div className="row">
								<div className="col-md-12 mb-3">
									<div className="form-group">
										<label className="form-label w-100 input-label">
											<strong>Street Address</strong>
											<sup className="text-danger">*</sup>
										</label>
										<input className="form-control form-control-lg form-control-user" type="text" name="street_address" />
									</div>
								</div>
							</div>
						</div>

						<div className="mb-4">
							<div className="row">
								<div className="col-md-4 mb-3">
									<div className="form-group">
										<label className="form-label w-100 input-label">
											<strong>City</strong>
											<sup className="text-danger">*</sup>
										</label>
										<input className="form-control form-control-lg form-control-user" type="text" name="city" />
									</div>
								</div>
								<div className="col-md-4 mb-3">
									<div className="form-group">
										<label className="form-label w-100 input-label">
											<strong>State</strong>
											<sup className="text-danger">*</sup>
										</label>
										<input className="form-control form-control-lg form-control-user" type="text" name="state" />
									</div>
								</div>


								{/* {isOpen ?  */}

								{/* <div className="col-md-4 mb-3">
									<div className="form-group">
										<label className="form-label w-100 input-label">
											<strong>Postal Code</strong>
											<sup className="text-danger">*</sup>
										</label>
										<input className="form-control form-control-lg form-control-user" type="text" name="postal_code" />
									</div>
								</div> */}
								
								 {/* : <div></div>} */}


							</div>
						</div>
						<div className="text-end mb-4">
							<button className="btn btn-primary btn-lg" type="submit" disabled={isProcessing}>
								{!isProcessing && "Next"}
								{isProcessing && <ShowSpinner color="text-light" />}
							</button>
						</div>
					</section>
				</form>
			</section>
		</section>
	)
}
