import React, { useState } from "react"
import ShowSpinner from "./misc/Spinner"

interface AwaitResponseInterface {
	api?: Function
	children: React.ReactNode
	onerror?: React.ReactNode
}
export default function AwaitResponse({ api, onerror, children }: AwaitResponseInterface) {
	const [loading, isloading] = useState(true)
	const [error, hasError] = useState(false)

	const [render, canRender] = useState(false)

	const initateApi = () => {
		if (typeof api === "function") {
			isloading(true)
			hasError(false)
			canRender(false)

			const res = api()

			if (res && typeof res.then !== "undefined") {
				res.then(() => {
					isloading(false)
					hasError(false)
					canRender(true)
				})
			}

			if (res && typeof res.catch !== "undefined") {
				res.catch(() => {
					hasError(true)
					isloading(false)
					canRender(false)
				})
			}
		} else {
			isloading(false)
			hasError(false)
			canRender(true)
		}
	}

	React.useEffect(() => {
		initateApi()
	}, [])

	return (
		<>
			{render === false && error === false && loading === true && (
				<section className="d-block w-100 text-center">
					<ShowSpinner />
				</section>
			)}
			{render === false && loading === false && error === true && onerror}
			{error === false && loading === false && render === true && children}
		</>
	)
}
