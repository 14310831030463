import React from "react"
import { useLoaderData, useNavigate } from "react-router-dom"
import { AssetsListItemProps, BackendResponse } from "types/app.types"
import { uniqueId } from "services/utility/Utils/Utils"
import { userMarketplaceApis } from "services/Api/marketplace/marketplace.index"
import { ShowServerErrors } from "services/utility/Utils/_toaster"
import { useMutation } from "react-query"
import { useUrlQuery } from "services/utility/hooks/useURL"
import DataTableMobile from "./DataTableMobile"
import ShowSpinner from "components/misc/Spinner"
import { encryptString } from "services/utility/Utils/utilities"
import EmptyContentMessage from "components/EmptyContentMessage"
import { FetchAssetApiProps } from "services/Api/marketplace/marketplace.apis"

export default function DashMobileContent() {
	const navigate = useNavigate()

	const { FetchBuyMarketplace, FetchSellMarketplace } = userMarketplaceApis()

	const fetchBuyAssets = useMutation(FetchBuyMarketplace, {
		onSuccess: (res: BackendResponse | any, params: FetchAssetApiProps | any) => {
			if (res?.status) {
				navigate(`/dashboard/marketplace?tb=mrk&type=buy&show=mob-tbl&asset=${params?.assetId}&payload=${encryptString(JSON.stringify(res?.data))}`)
			} else ShowServerErrors("Error", res)
		}
	})

	const fetchSellAssets = useMutation(FetchSellMarketplace, {
		onSuccess: (res: BackendResponse | any, params: FetchAssetApiProps | any) => {
			if (res?.status) {
				navigate(`/dashboard/marketplace?tb=mrk&type=sell&show=mob-tbl&asset=${params?.assetId}&payload=${encryptString(JSON.stringify(res?.data))}`)
			} else ShowServerErrors("Error", res)
		}
	})

	const isFetchingAssets = fetchBuyAssets.isLoading || fetchSellAssets.isLoading

	const assetList: any = useLoaderData()
	const data: Array<AssetsListItemProps> = assetList?.data

	const {
		show,
		type,
		payload
	}: {
		show?: "mob-tbl"
		type?: "buy" | "sell"
		payload?: string
	} = useUrlQuery()

	const handleBuyClick = (_assetId: string) => fetchBuyAssets.mutateAsync({ assetId: _assetId })

	const handleSellClick = (_assetId: string) => fetchSellAssets.mutateAsync({ assetId: _assetId })

	const paginator = type === "buy" ? fetchBuyAssets : fetchSellAssets

	return (
		<>
			{isFetchingAssets && (
				<section
					className="my-3 text-center"
					style={{
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
						top: "0%",
						bottom: "0%",
						left: "0%",
						right: "0%",

						zIndex: 100,
						background: "#0d0d0d17"
					}}
				>
					<ShowSpinner size={100} />
				</section>
			)}
			{show !== "mob-tbl" && (
				<div className="row ">
					{data && (
						<>
							{data?.length > 0 && (
								<>
									{data?.map(asset => (
										<div key={uniqueId()} className="col-6 p-2 content-row">
											<div className="card">
												<div className="card-body">
													<img className="m-auto d-block bk__asset-img" src={asset?.icon} alt={asset?.name} width="100" height="100" />
													<h4 className="text-center text-muted card-title bk__asset-text mt-2">{asset?.name}</h4>
												</div>
											</div>
											<ul className="list-group list-group-horizontal list-group-flush dash-card-opts">
												<li className="list-group-item buy bk__overlay-text pointer" onClick={() => handleBuyClick(asset?.asset_id)}>
													<span>Buy</span>
												</li>
												<li className="list-group-item sell bk__overlay-text pointer" onClick={() => handleSellClick(asset?.asset_id)}>
													<span>Sell</span>
												</li>
											</ul>
										</div>
									))}
								</>
							)}
						</>
					)}

					{(!data || data?.length <= 0) && <EmptyContentMessage text="No content" />}
				</div>
			)}

			{show === "mob-tbl" && <DataTableMobile paginator={paginator} type={type} payload={payload} />}
		</>
	)
}
