import { useFlusStores } from "@bilmapay/react-flux-storage"
import DashDesktopContainer from "components/DashDesktopContainer"
import DashSearchForm from "components/layout/nav/DashSearchForm"
import React from "react"
import { useSelector } from "react-redux"
import { Link, useLoaderData } from "react-router-dom"
import { getConfigs } from "redux/reducers/appSlice"
import { uniqueId } from "services/utility/Utils/Utils"
import { GeneralContentProps, TicketProps } from "types/app.types"

export default function DisputeZone() {
	const { DisputeIcon, BlockedIcon } = useFlusStores()
	const tickets: any = useLoaderData()

	const { data }: { data: Array<TicketProps> } = tickets

	const beekleContent = useSelector(getConfigs)

	return (
		<>
			<DashDesktopContainer title="Dispute Zone" page="dispute_zone" showOnMobile>
				<section className="my-4 p-4 bg-white">
					<header>
						<h1>
							Disputes
							<img className="img-fluid float-end" src={DisputeIcon} alt="" width="75" />
						</h1>
					</header>
					<p className="my-3 p-2" dangerouslySetInnerHTML={{ __html: beekleContent?.dispute_zone as string }}></p>

					<ul className="list-group list-group-flush mt-5">
						{data && Array.isArray(data) && (
							<>
								{data?.length > 0 && (
									<>
										{data.map(ticket => (
											<li key={uniqueId()} className="list-group-item">
												<div className="row">
													<div className="col">
														<h6 className="text-muted">
															<strong>{ticket?.ticket_number}</strong>
															{ticket?.status?.toLocaleLowerCase() === "open" && (
																<Link to={`/tickets/room/${ticket?.ticket_token}`} className="badge rounded-pill bg-primary text-light ms-3">
																	Open
																</Link>
															)}
														</h6>

														<small className="text-muted">{ticket?.date}</small>
													</div>
												</div>
											</li>
										))}
									</>
								)}
							</>
						)}
					</ul>

					{(!data || data?.length <= 0) && (
						<article className="mb-3">
							<img className="img-fluid m-auto d-block" src={BlockedIcon} alt="" width="85" />
							<h5 className="text-center text-danger my-3">
								<strong>Sorry!!!</strong>
							</h5>
							<p className="text-center text-danger">You currently have no dispute session in progress</p>
							<p className="text-center text-danger">Thank you!</p>
						</article>
					)}
				</section>
			</DashDesktopContainer>

			<DashSearchForm page="dispute_zone" />
		</>
	)
}
