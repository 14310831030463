import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { userKYCVerificatinApi } from "services/Api/kyc/kyc.index"
import { useMutation } from "react-query"
import { ShowInfo, ShowServerErrors } from "services/utility/Utils/_toaster"
import ShowSpinner from "components/misc/Spinner"
import { useNavigate } from "react-router-dom"
import UploadIDFront from "./ID/UploadIDFront"
import UploadIDBack from "./ID/UploadIDBack"
import UPloadIDSelfie from "./ID/UPloadIDSelfie"

export default function IDVerification() {
	const { StampImg } = useFlusStores()

	const { KYCLevelOneUpload } = userKYCVerificatinApi()
	const navigate = useNavigate()

	const kycLevelOneUploadApi = useMutation(KYCLevelOneUpload, {
		onSuccess: res => {
			if (res?.status) {
				navigate("/dashboard/verification")
				ShowInfo("KYC Verification Level 1", "Your KYC verification level 1 has been submitted pending verification. Thank you!")
			} else ShowServerErrors("KYC Verification", res)
		}
	})

	const isProcessingKYC = kycLevelOneUploadApi.isLoading

	const handleUserKYCUpload = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		kycLevelOneUploadApi.mutateAsync(formData)
	}

	return (
		<section className="p-3 bg-white">
			<header className="mb-3">
				<img className="img-fluid m-auto d-block mb-4" src={StampImg} alt="" width="150" height="150" />
				<h1 className="text-center">
					<strong>IDENTITY VERIFICATION</strong>
					<br />
				</h1>
				<p>Provide a recognized valid identity documents to carry out your verification process.</p>
				<p className="lead ms-5">
					<i className="fa fa-check me-2 verif-item-icon allowed"></i>Government Issued
				</p>
				<p className="lead ms-5">
					<i className="fa fa-check me-2 verif-item-icon allowed"></i>Clear &amp; Unedited
				</p>
				<p className="lead ms-5">
					<i className="fa fa-check me-2 verif-item-icon allowed"></i>Readable &amp; Colored
				</p>
				<p className="lead ms-5">
					<i className="fa fa-times-circle me-2 verif-item-icon not-allowed"></i>Expired &amp; Tattered
				</p>
				<p className="lead ms-5">
					<i className="fa fa-check me-2 verif-item-icon allowed"></i>Black &amp; White or Colored
				</p>
			</header>
			<form className="py-4" encType="multipart/form-data" onSubmit={handleUserKYCUpload}>
				<div className="mb-4">
					<h2>ID TYPE</h2>
					<select className="form-select form-select-lg" name="id_type" required>
						<option defaultValue="National Identity Card - NIN">National Identity Card (NIN slip)</option>
						<option value="international passport">International Passport</option>

						<option value="Driver's Licence">Driver's Licence</option>
					</select>
				</div>
				<section className="mb-3">
					<h3>
						<strong>STEP 1 (Front)</strong>
					</h3>
					<p className="mb-3">Take a front photo of your valid identity card</p>
					<small>Click on the image to select file</small>

					<UploadIDFront />
				</section>

				<section className="mb-3">
					<h3>
						<strong>STEP 2 (Back)</strong>
					</h3>
					<p className="mb-3">Take a Back photo of your valid identity card</p>
					<small>Click on the image to select file</small>

					<UploadIDBack />
				</section>
				<section className="mb-3">
					<h3>
						<strong>STEP 3 (Selfie)</strong>
					</h3>
					<p className="mb-3">
						Take a selfie with your valid identity card
						<br />
						<small className="text-info">Make sure to allow camera access for your browser app.</small>
					</p>

					<UPloadIDSelfie />
				</section>
				<button disabled={isProcessingKYC} className="btn btn-primary btn-lg m-auto d-block w-75 bg-blue mt-5" type="submit">
					{!isProcessingKYC && "Upload"}
					{isProcessingKYC && <ShowSpinner color="text-light" />}
				</button>
			</form>
		</section>
	)
}
