import {
	CheckUsername,
	UpdatePersonalInfo,
	UpdateContactInfo,
	UpdateResidentInfo,
	AcceptPrivacyPolicy,
	UpdateProfilePhoto,
	SetAccountPIN,
	UpdatePassword,
	ToggleSettings,
	FetchProfile,
	PrefetchProfile,
	
} from "./profile.apis"

export const userProfileApi = () => ({
	CheckUsername,
	UpdatePersonalInfo,
	UpdateContactInfo,
	UpdateResidentInfo,
	AcceptPrivacyPolicy,
	UpdateProfilePhoto,
	SetAccountPIN,
	UpdatePassword,
	ToggleSettings,
	FetchProfile,
	PrefetchProfile,
})
