import AboutHeader from "components/AboutHeader"
import ContactusHeader from "components/ContactusHeader"
import FaqsHeader from "components/FaqsHeader"
import HomeHeader from "components/HomeHeader"
import React from "react"
import MainDesktopNav from "./nav/MainDesktopNav"
import MainMobileNav from "./nav/MainMobileNav"
import MainMobileNavToggler from "./nav/MainMobileNavToggler"

type PropsTypes = {
	includeHeader: string
}

function DefaultNavigation({ includeHeader = "home" }: PropsTypes) {
	return (
		<header className="bp_header bp_intro">
			<>
				<MainDesktopNav />
				<MainMobileNavToggler />
				<MainMobileNav />

				{/* {includeHeader === "home" && <HomeHeader />} */}
				{includeHeader === "about" && <AboutHeader />}
				{includeHeader === "contact-us" && <ContactusHeader />}
				{includeHeader === "faq" && <FaqsHeader />}
			</>
		</header>
	)
}

export default DefaultNavigation
