import { FLUX_DYNAMIC_STORE, useFlusDispatcher, useFlusStores } from "@bilmapay/react-flux-storage"
import AwaitResponse from "components/AwaitResponse"
import CardNavigator from "components/CardNavigator"
import { APPEAL_MODAL, COIN_RELEASE_SUCCESS } from "flus/constants/modals.id.const"
import React from "react"
import { useMutation } from "react-query"
import { Link } from "react-router-dom"
import { userMarketplaceApis } from "services/Api/marketplace/marketplace.index"
import { useUrlQuery } from "services/utility/hooks/useURL"
import { secondsCountdownTimer } from "services/utility/Utils/utilities"
import { ShowInfo, ShowServerErrors } from "services/utility/Utils/_toaster"
import { AssetDetailsProps, GeneralContentProps } from "types/app.types"
import { useDispatch, useSelector } from "react-redux"
import { getConfigs } from "redux/reducers/appSlice"
import { getAssetDetails, setAssetDetails } from "redux/reducers/dashboardSlice"

interface SellerAwaitFundReleaseProps {
	ot: any
	bg: string | "bg-blue"
	txt: "text-primary" | "text-blue"
}

const timerRef = React.createRef<HTMLElement>()
const successMdTogglerRef = React.createRef<HTMLDivElement>()

export default function SellerAwaitFundRelease() {
	const { AwaitCoinRelease }: { AwaitCoinRelease: any } = useFlusStores()
	const generalContent = useSelector(getConfigs)
	const assetDetails = useSelector(getAssetDetails)
	const dispatcher = useDispatch()

	/* Get the payload and order_token */
	const { ot }: SellerAwaitFundReleaseProps = useUrlQuery()

	const { BuyerAwaitFundRelease } = userMarketplaceApis()

	const assetDetailsApi = useMutation(BuyerAwaitFundRelease, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(setAssetDetails(res?.data))
			} else ShowServerErrors("Error", res)
		}
	})

	const handleAssetDetailsFetching = async () => {
		const fd = new FormData()
		fd.append("order_token", ot)
		return assetDetailsApi.mutateAsync(fd)
	}

	const handleOrderTokenDispatch = () => {
		dispatcher({ type: FLUX_DYNAMIC_STORE, payload: { store: "_appealOrderToken", data: ot } })
	}

	React.useEffect(() => {
		if (assetDetails?.remaining_minutes) {
			const timer: unknown = assetDetails?.remaining_minutes
			secondsCountdownTimer(timer as number, (isFinished, timeLef) => {
				if (timerRef?.current) {
					const seconds: unknown = timeLef
					const timerSec = seconds as string
					timerRef.current.innerHTML = `${timerSec}s`
				}
			})
		}
	})

	console.log(assetDetails?.status_code)
	React.useEffect(() => {
		if (assetDetails && successMdTogglerRef?.current) {
			if (parseInt(assetDetails?.status_code) === 1) successMdTogglerRef?.current?.click()
		}

		let timer = setInterval(() => {
			if (parseInt(assetDetails?.status_code as string) === 1 || parseInt(assetDetails?.status_code as string) === 2) {
				clearInterval(timer)
			}

			handleAssetDetailsFetching()
		}, 10000)

		return () => clearInterval(timer)
	}, [assetDetails?.status_code])

	return (
		<CardNavigator returnLink="/dashboard">
			<AwaitResponse api={handleAssetDetailsFetching}>
				<div className="toggle-modal d-none" data-bs-toggle="modal" data-bs-target={`#${COIN_RELEASE_SUCCESS}`} ref={successMdTogglerRef}>
					hidden-element
				</div>
				<section>
					<header className="mb-4">
						<h3 className="mb-3 text-blue">
							<strong>
								{assetDetails?.status_code === "1" && "Coin Released"}
								{assetDetails?.status_code !== "1" && "Awaiting Coin Release"}
							</strong>
							<br />
						</h3>
						<img className="img-fluid m-auto d-block my-5" src={AwaitCoinRelease} alt="" width="150" />
					</header>
					<form className="trade-form">
						<section className="mb-3">
							<header>
								<div className="row">
									<div className="col-md-6 col-lg-6 mb-3 d-flex justify-content-start align-items-center">
										<h5>
											Order end in:{" "}
											<span className="text-danger" ref={timerRef}>
												0
											</span>
										</h5>
									</div>
								</div>
							</header>
							<div className="table-responsive border rounded">
								<table className="table table-borderless">
									<tbody>
										<tr>
											<td>
												<strong>Rate per USD:</strong>
											</td>
											<td className="text-end">{assetDetails?.rate_per_coin} NGN</td>
										</tr>
										<tr>
											<td>
												<strong>Response Time (Avg):</strong>
											</td>
											<td className="text-end">{assetDetails?.response_time}</td>
										</tr>
										<tr>
											<td>
												<strong>Asset Amount: </strong>
											</td>
											<td className="text-end">{assetDetails?.asset_amount}</td>
										</tr>
										<tr>
											<td>
												<strong>Amount Payable:</strong>
											</td>
											<td className="text-end">{assetDetails?.amount_payable} NGN</td>
										</tr>
										<tr>
											<td>
												<strong>Status:</strong>
											</td>
											<td className="text-end">{assetDetails?.status}</td>
										</tr>
										<tr>
											<td>
												<strong>Order ID:</strong>
											</td>
											<td className="text-end">
												{assetDetails?.order_no}
												<i
													className="fa fa-copy ms-3 pointer"
													onClick={() => {
														navigator.clipboard.writeText(assetDetails?.order_no as string)

														ShowInfo("Success", "Order ID coppied successfully!")
													}}
												></i>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</section>
						<section className=" text-center">
							<div className="btn-group btn-group-lg w-100 mb-5" role="group">
								{assetDetails?.status_code !== "1" && (
									<Link to={`/dashboard/cancel-order?ot=${ot}`} className="btn btn-light btn-lg me-3 w-100" type="button">
										Cancel
									</Link>
								)}
								<button
									disabled={parseInt(assetDetails?.status_code as string) === 1}
									className="btn btn-primary w-100 bg-blue"
									type="button"
									data-bs-toggle="modal"
									data-bs-target={`#${APPEAL_MODAL}`}
									onClick={handleOrderTokenDispatch}
								>
									Appeal
								</button>
							</div>
							<span className="p-2 bg-danger d-none">
								<strong>Auto Action</strong>
							</span>
						</section>

						{assetDetails?.status_code !== "1" && (
							<a href={`tel:${assetDetails?.seller_phone_number}`} className="badge bg-primary traders-chat-toggler bg-blue text-white justify-content-center align-content-center pt-3">
								<i className="fas fa-phone"></i>
							</a>
						)}
					</form>
					<article className="mb-4 mt-4  bg-gray">
						<h5 className="text-success">
							<strong>Notice</strong>
						</h5>
						<p dangerouslySetInnerHTML={{ __html: generalContent?.await_coin_release_notice as string }}></p>
					</article>
				</section>
			</AwaitResponse>
		</CardNavigator>
	)
}
