import { FetchSupportedCoins, FetchTestimonials, SendContactUs, FetchFAQs, FetchGeneralContent, FetchAboutUs, Search } from "./gen.apis"

export const generalApis = () => ({
	FetchSupportedCoins,
	FetchTestimonials,
	SendContactUs,
	FetchFAQs,
	FetchGeneralContent,
	FetchAboutUs,
	Search
})
