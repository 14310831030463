import { GET, POST, PUT, DROP } from "configs/api"
import { handleError } from "services/utility/Utils/Utils"
import { authEndpoint } from "./auth.endpoints"
import {
	CreateAccountParamsTypes,
	ForgetPasswordParamsTypes,
	LoginAccounParamsTypes,
	ResetAccountPasswordParamsTypes,
	VerifyEmailParamTypes,
	VerifyPasswordResetParamsTypes
} from "../apis-params-types"

export const FetchCSRFXSSToken = () => {
	try {
		return GET(authEndpoint.CSRF_TOKEN)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const CreateAccount = async (params: CreateAccountParamsTypes | FormData) => {
	try {
		return await POST(authEndpoint.CREATE_ACCOUNT, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const LoginAccount = async (params: LoginAccounParamsTypes | FormData) => {
	try {
		return await POST(authEndpoint.LOGIN, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}
export const LogoutAccount = async () => {
	try {
		return await POST(authEndpoint.LOGOUT)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}
export const VerifyEmail = async (params: VerifyEmailParamTypes | FormData) => {
	try {
		return await POST(authEndpoint.VERIFY_EMAIL, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const ResendVerificationLink = async () => {
	try {
		return await POST(authEndpoint.RESEND_LINK)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const ChangeVerificationEmail = async (params: FormData) => {
	try {
		return await POST(authEndpoint.CHANGE_VERIFICATION_EMAIL, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const ForgetPassword = async (params: ForgetPasswordParamsTypes | FormData) => {
	try {
		return await POST(authEndpoint.FORGET_PASSWORD, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const VerifyPasswordReset = async (params: VerifyPasswordResetParamsTypes | FormData) => {
	try {
		return await POST(authEndpoint.VERIFY_FORGET_PASSWORD, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const ResetAccountPassword = async (params: ResetAccountPasswordParamsTypes | FormData) => {
	try {
		return await POST(authEndpoint.VERIFY_FORGET_PASSWORD, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}

export const FinalizeGoogleAuth = async (params: ResetAccountPasswordParamsTypes | FormData) => {
	try {
		return await POST(authEndpoint.FINALIZE_GOOGLE_AUTH, params)
			.then(res => res)
			.catch(handleError)
	} catch (error) {
		console.warn(`ASRE_ERROR:\n ${error}`)
	}
}
