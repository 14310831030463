import DefaultNavigation from "components/layout/DefaultNavigation"
import PageInfo from "components/misc/Page"
import React from "react"
import HowItWorksSection from "./HowItWorksSection"
import ReviewsSection from "./ReviewsSection"
import SafestPlaceToTradeSection from "./SafestPlaceToTradeSection"
import SupportedCoinsSection from "./SupportedCoinsSection"
import TopCoinsSection from "./TopCoinsSection"
import CustomerSection from "./CustomerSection"
import ConverterSection from "./converterSection"
import FirstSection from "./firstSection"
import "../../morecss.css"

export default function HomePage() {
	return (
		<>
			{/* <PageInfo titleText="Home" /> */}

			<DefaultNavigation includeHeader="home" />

			{/* <BestSellerSection /> */}
			{/* <section className="my-5 py-5"></section> */}
			{/* <div className=" sm:h-[500px]"></div> */}
			{/* <div className="firstSectionBG"> */}

			<FirstSection />

			{/* </div> */}

			<CustomerSection />
			<HowItWorksSection />
			<ConverterSection />
			<SafestPlaceToTradeSection />
			<TopCoinsSection />
			<SupportedCoinsSection />
			<ReviewsSection />
		</>
	)
}
