import React from "react"
import { sleep } from "./_sleep"
import $ from "jquery"

export const extractToken = (token: string): string => {
	return token.split("|")[1]
}

export const generateRatings = (ratings: number): number[] => {
	let Rating = []
	/* Create rating base on rating value */
	if (ratings) {
		for (let index = 1; index <= ratings; index++) {
			Rating.push(index)
		}
	}

	return Rating
}

export const encryptString = (data: string | any): string | any => {
	return btoa(data)
}

export const decryptString = (encryptedString: string | any): string | any => {
	return atob(encryptedString)
}

export const toggleMarketplaceTab = (e: React.ChangeEvent<HTMLButtonElement> | any) => {
	const target = e.target

	document.querySelectorAll(".bk__tab-item").forEach(elem => {
		if (elem?.classList?.contains("active")) {
			elem.classList.remove("active")
		}
	})

	sleep(1000).then(() => {
		$(target).addClass("active")
		target.classList.add("active")
	})
}

export type SecondsCountdownTimerTypes = {
	(seconds: number, callback: (isFinish: boolean, timeLef: number) => void): void
}

/**
 * A sconds countdown timer
 * @param seconds The number of seconds to count down
 * @param callback The callback to be executed everytime the timer runs
 */
export const secondsCountdownTimer: SecondsCountdownTimerTypes = (seconds, callback) => {
	let downloadTimer = setInterval(function () {
		let minutes: any
		let hour: any
		let absSeconds: any
		let result: any
		let absMinutes: any
		let newSeconds: any

		hour = seconds / 3600
		hour = parseInt(hour)
		absMinutes = seconds % 3600
		minutes = parseInt(absMinutes)
		minutes = minutes / 60
		minutes = parseInt(minutes)
		absSeconds = absMinutes % 60
		newSeconds = parseInt(absSeconds)

		if (seconds < 60) {
			result = seconds
		} else {
			result = hour + "h " + minutes + "m " + newSeconds
		}

		//minutes = minutes < 10 ? `0${minutes}` : minutes
		//seconds = seconds < 10 ? `0${seconds}` : seconds

		if (seconds <= 0) {
			clearInterval(downloadTimer)
		}
		if (typeof callback === "function") {
			callback(seconds === 0, result)
		}
		seconds -= 1
	}, 1000)
}

export const formatAmount = (value?: any): string => {
	const formatter = new Intl.NumberFormat(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	})

	return formatter.format(value)
}
