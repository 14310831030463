import { FLUX_DYNAMIC_STORE, useFlusDispatcher, useFlusStores } from "@bilmapay/react-flux-storage"
import FixChatNavigation from "components/misc/FixChatNavigation"
import React from "react"
import { useParams } from "react-router-dom"
import { userTicketApis } from "services/Api/ticket/ticket.index"
import { useMutation } from "react-query"
import { ShowServerErrors } from "services/utility/Utils/_toaster"
import AwaitResponse from "components/AwaitResponse"
import { TicketMessagesProps } from "types/app.types"
import { TextAvatar } from "services/utility/Utils/_avatar"
import classNames from "classnames"
import { sleep } from "services/utility/Utils/_sleep"
import { useDispatch, useSelector } from "react-redux"
import { getGroupChatMessages, setGroupChatMessages } from "redux/reducers/dashboardSlice"

const postAppealFormRef = React.createRef<HTMLFormElement>()
const chatAreaRef = React.createRef<HTMLUListElement>()

export const scrollOptions: ScrollIntoViewOptions = {
	behavior: "smooth",
	block: "end",
	inline: "nearest"
}

export default function GroupChat({ returnLink }: { returnLink: string }) {
	const _chatMessages = useSelector(getGroupChatMessages)
	const dispatcher = useDispatch()

	const { ticketToken } = useParams()

	const { FetchAppealChat, PostAppealChat } = userTicketApis()

	const fetchChatApis = useMutation(FetchAppealChat, {
		onSuccess: (res: any) => {
			if (res?.status) {
				dispatcher(setGroupChatMessages(res?.data))

				sleep(200).then(() => {
					if (chatAreaRef?.current) {
						chatAreaRef?.current?.scrollIntoView(scrollOptions)
					}
				})
			} else ShowServerErrors("Error", res)
		}
	})

	const isFetchingChats = fetchChatApis.isLoading

	const handleChatMessagesFetching = async () => {
		const fd = new FormData()
		/* @ts-ignore */
		fd.append("appeal_token", ticketToken)

		return await fetchChatApis.mutateAsync(fd)
	}

	const postChatApi = useMutation(PostAppealChat, {
		onSuccess: (res: any) => {
			if (res?.status) {
				/* pull new messages */
				handleChatMessagesFetching()

				if (postAppealFormRef?.current) {
					postAppealFormRef?.current?.reset()
				}
			} else ShowServerErrors("Error", res)
		}
	})

	const isPostingMessage = postChatApi.isLoading

	const handleMessagePosting = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)
		/* @ts-ignore */
		formData.append("appeal_token", ticketToken)

		postChatApi.mutateAsync(formData)
	}

	const message = _chatMessages?.message

	/* Message fetching */
	React.useEffect(() => {
		const fetchInterval = window.setInterval(handleChatMessagesFetching, 60000)

		return () => {
			window.clearInterval(fetchInterval)
		}
	})
	return (
		<FixChatNavigation
			handleSubmit={handleMessagePosting}
			refreshChats={handleChatMessagesFetching}
			returnLink={returnLink}
			title={_chatMessages?.title}
			Ref={postAppealFormRef}
			isLoading={isPostingMessage}
			ticketToken={ticketToken}
		>
			<AwaitResponse api={handleChatMessagesFetching}>
				<section>
					<header className="mb-4">
						<h4 className="text-center">
							<strong>APPEAL</strong>
						</h4>
						<p className="text-primary my-3 text-center">{_chatMessages?.label}</p>
					</header>
					<section>
						<ul className="list-inline d-flex mb-4">
							<li className="list-inline-item w-100 text-blue">
								<strong>Transaction Amount:</strong>
							</li>

							<li className="list-inline-item w-100 text-end text-blue">
								<strong>{_chatMessages?.transaction_amount} NGN</strong>
							</li>
						</ul>
						<h2 className="text-muted text-center mt-3 mb-5 ">Negotiation Desk</h2>

						<ul className="list-group chat-messages group-room" ref={chatAreaRef}>
							{message && (
								<>
									{message?.length > 0 && (
										<>
											{message?.map(msg => (
												<React.Fragment key={msg?.chat_id}>
													{msg?.position === "right" && (
														<li
															className={classNames({
																"list-group-item  d-flex": true,
																sender: msg?.position === "right"
															})}
														>
															<p className="message-object">
																{msg?.type === "text" && msg?.message}
																{msg?.type === "media" && (
																	<a className="text-light" rel="noreferrer" target="_blank" href={msg?.message} download>
																		<svg className="bi bi-paperclip" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
																			<path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"></path>
																		</svg>{" "}
																		<em>Download file</em>
																	</a>
																)}
																<br />
															</p>
															<img className="user-avatar" src={TextAvatar(msg?.ticketer_type?.toLocaleUpperCase())} alt="" width="50" />
														</li>
													)}

													{(msg?.position === "center" || msg?.position === "left") && (
														<li className="list-group-item receiver d-flex">
															<img className="user-avatar" src={TextAvatar(msg?.ticketer_type?.toLocaleUpperCase())} alt="" width="50" />
															<p
																className={classNames({ "message-object": true })}
																style={{
																	background: msg?.ticketer_type === "complainant" ? "#87ceeb6b" : msg?.ticketer_type === "respondent" ? "#80000030" : ""
																}}
															>
																{msg?.type === "text" && msg?.message}
																{msg?.type === "media" && (
																	<a className="text-primary" rel="noreferrer" target="_blank" href={msg?.message} download>
																		<svg className="bi bi-paperclip" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
																			<path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"></path>
																		</svg>{" "}
																		<em>Download file</em>
																	</a>
																)}
															</p>
														</li>
													)}
												</React.Fragment>
											))}
										</>
									)}
								</>
							)}

							{isFetchingChats && (
								<li className="list-group-item text-center mt-4" style={{ backgroundColor: "transparent" }}>
									<span className="text-info">
										<strong>
											<em>loading chats...</em>
										</strong>
									</span>
								</li>
							)}
						</ul>
					</section>
				</section>
			</AwaitResponse>
		</FixChatNavigation>
	)
}
