import ShowSpinner from "components/misc/Spinner"
import React, { useState } from "react"
import { useMutation } from "react-query"
import { userProfileApi } from "services/Api/profile/profile.index"
import { ShowError, ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"

export default function UsernameInput() {
	const { CheckUsername } = userProfileApi()
	const [suggestedUsername, setSuggestedUsername] = React.useState<Element | null | any>(null)
	const [username, setUsername] = React.useState<HTMLInputElement | string | any>("")

	const checkUsernameApi = useMutation(CheckUsername, {
		onSuccess: (res: any) => {
			if (res?.status) {
				ShowSuccess("Username is available!")
			} else {
				switch (res?.code) {
					case 423:
						setSuggestedUsername(
							<span
								onClick={(e: any) => {
									// set username
									setUsername(res?.data)

									// toggle active state
									e.target.classList.toggle("active")
								}}
								className=" text-black bk__suggested_username"
							>
								{res.data}
							</span>
						)
						ShowError("Username", "The selected username already exist, You can pick from the suggested options.")
						break
					default:
						ShowServerErrors("Username", res)
				}
			}
		}
	})

	const isChecking = checkUsernameApi.isLoading

	const handleUsernameChecking = (e: React.ChangeEvent<HTMLInputElement>) => {
		const proposedUsername: string = e.target.value

		const fd = new FormData()
		fd.append("username", proposedUsername)

		checkUsernameApi.mutateAsync(fd)
	}

	return (
		<div className="row">
			<div className="col-md-6 mb-3">
				<div className="form-group">
					<label className="form-label w-100 input-label">
						<strong>Username</strong>
						<sup className="text-danger">*</sup>
					</label>
					<input onChange={e => setUsername(e.target.value)} value={username} className="form-control form-control-lg form-control-user" type="text" name="username" onBlur={handleUsernameChecking} />
				</div>
			</div>
			<div className="col-md-6 d-md-flex align-items-md-end mb-3">
				{!suggestedUsername && (
					<div className="form-group">
						<label className="form-label w-100 input-label">
							<strong>
								{isChecking && (
									<>
										<ShowSpinner size={25} /> <em>Checking availability...</em>
									</>
								)}
							</strong>
						</label>
					</div>
				)}

				{suggestedUsername && <div className="form-group">{suggestedUsername}</div>}
			</div>
		</div>
	)
}
