import  ProtectedRoute  from 'components/routes/ProtectedRoute';
import MerchantDashboard from './MerchantDashboard';
import MerchantOrders from './MerchantOrders';
import MerchantWithdrawalHistory from './MerchantWithdrawalHistory';
import MerchantDepositHistory from './MerchantDepositHistory';
import MerchantFundingHistory from './MerchantFundingHistory';
import { FetchMerchantDashboardAnalysis, FetchMerchantDepositHistory, FetchMerchantFundingHistory, FetchMerchantOrders, FetchMerchantWithdrawalHistory } from 'services/Api/merchant/merchant.apis';


export const merchantRoutes = [
    /* Dashboard index page */
    {
        index: true,
        element: (
            <ProtectedRoute>
              <MerchantDashboard />
            </ProtectedRoute>
        ),
        loader:FetchMerchantDashboardAnalysis
    },

    {
        path: 'orders',
        element: (
            <ProtectedRoute>
              <MerchantOrders />
            </ProtectedRoute>
        ),
        loader:FetchMerchantOrders
    },

    {
        path: 'withdrawal',
        element: (
            <ProtectedRoute>
              <MerchantWithdrawalHistory />
            </ProtectedRoute>
        ),
        loader:FetchMerchantWithdrawalHistory
    },

    {
        path: 'deposit',
        element: (
            <ProtectedRoute>
              <MerchantDepositHistory />
            </ProtectedRoute>
        ),
        loader:FetchMerchantDepositHistory
    },

    {
        path: 'funding',
        element: (
            <ProtectedRoute>
              <MerchantFundingHistory />
            </ProtectedRoute>
        ),
        loader:FetchMerchantFundingHistory
    },
]
