import * as yup from "yup"

export const merchantRegValidatorScheme = yup.object().shape({
	business_name: yup.string().required("Business Name is required"),
	min_investment_amount: yup.string().required("Please enter a minimum investment amount."),
	max_investment_amount: yup.string().required("Please enter a maximum investment amount.")
})

export const setLimitValidatorScheme = yup.object().shape({
	min_trade_amount: yup.string().required("Please enter a minimum investment amount."),
	max_trade_amount: yup.string().required("Please enter a maximum investment amount.")
})

export const merchantAccDeactivateValidatorScheme = yup.object().shape({
	password: yup.string().required("Your password is required").trim("Password never had empty spaces").min(8, "Passowrd had a minimum of 8 characters"),
	reason: yup.string().required("Please provide a reason for the account deactivation.")
})
