import { GET, POST, PUT, DROP } from "configs/api"
import { assetEndpoints } from "./asset.endpoints"

export const FetchBuyCointsAssets = async () => {
	return await GET(assetEndpoints.BUY_ASSET_COINS)
}

export const FetchSellCointsAssets = async () => {
	return await GET(assetEndpoints.SELL_ASSET_COINS)
}

export const FetchBuyRates = async (params: FormData | any) => {
	return await POST(assetEndpoints.BUY_RATES, params)
}

export const FetchSellRates = async (params: FormData | any) => {
	return await POST(assetEndpoints.SELL_RATES, params)
}

export const BuyAssets = async (params: FormData | any) => {
	return await POST(assetEndpoints.BUY_ASSET, params)
}

export const SellAssets = async (params: FormData | any) => {
	return await POST(assetEndpoints.SELL_ASSET, params)
}
