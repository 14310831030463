import { GET, POST, PUT, DROP } from "configs/api"
import { cashBridgeEndpoints } from "./cashbridge.endpoints"

export const InitiateDeposit = async (params: FormData | any) => {
	return await POST(cashBridgeEndpoints.deposit?.CREATE, params)
}

export const FetchDepositCharges = async (params: FormData | any) => {
	return await POST(cashBridgeEndpoints.deposit?.CHARGES, params)
}

export const CheckDepositStatus = async (params: FormData | any) => {
	return await POST(cashBridgeEndpoints.deposit?.LISTEN, params)
}

export const UploadDepositProof = async (params: FormData | any) => {
	return await POST(cashBridgeEndpoints.deposit?.UPLOAD_PROOF, params)
}

export const EscalateDepositDispute = async (params: FormData | any) => {
	return await POST(cashBridgeEndpoints.deposit?.ESCALATE, params)
}

export const InitiateWithdrawal = async (params: FormData | any) => {
	return await POST(cashBridgeEndpoints.withdrawal?.CREATE, params)
}

export const FetchWithdrawalCharges = async (params: FormData | any) => {
	return await POST(cashBridgeEndpoints.withdrawal?.CHARGES, params)
}

export const ProcessWithdrawal = async (params: FormData | any) => {
	return await POST(cashBridgeEndpoints.withdrawal?.CONTINUE, params)
}

export const CheckWithdrawalStatus = async (params: FormData | any) => {
	return await POST(cashBridgeEndpoints.withdrawal?.LISTEN, params)
}

export const ReleaseWithdrawalAsset = async (params: FormData | any) => {
	return await POST(cashBridgeEndpoints.withdrawal?.RELEASE, params)
}

export const CheckActiveWithdrawal = async () => {
	return await GET(cashBridgeEndpoints.withdrawal?.ACTIVE)
}

export const CheckActiveDeposit = async () => {
	return await GET(cashBridgeEndpoints.deposit?.ACTIVE)
}
