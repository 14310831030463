import CardNavigator from "components/CardNavigator"
import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"

export default function WalletReceive({ returnLink }: { returnLink: string }) {
	const { GiftImg } = useFlusStores()

	return (
		<CardNavigator returnLink={returnLink}>
			<section>
				<h3 className="mb-3">
					<img className="me-3" src={GiftImg} alt="" width="75" />
					<strong>Redeem Gift Code</strong>
				</h3>
				<p className="lead text-success">
					Enter your Voucher/Gift code number to acquire
					<br />
					the value into your wallet
				</p>
				<form className="mt-3">
					<div className="mb-3">
						<span className="text-muted mb-2 d-block">Gift Code Number</span>
						<input
							className="form-control form-control-lg bk-bg-light"
							type="text"
							placeholder="Enter 10 - character code"
						/>
						<small className="text-danger">This code is invalid...Check &amp; try again</small>
					</div>
					<div>
						<div className="form-group">
							<label className="form-label w-100 input-label text-muted">
								<strong>Passphrase</strong>
								<br />
							</label>
							<input
								className="form-control form-control-lg password-toggle-input"
								type="password"
								placeholder="Enter a passphrase"
								name="password"
							/>
							<i className="fas fa-eye-slash img-icon toggle-password"></i>
						</div>
						<span className="text-danger">
							Your passphrase can be a combination of letter and numbers
						</span>
						<ul className="list-inline d-flex mt-2">
							<li className="list-inline-item w-100">Balance</li>
							<li className="list-inline-item text-end w-100">0.00 NGN</li>
						</ul>
					</div>
					<div className="my-4">
						<button className="btn btn-primary btn-lg w-100 bk-navy-blue py-3" type="submit">
							<strong>Redeem Now</strong>
						</button>
						<span className="text-danger d-block text-center mt-1">
							Note: Once your code has being redeemed, <br />
							it cannot be reversed!!!
						</span>
					</div>
				</form>
			</section>
		</CardNavigator>
	)
}
