import PageInfo from "components/misc/Page"
import ShowSpinner from "components/misc/Spinner"
import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { useAuthApis } from "services/Api/auth/auth.apis.index"
import { useUrlQuery } from "services/utility/hooks/useURL"
import { ShowServerErrors, ShowSuccess } from "services/utility/Utils/_toaster"
import PasswordInput from "components/PasswordInput"

export default function ChangeVerificationEmail() {
	const { LOGO, VerificationImg } = useFlusStores()
	const navigate = useNavigate()

	const { email } = useUrlQuery()
	const { ChangeVerificationEmail } = useAuthApis()

	const changeVerificationEmailApi = useMutation(ChangeVerificationEmail, {
		onSuccess: (res, params: FormData) => {
			if (res?.status) {
				ShowSuccess("Verification", "Your verficiation email has been updated successfully!")

				navigate(`/auth/verify?email=${params.get("email")}`)
			} else ShowServerErrors("Verification", res)
		}
	})

	const isChanging = changeVerificationEmailApi.isLoading

	const handleUserEmailChanges = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		const formData = new FormData(e.target)

		changeVerificationEmailApi.mutate(formData)
	}

	return (
		<section>
			<PageInfo titleText="Change Verification Email" />
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-9 col-lg-12 col-xl-10">
						<div className="card o-hidden border-0 my-5">
							<div className="card-body p-0">
								<div className="row">
									<div className="col-lg-6 d-none d-lg-flex p-4 bg-light-blue">
										<div className="d-flex flex-grow-1 justify-content-center align-items-center auth-bg-image">
											<img src={VerificationImg} alt="" width="350" />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="p-5">
											<div className="text-center">
												<img src={LOGO} alt="" width="50" />
												<h4 className="text-dark mb-4 mt-3">
													Welcome to{" "}
													<span className="text-blue">
														<strong>Beekle</strong>
													</span>
												</h4>
											</div>
											<form className="user" onSubmit={handleUserEmailChanges}>
												<div className="form-group">
													<label className="form-label w-100 input-label">
														<strong>Enter a new verification email</strong>
														<br />
													</label>
													<input id="exampleInputPassword" className="form-control form-control-lg form-control-user" type="email" placeholder="Email" name="email" defaultValue={email} required />
												</div>

												<PasswordInput inputName="password" placeholder="Enter your registered password" />
												{/* <div className="border rounded p-1 my-3 bg-light-blue p-2">
													<div className="form-check my-2 w-100 d-block ">
														<input id="formCheck-1" className="form-check-input" type="checkbox" />
														<label className="form-check-label w-100 d-flex" htmlFor="formCheck-1">
															I am not a Robot
															<img
																className="d-block ms-auto"
																src={RecapchaImg}
																alt=""
																width="25"
															/>
														</label>
													</div>
												</div> */}
												<div className="row mt-4">
													<div className="col">
														<button onClick={() => window.history.back()} className="btn btn-primary btn-lg d-block login-btn w-100 bg-blue bg-outline text-muted" type="button">
															Exit
														</button>
													</div>
													<div className="col p-0">
														<button className="btn btn-primary btn-lg d-block login-btn w-100 bg-outline text-primary" type="submit" disabled={isChanging}>
															{!isChanging && "Change Email"}
															{isChanging && <ShowSpinner />}
														</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
