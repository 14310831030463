import React from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"

type pageInfoTypes = {
	/**
	 * The title SEO title to be displayed
	 */
	titleText?: string
	/**
	 * A connical url
	 */
	path?: string
	/**
	 * The site or page description
	 */
	desc?: string
	/**
	 * The application or site name
	 */
	siteName?: string
}
/** Page and window information */
export default function PageInfo({ titleText, path, desc, siteName }: pageInfoTypes) {
	return (
		<HelmetProvider>
			<Helmet prioritizeSeoTags>
				<title>
					{titleText && titleText} - {siteName ? siteName : "Beekle"}
				</title>
				{path && <link rel="canonical" href={path} />}

				{desc && (
					<>
						<meta name="twitter:description" content={desc} />
						<meta name="description" content={desc} />
					</>
				)}
			</Helmet>
		</HelmetProvider>
	)
}
