import PageInfo from "components/misc/Page"
import React from "react"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import LoginForm from "./LoginForm"

export default function LoginPage() {
	const { LoginImg, LOGO } = useFlusStores()

	return (
		<>
			<PageInfo titleText="Login" />
			<section>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-9 col-lg-12 col-xl-10">
							<div className="card o-hidden border-0 my-5">
								<div className="card-body p-0">
									<div className="row">
										<div className="col-lg-6 d-none d-lg-flex p-4 bg-light-blue">
											<div className="d-flex flex-grow-1 justify-content-center align-items-center auth-bg-image">
												<img src={LoginImg} alt="" width="350" />
											</div>
										</div>
										<div className="col-lg-6">
											<div className="p-5">
												<div className="text-center">
													<img src={LOGO} alt="" width="50" />
													<h4 className="text-dark mb-4 mt-3">
														Log In with{" "}
														<span className="text-blue">
															<strong>Beekle</strong>
														</span>
													</h4>
													<p className="text-start p-2 bg-light-blue text-blue">
														All fields are required to Log In
													</p>
												</div>
												<LoginForm />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
