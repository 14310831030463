
import React from "react"
import { sleep } from "services/utility/Utils/_sleep"
import { useFlusStores } from "@bilmapay/react-flux-storage"
import {
    ArrowUpIcon,
    ArrowRightIcon,

} from "@heroicons/react/24/outline";
import "../../morecss.css"
import { Link } from "react-router-dom";

const wedgeSrc: string = "https://crypto.com/price/static/widget/index.js"

function FirstSection() {

    const { firstsectionimage } = useFlusStores()

    React.useEffect(() => {
        const scriptTag = document.createElement("script")
        const bodyTag = document.querySelector<HTMLBodyElement>("body")

        sleep(3000).then(() => {
            scriptTag.src = wedgeSrc
            if (bodyTag) {
                bodyTag.append(scriptTag)
            }
        })
    })

    return (
        <section className="bg-white flex-wrap-reverse sm:space-x-4 sm:h-screen mb-6 pt-6 flex flex-row flex-wrap justify-center bg-fixed firstSectionBG bg-no-repeat">

            <div className="w-full sm:w-1/2  flex flex-column items-center sm:items-start pl-4 sm:pl-20">

                <p className=" mb-4 text-blue-700 text-5xl sm:text-7xl sm:w-[100%] tracking-wide sm:leading-tight font-link">Empower Your Finances with Crypto</p>

                <div className="w-full sm:w-2/3 mb-6">
                    <p className=" openFont forcegrey text-lg  ">Trade Bitcoin, Ethereum & more for boundless opportunities. </p>
                    <strong className="text-justify font-black text-lg openFont2 text-black  text-center ">Exchange your Gift Cards for instant Naira.</strong>
                </div>

                <Link
                    role="button"
                    to="/auth/signup" className="bg-blue-600  hover:bg-blue-700 items-center space-x-4 justify-center text-white w-120 h-12 openFont flex flex-row mt-4 font-bold py-2 px-4 rounded" >
                    Get Started <ArrowRightIcon className="ml-2 mt-[1px]" height={20} width={20} />
                </Link>

                {/* </div> */}
            </div>




            <div className="w-5/6 sm:w-2/5 ">
                <img className="img-fluid block m-auto" src={firstsectionimage} alt="" />
            </div>
            
        </section>
    )
}

export default FirstSection
